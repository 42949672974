import React, { useEffect, useState, useRef, lazy } from 'react';
import { Modal } from 'react-bootstrap';
// import './UserPreference.scss';
import styles from './UserPreference.module.scss';
import '../../../GlobalStyle.scss';
import AsyncSelect from 'react-select/async';

// import TableUserPreference from './TableUserPreference';
import UserPreferenceData from './UserPreferenceData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
// import InitialData from './SystemConfigData.json';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { deleteUserPreference, searchUserPreference, updateUserPreference } from './UserPreferenceService';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'User Preference', link: '' },
];

const TableUserPreference = lazy(() => import('./TableUserPreference'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

const UserPreference = () => {
  // const [stepError, setStepError] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    userId: '',
    kpi1: '',
    kpi2: '',
    kpi3: '',
    kpi4: '',
    kpi5: '',
    kpi6: '',
    defaultSearchGroupLevel: '',
    activeInd: true,
    userName: '',
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [UserPreferenceDetails, setUserPreferenceData] = useState(UserPreferenceData);
  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchUserPreferenceData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchUserPreference(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchUserPreferenceData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchUserPreference(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };

    deleteUserPreference(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchUserPreferenceData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('User Preference deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      userId: data.userId,

      kpi1: data.kpi1,
      kpi2: data.kpi2,
      kpi3: data.kpi3,
      kpi4: data.kpi4,
      kpi5: data.kpi5,
      kpi6: data.kpi6,
      defaultSearchGroupLevel: data.defaultSearchGroupLevel,
      activeInd: data.activeInd,
      id: data.id,
      userName: data.userName,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearchText(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchUserPreference(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newUserPreferenceData = JSON.parse(JSON.stringify(UserPreferenceDetails));
    newUserPreferenceData.forEach((itm) => {
      itm.sortType = '';
    });
    setUserPreferenceData(newUserPreferenceData);
    await fetchNewUserPreferenceData();
  };

  // Code needs to change
  const fetchNewUserPreferenceData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchUserPreference(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchUserPreference(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.userPreferencePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.userPreferencePageContent}>
        <h1 className={styles.pageTitle}>User Preference</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.userPreferenceTable, 'glbUserPreferenceTable'].join(' ')}>
          <TableUserPreference
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={UserPreferenceDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditUserPreference
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchUserPreferenceData={fetchUserPreferenceData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle="User Preference"
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
};

// START:- Create / Edit record modal with functionality
const CreateEditUserPreference = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  useEffect(() => {
    setisRequiredError(false);
  }, [props.formData]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      // width: '222px',
    }),
    menu: (base) => ({
      ...base,
      // width: '222px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      // minWidth: '222px',
    }),
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const loadFolderList = async (inputValue, callback) => {
    // if (inputValue === '') {
    // } else {
    let constData = [2, 3, 4, 5, 6, 7, 8, 9];
    // await getFolderList(inputValue).then(async (response1) => {
    let options = await constData.map((res) => {
      return { label: res, value: res };
    });

    callback(options);
    // });
    // }
  };

  const handleEdit = async () => {
    let payload = _.cloneDeep(props.formData);

    payload.kpi1 = payload?.kpi1?.trim();
    payload.kpi2 = payload?.kpi2?.trim();
    payload.kpi3 = payload?.kpi3?.trim();
    payload.kpi4 = payload?.kpi4?.trim();
    payload.kpi5 = payload?.kpi5?.trim();
    payload.kpi6 = payload?.kpi6?.trim();

    props.isLoading(true);

    await updateUserPreference(payload).then((response) => {
      if (response.data) {
        props.isLoading(false);
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('User Preference updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);
        props.fetchUserPreferenceData();
        props.setRecordModalShow(false);
      } else if (response.error) {
        props.isLoading(false);
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error in EditMetadata()');
      }
    });
    // }
  };
  const onChangeFolderLevel = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        defaultSearchGroupLevel: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      defaultSearchGroupLevel: updatedValue,
    }));
  };
  const firstInputRef = useRef(null);
  const kpi2InputRef = useRef(null);
  const kpi3InputRef = useRef(null);
  const kpi4InputRef = useRef(null);
  const kpi5InputRef = useRef(null);
  const kpi6InputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.userPreferenceModal, 'glbUserPreferenceModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>Edit User Preference</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.userPreferenceModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Username </label>
                <input required name="userName" value={props.formData.userName} onChange={handleInputChange} disabled />
                {/* {(props.formData.name === null || props.formData.name === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.name?.length > 255 && isRequiredError === true && handleMaxLimitError(255)} */}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>KPI_1</label>
                <input ref={firstInputRef} onFocus={() => handleFocus(firstInputRef)} required name="kpi1" value={props.formData.kpi1} onChange={handleInputChange} />
                {/* {(props.formData.codeName === null || props.formData.codeName === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.codeName?.length > 100 && isRequiredError === true && handleMaxLimitError(100)} */}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>KPI_2</label>
                <input ref={kpi2InputRef} onFocus={() => handleFocus(kpi2InputRef)} required name="kpi2" value={props.formData.kpi2} onChange={handleInputChange} />
                {/* {props.formData.description?.length > 500 && isRequiredError === true && handleMaxLimitError(500)} */}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>KPI_3</label>
                <input ref={kpi3InputRef} onFocus={() => handleFocus(kpi3InputRef)} required name="kpi3" value={props.formData.kpi3} onChange={handleInputChange} />
                {/* {props.formData.description?.length > 500 && isRequiredError === true && handleMaxLimitError(500)} */}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>KPI_4</label>
                <input ref={kpi4InputRef} onFocus={() => handleFocus(kpi4InputRef)} required name="kpi4" value={props.formData.kpi4} onChange={handleInputChange} />
                {/* {props.formData.description?.length > 500 && isRequiredError === true && handleMaxLimitError(500)} */}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>KPI_5</label>
                <input ref={kpi5InputRef} onFocus={() => handleFocus(kpi5InputRef)} required name="kpi5" value={props.formData.kpi5} onChange={handleInputChange} />
                {/* {props.formData.description?.length > 500 && isRequiredError === true && handleMaxLimitError(500)} */}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>KPI_6</label>
                <input ref={kpi6InputRef} onFocus={() => handleFocus(kpi6InputRef)} required name="kpi6" value={props.formData.kpi6} onChange={handleInputChange} />
                {/* {props.formData.description?.length > 500 && isRequiredError === true && handleMaxLimitError(500)} */}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Default Search Folder Level</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadFolderList}
                  value={
                    props?.formData?.defaultSearchGroupLevel !== '' ? { label: props?.formData?.defaultSearchGroupLevel, value: props?.formData?.defaultSearchGroupLevel } : null
                  }
                  onChange={onChangeFolderLevel}
                />
                {/* {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)} */}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div className={styles.radioInputOuterDiv}>
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="active_Ind" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                    <input type="radio" name="active_Ind" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.userPreferenceFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
export default UserPreference;
