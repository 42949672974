import React, { useEffect, useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ObjectDefinitionTable.module.scss';
import '../../../GlobalStyle.scss';

import ObjectDefinitionData from './ObjectDefinitionTableData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { searchUsers, searchWorkgroups } from '../../../services/inBasketService';
import AsyncSelect from 'react-select/async';
import {
  createObjectDefinition,
  updateOneObjectDefinition,
  deleteObjectDefinition,
  searchObjectDefinition,
  getObjectIds,
  getObjectNames,
  getObject,
} from './ObjectDefinitionTableService';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';

const TableObjectDefinition = lazy(() => import('./TableObjectDefinitionTable'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Object Definition', link: '' },
];

const objectElementTypeData = ['Container', 'Element', 'Element Data', 'Object', 'Page', 'Sub Object'];

export default function ObjectDefinitionTable() {
  const glbContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [objectDefinitionDetails, setObjectDefinitionData] = useState(ObjectDefinitionData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    activeInd: true,
    contextId: glbContextId,
    description: '',
    buowName: '',
    object: '',
    buowObjectOwner: '',
    buowObjectOwnerName: '',
    buowObjectScrubberUserOrWorkgroup: '',
    buowObjectScrubberUserOrWorkgroupId: '',
    buowObjectScrubberUserOrWorkgroupName: '',
    buowObjectManagerUserOrWorkgroup: '',
    buowObjectManagerUserOrWorkgroupId: '',
    buowObjectManagerUserOrWorkgroupName: '',
    buowObjectSupervisorUserOrWorkgroupName: '',
    buowObjectSupervisorUserOrWorkgroupId: '',
    buowObjectSupervisorUserOrWorkgroup: '',
    redactable: false,
  });

  async function fetchObjectDefinitionData() {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchObjectDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchObjectDefinitionData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: '',
      order: '',
      contextId: glbContextId,
    };
    setSorting({ column: '', order: '' });
    await searchObjectDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchObjectDefinition(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      activeInd: true,
      contextId: glbContextId,
      description: '',
      buowName: '',
      object: '',
      buowObjectOwner: '',
      buowObjectOwnerName: '',
      buowObjectScrubberUserOrWorkgroup: '',
      buowObjectScrubberUserOrWorkgroupId: '',
      buowObjectScrubberUserOrWorkgroupName: '',
      buowObjectManagerUserOrWorkgroup: '',
      buowObjectManagerUserOrWorkgroupId: '',
      buowObjectManagerUserOrWorkgroupName: '',
      buowObjectSupervisorUserOrWorkgroupName: '',
      buowObjectSupervisorUserOrWorkgroupId: '',
      buowObjectSupervisorUserOrWorkgroup: '',
      redactable: false,
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      activeInd: data.activeInd,
      contextId: data.contextId,
      description: data.description,
      buowName: data.buowName,
      object: data.object,
      buowObjectOwner: data.buowObjectOwner,
      buowObjectOwnerName: data.buowObjectOwnerName,
      buowObjectScrubberUserOrWorkgroup: data.buowObjectScrubberUserOrWorkgroup,
      buowObjectScrubberUserOrWorkgroupId: data.buowObjectScrubberUserOrWorkgroupId,
      buowObjectScrubberUserOrWorkgroupName: data.buowObjectScrubberUserOrWorkgroupName,
      buowObjectManagerUserOrWorkgroup: data.buowObjectManagerUserOrWorkgroup,
      buowObjectManagerUserOrWorkgroupId: data.buowObjectManagerUserOrWorkgroupId,
      buowObjectManagerUserOrWorkgroupName: data.buowObjectManagerUserOrWorkgroupName,
      buowObjectSupervisorUserOrWorkgroup: data.buowObjectSupervisorUserOrWorkgroup,
      buowObjectSupervisorUserOrWorkgroupId: data.buowObjectSupervisorUserOrWorkgroupId,
      buowObjectSupervisorUserOrWorkgroupName: data.buowObjectSupervisorUserOrWorkgroupName,
      redactable: data.redactable,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewObjectDefinitionData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(objectDefinitionDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setObjectDefinitionData(newRoutingStepsData);
  };

  // Code needs to change
  const fetchNewObjectDefinitionData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: glbContextId,
    };
    await searchObjectDefinition(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
      contextId: glbContextId,
    };
    await searchObjectDefinition(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      buowName: DeleteData.buowName,
      contextId: DeleteData.contextId,
      object: DeleteData.object,
    };
    deleteObjectDefinition(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Object Definition deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="objectDefinition-container" className={styles.objectDefinitionPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="objectDefinition-page-wrapper" className={styles.objectDefinitionPageContent}>
        <h1 data-testid="objectDefinition-heading" className={styles.pageTitle}>
          Object Definition
        </h1>
        <div>
          <div data-testid="objectDefinition-search-container" className={styles.searchSection}>
            <div data-testid="objectDefinition-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="objectDefinition-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-objectDefinition'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="objectDefinition-create-btn-container">
                <CustomButton testId={'create-objectDefinition-btn'} title="Create Object Definition" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="objectDefinition-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="objectDefinition-table-container" className={[styles.objectDefinitionTable, 'glbObjectDefinitionTable'].join(' ')}>
          <TableObjectDefinition
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={objectDefinitionDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <AddEditObjectDefinitionModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchObjectDefinitionData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Object Definition'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditObjectDefinitionModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  const [objectIds, setObjectIds] = useState([]);
  const [UserList, setUserList] = useState(['U', 'W']);

  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  useEffect(() => {
    fetchObjectIds();
  }, []);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      // width: '222px',
    }),
    menu: (base) => ({
      ...base,
      // width: '222px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      // minWidth: '222px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      width: '73%',
    }),
  };

  const fetchObjectIds = async () => {
    let payload = {
      contextId: JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };

    await getObjectIds(payload).then((response) => {
      setObjectIds(response.data);
    });
  };

  const loadBuowObjectOwner = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await searchUsers(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.userName, value: res.userId };
        });

        callback(options);
      });
    }
  };

  const loadBuowObjectScrubber = async (inputValue, callback) => {
    if (inputValue === '') {
    } else if (props.formData.buowObjectScrubberUserOrWorkgroup === 'W') {
      await searchWorkgroups(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.workgroupDisplayName, value: res.workgroupId };
        });

        callback(options);
      });
    } else if (props.formData.buowObjectScrubberUserOrWorkgroup === 'U') {
      await searchUsers(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.userName, value: res.userId };
        });

        callback(options);
      });
    }
  };
  const loadObjectManager = async (inputValue, callback) => {
    if (inputValue === '') {
    } else if (props.formData.buowObjectManagerUserOrWorkgroup === 'W') {
      await searchWorkgroups(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.workgroupDisplayName, value: res.workgroupId };
        });

        callback(options);
      });
    } else if (props.formData.buowObjectManagerUserOrWorkgroup === 'U') {
      await searchUsers(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.userName, value: res.userId };
        });

        // setSearchData(options);
        callback(options);
      });
    }
  };

  const loadBuowObjectSuperwiser = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchData([]);
    } else if (props.formData.buowObjectSupervisorUserOrWorkgroup === 'W') {
      await searchWorkgroups(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.workgroupDisplayName, value: res.workgroupId };
        });

        // setSearchData(options);
        callback(options);
      });
    } else if (props.formData.buowObjectSupervisorUserOrWorkgroup === 'U') {
      await searchUsers(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.userName, value: res.userId };
        });

        // setSearchData(options);
        callback(options);
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const handleSubmit = async (data) => {
    if (!data.buowName || !data.object) setisRequiredError(true);
    else {
      let payload = _.cloneDeep(data);

      payload.description = payload?.description?.trim();
      payload.buowName = payload?.buowName?.trim();
      payload.object = payload?.object?.trim();

      delete payload.buowObjectManagerUserOrWorkgroupName;
      delete payload.buowObjectScrubberUserOrWorkgroupName;
      delete payload.buowObjectSupervisorUserOrWorkgroupName;
      delete payload.buowObjectOwnerName;

      props.isLoading(true);
      await createObjectDefinition(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Object Definition created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchObjectDefinitionData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.buowName || !data.object) setisRequiredError(true);
    else {
      let payload = _.cloneDeep(data);

      payload.description = payload?.description?.trim();
      payload.buowName = payload?.buowName?.trim();
      payload.object = payload?.object?.trim();

      delete payload.buowObjectManagerUserOrWorkgroupName;
      delete payload.buowObjectScrubberUserOrWorkgroupName;
      delete payload.buowObjectSupervisorUserOrWorkgroupName;
      delete payload.buowObjectOwnerName;
      props.isLoading(true);

      await updateOneObjectDefinition(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Object Definition updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchObjectDefinitionData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value, label } = event;
    const updatedValue = value === '' ? null : value;
    const updatedLabel = label === '' ? null : label;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };
  const onChangeWorkgroupList = (event, name, idName, idValue) => {
    if (event == null) {
      props.setFormData((prevData) => ({
        ...prevData,
        [idName]: '',
        [idValue]: '',
      }));
      return props.setFormData((prevData) => ({
        ...prevData,
        [name]: '',
      }));
    }
    const { label, value } = event;
    const updatedLabel = label === '' ? null : label;

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: updatedLabel,
    }));
    props.setFormData((prevData) => ({
      ...prevData,
      [idName]: '',
      [idValue]: '',
    }));
  };

  const onChangeWorkgroupIdList = (event, name, id) => {
    if (event == null) {
      props.setFormData((prevData) => ({
        ...prevData,
        [name]: '',
        [id]: '',
      }));
      return props.setFormData((prevData) => ({
        ...prevData,
        [name]: '',
      }));
    }
    const { label, value } = event;
    const updatedLabel = label === '' ? null : label;
    const updatedValue = value === '' ? null : value;

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: updatedLabel,
      [id]: updatedValue,
    }));
  };
  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
    setisRequiredError(false);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
    setisRequiredError(false);
  };

  const loadObject = async (inputValue, callback) => {
    // if (props.isNew) {
    if (inputValue?.trim() === '') {
      callback([]);
      // await getObject(inputValue).then(async (response) => {
      //   let options = [];
      //   await response.data.map((res) => {
      //     options.push({ label: res.name, value: res.name });

      //     setTimeout(() => {
      //       callback(options);
      //     }, 1000);
      //   });

      //   callback(options);
      // });
    } else {
      await getObject(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.name, value: res.name });
        });

        callback(options);
      });
    }
  };
  const onChangeObject = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      object: selectedOptions.label,
    }));
  };

  const firstInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const buowObjectOwnerInputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.objectDefinitionModal, 'glbObjectDefinitionModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Object Definition' : 'Edit Object Definition'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.objectDefinitionModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {' '}
              {props.isNew === false && (
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context ID *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    // loadOptions={loadObjectsList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                    isSearchable={false}
                    defaultOptions
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                </div>
              )}
              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Name *</label>
                <input
                  required
                  name="buowName"
                  value={props.formData.buowName}
                  onChange={handleInputChange}
                  disabled={!props.isNew}
                  className={props.isNew ? '' : styles.disabledInput}
                  ref={props.isNew === true ? firstInputRef : null}
                  onFocus={() => handleFocus(firstInputRef)}
                />
                {(props.formData.buowName === null || props.formData.buowName === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Object *</label>
                {/* <input
                  required
                  name="object"
                  value={props.formData.object}
                  onChange={handleInputChange}
                  disabled={!props.isNew}
                  className={props.isNew ? '' : styles.disabledInput}
                  title={props.formData.object}
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                /> */}
                <AsyncSelect
                  // isMulti
                  styles={customStyles}
                  isClearable
                  loadOptions={loadObject}
                  isSearchable={true}
                  // value={props?.formData?.buowName?.map((name) => ({
                  //   label: name,
                  //   value: name,
                  // }))}
                  isDisabled={!props.isNew}
                  value={
                    props?.formData?.object !== '' && props?.formData?.object !== undefined && props?.formData?.object !== null
                      ? { label: props?.formData?.object, value: props?.formData?.object }
                      : { label: '---Select---' }
                  }
                  onChange={onChangeObject}
                />
                {(props.formData.object === null || props.formData.object === '') && isRequiredError === true && handleIsRequiredError()}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Object Owner </label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadBuowObjectOwner}
                  ref={props.isNew === false ? firstInputRef : buowObjectOwnerInputRef}
                  onFocus={() => handleFocus(buowObjectOwnerInputRef)}
                  value={
                    props?.formData?.buowObjectOwner !== '' || props?.formData?.buowObjectOwner !== null
                      ? { label: props?.formData?.buowObjectOwnerName, value: props?.formData?.buowObjectOwner }
                      : 'Select...'
                  }
                  onChange={(event) => onChangeWorkgroupIdList(event, 'buowObjectOwnerName', 'buowObjectOwner')}
                  defaultOptions
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Object Scrubber User or Workgroup</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable={false}
                  loadOptions={(inputValue, callback) => {
                    if (inputValue === '') {
                      callback([]);
                      let options = [];
                      UserList.map((res) => {
                        options.push({ label: res, value: res });
                        setTimeout(() => {
                          callback(options);
                        }, 1000);
                      });
                      callback(options);
                    } else {
                      let options = UserList.map((res) => {
                        return { label: res, value: res };
                      });
                      callback(options);
                    }
                  }}
                  value={
                    props?.formData?.buowObjectScrubberUserOrWorkgroup !== '' || props?.formData?.buowObjectScrubberUserOrWorkgroup !== null
                      ? { label: props?.formData?.buowObjectScrubberUserOrWorkgroup, value: props?.formData?.buowObjectScrubberUserOrWorkgroup }
                      : 'Select...'
                  }
                  onChange={(event) =>
                    onChangeWorkgroupList(event, 'buowObjectScrubberUserOrWorkgroup', 'buowObjectScrubberUserOrWorkgroupName', 'buowObjectScrubberUserOrWorkgroupId')
                  }
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Object Scrubber User or WorkgroupID</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadBuowObjectScrubber}
                  value={
                    props?.formData?.buowObjectScrubberUserOrWorkgroupId !== '' || props?.formData?.buowObjectScrubberUserOrWorkgroupId !== null
                      ? { label: props?.formData?.buowObjectScrubberUserOrWorkgroupName, value: props?.formData?.buowObjectScrubberUserOrWorkgroupId }
                      : 'Select...'
                  }
                  onChange={(event) => onChangeWorkgroupIdList(event, 'buowObjectScrubberUserOrWorkgroupName', 'buowObjectScrubberUserOrWorkgroupId')}

                  // isDisabled={props.isNew ? false : true}
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Object Manager User or Workgroup</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable={false}
                  loadOptions={(inputValue, callback) => {
                    if (inputValue === '') {
                      callback([]);
                      let options = [];
                      UserList.map((res) => {
                        options.push({ label: res, value: res });
                        setTimeout(() => {
                          callback(options);
                        }, 1000);
                      });
                      callback(options);
                    } else {
                      let options = UserList.map((res) => {
                        return { label: res, value: res };
                      });
                      callback(options);
                    }
                  }}
                  value={
                    props?.formData?.buowObjectManagerUserOrWorkgroup !== '' || props?.formData?.buowObjectManagerUserOrWorkgroup !== null
                      ? { label: props?.formData?.buowObjectManagerUserOrWorkgroup, value: props?.formData?.buowObjectManagerUserOrWorkgroup }
                      : 'Select...'
                  }
                  onChange={(event) =>
                    onChangeWorkgroupList(event, 'buowObjectManagerUserOrWorkgroup', 'buowObjectManagerUserOrWorkgroupName', 'buowObjectManagerUserOrWorkgroupId')
                  }
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Object Manager User or WorkgroupID</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadObjectManager}
                  value={
                    props?.formData?.buowObjectManagerUserOrWorkgroupId !== '' || props?.formData?.buowObjectManagerUserOrWorkgroupId !== null
                      ? { label: props?.formData?.buowObjectManagerUserOrWorkgroupName, value: props?.formData?.buowObjectManagerUserOrWorkgroupId }
                      : 'Select...'
                  }
                  onChange={(event) => onChangeWorkgroupIdList(event, 'buowObjectManagerUserOrWorkgroupName', 'buowObjectManagerUserOrWorkgroupId')}

                  // isDisabled={props.isNew ? false : true}
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Object Supervisor User or Workgroup</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable={false}
                  loadOptions={(inputValue, callback) => {
                    if (inputValue === '') {
                      callback([]);
                      let options = [];
                      UserList.map((res) => {
                        options.push({ label: res, value: res });
                        setTimeout(() => {
                          callback(options);
                        }, 1000);
                      });
                      callback(options);
                    } else {
                      let options = UserList.map((res) => {
                        return { label: res, value: res };
                      });
                      callback(options);
                    }
                  }}
                  value={
                    props?.formData?.buowObjectSupervisorUserOrWorkgroup !== '' || props?.formData?.buowObjectSupervisorUserOrWorkgroup !== null
                      ? { label: props?.formData?.buowObjectSupervisorUserOrWorkgroup, value: props?.formData?.buowObjectSupervisorUserOrWorkgroup }
                      : 'Select...'
                  }
                  onChange={(event) =>
                    onChangeWorkgroupList(event, 'buowObjectSupervisorUserOrWorkgroup', 'buowObjectSupervisorUserOrWorkgroupName', 'buowObjectSupervisorUserOrWorkgroupId')
                  }
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Object Supervisor User or WorkgroupID</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadBuowObjectSuperwiser}
                  value={
                    props?.formData?.buowObjectSupervisorUserOrWorkgroupId !== '' || props?.formData?.buowObjectSupervisorUserOrWorkgroupId !== null
                      ? { label: props?.formData?.buowObjectSupervisorUserOrWorkgroupName, value: props?.formData?.buowObjectSupervisorUserOrWorkgroupId }
                      : 'Select...'
                  }
                  onChange={(event) => onChangeWorkgroupIdList(event, 'buowObjectSupervisorUserOrWorkgroupName', 'buowObjectSupervisorUserOrWorkgroupId')}
                  // isDisabled={props.isNew ? false : true}
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Active Ind: <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                </div>
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Redactable: <input type="radio" name="redactable" value={true} checked={props.formData.redactable === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="redactable" value={false} checked={props.formData.redactable === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Description</label>
                <textarea ref={descriptionInputRef} onFocus={() => handleFocus(descriptionInputRef)} name="description" required value={props.formData.description} onChange={handleInputChange} type="text" />
                {props.formData.description.length > 500 && handleMaxLimitError(500)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.objectDefinitionFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
