import React, { useRef, useEffect, useState, lazy } from 'react';

import styles from './UnitOfWork.module.scss';
import '../../../GlobalStyle.scss';
import {
  searchUnitOfWork,
  deleteUnitOfWork,
  getBuowName,
  getDefaultBuowName,
  createUnitOfWork,
  updateUnitOfWork,
  getObjectType,
  getObjectTypeList,
  getObject,
} from './UnitOfWorkService';
import { Modal } from 'react-bootstrap';
import UnitOfWorkData from './UnitOfWorkData.json';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
import { handleIsRequiredError } from '../../../utils/helper/handleReuiredErrorFunc.js';

const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Unit of Work', link: '' },
];

const UnitOfWorkTable = lazy(() => import('./UnitOfWorkTable'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function UnitOfWork() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [unitOfWorkDetails, setUnitOfWorkData] = useState(UnitOfWorkData);
  const [inputDisabled, setInputDisabled] = useState(false);
  const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

  const [formData, setFormData] = useState({
    buow: '',
    unitOfWork: '',
    classificationCriteria: '',
    contextId: userInformation?.contextId,
    objectType: '',
    activeInd: true,
  });

  const onRemoveSort = async () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    setLoading(true);
    let payload = {
      contextId: userInformation?.contextId,
      text: searchKeyword,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));
    setLoading(true);
    // setInputDisabled(true);
    let payload = {
      contextId: userInformation?.contextId,
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
    };
    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);
      // setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      buow: '',
      unitOfWork: '',
      classificationCriteria: '',
      contextId: userInformation?.contextId,
      objectType: '',
      activeInd: true,
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      id: data.id,
      buow: data?.buow?.trim(),
      unitOfWork: data?.unitOfWork?.trim(),
      classificationCriteria: data?.classificationCriteria?.trim(),
      contextId: data?.contextId,
      objectType: data?.objectType?.trim(),
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewUnitOfWorkData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newUnitOfWorkData = JSON.parse(JSON.stringify(unitOfWorkDetails));
    newUnitOfWorkData.forEach((itm) => {
      itm.sortType = '';
    });
    setUnitOfWorkData(newUnitOfWorkData);
    await fetchNewUnitOfWorkData();
  };

  // Code needs to change
  const fetchNewUnitOfWorkData = async () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    let payload = {
      contextId: userInformation?.contextId,
      text: '',
      column: '',
      order: '',
    };
    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      contextId: userInformation?.contextId,
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
    };
    await searchUnitOfWork(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
      buow: DeleteData.buow,
      unitOfWork: DeleteData.unitOfWork,
      classificationCriteria: DeleteData.classificationCriteria,
      contextId: DeleteData.contextId,
    };
    deleteUnitOfWork(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Unit of Work deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div className={styles.unitOfWorkPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div className={styles.unitOfWorkPageContent}>
        <h1 className={styles.pageTitle}>Unit of Work</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Unit of Work" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle">Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.tableUnitOfWork, 'glbTableUnitOfWork'].join(' ')}>
          <UnitOfWorkTable
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={unitOfWorkDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <AddEditUnitOfWorkModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchUnitOfWorkData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Unit of Work'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
    </div>
  );
}

const AddEditUnitOfWorkModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);

  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      unitOfWork: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      width: '72%',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color:'black'
    }),
  };

  const handleInputChange = (event) => {
    console.log('event', event);
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };
  const loadBuowNames = async (inputValue, callback) => {
    if (inputValue === '') {
      await getDefaultBuowName(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.buowName, value: res.buowName });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      await getBuowName(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          console.log('getBuowName', res);
          return { label: res.buowName, value: res.buowName };
        });
        callback(options);
      });
    }
  };
  const loadObjectTypeList = async (inputValue, callback) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    if (inputValue === '') {
      let payload = {
        contextId: JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
        searchKeyword: inputValue,
      };
      await getObjectTypeList(payload).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.objectType, value: res.objectType });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      let payload = {
        contextId: JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
        searchKeyword: inputValue,
      };
      await getObjectType(payload).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.objectType, value: res.objectType };
        });
        callback(options);
      });
    }
  };

  const handleSubmit = async (data) => {
    if (!data.buow || !data.unitOfWork || !data.objectType || !data.contextId) {
      setisRequiredError(true);
    } else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);
      payload.unitOfWork = payload?.unitOfWork?.trim();
      payload.classificationCriteria = payload?.classificationCriteria?.trim();
      payload.objectType = payload?.objectType?.trim();

      await createUnitOfWork(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Unit of Work created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchUnitOfWorkData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.buow || !data.unitOfWork || !data.objectType || !data.contextId) {
      setisRequiredError(true);
      return;
    } else {
      setisRequiredError(false);
      let payload = _.cloneDeep(data);
      payload.unitOfWork = payload?.unitOfWork?.trim();
      payload.classificationCriteria = payload?.classificationCriteria?.trim();
      payload.objectType = payload?.objectType?.trim();
      props.isLoading(true);

      await updateUnitOfWork(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Unit of Work Updated Successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchUnitOfWorkData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const onChangeBuowName = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        buow: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      buow: updatedValue,
    }));
  };
  const onChangeObjectType = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        objectType: '',
      }));
    const { value, label } = event;
    const updatedValue = value === '' ? null : value;
    const updatedLabel = label === '' ? null : label;
    props.setFormData((prevData) => ({
      ...prevData,
      objectType: updatedLabel,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };
  const loadObject = async (inputValue, callback) => {
    // if (props.isNew) {
    if (inputValue?.trim() === '') {
      callback([]);
      // await getObject(inputValue).then(async (response) => {
      //   let options = [];
      //   await response.data.map((res) => {
      //     const optionExists = options.some(
      //       (option) =>
      //         option.value?.toLowerCase()?.trim() === res.objectType?.toLowerCase()?.trim() || option.label?.toLowerCase()?.trim() === res.objectType?.toLowerCase()?.trim()
      //     );

      //     if (!optionExists) {
      //       options.push({ label: res.name, value: res.name });
      //     }

      //     setTimeout(() => {
      //       callback(options);
      //     }, 1000);
      //   });

      //   callback(options);
      // });
    } else {
      await getObject(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          const optionExists = options.some(
            (option) =>
              option.value?.toLowerCase()?.trim() === res.objectType?.toLowerCase()?.trim() || option.label?.toLowerCase()?.trim() === res.objectType?.toLowerCase()?.trim()
          );

          if (!optionExists) {
            options.push({ label: res.name, value: res.name });
          }
        });

        callback(options);
      });
    }
  };

  const onChangeObject = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      objectType: selectedOptions.label,
    }));
  };
  const firstInputRef = useRef(null);
  const classificationCriteriaInputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.unitOfWorkModal, 'glbUnitOfWorkModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Unit of Work' : 'Edit Unit of Work'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.unitOfWorkModalBody}>
          <div className={styles.modalBodySection}>
            {!props.isNew && (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context Id *</label>
                  <input name="Context Id *" required disabled value={props?.formData?.contextId} onChange={onChangeContextId} />
                </div>
              </div>
            )}
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Unit of Work *</label>
                <input ref={firstInputRef} onFocus={() => handleFocus(firstInputRef)} name="unitOfWork" required value={props.formData.unitOfWork} onChange={handleInputChange} />
                {(props.formData.unitOfWork === null || props.formData.unitOfWork === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Classification Criteria</label>
                <input ref={classificationCriteriaInputRef} onFocus={() => handleFocus(classificationCriteriaInputRef)} name="classificationCriteria" required value={props.formData.classificationCriteria} onChange={handleInputChange} />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Object Type *</label>
                <AsyncSelect
                  // isMulti
                  styles={customStyles}
                  isClearable
                  loadOptions={loadObject}
                  isSearchable={true}
                  // value={props?.formData?.buowName?.map((name) => ({
                  //   label: name,
                  //   value: name,
                  // }))}

                  value={
                    props?.formData?.objectType !== '' && props?.formData?.objectType !== undefined && props?.formData?.objectType !== null
                      ? { label: props?.formData?.objectType, value: props?.formData?.objectType }
                      : { label: '---Select---' }
                  }
                  onChange={onChangeObject}
                />
                {/* <input name="objectType" required value={props.formData.objectType} onChange={handleInputChange} /> */}

                {(props.formData.objectType === null || props.formData.objectType === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
              <div className={styles.inputFieldInternalDivCreate}>
                <label>BUOW *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadBuowNames}
                  value={props?.formData?.buow !== '' ? { label: props?.formData?.buow, value: props?.formData?.buow } : null}
                  onChange={onChangeBuowName}
                  defaultOptions
                />
                {(props.formData.buow === null || props.formData.buow === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6 pl-0'].join(' ')}>
                <div className={[styles.radioInputOuterDiv, 'mt-1'].join(' ')} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.unitOfWorkFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
