import React, { useEffect,useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
// import './Orchestration.scss';
import styles from './Orchestration.module.scss';
import '../../../GlobalStyle.scss';

// import TableOrchestration from './TableOrchestration';
import OrchestrationData from './OrchestrationData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { searchUsers, searchWorkgroups } from '../../../services/inBasketService';
import AsyncSelect from 'react-select/async';
import { createRoutingOrchestration, updateOneRoutingOrchestration, deleteRoutingOrchestration, searchRoutingOrchestration, getConditionalIdValue } from './OrchestrationService';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';
import { getBuow } from '../BUOW/BuowService.js';

const TableOrchestration = lazy(() => import('./TableOrchestration'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));
// const { DeleteModal } = lazy(() => import('../../../newcomponents/DeletePopup/DeleteModal'));

const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Routing Orchestration', link: '' },
];
export default function Orchestration() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [orchestrationDetails, setOrchestrationData] = useState(OrchestrationData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    conditional: false,
    conditionId: '',
    activeInd: true,
    buowName: '',
    calculatedContentId: '',
    contextId: '',
    object: '',
    parSeq: '',
    step: '',
    stepName: '',
    targetUserOrWorkgroup: '',
    userOrWorkgroupId: '',
    id: '',
  });

  async function fetchRoutingOrchestrationData() {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
    };
    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    // fetchRoutingOrchestrationData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
    };
    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      conditional: false,
      activeInd: true,
      buowName: '',
      conditionId: '',
      calculatedContentId: '',
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      object: '',
      parSeq: '',
      step: '',
      stepName: '',
      targetUserOrWorkgroup: '',
      userOrWorkgroupId: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      conditional: data.conditional,
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
      buowName: data.buowName,
      ...(data.conditionId && {
        conditionId: {
          label: data.conditionId,
          value: data.conditionId,
        },
      }), // Store conditionId only if it contains data
      calculatedContentId: data.calculatedContentId,
      contextId: data.contextId,
      object: data.object,
      parSeq: data.parSeq,
      step: data.step,
      stepName: data.stepName,
      targetUserOrWorkgroup: data.targetUserOrWorkgroup,
      // userOrWorkgroupId: data.targetUserOrWorkgroup === 'W'
      // ? { label: data?.workgroup?.workgroupDisplaName, value: data?.workgroup?.workgroupDisplayName }
      // : { label: data?.user?.userName, value: data?.user?.userName },
      userOrWorkgroupId: { label: data?.userOrWorkgroupName, value: data?.userOrWorkgroupId },
      id: data.id,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewRoutingOrchestrationData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(orchestrationDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setOrchestrationData(newRoutingStepsData);
  };

  // Code needs to change
  const fetchNewRoutingOrchestrationData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
    };
    await searchRoutingOrchestration(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  // const handleSearch = async (e) => {
  //   const { value } = e.target;
  //   setSearchKeyword(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };
    deleteRoutingOrchestration(sendData).then(async (response) => {
      if (response) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Routing Orchestration deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="orchestration-container" className={styles.orchestrationPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="orchestration-page-wrapper" className={styles.orchestrationPageContent}>
        <h1 data-testid="orchestration-heading" className={styles.pageTitle}>
          Routing Orchestration
        </h1>
        <div>
          <div data-testid="orchestration-search-container" className={styles.searchSection}>
            <div data-testid="orchestration-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="orchestration-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-orchestration'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="orchestration-create-btn-container">
                <CustomButton testId={'create-orchestration-btn'} title="Create Routing Orchestration" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="orchestration-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="orchestration-table-container" className={[styles.orchestrationTable, 'glbOrchestrationTable'].join(' ')}>
          <TableOrchestration
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={orchestrationDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <AddEditRoutingOrchModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchRoutingOrchestrationData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Routing Orchestration'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditRoutingOrchModal = (props) => {
  // const [searchData, setSearchData] = useState([]);
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  // const [searchDataBUOW, setSearchDataBUOW] = useState([]);
  const [selectedBuow, setSelectedBuow] = useState(null);

  useEffect(() => {
    setisRequiredError(false);
  }, [props.formData]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      // width: '222px',
    }),
    menu: (base) => ({
      ...base,
      // width: '222px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      // minWidth: '222px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };

  const handleChangeBuow = async (selectedOption) => {
    // setSelectedBuow(selectedOption);
    props.setFormData((prevData) => ({
      ...prevData,
      buowName: selectedOption.value,
    }));
  };
  const loadBuowOptions = async (inputValue, callback) => {
    if (inputValue === '') {
      await getBuow(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.buowName, value: res.buowName };
        });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
    } else {
      await getBuow(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.buowName, value: res.buowName };
        });
        callback(options);
      });
    }
  };
  const loadOptions = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchData([]);
    } else if (props.formData.targetUserOrWorkgroup === 'W') {
      await searchWorkgroups(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.workgroupDisplayName, value: res.workgroupId };
        });

        // setSearchData(options);
        callback(options);
      });
    } else if (props.formData.targetUserOrWorkgroup === 'C') {
    } else {
      await searchUsers(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.userName, value: res.userId };
        });

        // setSearchData(options);
        callback(options);
      });
    }
  };

  const loadConditionalIdOptions = async (inputValue, callback) => {
    if (inputValue === '') {
      await getConditionalIdValue(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.conditionId, value: res.conditionId };
        });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
    } else {
      await getConditionalIdValue(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.conditionId, value: res.conditionId };
        });
        callback(options);
      });
    }
  };

  const handleDropdownChange = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        userOrWorkgroupId: '',
      }));
    props.setFormData((prevData) => ({
      ...prevData,
      userOrWorkgroupId: event,
    }));
  };

  const handleConditionalIdDropdownChange = (event) => {
    props.setFormData((prevData) => ({
      ...prevData,
      conditionId: event,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const handleConditionalRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      conditional: booleanValue,
    }));
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === 'targetUserOrWorkgroup') {
      setIsUserSelected(false);
      props.setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        userOrWorkgroupId: '',
      }));
    } else {
      props.setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };

  const handleSubmit = async (data) => {
    if (
      !data.contextId ||
      !data.buowName ||
      !data.object ||
      !data.step ||
      !data.targetUserOrWorkgroup ||
      !data.userOrWorkgroupId ||
      data.contextId.length > 50 ||
      data.buowName.length > 35
    )
      setisRequiredError(true);
    else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);

      payload.calculatedContentId = payload?.calculatedContentId?.trim();
      payload.object = payload?.object?.trim();

      if (payload.userOrWorkgroupId !== '' && payload.userOrWorkgroupId !== null) payload.userOrWorkgroupId = payload.userOrWorkgroupId.value;
      if (payload.conditionId !== '' && payload.conditionId !== null) payload.conditionId = payload.conditionId.value;
      await createRoutingOrchestration(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Routing Orchestration created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchRoutingOrchestrationData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (
      !data.contextId ||
      !data.buowName ||
      !data.object ||
      !data.step ||
      !data.targetUserOrWorkgroup ||
      !data.userOrWorkgroupId ||
      data.contextId.length > 50 ||
      data.buowName.length > 35
    )
      setisRequiredError(true);
    else {
      let payload = _.cloneDeep(data);

      payload.calculatedContentId = payload?.calculatedContentId?.trim();
      payload.object = payload?.object?.trim();

      if (payload.userOrWorkgroupId !== '' && payload.userOrWorkgroupId !== null) payload.userOrWorkgroupId = payload.userOrWorkgroupId.value;
      if (payload.conditionId !== '' && payload.conditionId !== null) payload.conditionId = payload?.conditionId?.value;
      props.isLoading(true);

      await updateOneRoutingOrchestration(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Routing Orchestration updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchRoutingOrchestrationData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const firstInputRef = useRef(null);
  const objectInputRef = useRef(null);
  const calculatedContentIdInputRef = useRef(null);
  const stepInputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.orchestrationModal, 'glbOrchestrationModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Routing Orchestration' : 'Edit Routing Orchestration'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.orchestrationModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {!props.isNew && (
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                  {props.formData.contextId.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
              )}
              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Name *</label>
                {/* <input required name="buowName" value={props.formData.buowName} onChange={handleInputChange} /> */}
                <AsyncSelect
                  styles={customStyles}
                  className="modal-custom-input"
                  defaultValue={{ label: props.formData.buowName, value: props.formData.buowName }}
                  onChange={handleChangeBuow}
                  defaultOptions
                  loadOptions={loadBuowOptions}
                  placeholder="Select BUOW"
                  ref={firstInputRef}
                  onFocus={() => handleFocus(firstInputRef)} // Apply the class on focus
                />
                {(props.formData.buowName === null || props.formData.buowName === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.buowName.length > 35 && isRequiredError === true && handleMaxLimitError(35)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Object *</label>
                <input
                  name="object"
                  required
                  value={props.formData.object}
                  onChange={handleInputChange}
                  ref={objectInputRef}
                  onFocus={() => handleFocus(objectInputRef)} // Apply the class on focus
                />
                {(props.formData.object === null || props.formData.object === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Step *</label>
                {/* disabled={!props.isNew} */}
                <input
                  name="step"
                  required
                  value={props.formData.step}
                  onChange={handleInputChange}
                  type="number"
                  ref={stepInputRef}
                  onFocus={() => handleFocus(stepInputRef)} // Apply the class on focus
                />
                {(props.formData.step === null || props.formData.step === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Step Name</label>
                <select className={styles.dropdownStyle} id="selectField" name="stepName" value={props.formData.stepName} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Mode--
                  </option>
                  <option value="Processor">Processor</option>
                  <option value="Reviewer">Reviewer</option>
                </select>
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Parallel or Sequence</label>
                <select className={styles.dropdownStyle} id="selectField" name="parSeq" value={props.formData.parSeq} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Mode--
                  </option>
                  <option value="Parallel" disabled>
                    Parallel
                  </option>
                  <option value="Sequential">Sequential</option>
                </select>
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Calculated Content ID</label>
                <input
                  name="calculatedContentId"
                  value={props.formData.calculatedContentId}
                  onChange={handleInputChange}
                  ref={calculatedContentIdInputRef}
                  onFocus={() => handleFocus(calculatedContentIdInputRef)} // Apply the class on focus
                />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Target User or Workgroup *</label>
                <select className={styles.dropdownStyle} id="selectField" name="targetUserOrWorkgroup" value={props.formData.targetUserOrWorkgroup} onChange={handleSelectChange}>
                  <option value={''} disabled>
                    ---Select Mode--
                  </option>
                  <option value="U">User</option>
                  <option value="W">Workgroup</option>
                  <option value="C" disabled>
                    Conditional
                  </option>
                </select>
                {(props.formData.targetUserOrWorkgroup === null || props.formData.targetUserOrWorkgroup === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Conditional: <input type="radio" name="conditional" value={true} checked={props.formData.conditional === true} onChange={handleConditionalRadioChange} /> True
                  <input type="radio" name="conditional" value={false} checked={props.formData.conditional === false} onChange={handleConditionalRadioChange} /> False
                </div>
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Conditional ID</label>
                <AsyncSelect
                  styles={customStyles}
                  className="modal-custom-input"
                  isClearable
                  loadOptions={loadConditionalIdOptions}
                  value={props?.formData?.conditionId}
                  onChange={handleConditionalIdDropdownChange}
                  defaultOptions
                />
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>User or Workgroup *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadOptions}
                  value={props?.formData?.userOrWorkgroupId}
                  onChange={handleDropdownChange}
                  isDisabled={isUserSelected}
                />
                {(props.formData.userOrWorkgroupId === null || props.formData.userOrWorkgroupId === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Active Ind: <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.orchestrationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
