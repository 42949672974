import { React, useState, useRef, useEffect, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './WorkGroupUserXWalk.module.scss';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import WorkGroupUserXWalkData from './WorkGroupUserXWalkData.json';
// import TableWorkGroupUserXWalk from './TableWorkGroupUserXWalk.js';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';
import {
  createWorkGroupUserXWalk,
  deleteWorkGroupUserXWalk,
  searchForWorkGroupUserXWalk,
  updateSearchFolderTable,
  getUserName,
  getDefaultUserName,
  getWorkGroupDisplayName,
  getDefaultWorkGroupDisplayName,
} from './WorkGroupUserXWalkService.js';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';

var _ = require('lodash');
const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Workgroup User Xwalk', link: '' },
];

const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const TableWorkGroupUserXWalk = lazy(() => import('./TableWorkGroupUserXWalk.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));

const WorkGroupUserXWalk = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [searchTableDetails, setSearchTableDetails] = useState(WorkGroupUserXWalkData);
  const [formData, setFormData] = useState({
    userName: '',
    workGroupDisplayName: '',
    userId: '',
    workgroupId: '',
    activeInd: true,
  });
  const [isNew, setIsNew] = useState(false);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [deleteData, setDeleteData] = useState();
  const [inputDisabled, setInputDisabled] = useState(false);

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    setLoading(true);
    // fetchWorkGroupUserXWalk();
  }, []);

  async function fetchWorkGroupUserXWalk() {
    await searchForWorkGroupUserXWalk().then((response) => {
      setData(response.data);
      setLoading(false);
    });
  }

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchForWorkGroupUserXWalk(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);

    // Clear sort from table header
    let newWorkGroupXWalkTableData = JSON.parse(JSON.stringify(searchTableDetails));
    newWorkGroupXWalkTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setSearchTableDetails(newWorkGroupXWalkTableData);
    await fetchSearchOptionsData();
  };

  const createNewFunc = () => {
    setFormData({
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      userName: '',
      workGroupDisplayName: '',
      activeInd: true,
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      userId: DeleteData.id,
    };
    deleteWorkGroupUserXWalk(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchSearchOptionsData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Workgroup User Xwalk deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  async function fetchSearchOptionsData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchForWorkGroupUserXWalk(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const editFunc = (data) => {
    setFormData({
      contextId: userInfoContext,
      id: data.id,
      userId: data?.user?.userId,
      userName: data?.user?.userName?.trim(),
      workGroupDisplayName: data?.workgroup?.workgroupDisplayName?.trim(),
      workgroupId: data?.workgroup?.workgroupId,
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchForWorkGroupUserXWalk(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchForWorkGroupUserXWalk(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  return (
    <div className={styles.usersTablePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.usersTablePageContent}>
        <h1 className={styles.pageTitle}>Workgroup User Xwalk</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Workgroup User Xwalk" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.usersTableTable, 'glbUsersTableTable'].join(' ')}>
          <TableWorkGroupUserXWalk
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={searchTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            // setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditUsersTable
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchSearchOptionsData={fetchSearchOptionsData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle="Workgroup User Xwalk"
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
};

const CreateEditUsersTable = (props) => {
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      // position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: userInfoContext,
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const onChangeUserName = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      userName: selectedOptions.label,
      userId: selectedOptions.value,
    }));
  };

  const onChangeWorkGroupDisplayName = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }

    props.setFormData((prevData) => ({
      ...prevData,
      workGroupDisplayName: selectedOptions.label,
      workgroupId: selectedOptions.value,
    }));
  };

  const onChangeSystemFolder = (event) => {
    const { name, value } = event.target;
    const removeSpace = value.replace(/\s/g, '');
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: removeSpace.trimStart(),
    }));
  };

  const [isRequiredError, setisRequiredError] = useState(false);
  useEffect(() => {
    setisRequiredError(false);
    setPasswordVisible(false);
  }, [props.formData, props.isNew]);

  const handleInputName = (event) => {
    const { name, value } = event.target;
    const removeSpace = value.replace(/\s/g, '');
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: name !== 'description' ? (name === 'systemFolderLevel' ? parseInt(removeSpace.trimStart()) : value.trimStart()) : value.trimStart(),
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!props.formData?.userId || !props.formData?.workgroupId) {
        setisRequiredError(true);
        return;
      }

      let payload = _.cloneDeep(props.formData);

      payload.userName = payload?.user?.userName?.trim();
      payload.workGroupDisplayName = payload?.workgroup?.workgroupDisplayName?.trim();

      delete payload.userName;
      delete payload.workGroupDisplayName;
      props.isLoading(true);
      await createWorkGroupUserXWalk(payload)
        .then((response) => {
          if (response.data) {
            props.fetchSearchOptionsData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Workgroup User Xwalk created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            setIsSuperUser(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = async () => {
    if (!props.formData.userId || !props.formData.workgroupId) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);

    payload.userName = payload?.user?.userName?.trim();
    payload.workGroupDisplayName = payload?.workgroup?.workgroupDisplayName?.trim();

    delete payload.userName;
    delete payload.workGroupDisplayName;
    props.isLoading(true);
    await updateSearchFolderTable(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Workgroup User Xwalk updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchSearchOptionsData();
        props.setRecordModalShow(false);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  const loadUserNameList = async (inputValue, callback) => {
    if (inputValue === '') {
      // callback([]);
      await getDefaultUserName(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.userName, value: res.userId });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      await getUserName(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.userName, value: res.userId };
        });

        callback(options);
      });
    }
  };

  const loadWorkGroupDisplayName = async (inputValue, callback) => {
    if (inputValue === '') {
      // callback([]);
      await getDefaultWorkGroupDisplayName(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.workgroupDisplayName, value: res.workgroupId });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      await getWorkGroupDisplayName(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.workgroupDisplayName, value: res.workgroupId };
        });

        callback(options);
      });
    }
  };
  const firstInputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.usersTableModal, 'glbUsersTableModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Workgroup User Xwalk' : 'Edit Workgroup User Xwalk'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.usersTableModalBody}>
          <div className={styles.modalBodySection}>
            {!props.isNew && (
              <div className={styles.inputFieldSection}>
                <div className={[styles.inputFieldInternalDiv, 'col-12'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Workgroup Display Name *</label>
                <AsyncSelect
                  ref={firstInputRef}
                  onFocus={() => handleFocus(firstInputRef)}
                  styles={customStyles}
                  isClearable
                  defaultOptions
                  loadOptions={loadWorkGroupDisplayName}
                  value={
                    props?.formData?.workGroupDisplayName !== '' ? { label: props?.formData?.workGroupDisplayName, value: props?.formData?.workgroupId } : { label: '---Select---' }
                  }
                  onChange={onChangeWorkGroupDisplayName}
                />
                {(props.formData.workGroupDisplayName === undefined || props.formData.workGroupDisplayName === null || props.formData.workGroupDisplayName === '') &&
                  isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>User Name *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  defaultOptions
                  loadOptions={loadUserNameList}
                  value={props?.formData?.userName !== '' ? { label: props?.formData?.userName, value: props?.formData?.userId } : { label: '---Select---' }}
                  onChange={onChangeUserName}
                />
                {(props.formData.userName === undefined || props.formData.userName === null || props.formData.userName === '') && isRequiredError === true && (
                  <div>{handleIsRequiredError()}</div>
                )}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div className={[styles.radioInputOuterDiv, 'mt-3'].join(' ')} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Name *</label>
                <input name="name" value={props.formData.name} onChange={handleInputName} />
                {(props.formData.name === null || props.formData.name === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.name?.length > 50 && handleMaxLimitError(50)}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div className={styles.radioInputOuterDiv}>
                  <div>LowCard: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="lowCard" value={true} checked={props.formData.lowCard === true} onChange={handleRadioChange} /> True
                    <input type="radio" name="lowCard" value={false} checked={props.formData.lowCard === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-12'].join(' ')}>
                <label>Description </label>
                <textarea name="description" rows="5" value={props.formData.description} onChange={handleInputName} />
                {props.formData.description?.length > 150 && handleMaxLimitError(150)}
              </div>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.usersTableFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}

            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default WorkGroupUserXWalk;
