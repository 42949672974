const handleIsRequiredError = () => {
  return (
    <span className={'requiredErrorMsgStyle'} data-testid="required-error-message">
      This field is required *
    </span>
  );
};

const handleMaxLimitError = (maxLength) => {
  return <span className={'requiredErrorMsgStyle'}>Maximum {maxLength} char will be allowed *</span>;
};
const handleMinLimitError = (minLength) => {
  return <span className={'requiredErrorMsgStyle'}>Minimum {minLength} char will be allowed *</span>;
};

const handleCustomErrorMsg = (errorMsg) => {
  return <span className={'requiredErrorMsgStyle'}>{errorMsg}</span>;
};

const repeatOffsetWarningMsg = (axis) => {
  return (
    <span className={'requiredErrorMsgStyle'}>
      Repeat Offset {axis}% Change must be = or greater than Visual End {axis}%
    </span>
  );
};

const emptyVisualEndWarningMsg = (axis) => {
  return (
    <span className={'requiredErrorMsgStyle'}>
      Visual End {axis}% is required when adding Repeat Offset {axis}% Change
    </span>
  );
};

const handleUrlError = (url) => {
  const urlPattern = /^(https?|ftp):\/\/[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+(:\d+)?(\/[^\s]*)?$/;
  if (!urlPattern.test(url)) {
    console.log("url is invalid")
    return <span className={'requiredErrorMsgStyle'}>Please enter a valid URL (e.g., http://example.com or https://example.com) *</span>;
  }
  return null;
};

export { handleIsRequiredError, handleMaxLimitError, handleCustomErrorMsg, handleMinLimitError, repeatOffsetWarningMsg, emptyVisualEndWarningMsg, handleUrlError };
