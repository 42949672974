// const style = "background-image: url(&quot;https://www.cert.eauth.usda.gov/eauth/_GlobalAssets/usda/Skin/default/HeroImage/Images/winter/IceCracked_172825764.jpg&quot;);"

import React, { useContext, useState, useEffect } from 'react';
import CustomButton from '../../newcomponents/CustomButton/CustomButton';
// import Footer from '../../newcomponents/Footer/Footer';
import styles from './Login.module.scss';
// import TrademarkIcon from '../../fsa-style/img/svgs/trademarkIcon.svg';
import TrademarkIcon from '../../fsa-style/img/svgs/IrsTrademarkIcon.jpg';
import EyeCloseIcon from '../../fsa-style/img/svgs/eyeCloseIcon.svg';
import EyeOpenIcon from '../../fsa-style/img/svgs/eyeOpenIcon.svg';
import UserIcon from '../../fsa-style/img/svgs/userIcon.svg';
import { useNavigate } from 'react-router-dom';
import { userLogin } from './LoginService';
// import { useOktaAuth } from '@okta/okta-react';
import { searchPageColumnsAPI } from '../../pages/Search/SearchServices';
import { fixedPermissions } from '../../utils/helper/permissionFinder';
import UserContext from '../UserContext/UserContext';
import { getDoctype } from '../../services/suspenseQueueService';
import { getSystemConfigDetails } from '../../services/inBasketService';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../services/actions/action';

const Login = () => {
  const navigate = useNavigate();
  const { setUserJwt, fetchUserInfo } = React.useContext(UserContext);
  // const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [password, setPassword] = useState('admin');
  // const [email, setEmail] = useState('Courtney Tribel');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  // const { oktaAuth } = useOktaAuth();
  const { setUserPermissions } = useContext(UserContext);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   getSystemConfigDetails(window.env.REACT_APP_CLIENT_CONTEXT_ID);
  // }, []);
  const handleEmail = (event) => {
    setEmail(event.target.value);
    setError('');
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setError('');
  };

  const fetchSystemFolderData = async (payloadData) => {
    await searchPageColumnsAPI(payloadData).then(async (response) => {
      sessionStorage.setItem('SystemColumns', JSON.stringify(response?.data));
      let columnNewArray = [
        {
          metaId: 0,
          field: 'SELECTED_FIELD',
          title: 'Select',
        },
      ];
      await response?.data?.map((column, idx) => {
        columnNewArray.push({
          metaId: '_id',
          field: 'key.object_specific.' + column.name.replace(' ', ''),
          title: column.name,
          dataType: 'text',
          showMenuIcon: 'true',
          sortType: '',
          data: column,
        });
        if (response.data.length === idx + 1) {
          columnNewArray.push({
            metaId: idx + 1,
            field: 'VIEW_DETAILS',
            title: '',
          });
        }
        sessionStorage.setItem('systemFolderOptions', JSON.stringify(columnNewArray));
      });
    });
  };
  const handleLogin = async () => {
    sessionStorage.setItem('UserLoginFrom', 'System');
    if (password === '') {
      setError(`Password can't be null or Empty.`);
      return;
    } else if (email === '') {
      setError(`Username can't be null or Empty.`);
      return;
    }
    let payload = {
      username: email,
      password: password,
    };
  
    await userLogin(payload).then(async (response) => {
      // Set Token
      if (response.data) {
        dispatch(setUserInfo(response.data));

        sessionStorage.setItem('userToken', response.data.jwt);
        sessionStorage.setItem('userName', response.data.name);
        sessionStorage.setItem('user_information', JSON.stringify(response.data));
        sessionStorage.setItem('isUserAuthenticated', true);
  
        // Set showInfoPopup to true
        sessionStorage.setItem('showInfoPopup', 'true');
  
        await getSystemConfigDetails(JSON.parse(sessionStorage.getItem('user_information'))?.contextId, response?.data?.jwt);
        fetchSystemFolderData();
        getDoctype();
        // setUserInfo({ name: response.data.userName, usdaagencycode: [''] });
        setUserJwt(response.data.jwt);
        let x = response.data.rolePermissions;
        let obj = {};
        if (x !== undefined && x.length > 0) {
          await x.map((i) => {
            if (fixedPermissions.includes(i) === true) {
              obj[i] = true;
            }
            return null;
          });
  
          setUserPermissions(obj);
        }
        fetchUserInfo();

        // window.env.REACT_APP_FE_ENVIRONMENT !== undefined ? navigate.replace('/inbasketpage') : navigate.replace('/search');
  
        // Trigger a custom event after updating sessionStorage
        const loginEvent = new CustomEvent('loginSuccess', {
          detail: {
            showInfoPopup: true,
            loginMessage: JSON.parse(window.localStorage.getItem('system_config'))?.loginMessage,
          },
        });
        window.dispatchEvent(loginEvent);
  
        const redirectPage = window.env.REACT_APP_FE_ENVIRONMENT !== undefined ? '/inbasketpage' : '/search';
        sessionStorage.setItem('filesList', null);

        // Redirect to the appropriate page
        navigate(redirectPage, { replace: true });

        sessionStorage.setItem('filesList', null);
      } else if (response.error) {
        setError(response.error.message ? response.error.message : 'Something went wrong');
        sessionStorage.setItem('isUserAuthenticated', false);
      }
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // const loggingIn = async () => {
  //   sessionStorage.setItem('UserLoginFrom', 'Okta');
  //   oktaAuth.signInWithRedirect({ originalUri: '/search' });
  // };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };
  return (
    <>
      <div data-testid="login-container" className={styles.loginPage}>
        {/* Heading with green color */}
        {/* <div className={styles.loginHeader}></div> */}
        <div className={styles.loginSection}>
          {/* <div>
            <img src={TrademarkIcon}></img>
          </div> */}
          {window.env.REACT_APP_ENVIRONMENT === 'UAT' && (
            <div style={{ color: '#1F5492', paddingLeft: 6, fontWeight: 800, fontSize: 22, width: 200, marginTop: 20, textAlign: 'center' }}>SMC UAT</div>
          )}
          {window.env.REACT_APP_ENVIRONMENT === 'DRIMS Demo' && (
            <div style={{ color: '#1F5492', paddingLeft: 6, fontWeight: 800, fontSize: 22, marginTop: 20, textAlign: 'center' }}>TEGE Redaction System</div>
          )}
          {window.env.REACT_APP_ENVIRONMENT === 'DRIMS Dev' && (
            <div style={{ color: '#1F5492', paddingLeft: 6, fontWeight: 800, fontSize: 22, width: 200, marginTop: 20, textAlign: 'center' }}>DRIMS Dev</div>
          )}
          {window.env.REACT_APP_ENVIRONMENT === 'DRIMS QA' && (
            <div style={{ color: '#1F5492', paddingLeft: 6, fontWeight: 800, fontSize: 22, width: 200, marginTop: 20, textAlign: 'center' }}>DRIMS QA</div>
          )}
          {window.env.REACT_APP_ENVIRONMENT === 'PREPROD' && (
            <div style={{ color: '#1F5492', paddingLeft: 6, fontWeight: 800, fontSize: 44, marginTop: 20, textAlign: 'center' }}>TEGE Redaction System</div>
          )}
          {/* Login Card  */}
          <div className={styles.loginContent}>
            <section className={styles.cardSection}>
              <div className={['card', styles.loginCard].join(' ')}>
                <div className={styles.userIconOuterDiv}>
                  <img data-testid="user-icon" src={UserIcon} alt={'userIcon'}></img>
                </div>

                <div className={['card-body', styles.cardContent].join(' ')}>
                  <div className={styles.emailContainer}>
                    <label className={styles.inputLable}>Username</label>
                    <input data-testid="emailInput" className={styles.inputFieldStyle} value={email} onChange={handleEmail} required autoFocus />
                  </div>

                  <div className={styles.passwordContainer}>
                    <label className={styles.inputLable}>Password</label>
                    <input
                      data-testid="passwordInput"
                      type={passwordVisible ? 'text' : 'password'}
                      className={styles.inputFieldStyle}
                      value={password}
                      onChange={handlePassword}
                      onKeyDown={handleEnter}
                    />
                    <span className={styles.togglePassword} onClick={togglePasswordVisibility}>
                      {passwordVisible ? (
                        <img
                          data-testid="eyeIcon"
                          className={styles.passwordVisiblityIcon}
                          alt="EyeOpenIcon"
                          src={EyeOpenIcon}
                          style={{ position: 'relative', top: '15px', width: '3rem' }}
                        />
                      ) : (
                        <img
                          data-testid="eyeIcon"
                          className={styles.passwordVisiblityIcon}
                          alt="EyeCloseIcon"
                          src={EyeCloseIcon}
                          style={{ position: 'relative', top: '15px', width: '3rem' }}
                        />
                      )}
                    </span>
                  </div>

                  {error && (
                    <p data-testid="error-container" className={styles.errorMessage}>
                      {error}
                    </p>
                  )}
                  <div className="d-flex justify-content-center">
                    <CustomButton
                      className={[email.length > 0 && password.length > 0 ? styles.loginBtnStyle : styles.disabledLoginBtnStyle, styles.buttonStyle].join(' ')}
                      title="Login"
                      onClick={handleLogin}
                    />
                  </div>
                  <label className={[styles.inputLable, styles.warningLabel].join(' ')}>
                    <b style={{ marginRight: '8px' }}>Warning:</b> This system may contain non-public tax information. You may only access this system for official use. Any other
                    use of this system is an unauthorized use and is prohibited.
                  </label>
                </div>
              </div>
            </section>
          </div>
          {/* This needs to handled in future same as done on 4a env currently as an hotfix commenting okta code for DRIMS Demo */}
          {/* {window.env.REACT_APP_FE_ENVIRONMENT !== undefined ? <></> : <CustomButton className={styles.oktaButtonStyle} title="Login with Okta" onClick={loggingIn} />} */}
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Login;
