import React, { useEffect, useState, createContext } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from "oidc-react";
// import LoadingSplash from "../LoadingSplash/LoadingSplash";

const UserContext = createContext();

// const mockJwt =
//   'Bearer eyJraWQiOiJiNzBiNDYyNy1hMDQ5LTRmOWMtOGFiZi01YjI3NWJiNDZlOWIiLCJhbGciOiJSUzI1NiJ9.eyJ1c2RhX2VtcGxveWVlX3R5cGVfY29kZSI6WyJFX0ZBOkUiLCJDX0ZBOkMiXSwic3ViIjoiMjgyMDAzMTAxNjkwMjEwMzM3NDAiLCJmb3JtYXR0ZWQiOiIxMjMgTWFpbiBTdC5cXG5EZSBTb3RvLCBLUyA2NjAxOFxcblVTQSIsImlzcyI6Imh0dHBzOi8vZ2F0ZXdheS5jZXJ0LmVhdXRoLnVzZGEuZ292L2FmZndlYnNlcnZpY2VzL0NBU1NPL29pZGMvVVNEQV9GUEFDX0RSTVNfdjAiLCJ1c2RhX3dvcmtfb2ZmaWNlX2lkIjpbIkNfRkE6NjE5MTQiXSwidXNkYV9lbXBsb3llZV9zdGF0dXMiOlsiRV9GQTpUIiwiQ19GQTpBIl0sImdpdmVuX25hbWUiOiJDb3VydG5leSIsInVzZGFfaWFsIjoiMiIsImF1ZCI6IjQ3NTY0OGJjLTIxMTMtNDE5NS04MWJhLTY0OWEyODhkYjdhZCIsImFjciI6Imh0dHA6Ly9pZG1hbmFnZW1lbnQuZ292L25zL2Fzc3VyYW5jZS9sb2EvMSIsInVzZGFfYWdlbmN5X2NvZGUiOlsiRV9GQTpGQSIsIkNfRkE6RkEiXSwidXNkYV9hcHBfcm9sZXMiOlsiVVNEQV9FTlRWUE5fT0NJTy1JVFMtUkVNT1RFIiwiT0NJT19FTlRWUE5fSVRTX1JFTU9URSIsIk9DSU9fRUVNU19JbnRlcm5hbC1Ob24tQWRtaW4iLCJPQ0lPX0FnTGVhcm5fVXNlciJdLCJ1c2RhX2FhbCI6IjIwIiwiYXV0aF90aW1lIjoxNjM4ODE5NzAxLCJuYW1lIjoiQ291cnRuZXkgVGliZWwiLCJ1c2RhX2VhdXRoX2lkIjoiMjgyMDAzMTAxNjkwMjEwMzM3NDAiLCJleHAiOjE2Mzg4MTk3NjMsImlhdCI6MTYzODgxOTcwMywiZmFtaWx5X25hbWUiOiJUaWJlbCIsImVtYWlsIjoiR2VvcmdlLlNlbGxlY2tAa2NjLnVzZGEuZ292In0.R0kAXaRALoLXNPpPRqd7Fbidz1Gn36G3g5eAbgZA5WL9ISoltfLwEzDFJUvCCvgzDPVYyo_zGnPCtKamkRXSOhqFS4ktluGM4sISd_pseQ78W1Udd8D2g91_it6H5Y8YVsLMVL1fOmhxlcQQ9AamIO0N53sqIMFpcOLWKh9Kf9tiVXzCnRA60rYBLpj1UJaoF4msM4zp_FLlU884-j4-UP7fIAk7sOZnz6DW3QJlGypuBaVyz7RYVczqSlaIzckd-KdKvz6EgSh0mHHW7Euxl9mVd07BxwWjbR1S90KPhfDLI-lawjcT45oT2vo1ebdbkESCwnJE9mxw0JP8UGr2Vg';

const UserProvider = (props) => {
  // Context state
  const [userJwt, setUserJwt] = useState();
  const [userInfo, setUserInfo] = useState({ name: '', usdaagencycode: [''] });
  const [userPermissions, setUserPermissions] = useState({ permisison: true });
  // const navigate = useNavigate();

  // const authenticationUrl = "/security_api/security/login";
  // const authenticationUrl = window.env.NODE_ENV === 'development' ? '/security/login' : window.RUNTIME_DRMS_API_URL + '/security/login';

  // const { userData, isLoading } = useAuth();

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    if (sessionStorage.getItem('userToken')) {
      axios
        .get('/security_api/security/userinfo', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          },
        })
        .then((res) => {
          const userInfoResponse = res.data;
          setUserInfo(userInfoResponse);
          sessionStorage.setItem('user_information', JSON.stringify(userInfoResponse));
          sessionStorage.setItem('userToken', userInfoResponse.jwt);
          setUserJwt(userInfoResponse.jwt);
        })
        .catch((err) => {
          if (err.response.data.message === 'Access Denied: Your account is currently inactive.') {
            document.location = `/access-error`;
          }
          console.error(err);
        });
    }
  };
  // if (isLoading) {
  //   return <LoadingSplash />;
  // }

  return (
    <UserContext.Provider
      value={{
        userJwt,
        setUserJwt,
        userInfo,
        setUserInfo,
        setUserPermissions,
        userPermissions,
        fetchUserInfo,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;

export { UserProvider };
