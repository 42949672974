import React from 'react';

export default function LoadingSplash() {
  return (
    <div className="fsa-splash w-100">
      <div className="fsa-splash__bd">
        <div className="fsa-splash__item">
          <div className="fsa-splash__progress">
            <div className="fsa-progress fsa-progress--indeterminate" aria-live="polite">
              <div className="fsa-progress__details fsa-sr-only">
                <div className="fsa-progress__label">Application is loading</div>
              </div>
              <div className="fsa-progress__bar" aria-hidden="true">
                <div className="fsa-progress__primary"></div>
                <div className="fsa-progress__secondary"></div>
              </div>
            </div>
          </div>
          <div className="fsa-splash__title">
            {/* <span className="fsa-splash__title-abbr">DRIMS</span>
            <span className="fsa-splash__title-name">Digital Records Management System</span> */}
            {/* <span className="fsa-splash__title-abbr ms-5 mx-5">ALICE REDAX</span> */}
            <span className="fsa-splash__title-abbr ms-5 mx-5">TEGE Redaction System</span>
            {/* <span className="fsa-splash__title-name">Document Management System</span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
