import axios from 'axios';

export async function updateOnePackagerConfiguration(data) {
  console.log('data ===>>', data);
  let updateResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/packagerconfiguration/' + `${data.contextId}`;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function searchPackagerConfiguration(data) {
  console.log('searchPackagerConfiguration', data);
  let workQueueFilterResponse = [];
  try {
    let url = `${window.env.REACT_APP_BACKEND_URL}/buow_api/packagerconfiguration/search?contextId=${data.contextId}&keywords=${data.text}`;

    if (data.column !== '' && data.order !== '') {
      url += `&sortOn=${data.column}&sortOrder=${data.order}`;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getUserInfoID(text) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/user/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
