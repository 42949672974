import React, { useEffect, useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ObjectHierarchy.module.scss';
import '../../../GlobalStyle.scss';
import AsyncSelect from 'react-select/async';
// import TableObjectHierarchy from './TableObjectHierarchy';
import ObjectHierarchyData from './ObjectHierarchyData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { saveObjectHierarchy, deleteObjectHierarchy, searchObjectHierarchy, updateObjectHierarchy, searchParentObjectClass, getBuow, getObjectId } from './ObjectHierarchyService';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
// import { searchObjectHierarchy } from '../RoutingSteps/RoutingStepsServices';
import AsyncCreatableSelect from 'react-select/async-creatable';

const TableObjectHierarchy = lazy(() => import('./ObjectHierarchyTable'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
// const { DeleteModal } = lazy(() => import('../../../newcomponents/DeletePopup/DeleteModal'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Object Dictionary Object Hierarchy', link: '' },
];

export default function ObjectHierarchy() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contextId: '',
    objectClass: '',
    buowName: '',
    objectIds: [],
    objectNames: {},
    description: '',
    activeInd: true,
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [ObjectHierarchyDetails, setObjectHierarchyData] = useState(ObjectHierarchyData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const userContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  async function fetchObjectHierarchyData() {
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchObjectHierarchy(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchObjectHierarchyData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchObjectHierarchy(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: '',
      objectClass: '',
      buowName: '',
      objectIds: [],
      objectNames: {},
      description: '',
      activeInd: true,
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      contextId: DeleteData.contextId,
      objectClass: DeleteData.objectClass,
      buowName: DeleteData.buowName,
    };

    deleteObjectHierarchy(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchObjectHierarchyData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Object Dictionary Object Hierarchy Deleted Successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      objectClass: data.objectClass,
      buowName: data.buowName,
      previousBuowName: data.buowName,
      objectIds: data.objectIds,
      description: data.description,
      objectNames: data.objectNames,
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) onSearch(searchText);
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchObjectHierarchy(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  // const onSearch = async (searchText) => {
  //   setLoading(true);
  //   let payload = {
  //     text: searchText,
  //     column: sorting.column,
  //     order: sorting.order,
  //   };

  //   await searchObjectHierarchy(payload).then((response) => {
  //     setData(response.data);
  //     setPage(0);
  //     setLoading(false);
  //   });
  // };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newObjectHierarchyData = JSON.parse(JSON.stringify(ObjectHierarchyDetails));
    newObjectHierarchyData.forEach((itm) => {
      itm.sortType = '';
    });
    setObjectHierarchyData(newObjectHierarchyData);
    await fetchNewObjectHierarchyData();
  };

  // Code needs to change
  const fetchNewObjectHierarchyData = async () => {
    let payload = {
      contextId: userContextId,
      text: '',
      column: '',
      order: '',
    };
    await searchObjectHierarchy(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      contextId: userContextId,
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchObjectHierarchy(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.ObjectHierarchyPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.ObjectHierarchyPageContent}>
        <h1 className={styles.pageTitle}>Object Dictionary Object Hierarchy</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Object Dictionary Object Hierarchy" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.objectHierarchyTable, 'glbObjectHierarchyTable'].join(' ')}>
          <TableObjectHierarchy
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={ObjectHierarchyDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditObjectHierarchy
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchObjectHierarchyData={fetchObjectHierarchyData}
          userContextId={userContextId}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Object Dictionary Object Hierarchy'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditObjectHierarchy = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color:'black'
    }),
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  // Fetch options from API
  const loadObjectClassOptions = async (inputValue) => {
    if (!inputValue.trim()) return [];

    const params = {
      inputValue,
      contextId: JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };

    try {
      const response = await searchParentObjectClass(params);
      return (
        response?.data?.map((res) => ({
          label: res.objectClass,
          value: res.objectClass,
        })) || []
      );
    } catch (error) {
      console.error('Error fetching classes:', error);
      return [];
    }
  };

  // Handle new option creation
  const handleCreateObjectClass = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    onChangeObjectClass(newOption, 'fromCreate');
  };

  // Handle selection change
  const onChangeObjectClass = (selectedOption, fromCreate) => {
    if (!selectedOption) {
      props.setFormData((prevData) => ({
        ...prevData,
        objectClass: '',
      }));
      return;
    }
    if (fromCreate === 'fromCreate') {
      props.setFormData((prevData) => ({
        ...prevData,
        objectClass: selectedOption.label,
      }));
    } else {
      props.setFormData((prevData) => ({
        ...prevData,
        objectClass: selectedOption.label,
      }));
    }
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await searchObjectHierarchy(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });

        callback(options);
      });
    }
  };

  const loadBuowOptions = async (inputValue, callback) => {
    if (inputValue === '') {
      await getBuow(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.buowName, value: res.buowName };
        });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
    } else {
      await getBuow(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.buowName, value: res.buowName };
        });
        callback(options);
      });
    }
  };

  const handleChangeBuow = async (selectedOption) => {
    props.setFormData((prevData) => ({
      ...prevData,
      buowName: selectedOption.value,
    }));
  };

  const loadObjectIds = async (inputValue, callback) => {
    if (!inputValue.trim()) {
      callback([]);
      return;
    }

    try {
      const response = await getObjectId(inputValue);

      if (!response?.data || !Array.isArray(response.data)) {
        console.error('Invalid API response:', response);
        callback([]);
        return;
      }

      // Map response to dropdown options
      const options = response.data.map((res) => ({
        label: res.name, // Display name in dropdown
        value: res.objectId, // Store objectId in formData
      }));

      callback(options);
    } catch (error) {
      console.error('Error fetching Object IDs:', error);
      callback([]);
    }
  };

  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };
  const handleSubmit = async () => {
    try {
      if (
        // !props.formData.contextId ||
        // props.formData.contextId?.length > 50 ||
        !props.formData.objectClass ||
        !props.formData.buowName ||
        !Array.isArray(props.formData.objectIds) || // Ensure it's an array
        props.formData.objectIds.length === 0 || // Ensure it's not empty
        props.formData.description?.length > 500
      ) {
        setisRequiredError(true);
        return;
      }
      setisRequiredError(false);
      let payload = _.cloneDeep(props.formData);

      payload.objectClass = payload?.objectClass?.trim();
      payload.name = payload?.name?.trim();
      payload.description = payload?.description?.trim();
      payload.buowName = payload?.buowName?.trim();

      payload.contextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
      props.isLoading(true);
      await saveObjectHierarchy(payload)
        .then((response) => {
          if (response.data) {
            props.fetchObjectHierarchyData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Object Dictionary Object Hierarchy Created Successfully.');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEdit = async () => {
    if (
      // !props.formData.contextId ||
      // props.formData.contextId?.length > 50 ||
      !props.formData.objectClass ||
      !props.formData.buowName ||
      !Array.isArray(props.formData.objectIds) || // Ensure it's an array
      props.formData.objectIds.length === 0 || // Ensure it's not empty
      props.formData.description?.length > 500
    ) {
      setisRequiredError(true);
      return;
    }
    setisRequiredError(false);
    let payload = _.cloneDeep(props.formData);

    payload.objectClass = payload?.objectClass?.trim();
    payload.description = payload?.description?.trim();
    payload.buowName = payload?.buowName?.trim();
    if (payload?.previousBuowName) {
      payload.previousBuowName = payload?.previousBuowName?.trim();
    }

    props.isLoading(true);

    await updateObjectHierarchy(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Object Dictionary Object Hierarchy Updated Successfully.');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchObjectHierarchyData();
        props.setRecordModalShow(false);

        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };
  const firstInputRef = useRef(null);
  const descriptionInputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.ObjectHierarchyModal, 'glbObjectHierarchyModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Object Dictionary Object Hierarchy' : 'Edit Object Dictionary Object Hierarchy'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.ObjectHierarchyModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {props.isNew ? (
                <>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Object Class *</label>
                    <AsyncCreatableSelect
                      ref={props.isNew ? firstInputRef : null}
                      onFocus={() => handleFocus(firstInputRef)}
                      styles={customStyles}
                      isClearable
                      isDisabled={!props.isNew}
                      loadOptions={loadObjectClassOptions} // Async API fetching
                      defaultOptions // Load options initially
                      value={props?.formData?.objectClass ? { label: props?.formData?.objectClass, value: props?.formData?.objectClass } : null}
                      onChange={(e) => {
                        onChangeObjectClass(e, null);
                      }}
                      onCreateOption={handleCreateObjectClass} // Create new options dynamically
                    />
                    {(props.formData.objectClass === null || props.formData.objectClass === '') && isRequiredError === true && handleIsRequiredError()}
                  </div>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>BUOW Name *</label>
                    <AsyncSelect
                      styles={customStyles}
                      className="modal-custom-input"
                      defaultValue={{ label: props.formData.buowName, value: props.formData.buowName }}
                      onChange={handleChangeBuow}
                      defaultOptions
                      loadOptions={loadBuowOptions}
                      placeholder="Select BUOW"
                    />
                    {(props.formData.buowName === null || props.formData.buowName === '') && isRequiredError === true && handleIsRequiredError()}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Context Id *</label>
                    <AsyncSelect
                      styles={customStyles}
                      isClearable
                      isDisabled={props.isNew ? false : true}
                      loadOptions={loadContextIdList}
                      value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                      onChange={onChangeContextId}
                      className={props.isNew ? '' : styles.disabledInput}
                    />
                  </div>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Object Class *</label>
                    <AsyncCreatableSelect
                      styles={customStyles}
                      isClearable
                      isDisabled={!props.isNew}
                      loadOptions={loadObjectClassOptions} // Async API fetching
                      defaultOptions // Load options initially
                      value={props?.formData?.objectClass ? { label: props?.formData?.objectClass, value: props?.formData?.objectClass } : null}
                      onChange={(e) => {
                        onChangeObjectClass(e, null);
                      }}
                      onCreateOption={handleCreateObjectClass} // Create new options dynamically
                    />
                    {(props.formData.objectClass === null || props.formData.objectClass === '') && isRequiredError === true && handleIsRequiredError()}
                  </div>
                </>
              )}
            </div>
            {!props.isNew && (
              <div className={styles.inputFieldInternalDiv}>
                <label>BUOW Name *</label>
                <AsyncSelect
                  ref={!props.isNew ? firstInputRef : null}
                  onFocus={() => handleFocus(firstInputRef)}
                  styles={customStyles}
                  className="modal-custom-input"
                  defaultValue={{ label: props.formData.buowName, value: props.formData.buowName }}
                  onChange={handleChangeBuow}
                  defaultOptions
                  loadOptions={loadBuowOptions}
                  placeholder="Select BUOW"
                />
                {(props.formData.buowName === null || props.formData.buowName === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Object ID *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isMulti
                  cacheOptions
                  loadOptions={loadObjectIds}
                  defaultOptions
                  value={(props.formData.objectIds || []).map((id) => ({
                    label: props.formData.objectNames?.[id] || id, // Show name if available, else show objectId
                    value: id,
                  }))}
                  onChange={(selectedOptions) => {
                    props.setFormData((prevData) => ({
                      ...prevData,
                      objectIds: selectedOptions.map((option) => option.value), // Store objectId
                      objectNames: selectedOptions.reduce((acc, option) => {
                        acc[option.value] = option.label; // Store name for display
                        return acc;
                      }, {}),
                    }));
                  }}
                />
                {Array.isArray(props.formData.objectIds) && props.formData.objectIds.length === 0 && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label> Description</label>
                <textarea
                  ref={descriptionInputRef}
                  onFocus={() => handleFocus(descriptionInputRef)}
                  required
                  name="description"
                  value={props.formData.description}
                  onChange={handleInputChange}
                />
                {props.formData.description?.length > 500 && handleMaxLimitError(500)}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6 pl-0'].join(' ')}>
                <div className={[styles.radioInputOuterDiv, 'mt-1'].join(' ')} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.orchestrationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" parentWarpper={'d-flex align-items-center'} className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
