import React, { useEffect, useState, useRef, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './RoutingConditions.module.scss';
import '../../../GlobalStyle.scss';

import RoutingConditionsData from './RoutingConditionsData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { createRoutingConditions, updateOneRoutingConditions, deleteRoutingConditions, searchRoutingConditions } from './RoutingConditionsService.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';

const TableRoutingConditions = lazy(() => import('./TableRoutingConditions.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Routing Conditions', link: '' },
];

const argument1TypeData = ['BUOW Metadata', 'Object Metadata', 'Routing Metadata', 'System Variable', 'User Variable'];
const operatorData = ['And', 'Or'];
const operator1Data = ['>', '<', '=', '<>', '>=', '<='];

export default function RoutingConditions() {
  const glbContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [routingConditionsDetails, setRoutingConditionsData] = useState(RoutingConditionsData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    activeInd: true,
    contextId: glbContextId,
    value1: '',
    argument1: '',
    conditionId: '',
    conditionDescription: '',
    argument1Type: '',
    connector: '',
    operator1: '',
  });

  async function fetchRoutingConditionsData() {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchRoutingConditions(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchRoutingConditionsData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: '',
      order: '',
      contextId: glbContextId,
    };
    setSorting({ column: '', order: '' });
    await searchRoutingConditions(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchRoutingConditions(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      activeInd: true,
      contextId: glbContextId,
      value1: '',
      argument1: '',
      conditionDescription: '',
      conditionId: '',
      argument1Type: '',
      connector: '',
      operator1: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      activeInd: data.activeInd,
      contextId: data.contextId,
      value1: data.value1,
      argument1: data.argument1,
      conditionDescription: data.conditionDescription,
      conditionId: data.conditionId,
      argument1Type: data.argument1Type,
      connector: data.connector,
      operator1: data.operator1,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewRoutingConditionsData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(routingConditionsDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setRoutingConditionsData(newRoutingStepsData);
  };

  // Code needs to change
  const fetchNewRoutingConditionsData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: glbContextId,
    };
    await searchRoutingConditions(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
      contextId: glbContextId,
    };
    await searchRoutingConditions(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      contextId: glbContextId,
      value1: DeleteData.value1,
      argument1: DeleteData.argument1,
      conditionId: DeleteData.conditionId,
      conditionDescription: DeleteData.conditionDescription,
      id: DeleteData.id,
    };
    deleteRoutingConditions(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Routing Conditions deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="routingConditions-container" className={styles.routingConditionsPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="routingConditions-page-wrapper" className={styles.routingConditionsPageContent}>
        <h1 data-testid="routingConditions-heading" className={styles.pageTitle}>
          Routing Conditions
        </h1>
        <div>
          <div data-testid="routingConditions-search-container" className={styles.searchSection}>
            <div data-testid="routingConditions-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="routingConditions-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-routingConditions'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="routingConditions-create-btn-container">
                <CustomButton testId={'create-routingConditions-btn'} title="Create Routing Conditions" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="routingConditions-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="routingConditions-table-container" className={[styles.routingConditionsTable, 'glbRoutingConditionsTable'].join(' ')}>
          <TableRoutingConditions
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={routingConditionsDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <AddEditRoutingConditionsModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchRoutingConditionsData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
          glbContextId={glbContextId}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Routing Conditions'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditRoutingConditionsModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color:'black'
    }),
  };

  const loadValidateVisualTag = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      argument1TypeData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await argument1TypeData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const loadRedactData = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      operatorData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await operatorData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const loadOperator1Data = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      operator1Data.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await operator1Data.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === 'conditionId'
          ? value.replace(/\s/g, '') // Remove all spaces for conditionId
          : value.trimStart(), // Only trim leading spaces for other fields
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const handleSubmit = async (data) => {
    if (!data.conditionId || props.formData.value1?.length > 200 || props.formData.argument1?.length > 200 || props.formData.conditionDescription?.length > 500) {
      setisRequiredError(true);
      return;
    } else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);

      payload.value1 = payload?.value1?.trim();
      payload.argument1 = payload?.argument1?.trim();
      payload.conditionDescription = payload?.conditionDescription?.trim();
      payload.conditionId = payload?.conditionId?.trim();

      await createRoutingConditions(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Routing Conditions created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchRoutingConditionsData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.conditionId || props.formData.value1?.length > 200 || props.formData.argument1?.length > 200 || props.formData.conditionDescription?.length > 500) {
      setisRequiredError(true);
      return;
    } else {
      let payload = _.cloneDeep(data);

      payload.value1 = payload?.value1?.trim();
      payload.argument1 = payload?.argument1?.trim();
      payload.conditionDescription = payload?.conditionDescription?.trim();
      payload.conditionId = payload?.conditionId?.trim();

      props.isLoading(true);

      await updateOneRoutingConditions(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Routing Conditions updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchRoutingConditionsData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const onChangeValidateVisualTag = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        argument1Type: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      argument1Type: updatedValue,
    }));
  };

  const onChangeRedact = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        connector: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      connector: updatedValue,
    }));
  };

  const onChangeOperator1 = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        operator1: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      operator1: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };

  const firstInputRef = useRef(null);
  const conditionIdInputRef = useRef(null);
  const conditionDescriptionInputRef = useRef(null);
  const argument1InputRef = useRef(null);
  const value1InputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.routingConditionsModal, 'glbRoutingConditionsModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Routing Conditions' : 'Edit Routing Conditions'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.routingConditionsModalBody}>
          <div className={styles.modalBodySection}>
            {props.isNew === false && (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context ID *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    isSearchable={false}
                    defaultOptions
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Condition ID *</label>
                <input
                  required
                  name="conditionId"
                  value={props.formData.conditionId}
                  disabled={props.isNew ? false : true}
                  onChange={handleInputChange}
                  className={props.isNew ? '' : styles.disabledInput}
                  ref={props.isNew === true ? firstInputRef : conditionIdInputRef}
                  onFocus={() => handleFocus(conditionIdInputRef)}
                />
                {(props.formData.conditionId === null || props.formData.conditionId === '') && isRequiredError === true && handleIsRequiredError()}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Condition Description</label>
                <textarea
                  ref={props.isNew === false ? firstInputRef : conditionDescriptionInputRef}
                  onFocus={() => handleFocus(conditionDescriptionInputRef)}
                  name="conditionDescription"
                  value={props.formData.conditionDescription}
                  onChange={handleInputChange}
                  style={{ height: '32px' }}
                />
                {props.formData.conditionDescription?.length > 500 && handleMaxLimitError(500)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Argument 1 Type</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadValidateVisualTag}
                  value={props?.formData?.argument1Type !== '' ? { label: props?.formData?.argument1Type, value: props?.formData?.argument1Type } : null}
                  onChange={onChangeValidateVisualTag}
                  isSearchable={false}
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Argument 1</label>
                <input ref={argument1InputRef} onFocus={() => handleFocus(argument1InputRef)} name="argument1" value={props.formData.argument1} onChange={handleInputChange} />
                {props.formData.argument1?.length > 200 && handleMaxLimitError(200)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Operator 1</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadOperator1Data}
                  value={props?.formData?.operator1 !== '' ? { label: props?.formData?.operator1, value: props?.formData?.operator1 } : null}
                  onChange={onChangeOperator1}
                  isSearchable={false}
                  defaultOptions
                />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Value 1</label>
                <input ref={value1InputRef} onFocus={() => handleFocus(value1InputRef)} name="value1" value={props.formData.value1} onChange={handleInputChange} />
                {props.formData.value1?.length > 200 && handleMaxLimitError(200)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Connector</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  loadOptions={loadRedactData}
                  value={props?.formData?.connector !== '' ? { label: props?.formData?.connector, value: props?.formData?.connector } : null}
                  onChange={onChangeRedact}
                  isSearchable={false}
                  defaultOptions
                />
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Active Ind: <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.routingConditionsFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
