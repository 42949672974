import React, { useEffect, useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ElementGroupXwalk.module.scss';
import '../../../GlobalStyle.scss';
import AsyncSelect from 'react-select/async';
import elementGroupXwalkData from './ElementGroupXwalkData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import {
  savePermissionTable,
  deletePermissionTable,
  searchPermissionTable,
  updatePermissionTable,
  searchElementName,
  searchGroupName,
  getAllEleGroups,
} from './ElementGroupXwalkServices.js';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { handleCustomErrorMsg, handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import AddEleXwalk from '../../../fsa-style/img/svgs/AddEleXwalk.svg';
const TableelementGroupXwalk = lazy(() => import('./ElementGroupXwalkTable'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));
const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Element Group Xwalk', link: '' },
];

export default function ElementGroupXwalk() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    // contextId: '',
    elementGroupName: '',
    elementGroupId: '',
    elementName: '',
    elementId: '',
    elementSortOrder: null,
    activeInd: true,
    effectiveStartDate: null,
    effectiveEndDate: null,
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [elementGroupXwalkDetails, setelementGroupXwalkData] = useState(elementGroupXwalkData);
  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchelementGroupXwalkData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };

    await searchPermissionTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchelementGroupXwalkData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };
    setSorting({ column: '', order: '' });

    await searchPermissionTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      // contextId: '',
      elementGroupName: '',
      elementName: '',
      elementGroupId: '',
      elementId: '',
      elementSortOrder: null,
      activeInd: true,
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    // const sendData = {
    //   contextId: DeleteData.contextId,
    //   elementGroupId: DeleteData.elementGroupId,
    //   elementId: DeleteData.elementId,
    // };
    const sendData = {
      id: DeleteData.id,
    };

    deletePermissionTable(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchelementGroupXwalkData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Element Group Xwalk deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data?.contextId,
      elementGroupName: data?.elementGroupName,
      elementName: data?.elementName,
      elementGroupId: data?.elementGroupId,
      elementId: data?.elementId,
      elementSortOrder: data?.elementSortOrder,
      id: data?.id,
      activeInd: data?.activeInd === null || data?.activeInd === undefined ? true : data?.activeInd,
      effectiveEndDate: data?.effectiveEndDate,
      effectiveStartDate: data?.effectiveStartDate,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) onSearch(searchText);
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };

    await searchPermissionTable(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newelementGroupXwalkData = JSON.parse(JSON.stringify(elementGroupXwalkDetails));
    newelementGroupXwalkData.forEach((itm) => {
      itm.sortType = '';
    });
    setelementGroupXwalkData(newelementGroupXwalkData);
    await fetchNewelementGroupXwalkData();
  };

  // Code needs to change
  const fetchNewelementGroupXwalkData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };
    await searchPermissionTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };
    await searchPermissionTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.elementGroupXwalkPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.elementGroupXwalkPageContent}>
        <h1 className={styles.pageTitle}>Element Group Xwalk</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Element Group Xwalk" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.elementGroupXwalkTable, 'glbElementGroupXwalkTable'].join(' ')}>
          <TableelementGroupXwalk
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={elementGroupXwalkDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditelementGroupXwalk
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchelementGroupXwalkData={fetchelementGroupXwalkData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Element Group Xwalk'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditelementGroupXwalk = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [sortOrder, setSortOrder] = React.useState('asc');
  const [data, setData] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [listOfAllRecords, setListOfAllRecords] = useState([]);
  const sortedData = [...(data || [])].sort((a, b) => {
    return sortOrder === 'asc' ? a.elementSortOrder - b.elementSortOrder : b.elementSortOrder - a.elementSortOrder;
  });
  const groupedData = sortedData.reduce((acc, item) => {
    if (!acc[item.elementGroupName]) {
      acc[item.elementGroupName] = [];
    }
    acc[item.elementGroupName].push(item);
    return acc;
  }, {});
  // Handle drag start
  const handleDragStart = (item) => {
    setDraggedItem(item);
  };

  // Handle drag over (allow drop)
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Handle drop (reorder rows within group)
  const handleDrop = (targetItem) => {
    if (!draggedItem || draggedItem.elementGroupName !== targetItem.elementGroupName) return;

    // setData((prevData) => {
    //   // Extract only the relevant group
    //   const newGroupData = prevData.filter((itm) => itm.elementGroupName === targetItem.elementGroupName);

    //   // Find indexes of dragged and target items
    //   const fromIndex = newGroupData.findIndex((itm) => itm.elementName === draggedItem.elementName);
    //   const toIndex = newGroupData.findIndex((itm) => itm.elementName === targetItem.elementName);

    //   // Swap only the elementSortOrder values
    //   const tempSortOrder = newGroupData[fromIndex].elementSortOrder;
    //   newGroupData[fromIndex].elementSortOrder = newGroupData[toIndex].elementSortOrder;
    //   newGroupData[toIndex].elementSortOrder = tempSortOrder;

    //   // Update state with swapped values
    //   return prevData.map((itm) => {
    //     if (itm.elementName === newGroupData[fromIndex].elementName) {
    //       return { ...itm, elementSortOrder: newGroupData[fromIndex].elementSortOrder };
    //     }
    //     if (itm.elementName === newGroupData[toIndex].elementName) {
    //       return { ...itm, elementSortOrder: newGroupData[toIndex].elementSortOrder };
    //     }
    //     return itm;
    //   });
    // });

    setData((prevData) => {
      // Create a new array (shallow copy) to trigger re-render
      const newData = prevData.map((itm) => ({ ...itm }));

      // Find indexes within newData
      const fromIndex = newData.findIndex(
        (itm) => itm.elementName === draggedItem.elementName && itm.elementGroupName === draggedItem.elementGroupName && itm.elementSortOrder === draggedItem.elementSortOrder
      );
      const toIndex = newData.findIndex(
        (itm) => itm.elementName === targetItem.elementName && itm.elementGroupName === targetItem.elementGroupName && itm.elementSortOrder === targetItem.elementSortOrder
      );

      if (fromIndex === -1 || toIndex === -1) return prevData; // Avoid errors

      // Swap the elementSortOrder values
      [newData[fromIndex].elementSortOrder, newData[toIndex].elementSortOrder] = [newData[toIndex].elementSortOrder, newData[fromIndex].elementSortOrder];

      return newData; // Return new array to trigger state update
    });
    setDraggedItem(null);
  };

  const clearData = () => {
    props.setFormData({
      // contextId: '',
      elementGroupName: '',
      elementGroupId: null,
      elementName: '',
      elementId: null,
      elementSortOrder: null,
      activeInd: true,
      effectiveStartDate: null,
      effectiveEndDate: null,
    });
    setData([]);
  };

  const isUniqueObject = (userObject, existingArray) => {
    return !existingArray.some(
      (item) => item.elementId === userObject.elementId && item.elementGroupId === userObject.elementGroupId 
    );
  };
  
  const addToTable = () => {
    if (
      // !props.formData.contextId ||
      // props.formData.contextId?.length > 50 ||
      !props.formData.elementGroupName ||
      !props.formData.elementName ||
      !props.formData.elementSortOrder
    ) {
      setisRequiredError(true);
      return;
    }
    props.formData.contextId = userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
    if (isUniqueObject(props.formData, listOfAllRecords)) {
      if (isUniqueObject(props.formData, data)) {
        setData((prev) => [...prev, props.formData]); // Push into newArray
        props.setFormData({
          // contextId: '',
          elementGroupName: '',
          elementGroupId: null,
          elementName: '',
          elementId: null,
          elementSortOrder: null,
          activeInd: true,
          effectiveStartDate: null,
          effectiveEndDate: null,
        });
        elementSortOrderInputRef.current.value = null;
        effectiveEndDateRef.current.value = null;
        effectiveStartDateRef.current.value = null;
      } else {
        props.setIsErrorGrowlHidden(false);

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.setMessage('The Element already exists in the group. "Please Enter different Element".');
      }
    } else {
      props.setIsErrorGrowlHidden(false);

      setTimeout(() => {
        props.setIsErrorGrowlHidden(true);
      }, 3000);
      props.setMessage('The Element already exists in the group. "Please Enter different Element".');
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      const params = {
        inputValue,
        contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      };
      await searchPermissionTable(params).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });

        callback(options);
      });
    }
  };
  const loadGroupNameOptions = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      const params = {
        inputValue,
        contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      };
      await searchGroupName(params).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.groupName, value: res.elementGroupId };
        });

        callback(options);
      });
    }
  };
  const loadElementNameOptions = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      const params = {
        inputValue,
        contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      };
      await searchElementName(params).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.elementName, value: res.elementId };
        });

        callback(options);
      });
    }
  };
  // const onChangeGroupName = (event) => {
  //   if (event == null)
  //     return props.setFormData((prevData) => ({
  //       ...prevData,
  //       elementGroupName: '',
  //       elementGroupId: '',
  //     }));
  //   const { value } = event;
  //   props.setFormData((prevData) => ({
  //     ...prevData,
  //     elementGroupName: event.label,
  //     elementGroupId: event.value,
  //   }));
  // };
  const onChangeElementName = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        elementName: '',
        elementId: '',
      }));
    const { value } = event;
    props.setFormData((prevData) => ({
      ...prevData,
      elementName: event.label,
      elementId: event.value,
    }));
  };
  useEffect(() => {
    setisRequiredError(false);
  }, [props.formData, props.isNew]);

  useEffect(() => {
    if (props.isNew === false) {
      //run edit function
      if ((props.formData.elementId === null || props.formData.elementId === '') && (props.formData.elementSortOrder === null || props.formData.elementSortOrder === '')) {
        setData([]);
      } else {
        setData([props.formData]);
      }
    }
  }, [props.formData]);
  const handleElementSortOrderChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      elementSortOrder: parseInt(value),
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        // !props.formData.contextId ||
        // props.formData.contextId?.length > 50 ||
        // !props.formData.elementGroupName ||
        // !props.formData.elementName ||
        // !props.formData.elementSortOrder
        data.length === 0
      ) {
        setisRequiredError(true);
        return;
      }
      let payload = _.cloneDeep(props.formData);
      payload.contextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

      payload.name = payload?.name?.trim();
      payload.codeName = payload?.codeName?.replace(/^_+|_+$/g, '').trim();
      payload.description = payload?.description?.trim();

      props.isLoading(true);
      await savePermissionTable(data)
        .then((response) => {
          if (response.data) {
            props.fetchelementGroupXwalkData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Element Group Xwalk created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            clearData();
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            clearData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEdit = async () => {
    if (!props.formData.elementGroupName || !props.formData.elementName || !props.formData.elementSortOrder || isNaN(props?.formData?.elementSortOrder)) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);

    payload.name = payload?.name?.trim();
    payload.codeName = payload?.codeName?.replace(/^_+|_+$/g, '').trim();
    payload.description = payload?.description?.trim();

    props.isLoading(true);

    await updatePermissionTable(data).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Element Group Xwalk updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchelementGroupXwalkData();
        props.setRecordModalShow(false);

        props.isLoading(false);
        clearData();
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() + 'T00:00:00' : value + 'T00:00:00',
    }));
    // Focus back on the date input after the value is set
    // if (name === 'activeStartDate') {
    //   activeStartDateInputRef.current?.focus();
    // }
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const firstInputRef = useRef(null);
  const elementSortOrderInputRef = useRef(null);
  const effectiveStartDateRef = useRef(null);
  const effectiveEndDateRef = useRef(null);
  useEffect(() => {
    if (props.show === false) {
      setData([]);
    }
  }, [props.show]);
  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
      fetchAllRecords();
      let data = { contextId: props.formData?.contextId, elementGroupId: props?.formData?.elementGroupId };
      if (props.isNew === false) {
        getAllEleGroups(data).then((response) => {
          if (response.data) {
            setData(response?.data);
          }
        });
      }
    }
  }, [props.show]);

  const fetchAllRecords = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };
    await searchPermissionTable(payload).then((response) => {
      setListOfAllRecords(response.data);
    });
  };

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  // Fetch options from API
  const loadOptions = async (inputValue) => {
    if (!inputValue.trim()) return [];

    const params = {
      inputValue,
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };

    try {
      const response = await searchGroupName(params);
      return (
        response?.data?.map((res) => ({
          label: res.groupName,
          value: res.elementGroupId,
        })) || []
      );
    } catch (error) {
      console.error('Error fetching group names:', error);
      return [];
    }
  };

  // Handle new option creation
  const handleCreate = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    onChangeGroupName(newOption, 'fromCreate');
  };

  // Handle selection change
  const onChangeGroupName = (selectedOption, fromCreate) => {
    if (!selectedOption) {
      props.setFormData((prevData) => ({
        ...prevData,
        elementGroupName: '',
        elementGroupId: '',
      }));
      return;
    }
    if (fromCreate === 'fromCreate') {
      props.setFormData((prevData) => ({
        ...prevData,
        elementGroupName: selectedOption.label,
        elementGroupId: null, // Use label if no ID
      }));
    } else {
      props.setFormData((prevData) => ({
        ...prevData,
        elementGroupName: selectedOption.label,
        elementGroupId: selectedOption.value || selectedOption.label, // Use label if no ID
      }));
    }
  };
  return (
    <Modal
      {...props}
      className={[styles.elementGroupXwalkModal, 'glbElementGroupXwalkModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Element Group Xwalk' : 'Edit Element Group Xwalk'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
                clearData();
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.elementGroupXwalkModalBody}>
          <div className={styles.modalBodySection}>
            {props.isNew ? (
              <></>
            ) : (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Group Name *</label>
                {props.isNew ? (
                  <AsyncCreatableSelect
                    ref={props.isNew ? firstInputRef : null}
                    onFocus={() => handleFocus(firstInputRef)}
                    styles={customStyles}
                    isClearable
                    // isDisabled={!props.isNew}
                    loadOptions={loadOptions} // Async API fetching
                    defaultOptions // Load options initially
                    value={props?.formData?.elementGroupName ? { label: props?.formData?.elementGroupName, value: props?.formData?.elementGroupName } : null}
                    onChange={(e) => {
                      onChangeGroupName(e, null);
                    }}
                    onCreateOption={handleCreate} // Create new options dynamically
                  />
                ) : (
                  <AsyncSelect
                    ref={props.isNew === true ? firstInputRef : null}
                    onFocus={() => handleFocus(firstInputRef)}
                    styles={customStyles}
                    isClearable
                    loadOptions={loadGroupNameOptions}
                    value={
                      props?.formData?.elementGroupName !== '' && props?.formData?.elementGroupName !== undefined && props?.formData?.elementGroupName !== null
                        ? { label: props?.formData?.elementGroupName, value: props?.formData?.elementGroupName }
                        : { label: '---Select---', value: '---Select---' }
                    }
                    onChange={onChangeGroupName}
                  />
                )}
                {(props.formData.elementGroupName === null || props.formData.elementGroupName === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Element Name *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={props.isNew ? false : true}
                  loadOptions={loadElementNameOptions}
                  value={
                    props?.formData?.elementName !== '' && props.formData?.elementName !== null && props.formData?.elementName !== undefined
                      ? { label: props?.formData?.elementName, value: props?.formData?.elementName }
                      : { label: '---Select---', value: '---Select---' }
                  }
                  onChange={onChangeElementName}
                />
                {(props.formData.elementName === null || props.formData.elementName === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Element Sort Order *</label>
                <input
                  ref={props.isNew === false ? firstInputRef : elementSortOrderInputRef}
                  onFocus={() => handleFocus(elementSortOrderInputRef)}
                  required
                  name="elementSortOrder"
                  disabled={props.isNew ? false : true}
                  defaultValue={props?.formData?.elementSortOrder}
                  onChange={handleElementSortOrderChange}
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === '.' || e.key === '-' || e.key === '+' || e.key === '=') e.preventDefault();
                  }}
                />
                {(props?.formData?.elementSortOrder === null ||
                  props?.formData?.elementSortOrder === '' ||
                  props?.formData?.elementSortOrder === 0 ||
                  props?.formData?.elementSortOrder === undefined ||
                  isNaN(props?.formData?.elementSortOrder)) &&
                  isRequiredError === true &&
                  handleIsRequiredError()}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Effective Start Date</label>
                <input
                  ref={props.isNew === false ? firstInputRef : effectiveStartDateRef}
                  name="effectiveStartDate"
                  onFocus={() => handleFocus(effectiveStartDateRef)}
                  onChange={handleInputChange}
                  defaultValue={props?.formData?.effectiveStartDate?.split('T')[0]}
                  type="date"
                  // min={new Date().toISOString().split('T')[0]}
                />
                {/* {(props.formData.activeStartDate === null || props.formData.activeStartDate === '') && isRequiredError === true && handleIsRequiredError()} */}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Effective End Date</label>
                {/* disabled={!props.isNew} */}
                <input
                  ref={props.isNew === false ? firstInputRef : effectiveEndDateRef}
                  onFocus={() => handleFocus(effectiveEndDateRef)}
                  name="effectiveEndDate"
                  onChange={handleInputChange}
                  defaultValue={props?.formData?.effectiveEndDate?.split('T')[0]}
                  type="date"
                  // min={new Date().toISOString().split('T')[0]}
                />
                {/* {(props.formData.activeStartDate === null || props.formData.activeStartDate === '') && isRequiredError === true && handleIsRequiredError()} */}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6 pl-0'].join(' ')}>
                <div className={[styles.radioInputOuterDiv, 'mt-1'].join(' ')} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>
            {props.isNew === true && (
              <div className={styles.AddEleXwalkContainer}>
                <img src={AddEleXwalk} alt="AddEleXWalk-btn" onClick={addToTable}></img>
              </div>
            )}
            <div className={styles.xwalkTableContainer}>
              {/*refned Ver */}
              {/* <table className={styles.tableOuter}>
                <thead>
                  <tr className={styles.tableRow}>
                    <th className={styles.tableHead}>Element Name</th>
                    <th className={styles.tableHead}>Group Name</th>
                    <th className={styles.tableHead} onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')} style={{ cursor: 'pointer' }}>
                      Element Sort Order {sortOrder === 'asc' ? '▲' : '▼'}
                    </th>
                    <th className={styles.tableHead}>Effective Start Date</th>
                    <th className={styles.tableHead}>Effective End Date</th>
                    <th className={styles.tableHead}>Active Ind</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedData).map((groupName, index) => (
                    <React.Fragment key={index}>
                      
                      {index !== 0 && (
                        <tr className={styles.groupRow}>
                          <td colSpan="6" className={styles.groupHeader}></td>
                        </tr>
                      )}

                      {groupedData[groupName].map((itm, idx) => (
                        <tr key={`${index}-${idx}`}>
                          <td className={styles.tableDataCell}>{itm?.elementName}</td>
                          <td className={styles.tableDataCell}>{itm?.elementGroupName}</td>
                          <td className={styles.tableDataCell}>{itm?.elementSortOrder}</td>
                          <td className={styles.tableDataCell}>{itm?.effectiveStartDate}</td>
                          <td className={styles.tableDataCell}>{itm?.effectiveEndDate}</td>
                          <td className={styles.tableDataCell}>{itm?.activeInd}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table> */}
              <table className={styles.tableOuter}>
                <thead>
                  <tr className={styles.tableRow}>
                    <th className={styles.tableHead}>Element Name</th>
                    <th className={styles.tableHead}>Group Name</th>
                    <th className={styles.tableHead}>Element Sort Order</th>
                    <th className={styles.tableHead}>Effective Start Date</th>
                    <th className={styles.tableHead}>Effective End Date</th>
                    <th className={styles.tableHead}>Active Ind</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedData).map((groupName, index) => (
                    <React.Fragment key={index}>
                      {/* Group Separator */}
                      {index !== 0 && (
                        <tr className={styles.groupRow}>
                          <td colSpan="6" className={styles.groupHeader}></td>
                        </tr>
                      )}
                      {/* Draggable Rows */}
                      {groupedData[groupName].map((itm, idx) => (
                        <tr
                          key={idx}
                          className={styles.tableRow}
                          draggable
                          onDragStart={() => handleDragStart(itm)}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() => handleDrop(itm)}
                          style={{ cursor: 'grab' }}
                        >
                          <td className={styles.tableDataCell}>{itm?.elementName}</td>
                          <td className={styles.tableDataCell}>{itm?.elementGroupName}</td>
                          <td className={styles.tableDataCell}>{itm?.elementSortOrder}</td>
                          <td className={styles.tableDataCell}>{itm?.effectiveStartDate?.split('T')[0]}</td>
                          <td className={styles.tableDataCell}>{itm?.effectiveEndDate?.split('T')[0]}</td>
                          <td className={styles.tableDataCell}>{itm?.activeInd.toString()}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.orchestrationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton
                disabled={data.length === 0 ? true : false}
                title="Save"
                parentWarpper={'d-flex align-items-center'}
                className={styles.saveRecordBtn}
                onClick={handleSubmit}
              />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
                clearData();
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
