import axios from 'axios';

export async function createPackagerPreferredOrder(data) {
  let updateResponse = [];
  try {
    const url = '/buow_api/packagerpreferredorder';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in creating routing orchestration: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function updateOnePackagerPreferredOrder(data) {
  console.log(data);
  let updateResponse = [];
  try {
    const url =
      window.env.REACT_APP_BACKEND_URL +
      `/buow_api/packagerpreferredorder?buowName=${data?.buowName}&contextId=${data.contextId}&objectId=${data.objectId}&position=${data.position}`;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deletePackagerPreferredOrder(params) {
  let deleteResponse = {};
  try {
    const url = `/buow_api/packagerpreferredorder?buowName=${params?.buowName}&contextId=${params.contextId}&objectId=${params.objectId}&position=${params.position}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchPackagerPreferredOrder(data) {
  let workQueueFilterResponse = [];
  try {
    let url = `${window.env.REACT_APP_BACKEND_URL}/buow_api/packagerpreferredorder/search`;

    if (data.text !== '' && data.column === '' && data.order === '') {
      url += '?keywords=' + data.text;
    } else {
      url += '?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
