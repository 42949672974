import React, { useEffect, useState, useRef } from 'react';
import { Dialog, Window } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { getHolds, searchHolds } from '../../services/recordsManagementService';
import HoldAdvSearch from './HoldAdvSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { EventEmitter } from '../../services/events';
import holdFields from '../../json/holdFields.json';
import HoldSuccessGrowl from './HoldSuccessGrowl';
import HoldEditSuccessGrowl from './HoldEditSuccessGrowl';
import HoldManagementForm from './HoldManagementForm';
import EditHold from './EditHold';
import Table from '../Table/Table';
import { RecordReferencesProvider } from '../RecordReferences/RecordReferencesProvider';
import UserContext from '../UserContext/UserContext';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';
import '../../GlobalStyle.scss'

export default function HoldManagement() {
  const { EventEmitter } = require('../../services/events');

  const [holdName, setHoldName] = useState('');
  const [modalLocation, setModalLocation] = useState();
  const elementRef = useRef();
  const [selectedHoldObject, setSelectedHoldObject] = useState('');
  const [isHoldSuccessGrowlHidden, setIsHoldSuccessGrowlHidden] = useState(true);
  const [isHoldEditSuccessGrowlHidden, setIsHoldEditSuccessGrowlHidden] = useState(true);
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [createHoldOpen, setCreateHoldOpen] = useState(false);
  const [editHoldOpen, setEditHoldOpen] = useState(false);
  const [holdData, setHoldData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const { userPermissions } = React.useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');

  // Track the advSearch values so that the values initially appear when the modal is reopened
  const [advSearch, setAdvSearch] = useState({});

  useEffect(() => {
    setLoading(true);
    getHolds().then((response) => {
      setHoldData(response.data);
      setLoading(false);
    });

    if (elementRef) {
      const defaultValue = { bottom: 202.09375, height: 25.5, left: 105, right: 260.7265625, top: 176.59375, width: 155.7265625, x: 105, y: 176.59375 };

      const divElement = elementRef?.current?.getBoundingClientRect() || defaultValue;
      setModalLocation(divElement);
    }

    EventEmitter.on('refreshHoldGrid', () => {
      getHolds().then((response) => {
        setHoldData(response.data);
      });
    });
    EventEmitter.on('toggleEditModal', () => {
      setCreateHoldOpen(false);
    });
  }, []);

  const handleAdvModal = () => {
    setSearchPopupOpen(!searchPopupOpen);
  };
  const handleCreateModal = () => {
    setCreateHoldOpen(!createHoldOpen);
  };
  const handleEditModal = () => {
    setEditHoldOpen(!editHoldOpen);
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    const newPage = page.skip / page.take;
    setPage(newPage);
  };

  const onRowClick = (e) => {
    setSelectedHoldObject({ ...e.dataItem });
    handleEditModal();
  };

  const handleKeywordChange = (e) => {
    setHoldName(e.target.value);
  };

  const handleKeywordSearch = () => {
    searchHolds({ holdName: holdName })
      .then((response) => {
        setHoldData(response.data);
        setPage(0);
      })
      .catch((err) => {
        console.log(err);
        setMessage(err.message);
        setIsErrorGrowlHidden(false);

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
          setMessage('');
        }, 5000);
        // addNotification({ style: 'error', msg: err.message });
      });
  };

  return (
    <RecordReferencesProvider>
      {isLoading && <GlobalLoader />}
      <div className="content">
        <div className="pageContent">
          <h1 className="HeaderOne-StyleOne">Manage Holds</h1>
          <div className="flex">
            {userPermissions['Search_Hold_By_Keyword'] === true && (
              <div className="formRow">
                <div className="form-field" style={{ width: '30%', marginRight: '1.5rem' }}>
                  <label className="fsa-field__label" htmlFor="filecode">
                    Search Hold Name:
                  </label>
                  <Input id="keywordSearch" name="keywordSearch" value={holdName} onChange={handleKeywordChange} className="fsa-input fc-keyword-input" />
                </div>
                <div className="form-field">
                  <button className="fsa-btn" onClick={handleKeywordSearch}>
                    Search
                  </button>
                </div>
              </div>
            )}

            {userPermissions['Search_Hold_By_Advance_Search'] === true && (
              <div className="flexrow underKeywordContainer">
                <div className="modal-link-con" ref={elementRef}>
                  <p className="modal-link" style={{ cursor: 'pointer' }} onClick={handleAdvModal}>
                    Advanced Search
                    <span>
                      {searchPopupOpen ? (
                        <FontAwesomeIcon onClick={handleAdvModal} className="float-right chevron" icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                      )}
                    </span>
                  </p>
                </div>
              </div>
            )}

            <div className="fc-grid-head-row">
              <h3>Hold Search Results</h3>
              <div className="form-field">
                {userPermissions['Create_Hold'] === true && (
                  <button className="fsa-btn lite-btn" onClick={handleCreateModal}>
                    Create Hold
                  </button>
                )}
              </div>
            </div>

            {searchPopupOpen && (
              <Dialog
                minimizeButton={() => null}
                maximizeButton={() => null}
                title={'Advanced Search'}
                onClose={handleAdvModal}
                // initialHeight={600}
                // initialWidth={900}
                // initialLeft={modalLocation?.left}
                // initialTop={modalLocation?.top + 45}
                className="glbModalHeader"
              >
                <HoldAdvSearch handleAdvModal={handleAdvModal} setHoldData={setHoldData} initialValues={advSearch} setInitialValues={setAdvSearch} />
              </Dialog>
            )}

            {createHoldOpen && (
              <Window
                minimizeButton={() => null}
                maximizeButton={() => null}
                title={'Create Hold'}
                onClose={handleCreateModal}
                initialHeight={600}
                initialWidth={900}
                restoreButton={() => null}
                modal={true}
                draggable={false}
                className="glbModalHeader"
              >
                <HoldManagementForm onClose={handleCreateModal} />
              </Window>
            )}

            {editHoldOpen && (
              <Window
                minimizeButton={() => null}
                maximizeButton={() => null}
                title={'Edit Hold'}
                onClose={handleEditModal}
                initialHeight={660}
                initialWidth={900}
                restoreButton={() => null}
                modal={true}
                draggable={false}
                className="glbModalHeader"
              >
                <div className='glbEditHoldModal' >
                <EditHold holdObject={selectedHoldObject} onClose={handleEditModal} /></div>
              </Window>
            )}
          </div>

          <Table data={holdData} fields={holdFields} total={holdData.length} onRowClick={onRowClick} page={page} pageSize={pageSize} onPageChange={onPageChange} />
          <div className="alertBar-right">
            <HoldSuccessGrowl isHoldSuccessGrowlHidden={isHoldSuccessGrowlHidden} setIsHoldSuccessGrowlHidden={setIsHoldSuccessGrowlHidden} />
            <HoldEditSuccessGrowl isHoldEditSuccessGrowlHidden={isHoldEditSuccessGrowlHidden} setIsHoldEditSuccessGrowlHidden={setIsHoldEditSuccessGrowlHidden} />
          </div>
        </div>
      </div>
      <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
    </RecordReferencesProvider>
  );
}
