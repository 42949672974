import axios from 'axios';

export async function getBUOWDisplayNames() {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/buowdisplayname';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function createBUOWDisplayName(data) {
  let createResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/buowdisplayname';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.response.data);
    createResponse.error = ex.response.data;
  }
  // console.log(createResponse);
  return createResponse;
}

export async function updateBUOWDisplayName(data) {
  let updateResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/buowdisplayname/' + data.id;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteBUOWDisplayName(data) {
  let deleteResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/buowdisplayname/${data.id}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchBUOWDisplayName(data) {
  let workQueueFilterResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/buow_api/buowdisplayname/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
