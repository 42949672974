import React, { useEffect, useState } from 'react';
import { Routes, useLocation, useNavigate } from 'react-router-dom';
import DrmsSearch from './components/search/DrmsSearch';
// import ThumbnailsSearch from './components/search/ThumbnailsSearch/ThumbnailsSearch';
import Dashboard from './components/Dashboard/Dashboard';
import FilePlan from './components/FilePlan/NewFilePlan';
import FileCodeSearch from './components/FileCode/FileCodeSearch';
import SuspenseQueue from './components/SuspenseQueue/SuspenseQueue';
import HoldManagement from './components/Holds/HoldManagement';
import TransferManagement from './components/Transfer/TransferManagement';
import SideNavBar from './components/SideNavBar/SideNavBar';
import InBasketPage from './components/InBasketPage/InBasketPage';
// import BUOWPage from './components/BUOWPage/BUOWPage';

// import SignInPage from './components/Login/SignInPage';
import ScanOnDemand from './components/ScanOnDemand/ScanOnDemand';
import RuleTrigger from './pages/AdminPanel/RuleTrigger/RuleTrigger';

import RuleDefinition from './pages/AdminPanel/RuleDefinition/RuleDefinition';
import RoutingSteps from './pages/AdminPanel/RoutingSteps/RoutingSteps';
import Orchestration from './pages/AdminPanel/Orchestration/Orchestration';
import ElementGroup from './pages/AdminPanel/ElementGroup/ElementGroup';
import RedactionActor from './pages/AdminPanel/RedactionActor/RedactionActor';
import RedactionConfiguration from './pages/AdminPanel/Redaction Configuration/RedactionConfiguration'
import RedactionReason from './pages/AdminPanel/RedactionReason/RedactionReason';
import SystemConfiguration from './pages/AdminPanel/SystemConfiguration/SystemConfiguration';
import UsersTable from './pages/AdminPanel/UsersTable/UsersTable';
import RolesTable from './pages/AdminPanel/RolesTable/RolesTable';
import PermissionsTable from './pages/AdminPanel/PermissionsTable/PermissionsTable';
import BuowTable from './pages/AdminPanel/BUOW/Buow';
import AdministratorPanel from './pages/AdminPanel/AdminSearchPage/AdminSearchPage';
import UserGuide from './pages/AdminPanel/UserGuide/UserGuide';
import InBasketTable from './pages/AdminPanel/InBasketTable/InBasketTable';
import PackagerConfiguration from './pages/AdminPanel/PackagerConfiguration/PackagerConfiguration';
import DashboardIndicatorsGroup from './pages/AdminPanel/DashboardIndicatorsGroup/DashboardIndicatorsGroup';
import DashboardIndicators from './pages/AdminPanel/DashboardIndicators/DashboardIndicators';
import DashboardIndicatorsDataSetCardXRef from './pages/AdminPanel/DashboardIndicatorsDataSetCardXRef/DashboardIndicatorsDataSetCardXRef';
import DashboardGroupCardXRef from './pages/AdminPanel/DashboardGroupCardXRef/DashboardGroupCardXRef';
import SecRoleDashboardCardXRef from './pages/AdminPanel/SecRoleDashboardCardXRef/SecRoleDashboardCardXRef';
import DashboardCardBenchmarkDisplay from './pages/AdminPanel/DashboardCardBenchmarkDisplay/DashboardCardBenchmarkDisplay';
import IndicatorsDataSet from './pages/AdminPanel/IndicatorsDataSet/IndicatorsDataSet';
import Elements from './pages/AdminPanel/ElementsCRUD/Elements';
import RuleTriggerMetadata from './pages/AdminPanel/RuleTriggerMetadata/RuleTriggerMetadata';
import Search from './pages/Search/SearchPage'; //New search page
// import Profile from './pages/Profile/Profile';

import Head from './components/Head/head';
// import Foot from './components/Foot/foot';
// import { Security, LoginCallback } from '@okta/okta-react';
// import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import PrivateRoute from './PrivateRoute';
import DashboardSMC from './components/Dashboard/DashboardOld';
import ManageDisposition from './components/ManageDisposition/ManageDisposition';

import Login from './components/Login/Login';
import { Route } from 'react-router-dom';
import AdminRoute from './AdminRoute';
import SearchFolderTable from './pages/AdminPanel/SearchFolderOptions/SearchFolderOptionsTable';
import UserPreference from './pages/AdminPanel/UserPreference/UserPreference';
import AdministratorRoute from './AdministratorRoute';
import NoAccess from './pages/NoAccess/NoAccess';
import UserInfo from './pages/AdminPanel/UserInfo/UserInfo';
import packageJson from '../package.json';
import BUOWDisplayName from './pages/AdminPanel/BUOWDisplayName/BUOWDisplayName';
import WorkGroup from './pages/AdminPanel/WorkGroup/WorkGroup';
import RuleLog from './pages/AdminPanel/RuleLog/RuleLog';
import Messages from './pages/AdminPanel/Messages/Message';
import UnitOfWork from './pages/AdminPanel/UnitOfWork/UnitOfWork';
import WorkGroupUserXWalk from './pages/AdminPanel/WorkGroupUserXWalk/WorkGroupUserXWalk';
import ObjectHierarchyTable from './pages/AdminPanel/ObjectHierarchyTable/ObjectHierarchy';
import RedactionHistoryTable from './pages/AdminPanel/RedactionHistoryTable/RedactionHistory';
import RedactionReference from './pages/AdminPanel/RedactionReference/RedactionReference';

import ObjectDefinition from './pages/AdminPanel/ObjectDefinition/ObjectDefinition';

import RedactionDefinition from './pages/AdminPanel/RedactionDefinition/RedactionDefinition';

import ObjectDictionaryObjectTable from './pages/AdminPanel/ObjectDictionaryObject/ObjectDictionaryObject';
import ObjectDictionaryObjectTypeTable from './pages/AdminPanel/ObjectDictionaryObjectType/ObjectDictionaryObjectType';
import BatchDefinitionTable from './pages/AdminPanel/BatchDefinitionTable/BatchDefinition';

import PackagerPreferredOrderTable from './pages/AdminPanel/PackagerPreferredOrderTable/PackagerPreferredOrder';

import BatchXwalk from './pages/AdminPanel/BatchXwalk/BatchXwalk';
import ObjectDefinitionTable from './pages/AdminPanel/ObjectDefinitionCRUD/ObjectDefinitionTable';
import RoutingConditions from './pages/AdminPanel/RoutingConditions/RoutingConditions';
import ElementGroupXwalk from './pages/AdminPanel/ElementGroupXwalk/ElementGroupXwalk';
const BUOWPage = React.lazy(() => import('./components/BUOWPage/BUOWPage'));
// import BUOWPage from  "./components/BUOWPage/BUOWPage";

const buildDateGreaterThan = (latestDate, currentDate) => {
  if (latestDate > currentDate) {
    return true;
  } else {
    return false;
  }
};

//import queryString from 'query-string';
const RouterControl = () => {
  let query = useQuery();
  const navigate = useNavigate();
  const onAuthRequired = () => navigate('/');
  const currentPath = window.location.pathname;

  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  useEffect(() => {
    // Your code to handle URL change and trigger a rerender
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDateTime;
        const currentVersionDate = packageJson.buildDateTime;
        const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
        console.log(packageJson.version, 'APP_VERSION');
        if (shouldForceRefresh) {
          setIsLatestBuildDate(false);
          refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
        }
      });
  }, [currentPath]); // Only rerender on pathname change

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  // const oktaAuth = new OktaAuth({
  //   issuer: `https://${window.env.REACT_APP_OKTA_BASE_URL}/oauth2/default`,
  //   clientId: window.env.REACT_APP_OKTA_CLIENT_ID,
  //   redirectUri: `${window.location.origin}/login/callback`,
  //   scopes: ['openid', 'profile', 'email'],
  //   onAuthRequired: onAuthRequired,
  //   pkce: true,
  //   offline_access: true,
  //   tokenManager: {
  //     storage: 'sessionStorage',
  //   },
  //   postLogoutRedirectUri: `${window.location.origin}/login`,
  // });

  /* Start->Commented changes for login page */

  // const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  //   history.replace(toRelativeUrl(originalUri || "/inbasketpage", window.location.origin));
  // };

  /* End->Commented changes for login page */

  // const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  // navigate(toRelativeUrl(originalUri || '/inbasketpage', window.location.origin), { replace: true });
  // };
  return (
    <div>
      {/* NOTE:-  Added Security tag for OKTA Integration */}
      {/* <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}> */}
      {/* Start->Commented changes for login page */}
      {/* {currentPath !== '/' && <Head></Head> }
                  <div style={ currentPath !== '/' ? {display: "flex"} : {} }>
                  {currentPath !== '/' && <SideNavBar></SideNavBar> } */}
      {/* End->Commented changes for login page */}
      {currentPath !== '/login' && currentPath !== '/oktalogin' && currentPath !== '/access-error' && <Head> </Head>}
      <div
        style={
          currentPath !== '/login' && currentPath !== '/oktalogin'
            ? {
                display: 'flex',
              }
            : {}
        }
      >
        {currentPath !== '/login' && currentPath !== '/oktalogin' && currentPath !== '/access-error' && <SideNavBar> </SideNavBar>}
        <Routes>
          {/* {window.env.REACT_APP_FE_ENVIRONMENT !== undefined ? (
              <PrivateRoute query={query.get('query')} path="/scan" component={ScanOnDemand} />
            ) : (
              <PrivateRoute query={query.get('query')} path="/scan" component={SuspenseQueue} />
            )}
            <PrivateRoute query={query.get('query')} path="/search" component={DrmsSearch} /> */}

          {/* <PrivateRoute query={query.get('query')} path="/search" component={ThumbnailsSearch} /> */}
          {/* <PrivateRoute query={query.get('query')} path="/fileplan" component={FilePlan} />
                            <PrivateRoute query={query.get('query')} path="/filecode" component={FileCodeSearch} />
                            <PrivateRoute query={query.get('query')} path="/suspenseQueue" component={SuspenseQueue} />
                            <PrivateRoute query={query.get('query')} path="/holds" component={HoldManagement} />
                            <PrivateRoute query={query.get('query')} path="/transfer" component={TransferManagement} /> */}
          {/* <PrivateRoute query={query.get('query')} path="/dashboard" component={Dashboard} /> */}

          {/* <PrivateRoute query={query.get('query')} path="/buow/:id" element={<BUOWPage />} />
            <PrivateRoute query={query.get('query')} path="/transfer" element={<TransferManagement />} />
            <PrivateRoute query={query.get('query')} path="/dashboard" element={<Dashboard />} /> */}
          {/* <AdminRoute query={query.get('query')} path="/admin" element={<AdministratorPanel />} /> */}

          {/* <AdminRoute query={query.get('query')} path="/routingsteps" element={<RoutingSteps />} />
            <AdminRoute query={query.get('query')} path="/orchestration" element={<Orchestration />} />
            <AdminRoute query={query.get('query')} path="/redaction-actor" element={<RedactionActor />} />
            <AdminRoute query={query.get('query')} path="/redaction-reason" element={<RedactionReason />} />
            <AdminRoute query={query.get('query')} path="/packager-configuration" element={<PackagerConfiguration />} />
            <AdminRoute query={query.get('query')} path="/batch-xwalk" element={<BatchXwalk />} />
            <AdminRoute query={query.get('query')} path="/systemconfiguration" element={<SystemConfiguration />} />
            <AdminRoute query={query.get('query')} path="/buowdisplayname" element={<BUOWDisplayName />} /> */}

          {/* <AdminRoute query={query.get('query')} path="/ruledefinition" element={<RuleDefinition />} />
            <AdminRoute query={query.get('query')} path="/userstable" element={<UsersTable />} />
            <AdminRoute query={query.get('query')} path="/rolestable" element={<RolesTable />} />
            <AdminRoute query={query.get('query')} path="/permissionstable" element={<PermissionsTable />} />
            <AdminRoute query={query.get('query')} path="/system-folder" element={<SearchFolderTable />} />
            <AdminRoute query={query.get('query')} path="/user-preference" element={<UserPreference />} />
            <AdminRoute query={query.get('query')} path="/buowtable" element={<BuowTable />} />
            <AdminRoute query={query.get('query')} path="/work-group" element={<WorkGroup />} />
            <AdminRoute query={query.get('query')} path="/userguide" element={<UserGuide />} /> */}

          {/* <AdminRoute query={query.get('query')} path="/inbaskettable" element={<InBasketTable />} />
            <AdminRoute query={query.get('query')} path="/ruletrigger" element={<RuleTrigger />} />
            <AdminRoute query={query.get('query')} path="/unit-of-work" element={<UnitOfWork />} />
            <AdminRoute query={query.get('query')} path="/redactionhistorytable" element={<RedactionHistoryTable />} />
            <AdminRoute query={query.get('query')} path="/messages" element={<Messages />} />
            <AdminRoute query={query.get('query')} path="/workgroupuserxwalk" element={<WorkGroupUserXWalk />} />
            <AdminRoute query={query.get('query')} path="/Object-hierarchy-table" element={<ObjectHierarchyTable />} />
            <AdminRoute query={query.get('query')} path="/elements" element={<Elements />} />
            <AdminRoute query={query.get('query')} path="/element-group" element={<ElementGroup /> } />
            <AdminRoute query={query.get('query')} path="/rule-log" element={<RuleLog />} />
            <AdminRoute query={query.get('query')} path="/redactiondefinition" element={<RedactionDefinition />} />
            <AdminRoute query={query.get('query')} path="/dashboard-indicators-group" element={<DashboardIndicatorsGroup />} /> */}

          {/* <AdminRoute query={query.get('query')} path="/dashboard-indicator" element={<DashboardIndicators />} />
            <AdminRoute query={query.get('query')} path="/dashboard-indicators-dataSet-card-xref" element={<DashboardIndicatorsDataSetCardXRef />} />
            <AdminRoute query={query.get('query')} path="/dashboard-group-card-xref" element={<DashboardGroupCardXRef />} />
            <AdminRoute query={query.get('query')} path="/dashboard-card-benchmark-display" element={<DashboardCardBenchmarkDisplay />} />
            <AdminRoute query={query.get('query')} path="/sec-role-dashboard-card-xref" element={<SecRoleDashboardCardXRef />} />
            <AdminRoute query={query.get('query')} path="/indicators-data-set" element={<IndicatorsDataSet />} />
            <AdminRoute query={query.get('query')} path="/routingconditions" element={<RoutingConditions />} />
            <AdminRoute query={query.get('query')} path="/objectdefinition" element={<ObjectDefinition />} />
            <AdminRoute query={query.get('query')} path="/objectdefinitiontable" element={<ObjectDefinitionTable />} /> */}

          {/* <PrivateRoute query={query.get('query')} path="/packager-preferred-order" element={<PackagerPreferredOrderTable />} /> */}
          {/* SMC Routes Start */}
          {/* <Route query={query.get('query')} path="/login" element={<Login />} /> */}

          {/* <PrivateRoute query={query.get('query')} path="/" element={<InBasketPage />} />
            <PrivateRoute query={query.get('query')} path="/dashboard" element={<DashboardSMC />} />
            <PrivateRoute query={query.get('query')} path="/search" element={<DrmsSearch />} />
            <PrivateRoute query={query.get('query')} path="/holds" element={<HoldManagement />} />
            <PrivateRoute query={query.get('query')} path="/transfer" element={<TransferManagement />} />
            <PrivateRoute query={query.get('query')} path="/manage" element={<ManageDisposition />} />
            <PrivateRoute query={query.get('query')} path="/fileplan" element={<FilePlan />} />
            <PrivateRoute query={query.get('query')} path="/filecode" element={<FileCodeSearch />} /> */}

          {/* <PrivateRoute query={query.get('query')} path="/scan" element={SuspenseQueue} /> 
            <PrivateRoute query={query.get('query')} path="/inbasketpage" element={<InBasketPage />} />
            <PrivateRoute query={query.get('query')} path="/newsearch" element={<Search />} />
            <PrivateRoute query={query.get('query')} path="/profile" element={<UserInfo />} /> */}

          {/* Route for Okta callback */}
          {/* <Route path="/login/callback" element={<LoginCallback />} /> */}
          {/* <Route query={query.get('query')} path="/access-error" element={<NoAccess />} /> */}

          {/* <PrivateRoute query={query.get('query')} path="/objectdictionaryobject" element={<ObjectDictionaryObjectTable />} />
            <PrivateRoute query={query.get('query')} path="/objectdictionaryobjecttype" element={<ObjectDictionaryObjectTypeTable />} />
            <PrivateRoute query={query.get('query')} path="/batchdefinition" element={<BatchDefinitionTable />} />
            <PrivateRoute query={query.get('query')} path="/elementgroupxwalk" element={<ElementGroupXwalk />} /> */}

          {window.env.REACT_APP_FE_ENVIRONMENT !== undefined ? (
            <Route path="/scan" query={query.get('query')} element={<PrivateRoute path="/scan" element={<ScanOnDemand />} />} />
          ) : (
            <Route path="/scan" query={query.get('query')} element={<PrivateRoute path="/scan" element={<SuspenseQueue />} />} />
          )}
          <Route path="/search" query={query.get('query')} element={<PrivateRoute path="/search" element={<DrmsSearch />} />} />

          <Route path="/buow/:id" query={query.get('query')} element={<PrivateRoute path="/buow/:id" element={<BUOWPage />} />} />
          <Route path="/transfer" query={query.get('query')} element={<PrivateRoute path="/transfer" element={<TransferManagement />} />} />
          <Route path="/dashboard" query={query.get('query')} element={<PrivateRoute path="/dashboard" element={<Dashboard />} />} />
          <Route path="/admin" query={query.get('query')} element={<AdminRoute path="/admin" element={<AdministratorPanel />} />} />

          <Route path="/routingsteps" query={query.get('query')} element={<AdminRoute path="/routingsteps" element={<RoutingSteps />} />} />
          <Route path="/orchestration" query={query.get('query')} element={<AdminRoute path="/orchestration" element={<Orchestration />} />} />
          <Route path="/redaction-actor" query={query.get('query')} element={<AdminRoute path="/redaction-actor" element={<RedactionActor />} />} />
          <Route path="/redaction-reason" query={query.get('query')} element={<AdminRoute path="/redaction-reason" element={<RedactionReason />} />} />
          <Route path="/redaction-reference" query={query.get('query')} element={<AdminRoute path="/redaction-reference" element={<RedactionReference />} />} />
          <Route path="/packager-configuration" query={query.get('query')} element={<AdminRoute path="/packager-configuration" element={<PackagerConfiguration />} />} />
          <Route path="/batch-xwalk" query={query.get('query')} element={<AdminRoute path="/batch-xwalk" element={<BatchXwalk />} />} />
          <Route path="/systemconfiguration" query={query.get('query')} element={<AdminRoute path="/systemconfiguration" element={<SystemConfiguration />} />} />
          <Route path="/buowdisplayname" query={query.get('query')} element={<AdminRoute path="/buowdisplayname" element={<BUOWDisplayName />} />} />

          <Route path="/ruledefinition" query={query.get('query')} element={<AdminRoute path="/ruledefinition" element={<RuleDefinition />} />} />
          <Route path="/userstable" query={query.get('query')} element={<AdminRoute path="/userstable" element={<UsersTable />} />} />
          <Route path="/rolestable" query={query.get('query')} element={<AdminRoute path="/rolestable" element={<RolesTable />} />} />
          <Route path="/permissionstable" query={query.get('query')} element={<AdminRoute path="/permissionstable" element={<PermissionsTable />} />} />
          <Route path="/system-folder" query={query.get('query')} element={<AdminRoute path="system-folder" element={<SearchFolderTable />} />} />
          <Route path="/user-preference" query={query.get('query')} element={<AdminRoute path="/user-preference" element={<UserPreference />} />} />
          <Route path="/buowtable" query={query.get('query')} element={<AdminRoute path="/buowtable" element={<BuowTable />} />} />
          <Route path="/work-group" query={query.get('query')} element={<AdminRoute path="/work-group" element={<WorkGroup />} />} />
          <Route path="/userguide" query={query.get('query')} element={<AdminRoute path="/userguide" element={<UserGuide />} />} />

          <Route path="/inbaskettable" query={query.get('query')} element={<AdminRoute path="/inbaskettable" element={<InBasketTable />} />} />
          <Route path="/ruletrigger" query={query.get('query')} element={<AdminRoute path="/ruletrigger" element={<RuleTrigger />} />} />
          <Route path="/unit-of-work" query={query.get('query')} element={<AdminRoute path="/unit-of-work" element={<UnitOfWork />} />} />
          <Route path="/redactionhistorytable" query={query.get('query')} element={<AdminRoute path="/redactionhistorytable" element={<RedactionHistoryTable />} />} />
          <Route path="/messages" query={query.get('query')} element={<AdminRoute path="/messages" element={<Messages />} />} />
          <Route path="/workgroupuserxwalk" query={query.get('query')} element={<AdminRoute path="/workgroupuserxwalk" element={<WorkGroupUserXWalk />} />} />
          <Route path="/Object-hierarchy-table" query={query.get('query')} element={<AdminRoute path="/Object-hierarchy-table" element={<ObjectHierarchyTable />} />} />
          <Route path="/elements" query={query.get('query')} element={<AdminRoute path="/elements" element={<Elements />} />} />
          <Route path="/element-group" query={query.get('query')} element={<AdminRoute path="/element-group" element={<ElementGroup />} />} />
          <Route path="/rule-log" query={query.get('query')} element={<AdminRoute path="/rule-log" element={<RuleLog />} />} />
          <Route path="/redactiondefinition" query={query.get('query')} element={<AdminRoute path="/redactiondefinition" element={<RedactionDefinition />} />} />
          <Route path="/dashboard-indicators-group" query={query.get('query')} element={<AdminRoute path="/dashboard-indicators-group" element={<DashboardIndicatorsGroup />} />} />

          <Route path="/dashboard-indicator" query={query.get('query')} element={<AdminRoute path="/dashboard-indicator" element={<DashboardIndicators />} />} />
          <Route
            path="/dashboard-indicators-dataSet-card-xref"
            query={query.get('query')}
            element={<AdminRoute path="/dashboard-indicators-dataSet-card-xref" element={<DashboardIndicatorsDataSetCardXRef />} />}
          />
          <Route path="/dashboard-group-card-xref" query={query.get('query')} element={<AdminRoute path="/dashboard-group-card-xref" element={<DashboardGroupCardXRef />} />} />
          <Route
            path="/dashboard-card-benchmark-display"
            query={query.get('query')}
            element={<AdminRoute path="/dashboard-card-benchmark-display" element={<DashboardCardBenchmarkDisplay />} />}
          />
          <Route
            path="/sec-role-dashboard-card-xref"
            query={query.get('query')}
            element={<AdminRoute path="sec-role-dashboard-card-xref" element={<SecRoleDashboardCardXRef />} />}
          />
          <Route path="/indicators-data-set" query={query.get('query')} element={<AdminRoute path="/indicators-data-set" element={<IndicatorsDataSet />} />} />
          <Route path="/routingconditions" query={query.get('query')} element={<AdminRoute path="/routingconditions" element={<RoutingConditions />} />} />
          <Route path="/objectdefinition" query={query.get('query')} element={<AdminRoute path="/objectdefinition" element={<ObjectDefinition />} />} />
          <Route path="/objectdefinitiontable" query={query.get('query')} element={<AdminRoute path="/objectdefinitiontable" element={<ObjectDefinitionTable />} />} />

          <Route
            path="/packager-preferred-order"
            query={query.get('query')}
            element={<PrivateRoute path="/packager-preferred-order" element={<PackagerPreferredOrderTable />} />}
          />
          {/* SMC Routes Start */}
          <Route query={query.get('query')} path="/login" element={<Login />} />
          <Route path="/" query={query.get('query')} element={<PrivateRoute path="/" element={<InBasketPage />} />} />
          <Route path="/dashboard" query={query.get('query')} element={<PrivateRoute path="/dashboard" element={<DashboardSMC />} />} />
          <Route path="/search" query={query.get('query')} element={<PrivateRoute path="/search" element={<DrmsSearch />} />} />
          <Route path="/holds" query={query.get('query')} element={<PrivateRoute path="/holds" element={<HoldManagement />} />} />
          <Route path="/transfer" query={query.get('query')} element={<PrivateRoute path="/transfer" element={<TransferManagement />} />} />
          <Route path="/manage" query={query.get('query')} element={<PrivateRoute path="/manage" element={<ManageDisposition />} />} />
          <Route path="/fileplan" query={query.get('query')} element={<PrivateRoute path="/fileplan" element={<FilePlan />} />} />
          <Route path="/filecode" query={query.get('query')} element={<PrivateRoute path="/filecode" element={<FileCodeSearch />} />} />

          <Route path="/inbasketpage" query={query.get('query')} element={<PrivateRoute path="/inbasketpage" element={<InBasketPage />} />} />
          <Route path="/newsearch" query={query.get('query')} element={<PrivateRoute path="/newsearch" element={<Search />} />} />
          <Route path="/profile" query={query.get('query')} element={<PrivateRoute path="/profile" element={<UserInfo />} />} />

          {/* Route for Okta callback */}
          {/* <Route path="/login/callback" element={<LoginCallback />} /> */}
          <Route query={query.get('query')} path="/access-error" element={<NoAccess />} />
          <Route path="/objectdictionaryobject" query={query.get('query')} element={<PrivateRoute path="/objectdictionaryobject" element={<ObjectDictionaryObjectTable />} />} />
          <Route
            path="/objectdictionaryobjecttype"
            query={query.get('query')}
            element={<PrivateRoute path="/objectdictionaryobjecttype" element={<ObjectDictionaryObjectTypeTable />} />}
          />
          <Route path="/batchdefinition" query={query.get('query')} element={<PrivateRoute path="/batchdefinition" element={<BatchDefinitionTable />} />} />
          <Route path="/elementgroupxwalk" query={query.get('query')} element={<PrivateRoute path="/elementgroupxwalk" element={<ElementGroupXwalk />} />} />
          <Route path="/ruletriggermetadata" query={query.get('query')} element={<PrivateRoute path="/ruletriggermetadata" element={<RuleTriggerMetadata/>} />} />
          <Route path="/redaction-configuration" query={query.get('query')} element={<AdminRoute path="/redaction-configuration" element={<RedactionConfiguration />} />} />
        </Routes>
      </div>
      {/* {currentPath !== '/' && currentPath !== '/login' && currentPath !== '/oktalogin' && <Foot />} */}
      {/* </Security> */}
    </div>
  );
};
//if url has query string send flag that request was made from url
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default RouterControl;
// function Results() {
//   // The `path` lets us build <Route> paths that are
//   // relative to the parent route, while the `url` lets
//   // us build relative links.
//   let { path, url } = useRouteMatch();
//   let query = useQuery();
//   return (
//     <div>
//       <Switch>
//         <Route path={`${path}/:query`} query={query.get("query")}>
//           <Result />
//         </Route>
//       </Switch>
//     </div>
//   );
// }
// function Result({ query }) {
//   // The <Route> that rendered this component has a
//   // path of `/topics/:topicId`. The `:topicId` portion
//   // of the URL indicates a placeholder that we can
//   // get from `useParams()`.
//  // let { urlQuery } = useParams();
//   return (
//     <div>
//           <LoaderGrid urlQuery={query} />
//     </div>
//   );
// }
