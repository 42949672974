import React, { useEffect, useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './BatchXwalk.module.scss';
import '../../../GlobalStyle.scss';

import BatchXwalkData from './BatchXwalkData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';

import AsyncSelect from 'react-select/async';
import { createBatchXwalk, updateOneBatchXwalk, deleteBatchXwalk, searchBatchXwalk, searchBatchId } from './BatchXwalkService.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';

import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices.js';

const TableBatchXwalk = lazy(() => import('./TableBatchXwalk.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Batch Xwalk', link: '' },
];
export default function BatchXwalk() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [batchXwalkDetails, setBatchXwalkData] = useState(BatchXwalkData);
  const [inputDisabled, setInputDisabled] = useState(false);
  const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));
  const [disableRecord, setDisableRecord] = useState(false);

  const [formData, setFormData] = useState({
    activeInd: true,
    batchId: '',
    contextId: userInformation?.contextId,
    entitySelectionUniqueId: '',
    id: '',
    buowId: '',
  });

  const onRemoveSort = async () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    setLoading(true);
    let payload = {
      contextId: userInformation?.contextId,
      text: searchKeyword,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchBatchXwalk(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      contextId: userInformation?.contextId,
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
    };
    await searchBatchXwalk(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));
    setFormData({
      activeInd: true,
      batchId: '',
      contextId: userInformation?.contextId,
      entitySelectionUniqueId: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
    setDisableRecord(false);
  };

  const editFunc = async (data) => {
    setFormData({
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
      batchId: data.batchId,
      contextId: data.contextId,
      entitySelectionUniqueId: data.selectionUniqueId,
      prevEntitySelectionId: data.selectionUniqueId,
      id: data.id,
      buowId: data.buowId,
    });
    data.buowId === '00000000-0000-0000-0000-000000000000' ? setDisableRecord(false) : setDisableRecord(true);
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewBatchXwalkData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newBatchXwalkData = JSON.parse(JSON.stringify(BatchXwalkData));
    newBatchXwalkData.forEach((itm) => {
      itm.sortType = '';
    });
    setBatchXwalkData(newBatchXwalkData);
    await fetchNewBatchXwalkData();
  };

  // Code needs to change
  const fetchNewBatchXwalkData = async () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    let payload = {
      contextId: userInformation?.contextId,
      text: '',
      column: '',
      order: '',
    };
    await searchBatchXwalk(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      contextId: userInformation?.contextId,
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
    };
    await searchBatchXwalk(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword?.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword?.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      batchId: DeleteData.batchId,
      contextId: DeleteData.contextId,
      buowId: DeleteData.buowId,
      entitySelectionId: DeleteData.entitySelectionUniqueId,
    };
    deleteBatchXwalk(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Batch Xwalk Deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="batchxwalk-container" className={styles.batchXwalkPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="batchxwalk-page-wrapper" className={styles.batchXwalkPageContent}>
        <h1 data-testid="batchxwalk-heading" className={styles.pageTitle}>
          Batch Xwalk
        </h1>
        <div>
          <div data-testid="batchxwalk-search-container" className={styles.searchSection}>
            <div data-testid="batchxwalk-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="batchxwalk-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-batchxwalk'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="batchxwalk-create-btn-container">
                <CustomButton testId={'create-batchxwalk-btn'} title="Create Batch Xwalk" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="batchxwalk-help-text" className="searchNoteStyle">
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="batchxwalk-table-container" className={[styles.batchXwalkTable, 'glbBatchXwalkTable'].join(' ')}>
          <TableBatchXwalk
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={batchXwalkDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <AddEditBatchXwalkModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchBatchXwalkData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
          disableRecord={disableRecord}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Batch Xwalk'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditBatchXwalkModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);

  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
    }),
    menu: (base) => ({
      ...base,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color:'black'
    }),
  };
  const sanitizeValue = (value) => {
    if (!props.isNew) {
      return value.replace(/,/g, ''); // Remove commas if props.isNew is false
    }
    return value;
  };
  const handleInputChange = (event) => {
    let { name, value } = event.target;

    if (name === 'entitySelectionUniqueId') {
      // Split the value into an array of trimmed strings
      let values = value ? value.split(',').map((val) => val.trim()) : [];

      // If values is an array with a single empty string, set it to an empty array
      if (Array.isArray(values) && values.length === 1 && values[0] === '') {
        values = []; // Set to empty array if the input is empty
      }

      // Update formData with the array of trimmed strings
      props.setFormData((prevData) => ({
        ...prevData,
        [name]: values, // Directly set the values array
      }));
    } else {
      // Handle other fields normally
      props.setFormData((prevData) => ({
        ...prevData,
        [name]: value ? value.trimStart() : value,
      }));
    }
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        callback(options);
      });
    }
  };
  const loadBatchIdOptions = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await searchBatchId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.batchId, value: res.batchId };
        });

        callback(options);
      });
    }
  };
  const handleSubmit = async (data) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    if (!data.contextId || !data.entitySelectionUniqueId || !data.batchId || data?.contextId?.length > 50) {
      setisRequiredError(true);
      return;
    } else {
      setisRequiredError(false);
      props.isLoading(true);

      let payload = _.cloneDeep(data);
      payload.entitySelectionUniqueId =
        data.entitySelectionUniqueId[data.entitySelectionUniqueId.length - 1].trim() === '' || data.entitySelectionUniqueId[data.entitySelectionUniqueId.length - 1] === undefined
          ? data.entitySelectionUniqueId.pop()
          : data.entitySelectionUniqueId;
      payload.contextId = userInformation?.contextId;

      // Add the new field "selectionUniqueId" to the payload
      payload.selectionUniqueId = data.entitySelectionUniqueId;

      // Remove the "entitySelectionUniqueId" from the payload
      delete payload.entitySelectionUniqueId;

      await createBatchXwalk(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Batch Xwalk Created Successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchBatchXwalkData();
          props.setRecordModalShow(false);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.contextId || !data.batchId || !data.entitySelectionUniqueId || data?.contextId?.length > 50) {
      setisRequiredError(true);
    } else {
      setisRequiredError(false);
      let payload = _.cloneDeep(data);
      props.isLoading(true);

      // Add the new field "selectionUniqueId" to the payload
      payload.selectionUniqueId = data.entitySelectionUniqueId;

      // Add the "prevEntitySelectionId" field to the payload (previous value)
      payload.prevEntitySelectionId = data.prevEntitySelectionId;

      // Remove the "entitySelectionUniqueId" from the payload
      delete payload.entitySelectionUniqueId;

      await updateOneBatchXwalk(payload).then((response) => {
        if (response?.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Batch Xwalk Updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchBatchXwalkData();
          props.setRecordModalShow(false);
        } else if (response?.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
        }
      });
    }
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };
  const onChangeBatchId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        batchId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      batchId: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
  };
  const firstInputRef = useRef(null);
  const entitySelectionUniqueIdInputRef = useRef(null);

    // Handle modal show logic and initial focus
    useEffect(() => {
      const handleModalShown = () => {
        if (firstInputRef.current) {
          firstInputRef.current.focus(); // Focus on the first input
          if (firstInputRef.current?.style !== undefined) {
            firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
          }
        }
      };

      if (props.show === true) {
        handleModalShown();
      }
    }, [props.show]);

    // Focus handler for dynamically adding/removing class
    const handleFocus = (inputRef) => {
      if (inputRef.current) {
        inputRef.current.className = 'firstEleFocused'; // Add the focus class
      }
    };
  return (
    <Modal
      {...props}
      className={[styles.batchXwalkModal, 'glbBatchXwalkModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Batch Xwalk' : 'Edit Batch Xwalk'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.batchXwalkModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {!props.isNew && (
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
              )}
              <div className={!props.isNew ? styles.inputFieldInternalDiv : styles.inputFieldInternalDivCreate}>
                <label>Batch ID *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={props.isNew ? false : true}
                  loadOptions={loadBatchIdOptions}
                  value={props?.formData?.batchId !== '' ? { label: props?.formData?.batchId, value: props?.formData?.batchId } : null}
                  onChange={onChangeBatchId}
                  ref={props.isNew === true ? firstInputRef : null}
                  className={props.isNew ? '' : styles.disabledInput}
                />
                {(props.formData.batchId === null || props.formData.batchId === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            {!props.isNew && (
              <div className={styles.inputFieldSection}>
                <div className={!props.isNew ? styles.inputFieldInternalDiv : styles.inputFieldInternalDivCreate}>
                  <label>BUOW ID *</label>
                  <input
                    required
                    name="buowId"
                    value={props.formData.buowId}
                    onChange={handleInputChange}
                    disabled={props.isNew ? false : true}
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                  {(props.formData.buowId === null || props.formData.buowId === '') && isRequiredError === true && handleIsRequiredError()}
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Entity Selection Unique ID *</label>
                <textarea
                  name="entitySelectionUniqueId"
                  required
                  value={props.formData.entitySelectionUniqueId}
                  onChange={handleInputChange}
                  style={{ height: '28rem' }}
                  disabled={props.disableRecord === false ? false : true}
                  className={props.disableRecord === false ? '' : styles.disabledInput}
                  ref={props.isNew === false ? firstInputRef : entitySelectionUniqueIdInputRef}
                  onFocus={() => handleFocus(entitySelectionUniqueIdInputRef)}
                />
                {(props.formData.entitySelectionUniqueId === null || props.formData.entitySelectionUniqueId === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              {/* <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}> */}
              <div className={styles.radioInputOuterDiv} data-testid="radio-function">
                <div>Active Ind: </div>
                <div style={{ display: 'flex' }}>
                  <input
                    type="radio"
                    name="activeInd"
                    value={true}
                    checked={props.formData.activeInd === true}
                    onChange={handleRadioChange}
                    disabled={props.disableRecord === false ? false : true}
                  />
                  True
                  <input
                    type="radio"
                    name="activeInd"
                    value={false}
                    checked={props.formData.activeInd === false}
                    onChange={handleRadioChange}
                    disabled={props.disableRecord === false ? false : true}
                  />{' '}
                  False
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.batchXwalkFooter} style={{ border: props.disableRecord === false ? '' : 'none' }}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              props.disableRecord === false && <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            {props.disableRecord === false && <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />}
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality