import axios from 'axios';

export async function getRoutingOrchestration() {
  let routingOrchestrationData = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/routingorchestration';
    // console.log('getOrc: ' + url);
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        routingOrchestrationData = response;
      });
  } catch (ex) {
    console.log('Error in getting routing orchestration data: ', ex.message);
  }
  return routingOrchestrationData;
}

export async function getRoutingOrchestrationByBuowName(params) {
  let routingOrchestrationData = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/routingorchestration/${params.id}`;
    // console.log('getOrc: ' + url);
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        routingOrchestrationData = response;
      });
  } catch (ex) {
    console.log('Error in getting routing orchestration data: ', ex.message);
  }
  return routingOrchestrationData;
}

export async function createRoutingOrchestration(data) {
  let updateResponse = [];
  try {
    const url = '/buow_api/routingorchestration';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in creating routing orchestration: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function updateOneRoutingOrchestration(data) {
  let updateResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/routingorchestration/' + data.id;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteRoutingOrchestration(params) {
  let deleteResponse = {};
  try {
    const url = `/buow_api/routingorchestration/${params.id}`;
    console.log('deleteOrc: deleteRoutingOrchestration()' + url);
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchRoutingOrchestration(data) {
  let workQueueFilterResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/buow_api/routingorchestration/';

    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getWorkgroupDisplayNameByID(text) {
  let workQueueFilterResponse = [];
  try {
    const url = '/buow_api/workgroups/?workgroupId=' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function sortRoutingOrchestration(data) {
  let workQueueFilterResponse = [];
  try {
    const url = '/buow_api/routingorchestration/sort/' + data.column + '/' + data.order;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
export async function getUserInfoID(text) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/user/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getConditionalIdValue(text) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/routingconditions/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
