import axios from 'axios';
import { serviceUserJwt } from './serviceConfig';
import { EventEmitter } from './events';
export async function getMessages() {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/message?userId=' + JSON.parse(sessionStorage.getItem('user_information')).userId;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
export async function buowLockCheck(data) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/in_baskets/lock/' + data;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
    return (workQueueFilterResponse = ex.response);
  }
  return workQueueFilterResponse;
}
export async function buowLockLooper(data,flag) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/in_baskets/lock/' + data + `?isLocked=${flag}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
    return (workQueueFilterResponse = ex.response);
  }
  return workQueueFilterResponse;
}
export async function dismissMessage(url) {
  let workQueueFilterResponse = [];
  try {
    const baseUrl = window.env.REACT_APP_BACKEND_URL + '/buow_api/message/dismiss_message?' + url;
    // console.log('get messages- getting work queue: Calling ' + baseUrl);
    await axios
      .patch(
        baseUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          },
        }
      )
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in dismissMessage ', ex.message);
  }
  return workQueueFilterResponse;
}
export async function getWorkgroups() {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/get-user-workgroups?userId=' + JSON.parse(sessionStorage.getItem('user_information')).userId;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
    return [];
  }
  return workQueueFilterResponse1;
}
export async function getWorkflowHistory(data) {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/buowauditlog/workflow?buowId=' +data;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
    return ex
  }
  return workQueueFilterResponse1;
}
// export async function getWorkgroupItems(id, params) {
//   let workQueueFilterResponse1 = [];
//   try {
//     const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/get-in-basket?workgroupId=' + id + params;
//     await axios
//       .get(url, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: serviceUserJwt,
//         },
//       })
//       .then((response) => {
//         workQueueFilterResponse1 = response;
//       });
//   } catch (ex) {
//     console.log('Error in get messages ', ex.message);
//   }
//   return workQueueFilterResponse1;
// }
export async function getWorkgroupItemsByUserId(param) {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/get-in-basket?userId=' + JSON.parse(sessionStorage.getItem('user_information')).userId + '&' + param;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse1;
}

export async function getWorkgroupItems(payload, param) {
  let workQueueFilterResponse1 = [];

  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/get-in-basket/v2?' + param;
    await axios
      .post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse1;
}

export async function getPages(param) {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + encodeURI('/buow_api/get-document/get-pages?documentId=' + param.documentId);
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get getPages ', ex.message);
  }
  return workQueueFilterResponse1;
}

export async function getXmlFile(param) {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + encodeURI('/buow_api/get-document/xml?documentId=' + param.documentId);
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get getPages ', ex.message);
  }
  return workQueueFilterResponse1;
}

export async function getDocuments(param) {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + encodeURI('/buow_api/get-document/v2?documentId=' + param.documentId);
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
        responseType: 'blob',
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get getDocuments ', ex.message);
  }
  return workQueueFilterResponse1;
}

export async function getXMLValue(param) {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + encodeURI('/buow_api/get-document/isxml?documentId=' + param.documentId);
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get getXmlValue ', ex.message);
  }
  return workQueueFilterResponse1;
}

export async function getBuowItems(param) {
  let workQueueFilterResponse1 = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/buow_api/buows/get-buow?buowType=' + param.buow_type + '&buowName=' + param.buow_name + '&buowId=' + param.buow_id;
    if (window.localStorage.getItem('buow_relation') === 'W') {
      url += '&workgroupId=' + window.localStorage.getItem('buow_WorkgroupId');
    } else {
      url += '&userId=' + JSON.parse(sessionStorage.getItem('user_information')).userId;
    }
    // const url = encodeURI("/buow_api/buows/get-buow?buowType=Document&buowName=A-file (A_Group1)&buowId="+ param.buow_id);
    // const url = "/buow_api/buows/get-buow?buowType=Form&buowName=1040&buowId="+ param.buow_id;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get getBuowItems ', ex.message);
  }
  return workQueueFilterResponse1;
}
export async function getThumbnailByBuowID(buowId) {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/get-pdf-thumbnail/v2';
    await axios
      .post(url, buowId, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get getThumbnailByBuowID ', ex.message);
  }
  return workQueueFilterResponse1;
}
export async function uploadBuowFile(data, progressBarData) {
  let uploadResponse = {};
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/upload-file';
    await axios
      .post(url, data, progressBarData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          type: 'formData',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
  }
  return uploadResponse;
}

export async function getFileData() {
  let workQueueFilterResponse1 = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/workqueues';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get getThumbnailByBuowID ', ex.message);
  }
  return workQueueFilterResponse1;
}
// export async function getSystemConfigDetails(contextId, token) {
//   if (!contextId) return;
//   let getSystemConfigResponse = [];
//   if (!contextId) return;
//   try {
//     const JwtToken = sessionStorage.getItem('userToken') === null || sessionStorage.getItem('userToken') === undefined ? token : sessionStorage.getItem('userToken');
//     const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/systemconfiguration?contextId=${contextId}`;
//     let system_config = JSON.parse(window.localStorage.getItem('system_config'));
//     if (system_config === null) {
//       await axios
//         .get(url, {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: 'Bearer ' + JwtToken,
//           },
//         })
//         .then((response) => {
//           getSystemConfigResponse = response;
//           window.localStorage.setItem('system_config', JSON.stringify(response.data));
//           EventEmitter.emit('LocalStorageUpdated');
//         });
//     }
//   } catch (ex) {
//     console.log('Error in get getSystemConfig ', ex.message);
//   }
//   return getSystemConfigResponse;
// }
export async function getSystemConfigDetails(contextId, token) {
  if (!contextId) return []; // Return an empty array if contextId is missing

  let getSystemConfigResponse = [];
  try {
    const JwtToken = sessionStorage.getItem('userToken') || token;

    const url = `${window.env.REACT_APP_BACKEND_URL}/buow_api/systemconfiguration?contextId=${contextId}`;
    let system_config = JSON.parse(window.localStorage.getItem('system_config'));

    if (!system_config) {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JwtToken}`,
        },
      });
      getSystemConfigResponse = response;
      window.localStorage.setItem('system_config', JSON.stringify(response.data));
      EventEmitter.emit('LocalStorageUpdated');
    }
  } catch (ex) {
    console.error('Error in getSystemConfigDetails:', ex.message);
  }

  return getSystemConfigResponse;
}
export async function getRoutingStepsBuow(buowID) {
  let getSystemConfigResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/routingstep?buowId=${buowID}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getSystemConfigResponse = response;
      });
  } catch (ex) {
    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getSystemConfigResponse;
}

export async function searchWorkgroups(text) {
  let getSystemConfigResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/workgroups/search/${text}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getSystemConfigResponse = response;
      });
  } catch (ex) {
    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getSystemConfigResponse;
}
export async function addNewRoutingTableEntryttoBuow(payload) {
  let uploadResponse = {};
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/routingstep/save';
    await axios
      .post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
  }
  return uploadResponse;
}
export async function addNewNotestoBuow(data) {
  let uploadResponse = {};
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/note';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
  }
  return uploadResponse;
}

export async function submitBUOW(data) {
  let uploadResponse = {};
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/routingstep/submit?userOrWorkgroupId=${data.workgroupId}&buowId=${data.buowId}&step=${data.step}`;
    await axios
      .post(
        url,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          },
        }
      )
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    uploadResponse = null;
  }
  return uploadResponse;
}

export async function buowRejectReInitiate(data) {
  let uploadResponse = {};
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/routingstep/reinitiate?buowId=${data.buowId}`;
    await axios
      .post(
        url,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          },
        }
      )
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    uploadResponse = null;
  }
  return uploadResponse;
}

export async function rejectBUOW(data) {
  let uploadResponse = {};
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/routingstep/reject?userOrWorkgroupId=${data.workgroupId}&buowId=${data.buowId}&step=${data.step}`;
    await axios
      .post(
        url,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          },
        }
      )
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    uploadResponse = null;
  }
  return uploadResponse;
}

export async function getSentimentData(payload) {
  let getSystemConfigResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/sentiments?documentId=${payload.id}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getSystemConfigResponse = response;
      });
  } catch (ex) {
    getSystemConfigResponse.error = ex.response.data;
    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getSystemConfigResponse;
}
export async function failedSentimentCall(payload) {
  let getSystemConfigResponse = [];
  try {
    const url = `/buow_api/sentiments/emotion?documentId=${payload.id}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getSystemConfigResponse = response;
      });
  } catch (ex) {
    getSystemConfigResponse.error = ex.response.data;

    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getSystemConfigResponse;
}
export async function getPdfOcr(param) {
  let workQueueFilterResponse1 = [];
  try {
    const url = encodeURI(window.env.REACT_APP_BACKEND_URL + '/buow_api/get-document/get-pdf-ocr?documentId=' + param.documentId);

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse1 = response;
      });
  } catch (ex) {
    console.log('Error in get getDocuments ', ex.message);
  }
  return workQueueFilterResponse1;
}

export async function addNewQuetion(data) {
  let uploadResponse = {};
  try {
    const url = 'https://u01tuuaph7.execute-api.us-east-1.amazonaws.com/PRODUCTION/ml-api-resources-qa';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    uploadResponse.error = ex;
  }
  return uploadResponse;
}

export async function addNewQuetionAlice(data) {
  let uploadResponse = {};
  try {
    const url = `http://3.143.141.252/brag_exec?query=${data.question}&domain=${data.contextName}`;
    await axios
      .get(
        url,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          },
        }
      )
      .then((response) => {
        uploadResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    uploadResponse.error = ex;
  }
  return uploadResponse;
}

export async function searchBUOWByText(text) {
  let getSystemConfigResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/in_baskets/search-buow/${text}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getSystemConfigResponse = response;
      });
  } catch (ex) {
    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getSystemConfigResponse;
}

export async function oktaLogin(accessToken) {
  let getUserResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/security_api/security/validate`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken,
        },
      })
      .then((response) => {
        getUserResponse = response;
      });
  } catch (ex) {
    if (ex.response.data.message === 'Access Denied: Your account is currently inactive.') {
      sessionStorage.clear();
      document.location = `/access-error`;
    }
    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getUserResponse;
}

export async function updateNextInBasket(data) {
  let UpdateResponse = [];
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
  };
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/rulelogs/datavalue';

    const response = await axios.put(url, data, { headers });

    UpdateResponse = response;
  } catch (ex) {
    console.error('Error in updateNextInBasket()', ex.message);
    UpdateResponse.error = ex.response.data;
  }
  return UpdateResponse;
}

export async function searchUsers(text) {
  let getSystemConfigResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/security_api/user/search/${text}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getSystemConfigResponse = response;
      });
  } catch (ex) {
    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getSystemConfigResponse;
}

export async function updateRestriction(data) {
  let UpdateResponse = [];
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
  };
  try {
    const url =
      window.env.REACT_APP_BACKEND_URL +
      `/buow_api/get-document/restrict?documentId=${data.documentId ? data.documentId : ''}&pageId=${data.pageId ? data.pageId : ''}&isRestricted=${data.isRestricted}`;

    const response = await axios.put(url, data, { headers });

    UpdateResponse = response;
  } catch (ex) {
    console.error('Error in updateNextInBasket()', ex.message);
    UpdateResponse.error = ex.response.data;
  }
  return UpdateResponse;
}

export async function getRuleNames(data) {
  let workQueueFilterResponse = [];
  try {
    const url = `/buow_api/ruledefinition/getallrules/search?contextid=${data.contextId}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
export async function deleteCard(data) {
  let DeleteResponse = [];
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
  };
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/rulelogs/logId/${data.logId}`;

    const response = await axios.delete(url, { headers }, data);

    DeleteResponse = response;
  } catch (ex) {
    console.error('Error in updateNextInBasket()', ex.message);
    DeleteResponse.error = ex.response.data;
  }
  return DeleteResponse;
}
