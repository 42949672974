import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import RouterControl from "./RouterControl";
import history from "./history";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";
import "./css/fsa-design-system.css";
import "./fsa-style/fsa-style.scss";
import 'react-notifications/lib/notifications.css';

// import oidcConfiguration from "./configuration";
import { UserProvider } from "./components/UserContext/UserContext";
// import Foot from "./components/Foot/foot";
import NotificationProvider from "./components/Notification/NotificationProvider";
import { Suspense } from "react";
import { GlobalLoader } from "./newcomponents/GlobalLoader/GlobalLoader";
import withClearCache from './ClearCache';
import { configureAxiosInterceptors } from './utils/helper/axiosInterceptor';
import InfoPopupModal from "./newcomponents/InfoPopupModal/InfoPopupModal"; // Import your modal component

// const oktaTokenVal = JSON.parse(sessionStorage.getItem("okta-token-storage"));

//  NOTE:- Commented Old Login Code
// const redirect_uri =
//   window.env.NODE_ENV === "development"
//     ? "https://dev.local.com"
//     : "https://drms-qaqc-train.fpac.usda.gov";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Initialize Axios interceptors
    configureAxiosInterceptors(setIsLoading);
  }, []); // Empty dependency array ensures this runs only once

  return (
    <>
      {/* Render the GlobalLoader when isLoading is true */}
      {isLoading && <GlobalLoader />}
      <ClearCacheComponent />
    </>
  );
}

function MainApp(props) {
  // // START clear cache

  // THIS IS THE OPTION THAT YOU CAN OPT, BUT DIFFERENT BROWSERS WILL // HAVE THEIR DIFFERENT MESSAGES FOR PROMPTING BEFORE RELOAD. ELSE // IF YOU ARE USING BROWSERROUTER IN YOUR CODE. GIVE A SHOT FOR //THIS. https://v5.reactrouter.com/web/api/BrowserRouter(forceRefresh prop)
  // useEffect(() => {
  //   window.addEventListener("beforeunload", () => true);

  //   return () => window.removeEventListener("beforeunload",  //() => false);
  // }, []);

  // END for clear cache

  // const logout = async () => {
  //   oidcConfiguration.userManager.signoutRedirect({
  //     id_token: sessionStorage.getItem("eAuthJwt").slice(7),
  //     post_logout_redirect_uri: redirect_uri,
  //   });
  //   sessionStorage.clear();
  // };

  // State to track modal visibility and Login message
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");

  // Effect to sync with sessionStorage for 'showInfoPopup' and 'loginMessage'
  useEffect(() => {
    // Function to check sessionStorage values
    const checkSessionStorage = () => {
      const showPopup = sessionStorage.getItem("showInfoPopup") === "true";
      const message = JSON.parse(window.localStorage.getItem("system_config"))?.loginMessage;
  
      // Update states as required
      if (showPopup && message) {
        setShowInfoPopup(true);
        setLoginMessage(message);
  
        // Clear 'showInfoPopup' in sessionStorage to prevent multiple triggers
        sessionStorage.removeItem("showInfoPopup");
      }
    };
  
    // Run the function on mount
    checkSessionStorage();
  
    // Add a listener for 'storage' events (for multi-tab support)
    const handleStorageChange = (event) => {
      if (event.key === "showInfoPopup" || event.key === "system_config") {
        checkSessionStorage();
      }
    };
  
    // Add a listener for the custom event
    const handleLoginEvent = (event) => {
      const { showInfoPopup, loginMessage } = event.detail;
      if (showInfoPopup && loginMessage) {
        setShowInfoPopup(true);
        setLoginMessage(loginMessage);
      }
    };
  
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("loginSuccess", handleLoginEvent);
  
    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("loginSuccess", handleLoginEvent);
    };
  }, []);
  

  // Function to handle closing the modal
  const handleClosePopup = () => {
    setShowInfoPopup(false);
    sessionStorage.setItem('showInfoPopup', 'false'); // Ensure it doesn't show again
    setLoginMessage("");
  };

  return (
    // <AuthProvider {...oidcConfiguration}>
    <UserProvider>
      <NotificationProvider>
        <div className="App">
          <Suspense fallback={<div><GlobalLoader/></div>}>
            <Router history={history} forceRefresh={true}>
              {/* <Head logout={logout}/> */}
              <RouterControl/>
              {/* {_.isEmpty(oktaTokenVal) === false && <Foot/>} */}
            </Router>
          </Suspense>

          {/* Conditionally Render InfoPopupModal */}
          {showInfoPopup && loginMessage && (
            <InfoPopupModal
              show={showInfoPopup}
              onClose={handleClosePopup}
              message={loginMessage}
            />
          )}
        </div>
      </NotificationProvider>
    </UserProvider>
  // </AuthProvider>
  );
};

export default App;