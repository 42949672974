import axios from 'axios';

const tempJWT =
  'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkNvdXJ0bmV5IFRpYmVsIiwiYWdlbmN5Y29kZSI6ImZzYSIsIm9mZmljZWlkIjoxMjM0NSwiZWF1dGhpZCI6IjI4MjAwNDA2MzA5MDMwMDIxNTEzIiwiZW1haWwiOiJzdXNwZW5zZXF1ZXVlQG1haWwuY29tIiwiaWF0IjoxNTE2MjM5MDIyfQ.FZYCQkb6CoPRHXV0PE-wyFUCWvzSnnSO8m6WJhOAihE';

//start
export async function getDoctype(tokenData) {
  let getDoctypeResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/metadata_schema_api/metadata-schema/objectType';
    // console.log('getDoctype.js - getting work queue: Calling ' + url);

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + (sessionStorage?.getItem('userToken')),
        },
      })
      .then((response) => {
        getDoctypeResponse = response;

        if (Array.isArray(response.data)) {
          const mappedData = response.data.map((item, index) => item.objectTypeName);
          sessionStorage.setItem('docList', JSON.stringify(mappedData));
        } else {
          console.error('Invalid data format from API:', response.data);
        }
      });
  } catch (ex) {
    console.log('Error in getDoctype.js getDoctype()', ex.message);
  }

  return getDoctypeResponse;
}
