import React, { useEffect, useRef, useState, lazy } from 'react';
import '../../../components/FileCode/FileCode.scss';
import RoutingStepsData from './RoutingStepsData.json';

// import TableRoutingSteps from './TableRoutingSteps';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import { createRoutingStep, updateOneRoutingStep, deleteRoutingStep, searchRoutingSteps, getSearchContextId } from './RoutingStepsServices';
import styles from './RoutingSteps.module.scss';
import '../../../GlobalStyle.scss';

import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

import AsyncSelect from 'react-select/async';
import { searchBUOWByText, searchWorkgroups, searchUsers } from '../../../services/inBasketService';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';

// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Routing Steps', link: '' },
];

const TableRoutingSteps = lazy(() => import('./TableRoutingSteps'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
// const { DeleteModal } = lazy(() => import('../../../newcomponents/DeletePopup/DeleteModal'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function RoutingSteps() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [createEditModalShow, setCreateEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedDeleteRowData, setSelectedDeleteRowData] = useState();
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [routingStepsDetails, setRoutingStepsData] = useState(RoutingStepsData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    contextId: '',
    dttm: '',
    routeOrigin: '',
    step: '',
    subStep: '',
    stepName: '',
    adHoc: true,
    adHocRouter: '',
    parSeq: '',
    status: '',
    disposition: '',
    targetUserOrWorkgroup: '',
    buowId: '',
    userOrWorkgroupId: '',
    id: '',
    activeInd: true,
  });

  async function fetchRoutingStepsData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRoutingSteps(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchRoutingStepsData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    // await fetchRoutingStepsData();
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRoutingSteps(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      dttm: null,
      routeOrigin: '',
      step: '',
      subStep: '',
      stepName: '',
      adHoc: true,
      adHocRouter: '',
      parSeq: '',
      status: '',
      disposition: '',
      targetUserOrWorkgroup: '',
      buowId: '',
      userOrWorkgroupId: '',
      id: '',
      activeInd: true,
    });
    setIsNewRecord(true);
    setCreateEditModalShow(true);
  };

  const EditRowDataFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      dttm: data.dttm,
      routeOrigin: data.routeOrigin,
      step: data.step,
      subStep: data.subStep,
      stepName: data.stepName,
      adHoc: data.adHoc,
      adHocRouter: data.adHocRouter,
      parSeq: data.parSeq,
      status: data.status,
      disposition: data.disposition,
      targetUserOrWorkgroup: data.targetUserOrWorkgroup,
      buowId: data.buowId,
      // userOrWorkgroupId:
      //   data.targetUserOrWorkgroup === 'W'
      //     ? { label: data?.workgroup?.workgroupDisplayName, value: data?.workgroup?.workgroupDisplayName }
      //     : { label: data?.user?.userName, value: data?.user?.userName },
      userOrWorkgroupId: { label: data?.userOrWorkgroupName, value: data?.userOrWorkgroupId },
      id: data.id,
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
    });
    setIsNewRecord(false);
    setCreateEditModalShow(true);
  };

  const DeleteRowDataFunc = (DeleteRowData) => {
    setDeleteModalShow(true);
    setSelectedDeleteRowData(DeleteRowData);
  };

  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearchText(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRoutingSteps(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };
    deleteRoutingStep(sendData).then(async (response) => {
      if (response) {
        setDeleteModalShow(false);
        await fetchRoutingStepsData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Routing Step Deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        setLoading(false);
      }
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(routingStepsDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setRoutingStepsData(newRoutingStepsData);
    await fetchNewRoutingStepsData();
  };

  // Code needs to change
  const fetchNewRoutingStepsData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchRoutingSteps(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchRoutingSteps(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div data-testid={'routing-steps-container'} className={styles.routingStepsPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div data-testid={'routing-steps-header-container'} className={styles.routingStepsContent}>
        <h1 data-testid={'routing-steps-header'} className={styles.pageTitle}>
          Routing Steps
        </h1>
        <div>
          <div data-testid={'search-section-container'} className={styles.searchSection}>
            <div date-testid={'search-section-label'} className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div date-testid={'search-outer-section'} className={styles.searchOuterContainer}>
              <div data-testid={'search-input-container'} className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-input'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid={'create-routing-steps'}>
                <CustomButton title="Create Routing Step" testId={'create-btn'} className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid={'search-help-text'} className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>

        <div data-testid={'routing-steps-table'} className={[styles.routingStepsTable, 'glbRoutingStepsTable'].join(' ')}>
          <TableRoutingSteps
            data={data}
            EditRowDataFunc={EditRowDataFunc}
            DeleteRowDataFunc={DeleteRowDataFunc}
            setDeleteModalShow={setDeleteModalShow}
            fields={routingStepsDetails}
            page={page} // Pass the page state from the parent component
            pageSize={pageSize} // Pass the pageSize state from the parent component
            onPageChange={onPageChange}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <CreateEditRoutingStepsModal
          show={createEditModalShow}
          isNewRecord={isNewRecord}
          formData={formData}
          setFormData={setFormData}
          fetchRoutingStepsData={fetchRoutingStepsData}
          setCreateEditModalShow={setCreateEditModalShow}
          onHide={() => setCreateEditModalShow(false)}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={selectedDeleteRowData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Routing Step'}
          ></DeleteModal>
        )}

        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

const CreateEditRoutingStepsModal = (props) => {
  // const [UTCDate, setUTCDate] = useState(moment(props.formData.dttm).format('M/DD/YY, h:mm:ss A'));
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserOrWorkgroupSelected, setIsUserOrWorkgroupSelected] = useState(true);
  // const [searchData, setSearchData] = useState([]);
  // const [searchDataBUOW, setSearchDataBUOW] = useState([]);

  // const [selectedUser, setSelectedUser] = useState([]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      // position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color:'black'
    }),
  };

  useEffect(() => {
    // if (props.formData.dttm !== null) setUTCDate(moment(props.formData.dttm).format('YYYY-MM-DDTHH:MM'));
    // else setUTCDate(null);
    setisRequiredError(false);
  }, [props.formData]);

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchData([]);
    } else if (props.formData.targetUserOrWorkgroup === 'W') {
      await searchWorkgroups(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.workgroupDisplayName, value: res.workgroupId };
        });

        // setSearchData(options);
        callback(options);
      });
    } else {
      await searchUsers(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.userName, value: res.userId };
        });

        // setSearchData(options);
        callback(options);
      });
    }
  };

  const loadBUOWOptions = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await searchBUOWByText(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.buowId, value: res.buowId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue === null || undefined ? updatedValue : updatedValue.trimStart(),
    }));
  };
  const handleDispositionChange = (event) => {
    if (event.target.value == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        disposition: '',
      }));
    props.setFormData((prevData) => ({
      ...prevData,
      disposition: event.target.value,
    }));
  };
  const handleDropdownChange = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        userOrWorkgroupId: '',
      }));
    props.setFormData((prevData) => ({
      ...prevData,
      userOrWorkgroupId: event,
    }));
  };

  const handleDropdownChangeBUOW = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        buowId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      buowId: updatedValue,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      adHoc: booleanValue,
    }));
  };

  const onChangeActiveInd = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === 'targetUserOrWorkgroup') {
      setIsUserOrWorkgroupSelected(false);
      props.setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        userOrWorkgroupId: '',
      }));
    } else {
      props.setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };

  const AddSubmitFunct = async (data) => {
    try {
      if (
        !props.formData.contextId ||
        !props.formData.routeOrigin ||
        !props.formData.step ||
        !props.formData.subStep ||
        !props.formData.stepName ||
        !props.formData.buowId ||
        !props.formData.targetUserOrWorkgroup ||
        !props.formData.userOrWorkgroupId ||
        data.contextId.length > 50
      ) {
        setisRequiredError(true);
        return;
      }
      let payload = _.cloneDeep(data);

      payload.routeOrigin = payload?.routeOrigin?.trim();
      payload.adHocRouter = payload?.adHocRouter?.trim();

      payload.userOrWorkgroupId = payload.userOrWorkgroupId.value;
      delete payload.dttm;
      props.isLoading(true);

      await createRoutingStep(payload)
        .then((response) => {
          if (response.data) {
            props.fetchRoutingStepsData();
            props.setCreateEditModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Routing Step Created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            setIsUserOrWorkgroupSelected(true);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const EditSubmitFunct = async (data) => {
    if (
      !props.formData.contextId ||
      !props.formData.routeOrigin ||
      props.formData.subStep === null ||
      !props.formData.stepName ||
      !props.formData.buowId ||
      !props.formData.targetUserOrWorkgroup ||
      !props.formData.userOrWorkgroupId ||
      props.formData.step === '' ||
      data.contextId.length > 50
    ) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(data);

    payload.routeOrigin = payload?.routeOrigin?.trim();
    payload.adHocRouter = payload?.adHocRouter?.trim();

    payload.userOrWorkgroupId = payload.userOrWorkgroupId.value;
    props.isLoading(true);
    await updateOneRoutingStep(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Routing Step Updated successfully');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchRoutingStepsData();
        props.setCreateEditModalShow(false);
        props.isLoading(false);
        setIsUserOrWorkgroupSelected(true);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  const cancelFunct = () => {
    props.setCreateEditModalShow(false);
    setIsUserOrWorkgroupSelected(true);
  };

  const handleModalCrossIconBtn = () => {
    props.setCreateEditModalShow(false);
    setIsUserOrWorkgroupSelected(true);
  };
  const firstInputRef = useRef(null);
  const routeOriginInputRef = useRef(null);
  const stepNameInputRef = useRef(null);
  const stepInputRef = useRef(null);
  const subStepInputRef = useRef(null);
  const adHocRouterInputRef = useRef(null);

 // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={[styles.routingStepsModal, 'glbRoutingStepsModalStyle'].join(' ')}
      onHide={props.onHide}
    >
      <div data-testid="popup-modal-container">
        <Modal.Header>
          <div data-testid="popup-modal-header-container" className={styles.modalHeader}>
            <div>
              <h2 data-testid="popup-modal-header" className={styles.modalHeaderTitle}>
                {props.isNewRecord ? 'Create Routing Step' : 'Edit Routing Step'}
              </h2>
            </div>
            <div data-testid="close-popup-container" className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img data-testid="cross-icn" src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.routingStepsModalBody}>
          <div data-testid="popup-body-container" className={[styles.modalBodySection, 'row mr-0 ml-0'].join(' ')}>
            {props.isNewRecord === false && (
              <div className={[styles.inputFieldSection, 'col-4'].join(' ')}>
                <label>DTTM *</label>
                {/* <input type="datetime-local" name="dttm" value={UTCDate} disabled={true} /> */}
                <input name="routeOrigin" value={moment(props.formData.dttm).format('M/DD/YY, h:mm:ss A')} disabled={true} />
              </div>
            )}
            {!props.isNewRecord && (
              <div data-testid="context-id-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
                <label>Context Id *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={!props.isNewRecord}
                  loadOptions={loadContextIdList}
                  value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                  onChange={onChangeContextId}
                />
                {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.contextId.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
              </div>
            )}

            <div data-testid="route-origin-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Route Origin *</label>
              <input
                ref={props.isNewRecord === true ? firstInputRef : routeOriginInputRef}
                onFocus={() => handleFocus(routeOriginInputRef)}
                name="routeOrigin"
                value={props.formData.routeOrigin}
                onChange={handleInputChange}
                disabled={props.isNewRecord ? false : true}
              />
              {(props.formData.routeOrigin === null || props.formData.routeOrigin === '') && isRequiredError === true && handleIsRequiredError()}
            </div>
            <div data-testid="step-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Step *</label>
              <input ref={stepInputRef} onFocus={() => handleFocus(stepInputRef)} name="step" value={props.formData.step} onChange={handleInputChange} disabled={props.isNewRecord ? false : true} type="number" />
              {(props.formData.step === null || props.formData.step === '') && isRequiredError === true && handleIsRequiredError()}
            </div>
            <div data-testid="sub-step-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Sub Step *</label>
              <input ref={subStepInputRef} onFocus={() => handleFocus(subStepInputRef)} name="subStep" value={props.formData.subStep} onChange={handleInputChange} disabled={props.isNewRecord ? false : true} type="number" />
              {(props.formData.subStep === null || props.formData.subStep === '') && isRequiredError === true && handleIsRequiredError()}
            </div>
            <div data-testid="step-name-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Step Name *</label>
              <select
                ref={props.isNewRecord === false ? firstInputRef : stepNameInputRef}
                onFocus={() => handleFocus(stepNameInputRef)}
                className={styles.dropdownStyle}
                id="selectField"
                name="stepName"
                value={props.formData.stepName}
                onChange={handleSelectChange}
              >
                <option value={''} disabled>
                  ---Select Mode--
                </option>
                <option value="Processor">Processor</option>
                <option value="Reviewer">Reviewer</option>
              </select>
              {(props.formData.stepName === null || props.formData.stepName === '') && isRequiredError === true && handleIsRequiredError()}
            </div>
            <div className={[styles.inputFieldSection, 'col-4'].join(' ')} style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <div data-testid="adhoc-container" className={styles.radioBtnContainer}>
                  <label className="mr-2">Ad Hoc: </label>
                  <input
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="adHoc"
                    value={true}
                    checked={props.formData.adHoc === true}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2">True</label>
                  <input
                    style={{ display: 'inline-grid', width: '20px', height: '20px', borderRadius: '10px' }}
                    type="radio"
                    name="adHoc"
                    value={false}
                    checked={props.formData.adHoc === false}
                    onChange={handleRadioChange}
                  />
                  <label className="m-2 ">False</label>
                </div>
              </div>
            </div>
            <div data-testid="ad-hoc-router-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Ad Hoc Router</label>
              <input ref={adHocRouterInputRef} onFocus={() => handleFocus(adHocRouterInputRef)} name="adHocRouter" value={props.formData.adHocRouter} onChange={handleInputChange} />
            </div>
            <div data-testid="sequence-dropdown-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Parallel or Sequence</label>
              <select id="selectField" name="parSeq" value={props.formData.parSeq} onChange={handleSelectChange}>
                <option value={''} disabled>
                  --Select--
                </option>
                <option value="Parallel">Parallel</option>
                <option value="Sequential">Sequential</option>
              </select>
            </div>
            <div data-testid="status-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Status</label>
              <select id="selectField" name="status" value={props.formData.status} onChange={handleSelectChange}>
                <option value={''} disabled>
                  --Select--
                </option>
                <option value="Complete">Complete</option>
                <option value="Future">Future</option>
                <option value="Waiting">Waiting</option>
              </select>
            </div>
            <div data-testid="disposition-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Disposition</label>
              <select name="disposition" value={props.formData.disposition} onChange={handleDispositionChange}>
                <option value={''} disabled>
                  --Select--
                </option>
                <option value="Routed">Routed</option>
                <option value="Routed/Approved">Routed/Approved</option>
                <option value="Rejected">Rejected</option>
                <option value="Redirected">Redirected</option>
              </select>
            </div>
            <div data-testid="target-user-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>Target User or Workgroup *</label>
              <select id="selectField" name="targetUserOrWorkgroup" value={props.formData.targetUserOrWorkgroup} onChange={handleSelectChange}>
                <option value={''} disabled>
                  --Select--
                </option>
                <option value="W">Workgroup</option>
                <option value="U">User</option>
              </select>
            </div>
            <div data-testid="buow-id-container" className={[styles.inputFieldSection, 'col-4'].join(' ')}>
              <label>BUOW ID *</label>
              <AsyncSelect
                styles={customStyles}
                isClearable
                isDisabled={props.isNewRecord ? false : true}
                loadOptions={loadBUOWOptions}
                value={props?.formData?.buowId !== '' ? { label: props?.formData?.buowId, value: props?.formData?.buowId } : null}
                onChange={handleDropdownChangeBUOW}
              />

              {(props.formData.buowId === null || props.formData.buowId === '') && isRequiredError === true && handleIsRequiredError()}
            </div>
            <div data-testid="user-or-workgroup-container" className={[styles.searchFieldDiv, 'col-4'].join(' ')}>
              <label className="mb-0">User or Workgroup *</label>
              <AsyncSelect
                styles={customStyles}
                isClearable
                loadOptions={loadOptions}
                value={props?.formData?.userOrWorkgroupId}
                onChange={handleDropdownChange}
                isDisabled={isUserOrWorkgroupSelected}
              />
              {(props.formData.userOrWorkgroupId === null || props.formData.userOrWorkgroupId === '') && isRequiredError === true && handleIsRequiredError()}
            </div>

            <div className={[styles.inputFieldSection, 'col-4 d-flex align-items-center'].join(' ')} style={props.isNewRecord ? { height: '45px' } : {}}>
              <div className={styles.inputFieldInternalDiv}>
                <div className={[styles.radioInputOuterDiv, 'mt-1'].join(' ')} data-testid="radio-function">
                  <div>Active Ind: </div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={onChangeActiveInd} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={onChangeActiveInd} /> False
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.routingStepsFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={'col-12 justify-content-center'}>
            {props.isNewRecord ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => AddSubmitFunct(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => EditSubmitFunct(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => cancelFunct()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
