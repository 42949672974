import React, { useEffect } from 'react';
import crossIcon from '../../fsa-style/img/crossIcon.svg';
import { Modal } from 'react-bootstrap';
import styles from './InfoPopupModal.module.scss'; 
import PropTypes from 'prop-types';
import '../../GlobalStyle.scss';

/**
 * InfoPopupModal component to display informational popup for users after login.
 *
 * This modal appears immediately upon successful login and provides an informational message.
 *
 * @component
 */
const InfoPopupModal = ({ show, onClose, message }) => {
  useEffect(() => {
    // Automatically close the modal after 20 seconds
    const timer = setTimeout(() => {
      if (show) onClose();
    }, 20000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, [show, onClose]);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={[styles.infoPopupContainer, 'glbInfoPopupStyle'].join(' ')} 
      onHide={onClose}
      style={{ zIndex: 99999999 }} //BAS-3614 zIndex increased to display popup on top of the loader from search
      backdrop="static"
    >
      <div className={styles.info_modal_popup}>
        <Modal.Header>
          <div className={styles.modal_header_section}>
            <div>
              <h2 className={styles.modal_header_panel_text}>Warning </h2>
            </div>
            <div className={styles.modal_header_panel_icon} onClick={onClose}>
              <img src={crossIcon} alt="close" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.flex_container_custom}>
            <div className={styles.info_modal_text_wrapper}>
              <span className={styles.info_modal_heading}>{message}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{border: 'none'}}>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

InfoPopupModal.propTypes = {
  /**
   * Flag to control the visibility of the modal.
   */
  show: PropTypes.bool.isRequired,

  /**
   * Function to be called when the modal is closed.
   */
  onClose: PropTypes.func.isRequired,
};

export default InfoPopupModal;
