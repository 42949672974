import './SuspenseQueueList.scss';
import jobDescription from './SuspenseJobsDescription.json';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SuspenseQueueList({ dataItem, onSuspenseQueueItemClicked }) {
  const job = jobDescription.filter((job) => job.jobStatus?.toLowerCase()?.trim() === dataItem.JobStatus?.toLowerCase()?.trim())[0];
  const jobIcon = job.indicator === '#F0BCBE' ? faExclamationTriangle : job.indicator === '#FAD980' ? faExclamationCircle : faUserTimes;

  const handleOnClick = () => {
    onSuspenseQueueItemClicked({
      jobId: dataItem.JobId,
      jobStatus: dataItem.JobStatus,
      fileName: dataItem.FileName,
      jobCreationDate: dataItem.JobSubmissionDate,
      jobStatusNote: dataItem.JobStatusNote,
      activityURL: dataItem.ActivityUrl,
      uploadingUser: dataItem.UserName,
      claimUserEauth: dataItem.LastModifiedBy,
    });
  };

  return (
    <div className="row border-bottom border-top align-middle job" onClick={handleOnClick}>
      <div style={{ borderColor: job.indicator, overflow: 'hidden' }} className="col-12 job-header-top">
        <div className="job-header">
          <FontAwesomeIcon style={{ marginRight: '10px' }} icon={jobIcon} />
          <span className="file-name fs-14">
            <b>{dataItem.FileName}</b>
          </span>
          <br />
          <span className="user-name fs-14">
            <b>User: {dataItem.UserName}</b>
          </span>

          <span className="upload fs-14">
            <b>Uploaded: {dataItem.UploadDate}</b>
          </span>
        </div>

        <div className="job-description">
          <div className="job-status fs-14">
            <b>Job Status:</b>
            {dataItem.JobStatus}
          </div>
          <div className="description fs-14">
            <b>Description:</b>
            {dataItem.JobStatusNote ? dataItem.JobStatusNote : job.description}
          </div>
        </div>
      </div>
    </div>
  );
}
