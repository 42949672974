import React, { useEffect, useState, useContext } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
// import { filterBy } from '@progress/kendo-data-query';
import FullscreenIcon from '../../../fsa-style/img/svgs/fullScreenIcon.svg';
// import _ from 'lodash';
import ImageView from '../../ImageView/ImageView';
import PDFView from '../../PDFView/PDFView';
import { useWindowSize } from '../../../services/helper';
import { getDoctype } from '../../../services/suspenseQueueService';
import SuccessProcessGrowl from '../../SuspenseQueue/SuccessProcessGrowl';
import FormDatepicker from '../../FormModules/FormDatepicker';
import UserContext from '../../UserContext/UserContext';
import styles from './PreviewDocuement.module.scss';
import moment from 'moment/moment';
import _, { isArray } from 'lodash';
import '../../../GlobalStyle.scss'
export default function PreviewDocument({
  isMetadataLoading,
  popupOpen,
  closePopup,
  doc,
  objectMetadata,
  onDocumentLoadSuccess,
  numPages,
  loading,
  docName,
  docType,
  metadataDisplayList,
  docDetailResponse,
  documentTitle,
  sections,
  selectedRecord,
  searchErrorMessage,
  selectedBuowFilter,
  additionalMetadata,
}) {
  const [zoom, setZoom] = useState(1);
  const [selected, setSelected] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [newPageNumber, setNewPageNumber] = useState(1);
  const [width, height] = useWindowSize();
  // const [EditRefresh, setEditRefresh] = useState(false);
  // const [startDate, setStartDate] = useState();

  // const [editedFields, setEditedFields] = useState();
  // const [editDocumentTitle, setEditDocumentTitle] = useState();
  const [doctype, setDoctype] = useState([]);
  const [doctypeFilteredData, setDoctypeFilteredData] = useState(doctype.slice());
  // const [sectionSelection, setSectionSelection] = useState(null);
  // const [documentTitleSelection, setDocumentTitleSelection] = useState(null);
  const [expand, setExpand] = useState('DEFAULT');
  const [modalTitle, setModalTitle] = useState(selectedRecord);
  const [systemColumnsData, setSystemColumnsData] = useState([]);
  const expandHandler = () => {
    if (expand === 'DEFAULT') {
      setExpand('FULLSCREEN');
    } else {
      setExpand('DEFAULT');
    }
  };
  const { userPermissions } = useContext(UserContext);

  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);

  // const doctypeFilterData = (filter) => {
  //   const data = doctype.slice();
  //   return filterBy(data, filter);
  // };

  // const defaultItemfordoctype = {
  //   display_name: documentTitle,
  // };
  // const defaultItemforSections = {
  //   display_name: sections,
  // };
  const [loaderUpdateMetaData, setLoaderUpdateMetaData] = useState(false);
  const [combinedFieldList, setCombinedFieldList] = useState([]);
  let system_config = JSON.parse(window.localStorage.getItem('system_config'))?.buowDescription;
  const defaultBUOW = Object.keys(system_config).find((key) => system_config[key]?.is_default === true);
  // Filter systemFolders to keep only those that match the selectedBuow
  const effectiveBuow = defaultBUOW; // Use defaultBUOW if selectedBuow is empty

  // const DocTitleDropListChange = (e) => {
  //   setDocumentTitleSelection(e.target.value);
  //   let keyTitle = 'title';
  //   let keyObjectType = 'object_type_name';
  //   let value = e.target.value.display_name;
  //   setEditDocumentTitle((values) => ({
  //     ...values,
  //     [keyTitle]: value,
  //     [keyObjectType]: value,
  //   }));
  // };
  // const sectionDropListChange = (e) => {
  //   setSectionSelection(e.target.value);
  //   let key = 'sections';
  //   let value = e.target.value.display_name;
  //   setEditedFields((values) => ({ ...values, [key]: value }));
  // };

  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem('SystemColumns')) || [];

    let system_config = JSON.parse(window.localStorage.getItem('system_config'))?.buowDescription;
    const defaultBUOW = Object.keys(system_config).find((key) => system_config[key]?.is_default === true);

    const effectiveBuow = selectedBuowFilter || defaultBUOW;

    const filteredData = data.filter((itm) => itm.buowName === effectiveBuow);

    setSystemColumnsData(filteredData);
  }, [selectedBuowFilter]);

  useEffect(() => {
    const getdoc = async () => {
      try {
        const response = await getDoctype();
        if (Array.isArray(response.data)) {
          const mappedData = response.data.map((item, index) => ({
            id: index + 1,
            display_name: item.objectTypeName,
          }));
          setDoctype(mappedData);
          setDoctypeFilteredData(mappedData);
        } else {
          console.error('Invalid data format from API:', response.data);
        }
      } catch (error) {
        console.error('Error in getDoctype:', error.message);
      }
    };

    getdoc();
    setModalTitle(selectedRecord);
    // setDocumentTitleSelection(_.find(doctype, { display_name: docDetailResponse?.metadata.object_type?.title }));
    // setSectionSelection(_.find(sectionList, { display_name: docDetailResponse?.metadata.object_specific?.Section }));
    // sectionList.fin
  }, [docDetailResponse, modalTitle]);

  // Disposition Information Functionality Start
  // const handleStartDateChange = (e) => {
  //   setStartDate(e.target.value);
  // };
  // const UpdateDisposition = () => {
  //   console.log(startDate, 'updatedispositiondate');
  // };
  // Disposition Information Functionality End

  // To add isEditSelected Start
  useEffect(() => {
    setTimeout(() => {
      metadataDisplayList &&
        Object.keys(metadataDisplayList).forEach((key) => {
          return (metadataDisplayList[key].isEditSelected = false);
        });
      objectMetadata &&
        Object.keys(objectMetadata).forEach((key) => {
          if (key === 'object_type') {
            return (objectMetadata[key].isEditSelected = false);
          }
        });
    }, 2000);
    renderOptions(systemColumnsData, metadataDisplayList);
  }, [metadataDisplayList, expand]);
  // To add isEditSelected End

  // const [fcHistory, setFcHistoryr] = useState(objectMetadata.record_management.file_code_change_history);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const handleZoom = (zoomDirection) => {
    zoomDirection === 'in' ? setZoom((previousZoom) => previousZoom + 0.1) : setZoom((previousZoom) => previousZoom - 0.1);
  };

  const changePage = (direction) => {
    direction === 'left' ? setPageNumber((prevPageNumber) => prevPageNumber - 1) : setPageNumber((prevPageNumber) => prevPageNumber + 1);
    direction === 'left' ? setNewPageNumber((prevPageNumber) => prevPageNumber - 1) : setNewPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const onChangePage = (pageNo) => {
    let page = pageNo === '' ? pageNo : parseInt(pageNo); 
    setNewPageNumber(page);
  };

  const onSaveEnteredPage = (pageNo) => {
    if(pageNo === '' || numPages < pageNo) setPageNumber((prevPageNumber) => prevPageNumber)
    else setPageNumber(parseInt(pageNo));
  };

  const onResetPage = () => {
    setNewPageNumber(pageNumber)
  };

  const closePopupActions = () => {
    closePopup();
    setExpand('DEFAULT');
    setSelected(0);
    setZoom(1.0);
  };

  const isCustomerInfo = (key) => {
    return (
      key.startsWith('producer[') ||
      key.startsWith('producer.') ||
      key.startsWith('entity.') ||
      key.startsWith('entity[') ||
      key.startsWith('participant.') ||
      key.startsWith('participant[') ||
      key.startsWith('member_info.') ||
      key.startsWith('member_info[') ||
      key.startsWith('guardian.') ||
      key.startsWith('guardian[') ||
      key.startsWith('title') ||
      key.startsWith('object_type_name') ||
      key.startsWith('edition_date')
    );
  };

  const metadataView = (metadataDisplayList) => {
    if (metadataDisplayList) {
      metadataDisplayList = Object.fromEntries(
        Object.entries(metadataDisplayList).sort((x, y) => {
          if (x[1]?.display_name < y[1]?.display_name) return -1;
          if (x[1]?.display_name > y[1]?.display_name) return 1;
          return 0;
        })
      );
      return Object.keys(metadataDisplayList).map((key, idx) => {
        if (key === 'sections') {
          return null;
        }
        if (key === 'object_ocr') {
          return null;
        }
        if (key === 'document_ocr_id') {
          return null;
        }
        if (!isCustomerInfo(key)) {
          return (
            <div className="returnedData" key={key}>
              <div className="keyfont">{metadataDisplayList[key]?.display_name}</div>
              <div className="valuefont">
                {metadataDisplayList[key]?.isEditSelected === true ? (
                  <>
                    {metadataDisplayList[key]?.display_name === 'Term Date' && (
                      <>
                        <FormDatepicker value={metadataDisplayList[key]?.value} />
                      </>
                    )}
                    {metadataDisplayList[key]?.display_name === 'Employment Status' && (
                      <>
                        <select value={metadataDisplayList[key]?.value !== 'Active' || metadataDisplayList[key]?.value !== 'TERMINATED' ? false : true}>
                          <option value={true}>Active</option>

                          <option value={false}>In-active</option>
                        </select>
                      </>
                    )}

                    {metadataDisplayList[key]?.display_name !== 'Term Date' && metadataDisplayList[key]?.display_name !== 'Employment Status' && (
                      <input value={metadataDisplayList[key]?.value !== '.' && metadataDisplayList[key]?.value} style={{ width: 'calc(100% - 15%)' }} />
                    )}
                  </>
                ) : (
                  <>
                    {metadataDisplayList[key]?.display_name === 'Term Date' && metadataDisplayList[key]?.value && (
                      <>
                        {console.log(metadataDisplayList[key]?.value)} {moment(metadataDisplayList[key]?.value).format('MM/DD/YYYY')}
                      </>
                    )}
                    {metadataDisplayList[key]?.display_name === 'Employment Status' && (
                      <>
                        {metadataDisplayList[key]?.value === 'TERMINATED' && 'In-Active'}
                        {metadataDisplayList[key]?.value?.toLowerCase() === 'true' && 'Active'}
                        {metadataDisplayList[key]?.value?.toLowerCase() === 'false' && 'In-Active'}
                        {metadataDisplayList[key]?.value === true && 'Active'}
                        {metadataDisplayList[key]?.value === false && 'In-Active'}
                      </>
                    )}
                    {metadataDisplayList[key]?.display_name !== 'Term Date' &&
                    metadataDisplayList[key]?.display_name !== 'Employment Status' &&
                    (metadataDisplayList[key]?.value === null ||
                      metadataDisplayList[key]?.value === 'null' ||
                      metadataDisplayList[key]?.value === '' ||
                      metadataDisplayList[key]?.value === undefined)
                      ? 'NA'
                      : metadataDisplayList[key]?.value}
                  </>
                )}
              </div>
            </div>
          );
        }
      });
    }
  };
  const additionalMetadataView = (additionalData) => {
    if (additionalData) {
      return additionalData.map((dataItem, idx) => {
        return (
          //Here...
          <>
            {Object.entries(dataItem).map(([key, value]) => (
              <div className="returnedData" key={idx + '_additionalData'}>
                <div className="keyfont" style={{ textTransform: 'capitalize' }}>
                  {key}
                </div>
                <div
                  className="valuefont"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {isArray(value) === true ? <span className="mx-2">{value?.join(', ')}</span> : <span className="mx-2">{value}</span>}
                </div>
              </div>
            ))}
          </>
        );
      });
    }
  };
  const returnTitle = (obj) => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    let system_config = JSON.parse(window.localStorage.getItem('system_config'))?.buowDescription;
    const defaultBUOW = Object.keys(system_config).find((key) => system_config[key]?.is_default === true);

    const effectiveBuow = selectedBuowFilter || defaultBUOW;

    systemFolders = systemFolders.filter((itm) => itm.buowName === effectiveBuow);

    let title = '';

    if (obj?.['object_specific'][systemFolders[0]?.name] !== undefined) title = obj?.['object_specific'][systemFolders[0]?.name].replace(/\s/g, '');
    if (obj?.['object_specific']['Folder'] !== undefined) title = title + ' > ' + obj?.['object_specific']['Folder'];

    return title;
  };

  const reorderObjectKeys = (data, order) => {
    // Create a map of keys to their sequence numbers from the order object
    const sequenceMap = Object.keys(order).reduce((map, key) => {
      map[key] = order[key].sequence;
      return map;
    }, {});

    // Sort the keys of 'data' based on the sequence values in 'sequenceMap'
    const sortedKeys = Object.keys(data).sort((a, b) => {
      // Handle case where a key may not be present in the sequenceMap
      const seqA = sequenceMap[a];
      const seqB = sequenceMap[b];
      return seqA - seqB;
    });

    // Rebuild the object with sorted keys
    const sortedData = {};
    sortedKeys.forEach((key) => {
      sortedData[key] = data[key];
    });

    return sortedData;
  };
  const renderSysCol = () => {
    return systemColumnsData
      .sort((a, b) => a.systemFolderLevel - b.systemFolderLevel)
      .map((col, idx) => {
        return (
          <div className="returnedData" key={uuidv4()}>
            {/* start Object Type dropdown */}
            <div className="keyfont">{col?.name}</div>
            {/* <div>{objectMetadata.object_specific[col.name]}</div> */}
            {objectMetadata.object_specific[col.name] === null ||
            objectMetadata.object_specific[col.name] === 'null' ||
            objectMetadata.object_specific[col.name] === '' ||
            objectMetadata.object_specific[col.name] === undefined
              ? 'NA'
              : objectMetadata.object_specific[col.name]}
            {/* end Object Type dropdown */}
          </div>
        );
      });
  };

  const renderOptions = (SystemColumnsFolderData, metadataDisplayList, additionalData) => {
    // Helper function to sort data alphabetically by a given key
    const sortAlphabetically = (data, key) => {
      return data.sort((a, b) => {
        const nameA = (key ? a[key] : a).toLowerCase();
        const nameB = (key ? b[key] : b).toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
    };

    // Process SystemColumnsFolderData
    const processSysOptions = (data) => {
      if (!data || data.length === 0) return [];
      data = data.filter((itm) => itm.buowName == effectiveBuow);
      return data.map((itm) => ({
        name: itm.name,
        type: 'sysOptions',
        content: itm, // Preserve the entire object for rendering
      }));
    };

    // Process metadataDisplayList
    const processMetadata = (data) => {
      if (!data) return [];
      data = reorderObjectKeys(data, system_config[defaultBUOW]?.indexSheet);
      return Object.entries(data)
        .filter(([key]) => !['sections', 'object_ocr', 'full_text_search', 'document_ocr_id'].includes(key))
        .map(([key, value]) => ({
          name: value?.display_name,
          type: 'metadata',
          content: { key, ...value },
        }));
    };

    // Process additionalData
    const processAdditionalData = (data) => {
      if (!data || data.length === 0) return [];
      return data.flatMap((dataItem, idx) =>
        Object.entries(dataItem).map(([key, value]) => ({
          name: key,
          type: 'additionalData',
          content: { key, value, index: idx },
        }))
      );
    };

    // Combine and sort all data
    const combinedData = sortAlphabetically(
      [...processSysOptions(SystemColumnsFolderData), ...processMetadata(metadataDisplayList), ...processAdditionalData(additionalData)],
      'name'
    );
    console.log(combinedData);
    setCombinedFieldList(combinedData);
  };
  const fieldRenderer = (combinedData) => {
    console.log(combinedData);
    return combinedData.map((item, idx) => {
      if (item.type === 'sysOptions') {
        const itm = item.content;

        return (
          <div className="returnedData" key={uuidv4()}>
            {/* start Object Type dropdown */}
            <div className="keyfont">{item?.name}</div>
            {/* <div>{objectMetadata.object_specific[col.name]}</div> */}
            {objectMetadata.object_specific[itm?.name] === null ||
            objectMetadata.object_specific[itm?.name] === 'null' ||
            objectMetadata.object_specific[itm?.name] === '' ||
            objectMetadata.object_specific[itm?.name] === undefined
              ? 'NA'
              : objectMetadata.object_specific[itm?.name]}
            {/* end Object Type dropdown */}
          </div>
        );
      } else if (item.type === 'metadata') {
        const content = item.content;
        console.log(item);
        const renderValue = content?.value && typeof content?.value === 'object' ? JSON.stringify(content?.value) : content?.value;

        return (
          metadataDisplayList[content?.key]?.display_name !== 'Additional Metadata' && (
            <div className="returnedData" key={content?.key}>
              <div className="keyfont">{metadataDisplayList[content?.key]?.display_name}</div>
              <div className="valuefont">
                {metadataDisplayList[content?.key]?.isEditSelected === true ? (
                  <>
                    {metadataDisplayList[content?.key]?.display_name === 'Term Date' && (
                      <>
                        <FormDatepicker value={metadataDisplayList[content?.key]?.value} />
                      </>
                    )}
                    {metadataDisplayList[content?.key]?.display_name === 'Employment Status' && (
                      <>
                        <select value={metadataDisplayList[content?.key]?.value !== 'Active' || metadataDisplayList[content?.key]?.value !== 'TERMINATED' ? false : true}>
                          <option value={true}>Active</option>

                          <option value={false}>In-active</option>
                        </select>
                      </>
                    )}

                    {metadataDisplayList[content?.key]?.display_name !== 'Term Date' && metadataDisplayList[content?.key]?.display_name !== 'Employment Status' && (
                      <input value={metadataDisplayList[content?.key]?.value !== '.' && metadataDisplayList[content?.key]?.value} style={{ width: 'calc(100% - 15%)' }} />
                    )}
                  </>
                ) : (
                  <>
                    {metadataDisplayList[content?.key]?.display_name === 'Term Date' && metadataDisplayList[content?.key]?.value && (
                      <>
                        {console.log(metadataDisplayList[content?.key]?.value)} {moment(metadataDisplayList[content?.key]?.value).format('MM/DD/YYYY')}
                      </>
                    )}
                    {metadataDisplayList[content?.key]?.display_name === 'Employment Status' && (
                      <>
                        {metadataDisplayList[content?.key]?.value === 'TERMINATED' && 'In-Active'}
                        {metadataDisplayList[content?.key]?.value?.toLowerCase() === 'true' && 'Active'}
                        {metadataDisplayList[content?.key]?.value?.toLowerCase() === 'false' && 'In-Active'}
                        {metadataDisplayList[content?.key]?.value === true && 'Active'}
                        {metadataDisplayList[content?.key]?.value === false && 'In-Active'}
                      </>
                    )}
                    {metadataDisplayList[content?.key]?.display_name !== 'Term Date' &&
                    metadataDisplayList[content?.key]?.display_name !== 'Employment Status' &&
                    (metadataDisplayList[content?.key]?.value === null ||
                      metadataDisplayList[content?.key]?.value === 'null' ||
                      metadataDisplayList[content?.key]?.value === '' ||
                      metadataDisplayList[content?.key]?.value === undefined)
                      ? 'NA'
                      : metadataDisplayList[content?.key]?.value}
                  </>
                )}
              </div>
            </div>
          )

          // })
        );
      } else if (item.type === 'additionalData') {
        const content = item.content;
        return (
          <div className="returnedData" key={`${content.index}_additionalData`}>
            <div className="keyfont" style={{ textTransform: 'capitalize' }}>
              {content.key}
            </div>
            <div
              className="valuefont"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {Array.isArray(content.value) ? (
                <span className="mx-2">{content.value.join(', ')}</span>
              ) : (
                <span className="mx-2">{content.value === 'null' || content.value === null || content.value === undefined || content.value === '' ? 'NA' : content.value}</span>
              )}
            </div>
          </div>
        );
      }
    });
  };
  return (
    popupOpen && (
      <div>
        <Window
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            maxHeight: '100%',
          }}
          minimizeButton={() => null}
          maximizeButton={() => null}
          stage={expand}
          title={returnTitle(objectMetadata)}
          closeButton={() => (
            <>
              <button aria-label="Expand Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => expandHandler()}>
                {/* <b>X</b> */}
                <img src={FullscreenIcon} alt="FullScreen icon" />
              </button>
              <button aria-label="Close Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => closePopupActions()}>
                {/* <b>X</b> */}
                <img src={crossIcon} alt="cross icon" />
              </button>
            </>
          )}
          restoreButton={() => null}
          modal={true}
          draggable={false}
          left={width * 0.025}
          top={height * 0.05}
          onClose={closePopupActions}
          height={height * 0.9}
          width={width * 0.95}
        >
          {/* {loading ? (
            <div className="center">
              <CircularProgress />
            </div>
          ) : objectMetadata ? ( */}

          {isMetadataLoading ? (
            <div className="center">
              <CircularProgress />
            </div>
          ) : (
            // objectMetadata ? (
            <>
              <div>
                <div className="detailViewCon">
                  {loading ? (
                    <div className="center">
                      <CircularProgress />
                    </div>
                  ) : objectMetadata ? (
                    <>
                      <div className={styles.fileViewer}>
                        {docType === 'pdf' && doc !== undefined && (
                          <>
                            {' '}
                            <header className="objectTypeHeaderContainer">
                              <p className="objectTypeHeading" style={{fontSize: '1.5em'}}>Previews</p>
                            </header>
                            <PDFView
                              doc={doc}
                              docName={docName}
                              docType={docType}
                              onDocumentLoadSuccess={onDocumentLoadSuccess}
                              pageNumber={pageNumber}
                              changePage={changePage}
                              newPageNumber={newPageNumber}
                              onChangePage={onChangePage}
                              onResetPage={onResetPage}
                              onSaveEnteredPage={onSaveEnteredPage}
                              numPages={numPages}
                              zoom={zoom}
                              handleZoom={handleZoom}
                              pageWidth={900}
                            ></PDFView>
                          </>
                        )}
                        {docType === 'image' && <ImageView doc={doc} docName={docName}></ImageView>}
                        {/* <DownloadFile
                      doc={doc}
                      docName={docName}
                      docType={docType}
                    ></DownloadFile> */}
                      </div>

                      <TabStrip className={["detailsPreviewTab glbPreviewDocModal detailViewerPreview w-100"].join(' ')} selected={selected} onSelect={handleSelect}>
                        {userPermissions['ObjectMetadata_View_Object'] === true && (
                          <TabStripTab title="Document Metadata" className='detailViewerPreview'>
                            {/* TODO1  */}
                            <div
                              className="metadata"
                              style={{
                                pointerEvents: loaderUpdateMetaData === true ? 'none' : 'auto',
                              }}
                            >
                              {/* {renderSysCol()} */}
                              {/* <div className="returnedData" key={uuidv4()}> */}
                              {/* start Object Type dropdown */}
                              {/* <div className="keyfont">Object Type</div>
                                <div>{documentTitle}</div> */}
                              {/* end Object Type dropdown */}
                              {/* </div> */}

                              {/* <div className="returnedData" key={uuidv4()}> */}
                              {/* start Sections dropdown */}
                              {/* <div className="keyfont">Sections</div>
                                <div>{sections}</div> */}
                              {/* end Sections dropdown */}
                              {/* </div> */}
                              {/* {metadataView(metadataDisplayList)} */}
                              {fieldRenderer(combinedFieldList)}

                              {additionalMetadataView(additionalMetadata)}
                            </div>
                            <div
                              style={{
                                textAlign: 'right',
                                marginTop: '10px',
                                marginRight: '1em',
                              }}
                              className="d-flex justify-content-end"
                            >
                              {' '}
                              {loaderUpdateMetaData && (
                                <div>
                                  <CircularProgress />
                                </div>
                              )}
                            </div>
                          </TabStripTab>
                        )}
                      </TabStrip>
                    </>
                  ) : (
                    <div className="center">
                      {/* <h3>{errorMessage}</h3> */}
                      <div className="fsa-divider"></div>
                      {/* <h2>{cellClicked}</h2> */}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    textAlign: 'right',
                    marginTop: '10px',
                    marginRight: '5em',
                  }}
                >
                  {' '}
                  {/* <button onClick={closePopupActions} className="fsa-btn">
                  <span>Done</span>
                </button> */}
                </div>
              </div>

              {searchErrorMessage !== '' && (
                <div className="center">
                  <h3>{searchErrorMessage}</h3>
                  <div className="fsa-divider"></div>
                </div>
              )}
            </>
          )}
          {/* // ) : (
            <div className="center">
              <h3>{errorMessage}</h3>
              <div className="fsa-divider"></div>
              <h2>{cellClicked}</h2>
            </div>
          )} */}

          {/* // ) : (
          //   <div className="center">
          //     <h3>Could not retrieve. Provide reference number below to support team.</h3>
          //     <div className="fsa-divider"></div>
          //     <h2>{cellClicked}</h2>
          //   </div>
          // )} */}
          <SuccessProcessGrowl
            isSuccessGrowlHidden={isSuccessGrowlHidden}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            message={'Successfully updated file object metadata.'}
          />
        </Window>
      </div>
    )
  );
}
