import axios from 'axios';

export async function getReferenceValues() {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/references';
  const response = await axios.get(url, config);
  return response;
}

//you need a list of records to send to a file code
//or vice versa
export async function assignFileCode(fcId, selectedIds) {
  const assignResponse = null;
  let url = '/recordsmanagementapi/records-management/record?filecodeId=' + fcId;
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };

  try {
    const response = await axios.put(url, selectedIds, config);
    if (response.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (ex) {
    return assignResponse;
  }
}

export async function searchFileCodes(searchCriteria) {
  let searchFileCodesResponse = [];
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/filecodes/search';
  try {
    searchFileCodesResponse = await axios.post(url, searchCriteria, config);
    return searchFileCodesResponse;
  } catch (ex) {
    return searchFileCodesResponse;
  }
}

export async function getFileCodes() {
  //TODO remove CONFIG
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/filecodes';
  const response = await axios.get(url, config);
  return response;
}

export async function keywordSearchFileCodes(FileCodeContract) {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/filecodes/search?param=getFilecodesWithPredicate';
  const response = await axios.post(url, FileCodeContract, config);
  return response;
}

export async function advSearchFileCodes(FileCodeContract) {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/filecodes/search?param=getFilecodesWithPredicate';
  const response = await axios.post(url, FileCodeContract, config);
  return response;
}

export async function getHolds() {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/holds';
  const response = await axios.get(url, config);
  return response;
}

export async function searchHolds(HoldContract) {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/holds/search?param=getHoldsWithPredicate';
  const response = await axios.post(url, HoldContract, config);
  return response;
}

export async function getRecordSchedules() {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/schedules';
  const response = await axios.get(url, config);
  return response;
}

export async function addScheduleStep(step) {
  let scheduleStepResponse = null;
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  try {
    const url = '/recordsmanagementapi/records-management/schedules/schedulesteps';
    await axios.post(url, step, config).then((response) => {
      scheduleStepResponse = response;
    });
  } catch (ex) {
    console.log('Error in getManagementService.js addScheduleStep()', ex.message);
  }
  return scheduleStepResponse;
}

export async function addRecordSchedule(recordSchedule) {
  let scheduleStepResponse = null;
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  try {
    const url = '/recordsmanagementapi/records-management/schedules';
    await axios.post(url, JSON.stringify(recordSchedule), config).then((response) => {
      scheduleStepResponse = response;
    });
  } catch (ex) {
    console.log('Error in getManagementService.js addRecordSchedule()', ex.message);
  }
  return scheduleStepResponse;
}

export async function addNewTrigger(trigger) {
  let triggerAddResponse = null;
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  try {
    console.log('getManagementService.js - addNewTrigger');
    const url = '/recordsmanagementapi/records-management/references/event_name';
    await axios.put(url, trigger, config).then((response) => {
      triggerAddResponse = response;
    });
  } catch (ex) {
    console.log('Error in getManagementService.js addNewTrigger()', ex.message);
  }
  return triggerAddResponse;
}

// For Disposition Management Start
// TODO: Temporary token for API requests.
// const tempJWT = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkNvdXJ0bmV5IFRpYmVsIiwiYWdlbmN5Y29kZSI6ImZzYSIsIm9mZmljZWlkIjoxMjM0NSwiZWF1dGhpZCI6IjI4MjAwNDA2MzA5MDMwMDIxNTEzIiwiZW1haWwiOiJzdXNwZW5zZXF1ZXVlQG1haWwuY29tIiwiaWF0IjoxNTE2MjM5MDIyfQ.FZYCQkb6CoPRHXV0PE-wyFUCWvzSnnSO8m6WJhOAihE"

export async function getDisposition() {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  let url = '/recordsmanagementapi/records-management/disposition/search';

  const response = await axios.get(url, config);
  return response;
}

export async function approveDisposition(data) {
  let approveDispositionResponse = {};
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  try {
    const url = '/recordsmanagementapi/records-management/disposition/approvalrequest?param=dispositionFirstApproval';
    await axios.put(url, data, config).then((response) => {
      approveDispositionResponse.data = response;
    });
  } catch (ex) {
    console.log('Error in approveDisposition()', ex.response);
    approveDispositionResponse.error = ex.response;
  }
  return approveDispositionResponse;
}
// For Disposition Management End

// delete object record from search page using guid
export async function deleteRecords(data) {
  let deleteObjResponse = {};
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo ? userInfo.name : '',
      usdaeauthid: userInfo ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  try {
    const url = '/elasticsearch_api/metadata/bulk/' + data;
    await axios.delete(url, data, config).then((response) => {
      deleteObjResponse.data = response;
    });
  } catch (ex) {
    console.log('Error in approveDisposition()', ex.response);
    deleteObjResponse.error = ex.response;
  }
  return deleteObjResponse;
}
