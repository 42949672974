import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";

export default function PrimaryOrganizationDropDownList(props) {
  // TODO Get list from api
  const {value, onChange} = props;
  const primaryorg = [window.env.REACT_APP_CLIENT_NAME]

  return (
    <DropDownList
      data={primaryorg}
      className="fsa-field__item"
      ariaLabell="button"
      onChange={onChange} value={value}
    />
  )
}