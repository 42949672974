import React, { useState, useEffect, lazy, useRef } from 'react';
import '../../../components/FileCode/FileCode.scss';
import RuleTriggerMetadataData from './RuleTriggerMetadata.json';
// import TableRuleTriggerMetadata from './TableRuleTriggerMetadata';
import { createRolesTable, deleteRolesTable, searchRolesTable, getSearchContextId } from './RuleTriggerMetadataTableService.js';
import { getDefaultBuowName, getObjectId } from '../RuleTrigger/RuleTriggerTableService.js';
import { searchObjectHierarchy } from '../ObjectHierarchyTable/ObjectHierarchyService.js';
import styles from './RuleTriggerMetadata.module.scss';
import '../../../GlobalStyle.scss';

import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { Modal } from 'react-bootstrap';
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
import AsyncSelect from 'react-select/async';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AddEleXwalk from '../../../fsa-style/img/svgs/AddEleXwalk.svg';

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Rule Trigger Metadata', link: '' },
];

const TableRuleTriggerMetadata = lazy(() => import('./TableRuleTriggerMetadata.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
// const { DeleteModal } = lazy(() => import('../../../newcomponents/DeletePopup/DeleteModal'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function RuleTriggerMetadataTable() {
  const [data, setData] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [createEditModalShow, setCreateEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedDeleteRowData, setSelectedDeleteRowData] = useState();
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [ruleTriggerMetadata, setRuleTriggerMetadata] = useState(RuleTriggerMetadataData);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [formData, setFormData] = useState({
    contextId: '',
    key: '',
    value_type: '',
    value: '',
  });
  const [jsonData, setJsonData] = useState({});

  async function fetchRolesTableData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(false);
    // fetchRolesTableData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);

    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: '',
      key: '',
      value_type: '',
      value: '',
    });
    setIsNewRecord(true);
    setCreateEditModalShow(true);
  };

  const EditRowDataFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      key: data.key === undefined ? '' : data.key,
      value_type: data.value_type === undefined ? '' : data.value_type,
      value: data.value === undefined ? '' : data.value,
    });
    setJsonData(data?.ruleTriggerMetadata);
    setIsNewRecord(false);
    setCreateEditModalShow(true);
  };

  const DeleteRowDataFunc = (DeleteRowData) => {
    setDeleteModalShow(true);
    setSelectedDeleteRowData(DeleteRowData);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };
    deleteRolesTable(sendData).then(async (response) => {
      if (response) {
        setDeleteModalShow(false);
        await fetchRolesTableData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Rule Trigger Metadata deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        setLoading(false);
      }
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRolesTableData = JSON.parse(JSON.stringify(ruleTriggerMetadata));
    newRolesTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setRuleTriggerMetadata(newRolesTableData);
    await fetchNewRolesTableData();
  };

  const fetchNewRolesTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchRolesTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.rolesTablePage} data-testid="rolesTable-component">
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.rolesTableContent}>
        <h1 className={styles.pageTitle}>Rule Trigger Metadata</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId="keyword-search"
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Rule Trigger Metadata" className={styles.createRecordBtnStyle} onClick={createNewFunc} data-testid="create-button" />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>

        <div className={[styles.rolesTable, 'glbRolesTable'].join(' ')}>
          <TableRuleTriggerMetadata
            data={data}
            EditRowDataFunc={EditRowDataFunc}
            DeleteRowDataFunc={DeleteRowDataFunc}
            setDeleteModalShow={setDeleteModalShow}
            fields={ruleTriggerMetadata}
            page={page} // Pass the page state from the parent component
            pageSize={pageSize} // Pass the pageSize state from the parent component
            onPageChange={onPageChange}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <CreateEditRolesTableModal
          show={createEditModalShow}
          isNewRecord={isNewRecord}
          formData={formData}
          setFormData={setFormData}
          fetchRolesTableData={fetchRolesTableData}
          setCreateEditModalShow={setCreateEditModalShow}
          onHide={() => setCreateEditModalShow(false)}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
          jsonData={jsonData}
          setJsonData={setJsonData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={selectedDeleteRowData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Rule Trigger Metadata'}
          ></DeleteModal>
        )}

        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} dataTestId="success-growl" />
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} dataTestId="error-growl" />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

const CreateEditRolesTableModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [eleRefreshFlag, setEleRefreshFlag] = useState(false);
  const KeyOptions = [
    { label: 'Primary Object Class', value: 'Primary Object Class' },
    { label: 'Redaction Reason', value: 'Redaction Reason' },
    { label: 'Redaction Refrence', value: 'Redaction Refrence' },
  ];
  const ValueTypeOptions = [
    { label: 'Object ID', value: 'Object ID' },
    { label: 'Object Class', value: 'Object Class' },
    { label: 'BUOW Name', value: 'BUOW Name' },
    { label: 'Static', value: 'Static' },
  ];

  useEffect(() => {
    setisRequiredError(false);
    setEleRefreshFlag(true);
    if (props.formData.value_type !== '') {
      if (props.formData.value_type === 'BUOW Name') {
        getDefaultBuowName('').then((res) => {
          let options = res?.data.map((item) => {
            return { label: item?.buowName, value: item?.buowName };
          });
          setValueList(options);
          setEleRefreshFlag(false);
        });
      }
      if (props.formData.value_type === 'Object ID') {
        getObjectId('').then((res) => {
          let options = res?.data.map((item) => {
            return { label: item?.name, value: item?.name };
          });
          setValueList(options);
          setEleRefreshFlag(false);
        });
      }
      if (props.formData.value_type === 'Object Class') {
        searchObjectHierarchy({
          text: '',
          column: '',
          order: '',
          contextId: JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
        }).then((res) => {
          let options = res?.data.map((item) => {
            return { label: item?.objectClass, value: item?.objectClass };
          });
          setValueList(options);
          setEleRefreshFlag(false);
        });
      }
    }
  }, [props.formData]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const filterKeyOptions = (inputValue) => {
    return KeyOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterKeyOptions(inputValue));
      }, 1000);
    });

  const filterValueTypeOptions = (inputValue) => {
    return ValueTypeOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseValueTypeOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterValueTypeOptions(inputValue));
      }, 1000);
    });
  const filterValueOptions = (inputValue) => {
    return valueList.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseValueOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterValueOptions(inputValue));
      }, 1000);
    });

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };
  const onChangeValueType = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        value_type: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      value_type: updatedValue,
    }));
  };
  const onChangeValueDropDown = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        value: '',
      }));

    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      value: updatedValue,
    }));
  };
  const handleCreate = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    onChangeKey(newOption, 'fromCreate');
  };
  const onChangeKey = (selectedOption, fromCreate) => {
    if (!selectedOption) {
      props.setFormData((prevData) => ({
        ...prevData,
        key: '',
      }));
      return;
    }
    if (fromCreate === 'fromCreate') {
      props.setFormData((prevData) => ({
        ...prevData,
        key: selectedOption.value,
      }));
    }
    //  else {
    //   props.setFormData((prevData) => ({
    //     ...prevData,
    //     elementGroupName: selectedOption.label,
    //     elementGroupId: selectedOption.value || selectedOption.label, // Use label if no ID
    //   }));
    // }
  };

  const addToTable = () => {
    if (
      !props.formData.key ||
      !props.formData.value_type ||
      !props.formData.value ||
      props.formData.key === '' ||
      props.formData.value === '' ||
      props.formData.value_type === '' ||
      props.formData.value.length === 0
      // props.formData.value.le
    ) {
      setisRequiredError(true);
      return;
    }
    let tempObj = _.cloneDeep(props.jsonData);
    // setJsonData((prev) => [...prev, {
    //   [props.formData.key]:props.formData.value
    // }]);
    tempObj[props.formData.key] = props.formData.value;
    props.setJsonData(tempObj);

    props.setFormData({ contextId: '', key: '', value_type: '', value: [] });
  };

  const clearData = () => {
    props.setJsonData({});
    props.setFormData({ contextId: '', key: '', value_type: '', value: [] });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };
  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });

        callback(options);
      });
    }
  };

  const AddSubmitFunct = async (data) => {
    try {
      // if (!props.formData.roleName || data.roleName.length > 150 || data.roleDescription.length > 500) {
      //   setisRequiredError(true);
      //   return;
      // }
      let payload = { ruleTriggerMetadata: props.jsonData };
      payload.contextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
      props.isLoading(true);

      await createRolesTable(payload)
        .then((response) => {
          if (response.data) {
            props.fetchRolesTableData();
            props.setCreateEditModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Rule Trigger metadata created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            clearData();
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const firstInputRef = useRef(null);
  const roleDescription = useRef(null);

  // Handle modal show logic and initial focusa
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={[styles.rolesTableModal, 'glbRuleTriggerMetadataTableModalStyle'].join(' ')}
        onHide={props.onHide}
        data-testid="create-edit-modal"
      >
        <div>
          <Modal.Header>
            <div className={styles.modalHeader}>
              <div data-testid="modal-heading">
                <h2 className={styles.modalHeaderTitle}>{props.isNewRecord ? 'Create Rule Trigger Metadata' : 'View Rule Trigger Metadata'}</h2>
              </div>
              <div className={styles.modalHeaderIcon} onClick={() => (props.setCreateEditModalShow(false), clearData())}>
                <img src={crossIcon} alt="cross icon" />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className={styles.rolesTableModalBody}>
            <div className={[styles.modalBodySection, 'row mr-0 ml-0'].join(' ')}>
              {props.isNewRecord ? null : (
                <div className={[styles.inputFieldSection, 'col-4'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled={!props.isNewRecord}
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                </div>
              )}

              <div className={[styles.inputFieldSection, 'col-4'].join(' ')}>
                <label>Key *</label>
                <AsyncCreatableSelect
                  ref={props.isNew ? firstInputRef : null}
                  //   onFocus={() => handleFocus(firstInputRef)}
                  styles={customStyles}
                  isClearable
                  //   loadOptions={loadOptions} // Async API fetching
                  defaultOptions
                  loadOptions={promiseOptions}
                  isDisabled={!props.isNewRecord}
                  value={props?.formData?.key ? { label: props?.formData?.key, value: props?.formData?.key } : null}
                  onChange={(e) => {
                    onChangeKey(e, 'fromCreate');
                  }}
                  onCreateOption={handleCreate} // Create new options dynamically
                />

                {/* {props.formData.roleName.length > 150 && handleMaxLimitError(150)} */}
                {(props.formData.key === null || props.formData.key === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>
              <div className={[styles.inputFieldSection, 'col-4'].join(' ')}>
                <label>Value Type *</label>
                {/* <textarea
                  ref={roleDescription}
                  onFocus={() => handleFocus(roleDescription)}
                  name="roleDescription"
                  value={props.formData?.roleDescription !== null ? props.formData.roleDescription : ''}
                  onChange={handleInputChange}
                  type="text"
                /> */}
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={!props.isNewRecord}
                  defaultOptions
                  loadOptions={promiseValueTypeOptions}
                  onChange={onChangeValueType}
                  value={
                    props.formData.value_type !== '' &&
                    (props.formData.value_type !== undefined || props.formData.value_type !== null) && { label: props.formData.value_type, value: props.formData.value_type }
                  }
                />
                {(props.formData.value_type === null || props.formData.value_type === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
              </div>

              <div className={[styles.inputFieldSection, 'col-4'].join(' ')}>
                <label>Value *</label>
                {props.formData.value_type === 'Static' || props.formData.value_type === 'static' ? (
                  <input name="value" value={props.formData.value} type="text" onChange={handleInputChange} data-testid="name-input" />
                ) : (
                  <AsyncSelect
                    key={eleRefreshFlag}
                    styles={customStyles}
                    isClearable
                    isDisabled={!props.isNewRecord || props.formData.value_type === ''}
                    value={
                      props.formData.value !== '' &&
                      (props.formData.value !== undefined || props.formData.value !== null) && { label: props.formData.value, value: props.formData.value }
                    }
                    defaultOptions
                    loadOptions={promiseValueOptions}
                    onChange={onChangeValueDropDown}
                  />
                )}
                {(props.formData.value === null || props.formData.value === '' || props.formData.value.length === 0) && isRequiredError === true && (
                  <div>{handleIsRequiredError()}</div>
                )}
              </div>
              {props.isNewRecord === true && <div className={styles.AddRuleTriggerMetadataContainer}></div>}
              <div className={styles.tableContainer}>
                <div className={styles.tableHead}>
                  <p>Rule Trigger Metadata</p>
                  {props.isNewRecord === true && <img src={AddEleXwalk} alt="AddEleXWalk-btn" onClick={addToTable}></img>}
                </div>
                <div className={styles.tableBody}>
                  <textarea
                    disabled
                    value={Object.keys(props.jsonData).length !== 0 ? JSON.stringify(props.jsonData, null, 2) : ''} // Pretty-print JSON
                  ></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.rolesTableFooter}>
            <div style={{ display: 'flex', gap: '3rem' }} className={'col-12 justify-content-center'}>
              {props.isNewRecord && (
                <CustomButton
                  title="Save"
                  disabled={Object.keys(props.jsonData).length !== 0 ? false : true}
                  className={styles.saveRecordBtn}
                  onClick={() => AddSubmitFunct(props.formData)}
                  data-testid="create-submit-button"
                />
              )}
              {/* : ( */}
              {/* <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => EditSubmitFunct(props.formData)} /> */}
              {/* )} */}
              <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => (props.setCreateEditModalShow(false), clearData())} data-testid="cancel-button" />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
