import axios from 'axios';

export async function searchInBasketTable(data) {
  let inBasketTableResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/buow_api/in_baskets/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        inBasketTableResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages while calling searchInBasketTable() ', ex.message);
  }
  return inBasketTableResponse;
}
