import axios from 'axios';
import { serviceUserJwt } from '../../../services/serviceConfig';
export async function getRolesTable() {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/role';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function createRolesTable(data) {
  let createResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/security_api/role';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    createResponse.error = ex.response.data;
  }
  return createResponse;
}

export async function updateOneRolesTable(data) {
  let updateResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/security_api/role/' + data.id;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteRolesTable(data) {
  let deleteResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + `/security_api/role/${data.id}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchRolesTable(data) {
  let workQueueFilterResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/security_api/role/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function sortRolesTable(data) {
  let workQueueFilterResponse = [];
  try {
    // search/keywords=123?sortOn=columnName?sortOrder=ASC
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/role/sort/' + data.column + '/' + data.order;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function sortPermissionsTable(data) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/permission/sort/' + data.column + '/' + data.order;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
    workQueueFilterResponse.error = ex.response.data;
  }
  return workQueueFilterResponse;
}

export async function getSearchContextId(text) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/role/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getPermissions(text) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/permission/search/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
export async function getDefaultPermissions() {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/permission/';
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
