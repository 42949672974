import React, { useEffect, useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ElementGroup.module.scss';
import '../../../GlobalStyle.scss';

import ElementGroupData from './ElementGroupData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { createElementGroup, updateOneElementGroup, deleteElementGroup, searchElementGroup } from './ElementGroupService';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices';
import moment from 'moment';

const TableElementGroup = lazy(() => import('./TableElementGroup'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

const userInfoContext = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Element Group', link: '' },
];
export default function ElementGroup() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [elementGroupDetails, setElementGroupData] = useState(ElementGroupData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    activeInd: true,
    groupName: '',
    effectiveStartDate: '',
    effectiveEndDate: '',
    contextId: '',
    elementGroupId: '',
  });

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchElementGroup(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      order: sorting.order,
    };
    await searchElementGroup(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      activeInd: true,
      groupName: '',
      effectiveStartDate: '',
      effectiveEndDate: '',
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      elementGroupId: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
      groupName: data.groupName,
      effectiveStartDate: moment(data.effectiveStartDate).format('YYYY-MM-DD'),
      effectiveEndDate: moment(data.effectiveEndDate).format('YYYY-MM-DD'),
      contextId: data.contextId,
      elementGroupId: data.elementGroupId,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewElementGroupData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newElementGroupData = JSON.parse(JSON.stringify(elementGroupDetails));
    newElementGroupData.forEach((itm) => {
      itm.sortType = '';
    });
    setElementGroupData(newElementGroupData);
  };

  // Code needs to change
  const fetchNewElementGroupData = async () => {
    let payload = {
      text: '',
      column: '',
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
      order: '',
    };
    await searchElementGroup(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
      contextId: userInfoContext || JSON.parse(sessionStorage.getItem('user_information'))?.contextId,
    };
    await searchElementGroup(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  // const handleSearch = async (e) => {
  //   const { value } = e.target;
  //   setSearchKeyword(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      elementGroupId: DeleteData.elementGroupId,
      contextId: DeleteData.contextId,
    };
    deleteElementGroup(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Element Group deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="elementGroup-container" className={styles.elementGroupPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="elementGroup-page-wrapper" className={styles.elementGroupPageContent}>
        <h1 data-testid="elementGroup-heading" className={styles.pageTitle}>
          Element Group
        </h1>
        <div>
          <div data-testid="elementGroup-search-container" className={styles.searchSection}>
            <div data-testid="elementGroup-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="elementGroup-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-elementGroup'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="elementGroup-create-btn-container">
                <CustomButton testId={'create-elementGroup-btn'} title="Create Element Group" className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span data-testid="elementGroup-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="elementGroup-table-container" className={[styles.elementGroupTable, 'glbElementGroupTable'].join(' ')}>
          <TableElementGroup
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={elementGroupDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <AddEditElementGroupModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchElementGroupData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Element Group'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditElementGroupModal = (props) => {
  // const [searchData, setSearchData] = useState([]);
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  // const [searchDataBUOW, setSearchDataBUOW] = useState([]);
  const [selectedBuow, setSelectedBuow] = useState(null);

  useEffect(() => {
    setisRequiredError(false);
  }, [props.formData]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      // width: '222px',
    }),
    menu: (base) => ({
      ...base,
      // width: '222px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      // minWidth: '222px',
    }),
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));

    // Focus back on the date input after the value is set
    // if (name === 'effectiveStartDate') {
    //   effectiveStartDateInputRef.current?.focus();
    // } else if (name === 'effectiveEndDate') {
    //   effectiveEndDateInputRef.current?.focus();
    // }
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      // setSearchDataBUOW([]);
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        // setSearchDataBUOW(options);
        callback(options);
      });
    }
  };

  const handleSubmit = async (data) => {
    if (!data.contextId || !data.groupName || data.contextId.length > 50 || data.groupName.length > 50) setisRequiredError(true);
    else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);

      payload.effectiveStartDate = moment(payload.effectiveStartDate).isValid() ? moment(payload.effectiveStartDate).format('yyyy-MM-DDTHH:mm:ss') : '';
      payload.effectiveEndDate = moment(payload.effectiveEndDate).isValid() ? moment(payload.effectiveEndDate).format('yyyy-MM-DDTHH:mm:ss') : '';

      payload.elementGroupId = payload?.elementGroupId;

      await createElementGroup(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Element Group created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchElementGroupData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.contextId || !data.groupName || !data.elementGroupId || data.contextId.length > 50 || data.groupName.length > 50) setisRequiredError(true);
    else {
      let payload = _.cloneDeep(data);

      payload.effectiveStartDate = moment(payload.effectiveStartDate).isValid() ? moment(payload.effectiveStartDate).format('yyyy-MM-DDTHH:mm:ss') : '';
      payload.effectiveEndDate = moment(payload.effectiveEndDate).isValid() ? moment(payload.effectiveEndDate).format('yyyy-MM-DDTHH:mm:ss') : '';

      payload.elementGroupId = payload?.elementGroupId;

      props.isLoading(true);

      await updateOneElementGroup(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Element Group updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchElementGroupData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };

  const firstInputRef = useRef(null);
  const effectiveStartDateInputRef = useRef(null);
  const effectiveEndDateInputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.elementGroupModal, 'glbElementGroupModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Element Group' : 'Edit Element Group'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.elementGroupModalBody}>
          <div className={styles.modalBodySection}>
            {!props.isNew && (
              <div className={styles.inputFieldSection}>
                <>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Context Id *</label>
                    <AsyncSelect
                      styles={customStyles}
                      isClearable
                      isDisabled={props.isNew ? false : true}
                      loadOptions={loadContextIdList}
                      value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                      onChange={onChangeContextId}
                    />
                    {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                    {props.formData.contextId.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                  </div>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Element Group ID *</label>
                    <input name="elementGroupId" disabled required value={props.formData.elementGroupId} onChange={handleInputChange} type="number" />
                    {(props.formData.elementGroupId === null || props.formData.elementGroupId === '') && isRequiredError === true && handleIsRequiredError()}
                  </div>
                </>
              </div>
            )}
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Group Name *</label>
                <input ref={firstInputRef} onFocus={() => handleFocus(firstInputRef)} name="groupName" required value={props.formData.groupName} onChange={handleInputChange} />
                {(props.formData.groupName === null || props.formData.groupName === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.groupName.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <label>Effective Start Date</label>
                <input
                  name="effectiveStartDate"
                  value={props.formData.effectiveStartDate}
                  onChange={handleInputChange}
                  type="date"
                  // min={new Date().toISOString().split('T')[0]}
                  ref={effectiveStartDateInputRef}
                  onFocus={() => handleFocus(effectiveStartDateInputRef)}
                />
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Effective End Date</label>
                <input
                  name="effectiveEndDate"
                  value={props.formData.effectiveEndDate}
                  onChange={handleInputChange}
                  type="date"
                  // min={new Date().toISOString().split('T')[0]}
                  ref={effectiveEndDateInputRef}
                  onFocus={() => handleFocus(effectiveEndDateInputRef)}
                />
              </div>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Active Ind: <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.elementGroupFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
