import { useState, useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import FetchLocalStorageDataByPath from './utils/helper/FetchLocalstorageDataByPath';

const PrivateRoute = ({ component: Component, element,  ...rest }) => {
  const [isFeatureActive, setisFeatureActive] = useState({});
  const { path } = rest;
  const newPath = path.substr(1);

  useEffect(() => {
    getLocalStorageDataFunc();
  }, [path]);

  const oktaTokenVal =
    // isFeatureActive?.inbasketpage===false ? false :
    sessionStorage.getItem('UserLoginFrom') !== 'System'
      ? JSON.parse(sessionStorage.getItem('okta-token-storage')) !== null && Object.keys(JSON.parse(sessionStorage.getItem('okta-token-storage'))).length !== 0
        ? true
        : false
      : JSON.parse(sessionStorage.getItem('isUserAuthenticated'));

  const getLocalStorageDataFunc = async () => {
    try {
      const fetchedLocalstorageData = FetchLocalStorageDataByPath('system_config', 'features') ?? [];

      const foundFeature = await fetchedLocalstorageData.find((values) => values.path_name === newPath);

      if (foundFeature) {
        setisFeatureActive((prev) => ({
          ...prev,
          [newPath]: foundFeature.value,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (oktaTokenVal) {
    if (isFeatureActive === null) {
      return <Navigate to="/login" />;
    }
    if (isFeatureActive.hasOwnProperty(newPath)) {
      if (isFeatureActive[newPath] === true) {
        return element;
      } else {
        return <Navigate to="/inbasketpage" />;
      }
    }
    return element;
  } else {
    return <Navigate to="/login" />;
  }

  // return (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       oktaTokenVal === true ? (
  //         isFeatureActive === null ? (
  //           <Redirect to={'/login'} />
  //         ) : isFeatureActive.hasOwnProperty(newPath) ? (
  //           isFeatureActive[newPath] === true ? (
  //             <Component {...props} />
  //           ) : (
  //             <Redirect to={'/inbasketpage'} />
  //           )
  //         ) : (
  //           <Component {...props} />
  //         )
  //       ) : (
  //         <Redirect to={'/login'} />
  //       )
  //     }
  //   />
  // );
};

export default PrivateRoute;
