import React, { useEffect, useState, useContext, useRef } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DatePicker from "react-datepicker";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { v4 as uuidv4 } from 'uuid';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './DetailsViewV2.scss';
import EditIcon from '../../../fsa-style/img/svgs/EditPencilBlue.svg';
// import DownloadFile from '../../DownloadFile/DownloadFile';
// import CustomerInfoList from '../../CustomerInfo/CustomerInfoList';
import UpdateObjectFileCodeModal from '../UpdateObjectFileCodeModal/UpdateObjectFileCodeModal';
// import Collapsible from 'react-collapsible';
import { EditMetadata, getFileCodeById, updateDispositionDate, fetchCsvFiles } from '../../../services/searchService';
import { filterBy } from '@progress/kendo-data-query';
import FullscreenIcon from '../../../fsa-style/img/svgs/fullScreenIcon.svg';
// import OptionArrow from '../../../fsa-style/img/svgs/OptionArrow.svg';
import SelectedObjectTypeIcon from '../../../fsa-style/img/svgs/SelectedObjectTypeIcon.svg';
import DeleteIcon from '../../../fsa-style/img/trashIcon.svg';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { deleteObject } from '../../../services/searchService';
import { handleCustomErrorMsg } from '../../../utils/helper/handleReuiredErrorFunc';
//import { GlobalLoader } from "@mui/material";

//import { ListView } from "@progress/kendo-react-listview";
//import { Popup } from '@progress/kendo-react-popup'

// import ImageView from "../ImageView/ImageView";
// import DownloadFile from "../DownloadFile/DownloadFile";
import _, { isArray } from 'lodash';
// import SearchDetail from '../../SearchDetail/SearchDetail';
import ImageView from '../../ImageView/ImageView';
import PDFView from '../../PDFView/PDFView';
// import CustomerInfo from '../../CustomerInfo/CustomerInfo';
import { ListView } from '@progress/kendo-react-listview';
import { useWindowSize } from '../../../services/helper';
import { getDoctype } from '../../../services/suspenseQueueService';
import SuccessProcessGrowl from '../../SuspenseQueue/SuccessProcessGrowl';
import moment from 'moment';
import FormDatepicker from '../../FormModules/FormDatepicker';
import AliceSenseTab from '../../../pages/Search/AliceSenseTab/AliceSenseTab';
import UserContext from '../../UserContext/UserContext';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
import { CircularProgress } from '@mui/material';
import UpdateObjectHoldModal from '../UpdateObjectHoldModal/UpdateObjectHoldModal';
import ErrorGrowl from '../../SuspenseQueue/ErrorGrowl';
import { EventEmitter } from '../../../services/events';
import { groupData, sectionData } from '../../../utils/helper/helperData';
import AsyncSelect from 'react-select/async';
import DownloadIcon from '../../../fsa-style/img/svgs/downloadIcon.svg';
import { getXmlFile, getXMLValue } from '../../../services/inBasketService';
import PDFBtnIcon from '../../../fsa-style/img/svgs/PDFbtn.svg';
import XMLBtnIcon from '../../../fsa-style/img/svgs/XMLbtn.svg';
import ArrowIcon from '../../../fsa-style/img/svgs/ArrowPDFXML.svg';

export default function DetailsView({
  isMetadataLoading,
  cellClicked,
  objectID,
  popupOpen,
  closePopup,
  doc,
  objectMetadata,
  setObjectMetadata,
  onDocumentLoadSuccess,
  numPages,
  loading,
  docName,
  docType,
  scimsCustomers,
  metadataDisplayList,
  setMetadataDisplayList,
  setRecords,
  records,
  userId,
  agencyCode,
  docDetailResponse,
  documentTitle,
  sections,
  detailsObjList,
  selectObjectTypeToView,
  objectTypeToView,
  selectedRecord,
  errorMessage,
  holdResponse,
  refreshData,
  fetchDocDetails,
  deleteRecordEntry,
  dataUpdated,
  setDataUpdated,
  fullTextFlag,
  keywordSearch,
  setDropdownFilterChanged,
  fetchDropDownFilterCell,
  setDropdownFilterCell,
  dropdownFilterCell,
  dropdownFilterChanged,
  buowSearch,
  fetchAdditionalMetadataDetails,
  additionalMetadata,
  setAdditionalMetadata,
}) {
  const userInformation = JSON.parse(sessionStorage.getItem('user_information'))?.rolesName;
  const rolesToCheck = ['Admin', 'Record Manager', 'Application Administrator', 'Full text search user', 'Delete Object Individual'];
  const isDeleteAccessible = userInformation?.some((itm) => rolesToCheck.some((role) => itm.toLowerCase().includes(role.toLowerCase())));

  const [zoom, setZoom] = useState(1);
  const [selected, setSelected] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [newPageNumber, setNewPageNumber] = useState(1);
  const [sectionOpen, setSectionOpen] = useState(false);
  // const [producersMinimized, setProducersMinimized] = useState(false);
  // const [entityMinimized, setEntityMinimized] = useState(false);
  // const [guardianMinimized, setGuardianMinimized] = useState(false);
  // const [memberInfoMinimized, setMemberInfoMinimized] = useState(false);
  // const [participantMinimized, setParticipantMinimized] = useState(false);
  const [loadingFileCodes, setLoadingFileCodes] = useState(true);
  const [objFileCodePopupOpen, setObjFileCodePopupOpen] = useState(false);
  const [width, height] = useWindowSize();
  const [EditRefresh, setEditRefresh] = useState(false);
  const [startDate, setStartDate] = useState(null);

  const [editedFields, setEditedFields] = useState();
  const [editDocumentTitle, setEditDocumentTitle] = useState();
  const [doctype, setDoctype] = useState([]);
  const [doctypeFilteredData, setDoctypeFilteredData] = useState(doctype.slice());
  const [sectionSelection, setSectionSelection] = useState(null);
  const [groupSelection, setGroupSelection] = useState(null);
  const [documentTitleSelection, setDocumentTitleSelection] = useState(null);
  const [expand, setExpand] = useState('DEFAULT');
  const [modalTitle, setModalTitle] = useState(selectedRecord);
  const [isHoldModalVisible, setIsHoldModalVisible] = useState(false);
  const [isMetadataModified, setIsMetadataModified] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isRequiredError, setisRequiredError] = useState(false);
  const [divWidth, setdivWidth] = useState();
  const [csvFiles, setCsvFiles] = useState({ fullCsv: null, verificationCsv: null });
  const [redactionStatus, setRedactionStatus] = useState({});

  const observedDiv = useRef(null);
  const resizingDelayTimer = useRef(null);

  const expandHandler = () => {
    if (expand === 'DEFAULT') {
      setExpand('FULLSCREEN');
    } else {
      setExpand('DEFAULT');
    }
  };
  const { userPermissions } = useContext(UserContext);

  //api
  // const doctypeFilterChange = (event) => {
  //   setDoctypeFilteredData(doctypeFilterData(event.filter));
  // };

  const [sectionList, setSectionList] = useState(_.cloneDeep(sectionData));
  const [groupList, setGroupList] = useState(_.cloneDeep(groupData));

  const [sectionFilteredData, setSectionFilteredData] = useState(sectionList.slice());
  const [groupFilteredData, setGroupFilteredData] = useState(groupList.slice());
  const sectionFilterChange = (event) => {
    setSectionFilteredData(sectionFilterData(event.filter));
  };

  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden1, setIsSuccessGrowlHidden1] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [holdInfo, setHoldInfo] = useState(null);
  const [fileCodeName, setFileCodeName] = useState(null);
  const [folderValue, setFolderValue] = useState('');
  const [folderEditable, setFolderEditable] = useState(false);
  const [SystemColumnsData, setSystemColumnsData] = useState(JSON.parse(sessionStorage.getItem('SystemColumns')));
  const [sortedDropdownVal, setSortedDropdownVal] = useState({ label: 'Added On', value: 'AddedOn' });
  const [isXmlActive, setIsXmlActive] = useState(false);
  const [pdfToggle, setpdfToggle] = useState('pdf');
  const [iframeUrl, setIframeUrl] = useState('');
  const [xmlUrl, setXmlUrl] = useState('');
  const [combinedFieldList, setCombinedFieldList] = useState([]);
  const dropdownData = JSON.parse(sessionStorage.getItem('systemFolderOptions'));

  let system_config = JSON.parse(window.localStorage.getItem('system_config'))?.buowDescription;
  const defaultBUOW = Object.keys(system_config).find((key) => system_config[key]?.is_default === true);
  // Filter systemFolders to keep only those that match the selectedBuow
  const effectiveBuow = buowSearch || defaultBUOW; // Use defaultBUOW if selectedBuow is empty

  const sectionFilterData = (filter) => {
    const data = sectionList.slice();
    return filterBy(data, filter);
  };

  const doctypeFilterData = (filter) => {
    const data = doctype.slice();
    return filterBy(data, filter);
  };

  const onSelectIcon = (type) => {
    setpdfToggle(type);
  };
  const fetchXmlFiles = async (documentPayload) => {
    await getXmlFile(documentPayload)
      .then((xmlData) => {
        if (xmlData) {
          let xmlurl = xmlData?.data?.xmlString;
          convertXmlString(xmlurl);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const convertXmlString = (xmlString) => {
    const decodedString = decodeHexString(xmlString);
    // Create a Blob from the XML string
    const blob = new Blob([decodedString]);
    const url = URL.createObjectURL(blob);
    const downloadXmlUrl = URL.createObjectURL(new Blob([decodedString], { type: 'text/xml' }));
    setXmlUrl(downloadXmlUrl);
    setIframeUrl(url);
    return () => URL.revokeObjectURL(url);
  };
  const decodeHexString = (hex) => {
    return hex
      .replace(/\\x/g, '')
      .match(/.{1,2}/g)
      .map((byte) => String.fromCharCode(parseInt(byte, 16)))
      .join('');
  };

  // const defaultItemfordoctype = {
  //   display_name: documentTitle,
  // };
  // const defaultItemforSections = {
  //   display_name: sections,
  // };
  const [loaderUpdateMetaData, setLoaderUpdateMetaData] = useState(false);
  const DocTitleDropListChange = (e) => {
    setDocumentTitleSelection(e.target.value);
    let keyTitle = 'title';
    let keyObjectType = 'object_type_name';
    let value = e.target.value.display_name;
    setEditDocumentTitle((values) => ({
      ...values,
      [keyTitle]: value,
      [keyObjectType]: value,
    }));
  };
  const sectionDropListChange = (e) => {
    setSectionSelection(e.target.value);
    let key = 'sections';
    let value = e.target.value.display_name;
    setEditedFields((values) => ({ ...values, [key]: value }));
  };
  const sysFolderDropdownChange = (e, keyName) => {
    if (keyName === 'ObjectType') {
      let keyTitle = 'title';
      let keyObjectType = 'object_type_name';
      let value = e.target.value.display_name;
      setEditDocumentTitle((values) => ({
        ...values,
        [keyTitle]: value,
        [keyObjectType]: value,
      }));
      setEditedFields((values) => ({ ...values, [keyName]: value }));
    } else {
      let key = keyName;
      let value = e.target.value.display_name;
      setEditedFields((values) => ({ ...values, [key]: value }));
    }
    setisRequiredError(false);
  };
  const sysFolderInputChange = (e, keyName) => {
    let key = keyName;
    let value = e.target.value;
    setEditedFields((values) => ({ ...values, [key]: value }));
    setisRequiredError(false);
  };

  const holdArrFilter = async () => {
    let filteredHold =
      holdResponse?.length !== 0 &&
      objectMetadata?.holds &&
      holdResponse?.find((holdItm) => holdItm?.holdNumber === objectMetadata?.holds[objectMetadata?.holds.length - 1]?.hold_number);
    setHoldInfo(filteredHold);
    let fileCodeid = objectMetadata?.record_management && objectMetadata?.record_management?.file_code;
    if (fileCodeid) {
      const response = await getFileCodeById(fileCodeid, {});
      if (response) setFileCodeName(response.recordtitle);
    } else setFileCodeName('');
  };
  useEffect(() => {
    holdArrFilter();
    setStartDate([]);
    setPageNumber(1);
    setNewPageNumber(1);
    if (objectMetadata && objectMetadata.record_management) {
      if (objectMetadata?.record_management?.projected_cut_off_date) setStartDate(moment(objectMetadata.record_management.projected_cut_off_date).format('YYYY-MM-DD'));
    }
  }, [objectMetadata, objFileCodePopupOpen]);

  useEffect(() => {
    const getdoc = async () => {
      try {
        const response = await getDoctype();
        if (Array.isArray(response.data)) {
          const mappedData = response.data.map((item, index) => ({
            id: index + 1,
            display_name: item.objectTypeName,
          }));
          setDoctype(mappedData);
          setDoctypeFilteredData(mappedData);
        } else {
          console.error('Invalid data format from API:', response.data);
        }
      } catch (error) {
        console.error('Error in getDoctype:', error.message);
      }
    };

    getdoc();
    setModalTitle(selectedRecord);
    setDocumentTitleSelection(_.find(doctype, { display_name: docDetailResponse?.metadata.object_type?.title }));
    setEditDocumentTitle((values) => ({
      ...values,
      object_type_name: docDetailResponse?.metadata.object_type?.object_type_name,
      title: docDetailResponse?.metadata.object_type?.title,
    }));
    setSectionSelection(_.find(sectionList, { display_name: docDetailResponse?.metadata.object_specific?.Section }));
    setGroupSelection(_.find(groupList, { display_name: docDetailResponse?.metadata.object_specific?.Group }));
    setFolderValue(docDetailResponse?.metadata.object_specific?.Folder);
    let SystemColumnsFolderData = SystemColumnsData;
    SystemColumnsFolderData = SystemColumnsFolderData.filter((itm) => itm.buowName == effectiveBuow); // Keep only those with matching buowName
    SystemColumnsFolderData.map((col, idx) => {
      setEditedFields((values) => ({
        ...values,
        [col?.name]: docDetailResponse?.metadata.object_specific[col?.name] ? docDetailResponse?.metadata.object_specific[col?.name] : '',
      }));
    });
  }, [docDetailResponse, modalTitle]);

  useEffect(() => {
    if (!observedDiv.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        if (observedDiv.current && observedDiv.current.offsetWidth != null && observedDiv.current.offsetWidth !== divWidth) {
          setdivWidth(observedDiv.current.offsetWidth / 16);
        }
      }, 100);
    });

    resizeObserver.observe(observedDiv.current);
    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [observedDiv.current]);

  // Fetch csv files for download
  useEffect(() => {
    fetchCsvFilesToDownload();
    if (isXmlActive === false) {
      setpdfToggle('pdf');
    }
  }, [objectMetadata]);

  const fetchCsvFilesToDownload = async () => {
    setIsXmlActive(false);
    if (objectMetadata?.object_specific?.document_ocr_id !== undefined) {
      let csvDetails = await fetchCsvFiles(objectMetadata?.object_specific?.document_ocr_id);
      if (csvDetails) {
        setCsvFiles(csvDetails?.data);
      }
      // Changes for showing xml file
      setpdfToggle('pdf');
      let documentsPayload = {
        documentId: objectMetadata?.object_specific?.document_ocr_id,
      };
      await getXMLValue(documentsPayload).then((documentResponse) => {
        setIsXmlActive(documentResponse?.data?.isXml);
        if (documentResponse?.data?.isXml === true) fetchXmlFiles(documentsPayload);
      });
    }
  };

  // Disposition Information Functionality Start
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const UpdateDisposition = async () => {
    // console.log(startDate, 'updatedispositiondate');
    setLoaderUpdateMetaData(true);
    let payload = [];
    payload.push(userId);
    try {
      const headers = {
        agencycode: agencyCode,
        applicationid: 0,
      };
      let newDate = startDate;
      if (startDate !== '') newDate = startDate + 'T00:00:00';
      else newDate = null;
      const response = await updateDispositionDate(newDate, payload, headers);

      if (response) {
        // console.log('Response:', response);
        setLoaderUpdateMetaData(false);
        selectObjectTypeToView(objectTypeToView);
        setMessage('Disposition data populated successfully');
        setIsSuccessGrowlHidden1(false);
        setTimeout(() => {
          setIsSuccessGrowlHidden1(true);
        }, 3000);
      }
    } catch (error) {
      // Handle error
      setLoaderUpdateMetaData(false);
      setMessage(error.response?.data?.message ? error.response.data.message : 'Something went wrong');
      console.error('Error in onSaveButtonClick:', error);
      setIsErrorGrowlHidden(false);
      setTimeout(() => {
        setIsErrorGrowlHidden(true);
      }, 3000);
    }
  };
  // Disposition Information Functionality End

  // To add isEditSelected Start
  useEffect(() => {
    setTimeout(() => {
      metadataDisplayList &&
        Object.keys(metadataDisplayList).forEach((key) => {
          return (metadataDisplayList[key].isEditSelected = false);
        });
      objectMetadata &&
        Object.keys(objectMetadata).forEach((key) => {
          if (key === 'object_type') {
            return (objectMetadata[key].isEditSelected = false);
          }
        });
    }, 2000);
    // }, [metadataDisplayList, expand]);
  }, [expand]); // metadataDisplayList removed for BAS-3932 check Above line
  // To add isEditSelected End

  // const [fcHistory, setFcHistoryr] = useState(objectMetadata.record_management.file_code_change_history);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const toggleUpdateObjFileCodePopup = (popupState) => {
    setLoadingFileCodes(true);
    setObjFileCodePopupOpen(popupState);
    if (popupState === false) selectObjectTypeToView(objectTypeToView);
  };
  const closeHoldModal = () => {
    setIsHoldModalVisible(false);
    selectObjectTypeToView(objectTypeToView);
  };

  const openHoldModal = () => {
    setIsHoldModalVisible(true);
  };

  const handleZoom = (zoomDirection) => {
    zoomDirection === 'in' ? setZoom((previousZoom) => previousZoom + 0.1) : setZoom((previousZoom) => previousZoom - 0.1);
  };

  const changePage = (direction) => {
    direction === 'left' ? setPageNumber((prevPageNumber) => prevPageNumber - 1) : setPageNumber((prevPageNumber) => prevPageNumber + 1);
    direction === 'left' ? setNewPageNumber((prevPageNumber) => prevPageNumber - 1) : setNewPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const onChangePage = (pageNo) => {
    let page = pageNo === '' ? pageNo : parseInt(pageNo);
    setNewPageNumber(page);
  };

  const onSaveEnteredPage = (pageNo) => {
    if (pageNo === '' || numPages < pageNo) setPageNumber((prevPageNumber) => prevPageNumber);
    else setPageNumber(parseInt(pageNo));
  };

  const onResetPage = () => {
    setNewPageNumber(pageNumber);
  };

  const closePopupActions = () => {
    closePopup();
    setExpand('DEFAULT');
    setSelected(0);
    setZoom(1.0);
    setSortedDropdownVal({ label: 'Added On', value: 'Added On' });
    if (isMetadataModified === true) {
      refreshData();
      setIsMetadataModified(false);
    }
    setSystemColumnsData(JSON.parse(sessionStorage.getItem('SystemColumns')));

    setDropdownFilterCell(null);
    setisRequiredError(false);
  };

  const closeSection = () => {
    if (!sectionOpen) {
      setSectionOpen(true);
    } else {
      setSectionOpen(false);
    }
  };

  // Edit Text Code Section Start
  const EditDisplayList = (ele, idx, checkEdit) => {
    let updateMetaClone = _.cloneDeep(metadataDisplayList);
    // if (checkEdit === 'metadataDisplayList') {
    //   Object.keys(objectMetadata).forEach((key, index) => {
    //     setEditRefresh(!EditRefresh);
    //     if (key === 'object_type') {
    //       return (objectMetadata[key].isEditSelected = false); // if metadataDisplayList is selected then objectMetadata is input false
    //     }
    //   });
    //   Object.keys(ele).forEach((key, index) => {
    //     setEditRefresh(!EditRefresh);
    //     if (idx === index) {
    //       return (ele[key].isEditSelected = !ele[key].isEditSelected);
    //     } else {
    //       return (ele[key].isEditSelected = false);
    //     }
    //   });
    // }
    // if (checkEdit === 'objectMetadata') {
    //   Object.keys(ele).forEach((key, index) => {
    //     setEditRefresh(!EditRefresh);
    //     return (ele[key].isEditSelected = false); // if objectMetadata is selected then metadataDisplayList is input false
    //   });
    //   Object.keys(objectMetadata).forEach((key, index) => {
    //     setEditRefresh(!EditRefresh);
    //     if (key === 'object_type') {
    //       return (objectMetadata[key].isEditSelected = !objectMetadata[key].isEditSelected);
    //     }
    //   });
    // }
    updateMetaClone[idx].isEditSelected = !updateMetaClone[idx].isEditSelected;
    console.log(updateMetaClone);
    setMetadataDisplayList(updateMetaClone);
  };
  //for sending only edited changes in payload
  const onChangeInput = (e, idx, checkEdit, currentField, metadataDisplayList) => {
    console.log(e, idx, checkEdit, currentField, metadataDisplayList);
    let metaClonedList = _.cloneDeep(metadataDisplayList);
    if (checkEdit === 'metadataDisplayList') {
      let value = e.target.value;
      if (currentField === 'Name Last' || currentField === 'Name First' || currentField === 'Employee Number') {
        value = e.target.value.trim() === '' ? '' : e.target.value;
        setisRequiredError(true);
      }
      Object.keys(metadataDisplayList).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        // if (idx === index && currentField) {
        //   setEditedFields((prevEditedFields) => ({
        //     ...prevEditedFields,
        //     [key]: (metadataDisplayList[key].value = value),
        //   }));
        // }
        if (currentField === key) {
          setEditedFields((prevEditedFields) => ({
            ...prevEditedFields,
            [key]: (metadataDisplayList[key].value = value),
          }));
          metaClonedList[key].value = value;
          setMetadataDisplayList(metaClonedList);
        }
      });
    }
    if (checkEdit === 'objectMetadata') {
      let value = e.target.value;
      Object.keys(objectMetadata).forEach((key, index) => {
        setEditRefresh(!EditRefresh);
        if (key === 'object_type') {
          return (objectMetadata[key].title = value);
        }
      });
    }
  };

  const onSaveDisplayList = (e, idx, checkEdit, metadataDisplayList, contentkey) => {
    let clonedMetaList = _.cloneDeep(metadataDisplayList);
    if (checkEdit === 'metadataDisplayList') {
      if (e.key === 'Enter') {
        let value = e.target.value;
        Object.keys(metadataDisplayList).forEach((key, index) => {
          setEditRefresh(!EditRefresh);
          if (idx === index) {
            return (metadataDisplayList[key].isEditSelected = false), (metadataDisplayList[key].value = value);
          } else if (contentkey === key) {
            clonedMetaList[key].isEditSelected = false;
            clonedMetaList[key].value = value;
            setMetadataDisplayList(clonedMetaList);
          }
        });
      }
    }
    if (metadataDisplayList['first_name']?.value === '' || metadataDisplayList['last_name']?.value === '' || metadataDisplayList['employee_number']?.value === '') {
      setisRequiredError(true);
    }

    if (checkEdit === 'objectMetadata') {
      if (e.key === 'Enter') {
        let value = e.target.value;
        Object.keys(objectMetadata).forEach((key, index) => {
          setEditRefresh(!EditRefresh);
          if (key === 'object_type') {
            return (objectMetadata[key].isEditSelected = false), (objectMetadata[key].title = value);
          }
        });
      }
    }
  };
  // Edit Text Code Section End

  Object.flatten = function (data) {
    var result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
        if (l === 0) result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }
    recurse(data, '');
    return result;
  };

  //TODO Move this out to component
  const LogItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div className="p-2 border-bottom align-middle vote-detail-row center">
        <div className="col-12 vote-detail-col">
          <h2 className="text-uppercase vote-detail">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span
                style={{
                  display: 'inline-block',
                  // width: '36%',
                  textAlign: 'left',
                }}
              >
                {item.change_log.split('_').join(' ')}
              </span>
              <span
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  // width: '30%',
                  textAlign: 'right',
                }}
              >
                {moment.utc(item.modification_date.split('T').join(' ')).local().format('YYYY-MM-DD hh:mm:ss A')}
              </span>
            </div>
          </h2>
        </div>
      </div>
    );
  };
  const FcLogItemRender = (props, data) => {
    let item = props.dataItem;
    return (
      <>
        {item?.old_file_code && (
          <div className="p-2 border-bottom align-middle vote-detail-row center">
            <div className="col-12 vote-detail-col">
              <h2 className="text-uppercase vote-detail">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="d-inline-block text-left w-50">File Code Update: {item?.old_file_code}</span>

                  <span className="d-inline-block align-top text-right w-30">{moment.utc(item.application_date.split('T').join(' ')).local().format('YYYY-MM-DD hh:mm:ss A')}</span>
                  {/* <span
                style={{
                  verticalAlign: 'top',
                  display: 'inline-block',
                  width: '33%',
                  textAlign: 'right',
                }}
              >
                {item.user_name}
              </span> */}
                </div>
              </h2>
            </div>
          </div>
        )}
      </>
    );
  };

  const isCustomerInfo = (key) => {
    return (
      key.startsWith('producer[') ||
      key.startsWith('producer.') ||
      key.startsWith('entity.') ||
      key.startsWith('entity[') ||
      key.startsWith('participant.') ||
      key.startsWith('participant[') ||
      key.startsWith('member_info.') ||
      key.startsWith('member_info[') ||
      key.startsWith('guardian.') ||
      key.startsWith('guardian[') ||
      key.startsWith('title') ||
      key.startsWith('object_type_name') ||
      key.startsWith('edition_date')
    );
  };

  //start for edit metadata
  const onSaveButtonClick = async () => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));

    systemFolders = systemFolders.filter((itm) => itm.buowName == effectiveBuow); // Keep only those with matching buowName

    if (isRequiredError === true) return;
    setLoaderUpdateMetaData(false);

    Object.keys(metadataDisplayList).forEach((key) => {
      return (metadataDisplayList[key].isEditSelected = false);
    });

    try {
      const headers = {
        agencycode: agencyCode,
        applicationid: 0,
      };
      setObjectMetadata((prevEditedFields) => ({
        // ...prevEditedFields,
        ...(prevEditedFields['object_specific'] = {
          ...prevEditedFields.object_specific,
          ...editedFields,
        }),
        ...(prevEditedFields['object_type'] = {
          ...prevEditedFields.object_type,
          ...editDocumentTitle,
        }),
      }));
      if (objectMetadata?.object_specific?.first_name === '' || objectMetadata?.object_specific?.last_name === '' || objectMetadata?.object_specific?.employee_number === '') {
        setisRequiredError(true);

        return;
      }
      setLoaderUpdateMetaData(true);
      objectMetadata['object_specific']['ObjectType'] = objectMetadata['object_type']['title'];

      delete objectMetadata[systemFolders[0]?.name];
      // delete objectMetadata.Folder;
      delete objectMetadata?.ObjectType;
      delete objectMetadata?.Section;
      delete objectMetadata?.contextID;
      delete objectMetadata?.document_ocr_id;
      delete objectMetadata?.emp_status;
      delete objectMetadata?.fsa_access_level;
      delete objectMetadata?.isEditSelected;
      delete objectMetadata?.object_ocr;
      delete objectMetadata?.object_type_name;
      delete objectMetadata?.smc_department;
      // delete objectMetadata.isEditSelected;
      // delete objectMetadata.isEditSelected;

      delete objectMetadata?.contract_number;
      delete objectMetadata?.employee_number;
      delete objectMetadata?.first_name;
      delete objectMetadata?.last_name;
      delete objectMetadata?.middle_name;
      delete objectMetadata?.sections;
      delete objectMetadata?.termination_date;
      delete objectMetadata?.term_date;
      delete objectMetadata?.title;
      if (objectMetadata?.object_specific?.term_date) {
        objectMetadata.object_specific.term_date = moment(objectMetadata?.object_specific?.term_date).format('YYYY-MM-DD');
      }
      // delete objectMetadata.object_specific.term_date;
      // delete objectMetadata.object_specific.emp_status;
      const response = await EditMetadata(userId, objectMetadata, headers);

      if (response) {
        setIsSuccessGrowlHidden(false);
        fetchDocDetails(response?._id, userId);
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);

        setObjectMetadata((prevState) => ({
          ...prevState,
          ...response,
        }));
        setIsMetadataModified(true);
        // EventEmitter.emit('refreshSearchDetailsData');
        // EventEmitter.off('refreshSearchDetailsData');

        setDataUpdated(false);
      } else {
        setTimeout(() => {
          setLoaderUpdateMetaData(false);
        }, [4000]);

        console.log('error in EditMetadata()');
      }
    } catch (error) {
      // Handle error
      setLoaderUpdateMetaData(false);

      console.error('Error in onSaveButtonClick:', error);
    }
  };
  useEffect(() => {
    //  whenever dataUpdated changes
    if (dataUpdated === true) {
      setTimeout(() => {
        setLoaderUpdateMetaData(false);
        setSortedDropdownVal({ label: 'Added On', value: 'Added On' });
        setDropdownFilterCell((prev) => ({ ...prev, sortKey: 'Added On' }));
      }, [1000]);
    }
  }, [dataUpdated]);
  //End for edit Metadata

  const sysColValidator = () => {
    let SystemColumnsFolderData = SystemColumnsData;
    SystemColumnsFolderData = SystemColumnsFolderData.filter((itm) => itm.buowName == effectiveBuow); // Keep only those with matching buowName
    SystemColumnsFolderData.map((col, idx) => {
      editedFields !== undefined &&
        Object.keys(editedFields).map((ele) => {
          if (col.name === ele) {
            if (editedFields[ele].trim() === '') setisRequiredError(true);
            // else setisRequiredError(false);
          }
        });
    });
  };

  useEffect(() => {
    if (editedFields && Object.keys(editedFields).some((key) => editedFields[key].trim() !== '')) {
      sysColValidator();
    }
  }, [editedFields]);

  const reorderObjectKeys = (data, order) => {
    // Create a map of keys to their sequence numbers from the order object
    const sequenceMap = Object.keys(order).reduce((map, key) => {
      map[key] = order[key].sequence;
      return map;
    }, {});

    // Sort the keys of 'data' based on the sequence values in 'sequenceMap'
    const sortedKeys = Object.keys(data).sort((a, b) => {
      // Handle case where a key may not be present in the sequenceMap
      const seqA = sequenceMap[a];
      const seqB = sequenceMap[b];
      return seqA - seqB;
    });

    // Rebuild the object with sorted keys
    const sortedData = {};
    sortedKeys.forEach((key) => {
      sortedData[key] = data[key];
    });

    return sortedData;
  };
  const metadataView = (metadataDisplayList) => {
    // metadataDisplayList = Object.fromEntries(
    //   Object.entries(metadataDisplayList).sort((x, y) => {
    //     if (x[1].display_name < y[1].display_name) return -1;
    //     if (x[1].display_name > y[1].display_name) return 1;
    //     return 0;
    //   })
    // );
    metadataDisplayList = reorderObjectKeys(metadataDisplayList, system_config[defaultBUOW]?.indexSheet);
    if (metadataDisplayList)
      return Object.keys(metadataDisplayList).map((key, idx) => {
        if (key === 'sections') return null;

        if (key === 'object_ocr') return null;

        if (key === 'full_text_search') return null;

        if (key === 'document_ocr_id') return null;

        if (!isCustomerInfo(key)) {
          let renderValue =
            metadataDisplayList[key].value && typeof metadataDisplayList[key].value === 'object' ? JSON.stringify(metadataDisplayList[key].value) : metadataDisplayList[key].value;
          return (
            //Here...
            <>
              <div className="returnedData" key={idx + '_metadata'}>
                <div className="keyfont">{metadataDisplayList[key]?.display_name}</div>
                <div
                  className="valuefont"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {metadataDisplayList[key].isEditSelected === true ? (
                    <>
                      {metadataDisplayList[key].display_name === 'Term Date' && (
                        <>
                          <FormDatepicker
                            value={moment(metadataDisplayList[key].value).isValid() ? metadataDisplayList[key].value : null}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                          />
                        </>
                      )}
                      {metadataDisplayList[key].display_name === 'Employment Status' && (
                        <>
                          <select
                            value={metadataDisplayList[key].value === true || metadataDisplayList[key].value === 'true' || metadataDisplayList[key].value === 'True' ? true : false}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                          >
                            <option value={'true'}>Active</option>

                            <option value={'false'}>In-Active</option>
                          </select>
                        </>
                      )}

                      {metadataDisplayList[key].display_name !== 'Term Date' && metadataDisplayList[key].display_name !== 'Employment Status' && (
                        <>
                          <input
                            value={metadataDisplayList[key].value !== '.' && metadataDisplayList[key].value}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[key].display_name, metadataDisplayList)}
                            onKeyPress={(e) => onSaveDisplayList(e, idx, 'metadataDisplayList', metadataDisplayList)}
                            style={{ width: 'calc(100% - 15%)' }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {metadataDisplayList[key].display_name === 'Term Date' && metadataDisplayList[key].value && (
                        <>{moment(metadataDisplayList[key].value).isValid() ? moment(metadataDisplayList[key].value).format('MM/DD/yyyy') : null}</>
                      )}

                      {metadataDisplayList[key].display_name === 'Employment Status' &&
                        (metadataDisplayList[key].value === 'TERMINATED' ||
                        metadataDisplayList[key].value === 'true' ||
                        metadataDisplayList[key].value === true ||
                        metadataDisplayList[key].value === false ||
                        metadataDisplayList[key].value === 'false' ? (
                          <>
                            {metadataDisplayList[key].value === 'TERMINATED' && 'In-Active'}
                            {typeof metadataDisplayList[key].value !== 'boolean' && metadataDisplayList[key].value.toLowerCase() === 'true' && 'Active'}
                            {typeof metadataDisplayList[key].value !== 'boolean' && metadataDisplayList[key].value.toLowerCase() === 'false' && 'In-Active'}
                            {metadataDisplayList[key].value === true && 'Active'}
                            {metadataDisplayList[key].value === false && 'In-Active'}
                          </>
                        ) : (
                          <>
                            {renderValue === null ||
                            renderValue === 'null' ||
                            renderValue === '' ||
                            renderValue === undefined ||
                            (typeof (metadataDisplayList[key].value === 'object') && Object.keys(metadataDisplayList[key].value)?.length === 0)
                              ? 'NA'
                              : renderValue}
                          </>
                        ))}
                      {metadataDisplayList[key].display_name !== 'Term Date' &&
                      metadataDisplayList[key].display_name !== 'Employment Status' &&
                      (renderValue === null ||
                        renderValue === 'null' ||
                        renderValue === '' ||
                        renderValue === undefined ||
                        (typeof (metadataDisplayList[key].value === 'object') && Object.keys(metadataDisplayList[key].value)?.length === 0))
                        ? 'NA'
                        : renderValue}
                    </>
                  )}
                  {metadataDisplayList[key].display_name !== 'BUOW ID' && (
                    <img
                      style={{
                        marginLeft: '2px',
                        padding: '2px 0px 4px 10px',
                        height: '2.5rem',
                        cursor: 'pointer',
                      }}
                      alt="pencilIcon"
                      src={EditIcon}
                      onClick={() => EditDisplayList(metadataDisplayList, idx, 'metadataDisplayList')}
                    />
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {metadataDisplayList[key].display_name === 'Name First' &&
                  metadataDisplayList['first_name']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
                {metadataDisplayList[key].display_name === 'Name Last' &&
                  metadataDisplayList['last_name']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
                {metadataDisplayList[key].display_name === 'Employee Number' &&
                  metadataDisplayList['employee_number']?.value === '' &&
                  isRequiredError === true &&
                  handleCustomErrorMsg('This field is required.')}
              </div>
            </>
          );
        }
      });
  };
  const additionalMetadataView = (additionalData) => {
    if (additionalData) {
      return additionalData.map((dataItem, idx) => {
        return (
          //Here...
          <>
            {Object.entries(dataItem).map(([key, value]) => (
              <div className="returnedData" key={idx + '_additionalData'}>
                <div className="keyfont" style={{ textTransform: 'capitalize' }}>
                  {key}
                </div>
                <div
                  className="valuefont"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {isArray(value) === true ? (
                    <span className="mx-2">{value?.join(', ')}</span>
                  ) : (
                    <span className="mx-2">{value === 'null' || value === null || value === undefined || value === '' ? 'NA' : value}</span>
                  )}
                </div>
              </div>
            ))}
          </>
        );
      });
    }
  };
  const returnTitle = () => {
    let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
    let system_config = JSON.parse(window.localStorage.getItem('system_config'))?.buowDescription;
    const defaultBUOW = Object.keys(system_config).find((key) => system_config[key]?.is_default === true);

    let title = '';
    let arrow = '';

    if (systemFolders.length > 0) {
      // Filter systemFolders to keep only those that match the selectedBuow
      const effectiveBuow = buowSearch || defaultBUOW;
      systemFolders = systemFolders.filter((itm) => itm.buowName == effectiveBuow);

      systemFolders.forEach((itm) => {
        // Check if the key exists and has a value
        const objectSpecificValue = JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.key?.['object_specific.' + itm.name];
        const folderValue =
          JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.[itm.name] === 'null' ? 'NA' : JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.[itm.name];

        if (objectSpecificValue !== undefined && objectSpecificValue !== '') {
          // Only add arrow if the title is not empty
          if (title.length > 0) {
            arrow = ' > ';
          } else {
            arrow = '';
          }
          title = title + arrow + objectSpecificValue;
        }

        if (folderValue !== undefined && folderValue !== '') {
          // Only add arrow if the title is not empty
          if (title.length > 0) {
            arrow = ' > ';
          } else {
            arrow = '';
          }
          title = title + arrow + folderValue;
        }
      });

      // Handle the 'section' part of selectedSearchRecord
      const section = JSON.parse(sessionStorage.getItem('selectedSearchRecord'))?.['section'];
      if (section !== undefined) {
        title = title + ' > ' + section;
      }

      // Handle the FullTextQuery part
      const fullTextQuery = JSON.parse(sessionStorage.getItem('FullTextQuery'))?.['value'];
      if (fullTextQuery !== undefined) {
        title = title + ' > ' + fullTextQuery;
      }
    }

    return (
      <span className="modalHeaderTitleStyle" title={title}>
        {title}
      </span>
    );
  };

  const DeleteFunc = (dataItem) => {
    setDeleteModalShow(true);
    setDeleteData(dataItem);
  };

  const DeleteDataFunc = (dataItem) => {
    setLoadingFileCodes(true);
    const headers = {
      agencycode: agencyCode,
      applicationid: 5,
    };

    const sendData = {
      id: dataItem,
    };
    deleteObject(sendData, headers).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await deleteRecordEntry(sendData.id);
        setIsMetadataModified(true);
        setLoadingFileCodes(true);
        setIsSuccessGrowlHidden1(false);
        setMessage('Record deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden1(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error ? response.error?.data?.message : 'Something went wrong');
        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        setLoadingFileCodes(true);
      }
    });
  };
  useEffect(() => {
    let SystemColumnsFolderData = SystemColumnsData;
    SystemColumnsFolderData = SystemColumnsFolderData.filter((itm) => itm.buowName == effectiveBuow); // Keep only those with matching buowName
    SystemColumnsFolderData.forEach((itm) => {
      if (itm?.lowCard === false) {
        return (itm.isEditSelected = false);
      }
    });
  }, []);
  const toggleSysFolderEdit = (ele, key, value) => {
    let SystemColumnsFolderData = SystemColumnsData;
    SystemColumnsFolderData = SystemColumnsFolderData.filter((itm) => itm.buowName == effectiveBuow); // Keep only those with matching buowName
    SystemColumnsFolderData.forEach((Ele) => {
      if (Ele.lowCard === false && ele.name === Ele.name) {
        setEditRefresh(!EditRefresh);
        return (Ele[key] = Ele[key] === true ? false : value);
      }
    });
  };
  const renderSysOptions = () => {
    let SystemColumnsFolderData = SystemColumnsData;
    SystemColumnsFolderData = SystemColumnsFolderData.filter((itm) => itm.buowName == effectiveBuow); // Keep only those with matching buowName
    return (
      SystemColumnsFolderData.length !== 0 &&
      SystemColumnsFolderData?.sort((a, b) => a.systemFolderLevel - b.systemFolderLevel)?.map((itm, idx) => {
        if (itm?.lowCard === true) {
          return (
            <>
              <div className="returnedData" key={uuidv4()}>
                {/* start dropdown part*/}
                <div className="keyfont" style={{ display: 'flex', width: '40%' }}>
                  {itm?.name}
                </div>
                <div className="fsa-grid__1/2" style={{ width: '60%', paddingLeft: '0px' }}>
                  <div className="fsa-field dropdownCustomStyle">
                    <DropDownList
                      data={
                        itm?.lowCardValue !== null
                          ? itm?.lowCardValue.map((itm, idx) => {
                              return { id: idx, display_name: itm };
                            })
                          : doctypeFilteredData
                      }
                      textField="display_name"
                      dataItemKey="id"
                      value={{
                        display_name:
                          editedFields[itm?.name] === null || editedFields[itm?.name] === undefined || editedFields[itm?.name] === 'null' || editedFields[itm?.name] === ''
                            ? 'NA'
                            : editedFields[itm?.name],
                      }}
                      onChange={(e) => {
                        sysFolderDropdownChange(e, itm?.name);
                      }}
                      style={{ width: 'calc(100%)', whiteSpace: 'balance', margin: '0.5em', lineHeight: '1em' }}
                      popupSettings={{ width: '12em' }}
                      itemRender={itemRender}
                    />
                  </div>
                </div>
                {/* end dropdown part*/}
              </div>
              {editedFields[itm?.name].trim() === '' && isRequiredError === true && (
                <div className="d-flex justify-content-end"> {handleCustomErrorMsg('This field is required.')}</div>
              )}
            </>
          );
        } else {
          return (
            <>
              <div className="returnedData">
                <div className="keyfont">{itm?.name}</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {itm?.isEditSelected === true ? (
                    <input
                      onChange={(e) => {
                        e.preventDefault();
                        sysFolderInputChange(e, itm?.name);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && itm?.isEditSelected === true) {
                          //  =
                          toggleSysFolderEdit(itm, 'isEditSelected', false);
                        }
                      }}
                      style={{ width: 'calc(100% - 15%)' }}
                      value={editedFields[itm?.name]}
                    />
                  ) : editedFields[itm?.name] === docDetailResponse?.metadata.object_specific[itm?.name] ? (
                    docDetailResponse?.metadata.object_specific[itm?.name] === 'null' ||
                    docDetailResponse?.metadata.object_specific[itm?.name] === null ||
                    docDetailResponse?.metadata.object_specific[itm?.name] === '' ||
                    docDetailResponse?.metadata.object_specific[itm?.name] === undefined ? (
                      'NA'
                    ) : (
                      docDetailResponse?.metadata.object_specific[itm?.name]
                    )
                  ) : editedFields[itm?.name] === 'null' || editedFields[itm?.name] === null || editedFields[itm?.name] === undefined || editedFields[itm?.name] === '' ? (
                    'NA'
                  ) : (
                    editedFields[itm?.name]
                  )}
                  <img
                    style={{
                      marginLeft: '2px',
                      padding: '2px 0px 4px 10px',
                      height: '2.5rem',
                      cursor: 'pointer',
                    }}
                    src={EditIcon}
                    alt="pencilIcon"
                    onClick={() => toggleSysFolderEdit(itm, 'isEditSelected', true)}
                  />
                </div>
              </div>
              {editedFields[itm?.name].trim() === '' && isRequiredError === true && (
                <div className="d-flex justify-content-end"> {handleCustomErrorMsg('This field is required.')}</div>
              )}
            </>
          );
        }
      })
    );
  };

  const loadSortDropdownList = (inputValue, callback) => {
    let options = dropdownData
      .map((res, i) => {
        if (i === 0) {
          return { label: 'Added On', value: 'Added On' };
        } else if (res.data && res.data.lowCard === true) {
          if (res.title === 'Group') {
            return { label: res.title, value: res.title, isDisabled: true };
          }
          return { label: res.title, value: res.title, isDisabled: false };
        }
        return null;
      })
      .filter((option) => option !== null);

    setTimeout(() => {
      callback(options);
    }, 1000);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
      fontSize: '1.4rem',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
      fontSize: '1.4rem',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
      whiteSpace: 'nowrap',
      width: '80rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    singleValue: (provided, state) => ({
      ...provided,

      maxWidth: 'calc(100% - 85px)',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 30,
      // padding:0,
      // margin: 0,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '45%',
      maxWidth: '50%',
      margin: '0rem 1rem 0rem 1rem',
    }),
  };

  const onChangeDropdownVal = (event) => {
    const { value } = event;
    const updatedValue = value === '' ? null : value;

    setSortedDropdownVal({ label: updatedValue, value: updatedValue });

    setDropdownFilterCell((prev) => ({ ...prev, sortKey: value }));

    setDropdownFilterChanged(true);
  };
  useEffect(() => {
    if (dropdownFilterChanged === true) {
      fetchDropDownFilterCell(dropdownFilterCell);
    }
  }, [dropdownFilterChanged]);

  const itemRender = (li, itemProps) => {
    const itemChildren = <span title={itemProps.dataItem.display_name}>{li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  // Changes for showing csv download options when disclosable status is post-redaction
  const onSelectObject = (selectedObj, data) => {
    data
      .filter((val) => val.data && val.data.lowCard === true && val.title.toLowerCase() !== 'objecttype' && val.data.isVisible === true)
      .map((itm, i) => {
        setRedactionStatus({ key: itm.title, value: selectedObj.object_specific[itm.title] });
      });
  };
  useEffect(() => {
    detailsObjList?.map((values, index) => {
      if (objectTypeToView === values._id) {
        onSelectObject(values, dropdownData);
      }
    });
  }, [detailsObjList, objectTypeToView]);
  const renderOptions = (SystemColumnsFolderData, metadataDisplayList, additionalData) => {
    // Helper function to sort data alphabetically by a given key
    const sortAlphabetically = (data, key) => {
      return data.sort((a, b) => {
        const nameA = (key ? a[key] : a).toLowerCase();
        const nameB = (key ? b[key] : b).toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
    };

    // Process SystemColumnsFolderData
    const processSysOptions = (data) => {
      if (!data || data.length === 0) return [];
      data = data.filter((itm) => itm.buowName == effectiveBuow);
      return data.map((itm) => ({
        name: itm.name,
        type: 'sysOptions',
        content: itm, // Preserve the entire object for rendering
      }));
    };

    // Process metadataDisplayList
    const processMetadata = (data) => {
      if (!data) return [];
      data = reorderObjectKeys(data, system_config[defaultBUOW]?.indexSheet);
      return Object.entries(data)
        .filter(([key]) => !['sections', 'object_ocr', 'full_text_search', 'document_ocr_id'].includes(key))
        .map(([key, value]) => ({
          name: value.display_name,
          type: 'metadata',
          content: { key, ...value },
        }));
    };

    // Process additionalData
    const processAdditionalData = (data) => {
      if (!data || data.length === 0) return [];
      return data.flatMap((dataItem, idx) =>
        Object.entries(dataItem).map(([key, value]) => ({
          name: key,
          type: 'additionalData',
          content: { key, value, index: idx },
        }))
      );
    };

    // Combine and sort all data
    const combinedData = sortAlphabetically(
      [...processSysOptions(SystemColumnsFolderData), ...processMetadata(metadataDisplayList), ...processAdditionalData(additionalData)],
      'name'
    );
    console.log(combinedData);
    setCombinedFieldList(combinedData);

    // Render the combined and sorted data
    // return combinedData.map((item) => {
    //   if (item.type === "sysOptions") {
    //     const itm = item.content;
    //     if (itm?.lowCard === true) {
    //       return (
    //         <div className="returnedData" key={uuidv4()}>
    //           <div className="keyfont" style={{ display: "flex", width: "40%" }}>
    //             {itm?.name}
    //           </div>
    //           <div className="fsa-grid__1/2" style={{ width: "60%", paddingLeft: "0px" }}>
    //             <div className="fsa-field dropdownCustomStyle">
    //               <DropDownList
    //                 data={
    //                   itm?.lowCardValue !== null
    //                     ? itm?.lowCardValue.map((item, idx) => ({
    //                         id: idx,
    //                         display_name: item,
    //                       }))
    //                     : doctypeFilteredData
    //                 }
    //                 textField="display_name"
    //                 dataItemKey="id"
    //                 value={{
    //                   display_name:
    //                     editedFields[itm?.name] === null ||
    //                     editedFields[itm?.name] === undefined ||
    //                     editedFields[itm?.name] === "null" ||
    //                     editedFields[itm?.name] === ""
    //                       ? "NA"
    //                       : editedFields[itm?.name],
    //                 }}
    //                 onChange={(e) => sysFolderDropdownChange(e, itm?.name)}
    //                 style={{
    //                   width: "calc(100%)",
    //                   whiteSpace: "balance",
    //                   margin: "0.5em",
    //                   lineHeight: "1em",
    //                 }}
    //                 popupSettings={{ width: "12em" }}
    //                 itemRender={itemRender}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div className="returnedData" key={uuidv4()}>
    //           <div className="keyfont">{itm?.name}</div>
    //           <div
    //             style={{
    //               display: "flex",
    //               flexDirection: "row",
    //               justifyContent: "flex-end",
    //               alignItems: "center",
    //             }}
    //           >
    //             {itm?.isEditSelected === true ? (
    //               <input
    //                 onChange={(e) => sysFolderInputChange(e, itm?.name)}
    //                 onKeyPress={(e) => e.key === "Enter" && toggleSysFolderEdit(itm, "isEditSelected", false)}
    //                 style={{ width: "calc(100% - 15%)" }}
    //                 value={editedFields[itm?.name]}
    //               />
    //             ) : (
    //               editedFields[itm?.name] || "NA"
    //             )}
    //             <img
    //               style={{
    //                 marginLeft: "2px",
    //                 padding: "2px 0px 4px 10px",
    //                 height: "2.5rem",
    //                 cursor: "pointer",
    //               }}
    //               src={EditIcon}
    //               alt="pencilIcon"
    //               onClick={() => toggleSysFolderEdit(itm, "isEditSelected", true)}
    //             />
    //           </div>
    //         </div>
    //       );
    //     }
    //   } else if (item.type === "metadata") {
    //     const content = item.content;
    //     const renderValue =
    //       content.value && typeof content.value === "object" ? JSON.stringify(content.value) : content.value;

    //     return (
    //       <div className="returnedData" key={content.key}>
    //         <div className="keyfont">{content.display_name}</div>
    //         <div
    //           className="valuefont"
    //           style={{
    //             display: "flex",
    //             flexDirection: "row",
    //             justifyContent: "flex-end",
    //             alignItems: "center",
    //             whiteSpace: "nowrap",
    //           }}
    //         >
    //           {content.isEditSelected ? (
    //             <input
    //               value={renderValue || ""}
    //               onChange={(e) => onChangeInput(e, content.index, "metadataDisplayList", content.display_name, metadataDisplayList)}
    //               style={{ width: "calc(100% - 15%)" }}
    //             />
    //           ) : (
    //             renderValue || "NA"
    //           )}
    //         </div>
    //       </div>
    //     );
    //   } else if (item.type === "additionalData") {
    //     const content = item.content;
    //     return (
    //       <div className="returnedData" key={`${content.index}_additionalData`}>
    //         <div className="keyfont" style={{ textTransform: "capitalize" }}>
    //           {content.key}
    //         </div>
    //         <div
    //           className="valuefont"
    //           style={{
    //             display: "flex",
    //             flexDirection: "row",
    //             justifyContent: "flex-end",
    //             alignItems: "center",
    //             whiteSpace: "nowrap",
    //           }}
    //         >
    //           {Array.isArray(content.value) ? (
    //             <span className="mx-2">{content.value.join(", ")}</span>
    //           ) : (
    //             <span className="mx-2">{content.value === "null" || content.value === null || content.value === undefined || content.value === "" ? "NA" : content.value}</span>
    //           )}
    //         </div>
    //       </div>
    //     );
    //   }
    // });
  };

  const fieldRenderer = (combinedData) => {
    return combinedData.map((item, idx) => {
      if (item.type === 'sysOptions') {
        const itm = item.content;
        if (itm?.lowCard === true) {
          return (
            <div className="returnedData" key={uuidv4()}>
              <div className="keyfont" style={{ display: 'flex', width: '40%' }}>
                {itm?.name}
              </div>
              <div className="fsa-grid__1/2" style={{ width: '60%', paddingLeft: '0px' }}>
                <div className="fsa-field dropdownCustomStyle">
                  <DropDownList
                    data={
                      itm?.lowCardValue !== null
                        ? itm?.lowCardValue.map((item, idx) => ({
                            id: idx,
                            display_name: item,
                          }))
                        : doctypeFilteredData
                    }
                    textField="display_name"
                    dataItemKey="id"
                    value={{
                      display_name:
                        editedFields[itm?.name] === null || editedFields[itm?.name] === undefined || editedFields[itm?.name] === 'null' || editedFields[itm?.name] === ''
                          ? 'NA'
                          : editedFields[itm?.name],
                    }}
                    onChange={(e) => sysFolderDropdownChange(e, itm?.name)}
                    style={{
                      width: 'calc(100%)',
                      whiteSpace: 'balance',
                      margin: '0.5em',
                      lineHeight: '1em',
                    }}
                    popupSettings={{ width: '12em' }}
                    itemRender={itemRender}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="returnedData" key={uuidv4()}>
              <div className="keyfont">{itm?.name}</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {itm?.isEditSelected === true ? (
                  <input
                    onChange={(e) => sysFolderInputChange(e, itm?.name)}
                    onKeyPress={(e) => e.key === 'Enter' && toggleSysFolderEdit(itm, 'isEditSelected', false)}
                    style={{ width: 'calc(100% - 15%)' }}
                    value={editedFields[itm?.name]}
                  />
                ) : (
                  editedFields[itm?.name] || 'NA'
                )}
                <img
                  style={{
                    marginLeft: '2px',
                    padding: '2px 0px 4px 10px',
                    height: '2.5rem',
                    cursor: 'pointer',
                  }}
                  src={EditIcon}
                  alt="pencilIcon"
                  onClick={() => toggleSysFolderEdit(itm, 'isEditSelected', true)}
                />
              </div>
            </div>
          );
        }
      } else if (item.type === 'metadata') {
        const content = item.content;
        const renderValue = content.value && typeof content.value === 'object' ? JSON.stringify(content.value) : content.value;

        return (
          // <div className="returnedData" key={content.key}>
          //   <div className="keyfont">{content.display_name}</div>
          //   <div
          //     className="valuefont"
          //     style={{
          //       display: "flex",
          //       flexDirection: "row",
          //       justifyContent: "flex-end",
          //       alignItems: "center",
          //       whiteSpace: "nowrap",
          //     }}
          //   >
          //     {content.isEditSelected ? (
          //       <input
          //         value={renderValue || ""}
          //         onChange={(e) => onChangeInput(e, content.index, "metadataDisplayList", content.display_name, metadataDisplayList)}
          //         style={{ width: "calc(100% - 15%)" }}
          //       />
          //     ) : (
          //       renderValue || "NA"
          //     )}
          //   </div>
          // </div>

          //Here...
          <>
            {metadataDisplayList[content.key]?.display_name !== 'Additional Metadata' && (
              <div className="returnedData" key={idx + '_metadata'}>
                <div className="keyfont">{metadataDisplayList[content?.key]?.display_name}</div>
                <div
                  className="valuefont"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {metadataDisplayList[content?.key]?.isEditSelected === true ? (
                    <>
                      {metadataDisplayList[content?.key]?.display_name === 'Term Date' && (
                        <>
                          <FormDatepicker
                            value={moment(metadataDisplayList[content?.key]?.value).isValid() ? metadataDisplayList[content?.key]?.value : null}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[content?.key]?.display_name, metadataDisplayList)}
                          />
                        </>
                      )}
                      {metadataDisplayList[content?.key]?.display_name === 'Employment Status' && (
                        <>
                          <select
                            value={
                              metadataDisplayList[content?.key]?.value === true ||
                              metadataDisplayList[content?.key]?.value === 'true' ||
                              metadataDisplayList[content?.key]?.value === 'True'
                                ? true
                                : false
                            }
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[content?.key]?.display_name, metadataDisplayList)}
                          >
                            <option value={'true'}>Active</option>

                            <option value={'false'}>In-Active</option>
                          </select>
                        </>
                      )}

                      {metadataDisplayList[content?.key]?.display_name !== 'Term Date' && metadataDisplayList[content?.key]?.display_name !== 'Employment Status' && (
                        <>
                          <input
                            value={metadataDisplayList[content?.key]?.value !== '.' && metadataDisplayList[content?.key]?.value}
                            onChange={(e) => onChangeInput(e, idx, 'metadataDisplayList', metadataDisplayList[content?.key]?.display_name, metadataDisplayList)}
                            onKeyPress={(e) => onSaveDisplayList(e, idx, 'metadataDisplayList', metadataDisplayList, content?.key)}
                            style={{ width: 'calc(100% - 15%)' }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {metadataDisplayList[content?.key]?.display_name === 'Term Date' && metadataDisplayList[content?.key]?.value && (
                        <>{moment(metadataDisplayList[content?.key]?.value).isValid() ? moment(metadataDisplayList[content?.key]?.value).format('MM/DD/yyyy') : null}</>
                      )}

                      {metadataDisplayList[content?.key]?.display_name === 'Employment Status' &&
                        (metadataDisplayList[content?.key]?.value === 'TERMINATED' ||
                        metadataDisplayList[content?.key]?.value === 'true' ||
                        metadataDisplayList[content?.key]?.value === true ||
                        metadataDisplayList[content?.key]?.value === false ||
                        metadataDisplayList[content?.key]?.value === 'false' ? (
                          <>
                            {metadataDisplayList[content?.key]?.value === 'TERMINATED' && 'In-Active'}
                            {typeof metadataDisplayList[content?.key]?.value !== 'boolean' && metadataDisplayList[content?.key]?.value.toLowerCase() === 'true' && 'Active'}
                            {typeof metadataDisplayList[content?.key]?.value !== 'boolean' && metadataDisplayList[content?.key]?.value.toLowerCase() === 'false' && 'In-Active'}
                            {metadataDisplayList[content?.key]?.value === true && 'Active'}
                            {metadataDisplayList[content?.key]?.value === false && 'In-Active'}
                          </>
                        ) : (
                          <>
                            {renderValue === null ||
                            renderValue === 'null' ||
                            renderValue === '' ||
                            renderValue === undefined ||
                            (typeof (metadataDisplayList[content?.key].value === 'object') && Object.keys(metadataDisplayList[content?.key].value)?.length === 0)
                              ? 'NA'
                              : renderValue}
                          </>
                        ))}
                      {metadataDisplayList[content?.key]?.display_name !== 'Term Date' &&
                      metadataDisplayList[content?.key]?.display_name !== 'Employment Status' &&
                      (renderValue === null ||
                        renderValue === 'null' ||
                        renderValue === '' ||
                        renderValue === undefined ||
                        (typeof (metadataDisplayList[content?.key]?.value === 'object') && Object.keys(metadataDisplayList[content?.key]?.value)?.length === 0))
                        ? 'NA'
                        : renderValue}
                    </>
                  )}
                  {metadataDisplayList[content?.key]?.display_name !== 'BUOW ID' && (
                    <img
                      style={{
                        marginLeft: '2px',
                        padding: '2px 0px 4px 10px',
                        height: '2.5rem',
                        cursor: 'pointer',
                      }}
                      alt="pencilIcon"
                      src={EditIcon}
                      onClick={() => EditDisplayList(metadataDisplayList, content.key, 'metadataDisplayList')}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end">
              {metadataDisplayList[content?.key].display_name === 'Name First' &&
                metadataDisplayList['first_name']?.value === '' &&
                isRequiredError === true &&
                handleCustomErrorMsg('This field is required.')}
              {metadataDisplayList[content?.key].display_name === 'Name Last' &&
                metadataDisplayList['last_name']?.value === '' &&
                isRequiredError === true &&
                handleCustomErrorMsg('This field is required.')}
              {metadataDisplayList[content?.key].display_name === 'Employee Number' &&
                metadataDisplayList['employee_number']?.value === '' &&
                isRequiredError === true &&
                handleCustomErrorMsg('This field is required.')}
            </div>
          </>
        );
      } else if (item.type === 'additionalData') {
        const content = item.content;
        return (
          <div className="returnedData" key={`${content.index}_additionalData`}>
            <div className="keyfont" style={{ textTransform: 'capitalize' }}>
              {content.key}
            </div>
            <div
              className="valuefont"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {Array.isArray(content.value) ? (
                <span className="mx-2">{content.value.join(', ')}</span>
              ) : (
                <span className="mx-2">{content.value === 'null' || content.value === null || content.value === undefined || content.value === '' ? 'NA' : content.value}</span>
              )}
            </div>
          </div>
        );
      }
    });
  };

  useEffect(() => {
    {
      renderOptions(SystemColumnsData, metadataDisplayList);
      console.log(SystemColumnsData, metadataDisplayList);
    }
  }, [SystemColumnsData, metadataDisplayList, additionalMetadata]);

  return (
    popupOpen && (
      <div id="detailsViewModalElement" data-testid="wrapper-test">
        <Window
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            maxHeight: '100%',
            // zIndex: deleteModalShow ? 10001 : '',
          }}
          minimizeButton={() => null}
          maximizeButton={() => null}
          stage={expand}
          title={returnTitle()}
          closeButton={() => (
            <>
              <button aria-label="Expand Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => expandHandler()}>
                {/* <b>X</b> */}
                <img src={FullscreenIcon} alt="FullScreen icon" />
              </button>
              <button aria-label="Close Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => closePopupActions()}>
                {/* <b>X</b> */}
                <img src={crossIcon} alt="cross icon" />
              </button>
            </>
          )}
          restoreButton={() => null}
          modal={true}
          draggable={false}
          left={width * 0.025}
          top={height * 0.05}
          onClose={closePopupActions}
          height={height * 0.9}
          width={width * 0.95}
        >
          {/* {loading ? (
            <div className="center">
              <GlobalLoader />
            </div>
          ) : objectMetadata ? ( */}

          {isMetadataLoading ? (
            <div className="center">
              <CircularProgress />
            </div>
          ) : (
            // objectMetadata ? (
            <>
              <div>
                <div className="detailViewCon">
                  {detailsObjList?.length !== 0 && (
                    <div className="objectTypeContainer" data-testid="objectType-container">
                      <header className="sortByDropdownHeaderContainer" data-testid="sortBy-section">
                        <label className="sortByDropdownTitle"> Sort By</label>

                        <AsyncSelect
                          styles={customStyles}
                          defaultOptions
                          // isDisabled={props.isNew ? false : true}
                          loadOptions={loadSortDropdownList}
                          getOptionDisabled={(option) => option.isDisabled}
                          value={sortedDropdownVal}
                          onChange={onChangeDropdownVal}
                        />
                      </header>
                      <header className="objectTypeHeaderContainer">
                        <p className="objectTypeHeading" style={{ width: divWidth ? `${divWidth}em` : '72%', minWidth: '60%' }}>
                          Document Type
                        </p>
                        <p className="pl-4 objectTypeHeading" style={{ width: 140 }}>
                          Added On
                        </p>
                      </header>
                      <section className="objectTypeBody">
                        {detailsObjList?.map((values, index) => (
                          <>
                            <div className={'tableRowSearchDetails'} key={index}>
                              <div
                                key={values.index}
                                className={[objectTypeToView === values._id && 'clickedData', 'tableRowObjectType', 'col'].join(' ')}
                                onClick={() => {
                                  selectObjectTypeToView(values._id);
                                  fetchAdditionalMetadataDetails(values.object_specific['BUOW ID']);
                                  onSelectObject(values, dropdownData);
                                }}
                              >
                                <p className={[objectTypeToView === values._id ? 'clickedObjectTypeName mb-0 pb-0 ' : 'objectTypeName mb-0 pb-0']}>{values.object_type.title}</p>
                                <div className="objectTypeContentSeperator">
                                  <div className={[objectTypeToView === values._id && 'clickedObjectTypeDiv', 'tableRowObjectTypeDiv'].join(' ')}></div>
                                  <img src={SelectedObjectTypeIcon} className={objectTypeToView === values._id ? 'optionClikedImage' : 'optionsImage'} alt={'options arrow'}></img>
                                </div>
                                <div className="d-flex flex-column pb-2">
                                  {dropdownData
                                    .filter((val) => val.data && val.data.lowCard === true && val.title.toLowerCase() !== 'objecttype' && val.data.isVisible === true)
                                    .map((itm, i) => {
                                      const newTitle = itm.title;
                                      let filteredArray = dropdownData.filter(
                                        (val) => val.data && val.data.lowCard === true && val.title.toLowerCase() !== 'objecttype' && val.data.isVisible === true
                                      );

                                      let hoveredTitles = '';
                                      let viewClickedId = null;
                                      filteredArray.map((j, index) => {
                                        viewClickedId = values._id;
                                        if (index >= 2)
                                          hoveredTitles = hoveredTitles + `${j.title} : ${values.object_specific[j.title] ?? ''} ${filteredArray.length - 1 === index ? '' : ', '}`;
                                      });

                                      return (
                                        <>
                                          <div className="d-flex">
                                            {i <= 1 && (
                                              <>
                                                <span className="pl-3 pr-0 mr-0">
                                                  <span style={{ fontWeight: '600', color: 'black', fontSize: '1.5rem' }}>{itm.title}:</span>{' '}
                                                  <span style={{ color: 'black', fontSize: '1.5rem' }}>
                                                    {/* faf7f7 */}
                                                    {values.object_specific[newTitle]}
                                                  </span>
                                                  {filteredArray.length >= 2 && i === 1 && filteredArray.length - 2 !== 0 && (
                                                    <span
                                                      title={`${hoveredTitles}`}
                                                      className={viewClickedId === objectTypeToView ? 'clickedObjectTypeOption' : 'objectTypeOptions'}
                                                    >
                                                      +{filteredArray.length - 2}
                                                    </span>
                                                  )}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                              {/*  */}
                              <div className={[objectTypeToView === values._id && 'clickedData', 'tableRowDate'].join(' ')}>
                                <p className="p-0 mt-3 mb-0 ml-3">{moment.utc(values.object_type.edition_date).local().format('MM/DD/YYYY hh:mm:ss A')}</p>
                              </div>
                              {userPermissions['Delete_Object_Individual'] === true && (
                                <div className="d-flex justify-content-center align-itms-center" data-testid="delete-icon">
                                  <img
                                    src={DeleteIcon}
                                    style={{ cursor: 'pointer', margin: '1rem 0rem 1rem 0.6rem' }}
                                    onClick={() => {
                                      DeleteFunc(values._id);
                                    }}
                                    alt={'deleteIcon'}
                                    className={'rowDeleteIcon'}
                                  ></img>
                                </div>
                              )}
                            </div>
                          </>
                        ))}
                      </section>
                    </div>
                  )}

                  {loading ? (
                    <div className="center">
                      <CircularProgress />
                    </div>
                  ) : objectMetadata ? (
                    <>
                      <div className="fileViewer" data-testid="pdf-container">
                        {docType === 'pdf' && doc !== undefined && (
                          <>
                            {' '}
                            <header className="previewHeaderContainer" style={{ justifyContent: 'space-between' }}>
                              <p className="objectTypeHeading">Preview</p>

                              <div className="d-flex align-items-center ml-3 mx-3 align-items-center buow-top-row-text">
                                {isXmlActive === true && (
                                  <div className="d-flex align-items-center ml-3 mx-3 align-items-center buow-top-row-text">
                                    <img
                                      style={{
                                        height: '35px',
                                        backgroundColor: `${pdfToggle === 'pdf' ? 'rgb(128,128,128,0.5)' : ''}`,
                                        border: `${pdfToggle === 'pdf' ? '1px solid red' : ''}`,
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        padding: '5px',
                                      }}
                                      alt="pdfIcon"
                                      onClick={() => onSelectIcon('pdf')}
                                      src={PDFBtnIcon}
                                    ></img>

                                    <img style={{ height: '10px' }} src={ArrowIcon}></img>

                                    <img
                                      style={{
                                        height: '35px',
                                        backgroundColor: `${pdfToggle === 'xml' ? 'rgb(128,128,128,0.5)' : ''}`,
                                        border: `${pdfToggle === 'xml' ? '1px solid red' : ''}`,
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        padding: '5px',
                                      }}
                                      alt="xmlIcon"
                                      onClick={() => onSelectIcon('xml')}
                                      src={XMLBtnIcon}
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </header>
                            {pdfToggle === 'pdf' ? (
                              <PDFView
                                doc={doc}
                                docName={docName}
                                docType={docType}
                                onDocumentLoadSuccess={onDocumentLoadSuccess}
                                pageNumber={pageNumber}
                                changePage={changePage}
                                newPageNumber={newPageNumber}
                                onChangePage={onChangePage}
                                onResetPage={onResetPage}
                                onSaveEnteredPage={onSaveEnteredPage}
                                numPages={numPages}
                                zoom={zoom}
                                handleZoom={handleZoom}
                                pageWidth={navigator.userAgent.includes('Windows') ? 465 : 600}
                              ></PDFView>
                            ) : (
                              <>
                                <div style={{ height: '100%' }}>
                                  <iframe title="XML Preview" src={iframeUrl} className="iframeStyle" />
                                </div>
                                <div className="downloadOuterWrapper">
                                  <a download={'preview'} href={xmlUrl}>
                                    <img src={DownloadIcon} alt="Download icon" style={{ width: '3.2rem' }} />
                                  </a>
                                  <div className="ml-2" style={{ color: 'black', fontWeight: 'bold', fontSize: '1.5em' }}>
                                    XML
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {docType === 'image' && <ImageView doc={doc} docName={docName}></ImageView>}
                      </div>

                      <TabStrip className="detailViewer newDetailView" selected={selected} onSelect={handleSelect}>
                        {userPermissions['ObjectMetadata_View_Object'] === true && (
                          <TabStripTab title="Document Metadata" data-tesid="object-metadata">
                            {/* TODO1  */}
                            <div
                              className="metadata"
                              style={{
                                pointerEvents: loaderUpdateMetaData === true ? 'none' : 'auto',
                              }}
                            >
                              {/* {renderSysOptions()}                              
                              {metadataView(metadataDisplayList)} */}
                              {/* {renderOptions(SystemColumnsData, metadataDisplayList, additionalMetadata)} */}
                              {fieldRenderer(combinedFieldList)}
                              {additionalMetadataView(additionalMetadata)}
                            </div>

                            {redactionStatus?.key === 'Disclosable Status' && redactionStatus?.value === 'Post-Redaction' && csvFiles !== undefined && (
                              <>
                                {console.log('csvFiles', csvFiles)}
                                {csvFiles?.verificationCsv !== null && (
                                  <div className="returnedData">
                                    <div className="keyfont"> Verification CSV </div>
                                    <div
                                      className="valuefont"
                                      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', whiteSpace: 'nowrap' }}
                                    >
                                      <a
                                        download={'verificationCsv'}
                                        href={(() => {
                                          const base64String = csvFiles?.verificationCsv;
                                          const decoded = atob(atob(base64String));
                                          const blob = new Blob([decoded], { type: 'text/csv' });
                                          return URL.createObjectURL(blob);
                                        })()}
                                      >
                                        <img src={DownloadIcon} alt="Download icon" style={{ width: '2.5rem' }} />
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {csvFiles?.fullCsv !== null && (
                                  <div className="returnedData">
                                    <div className="keyfont"> Full CSV </div>
                                    <div
                                      className="valuefont"
                                      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', whiteSpace: 'nowrap' }}
                                    >
                                      <a
                                        download={'fullCsv'}
                                        href={(() => {
                                          const base64String = csvFiles?.fullCsv;
                                          const decoded = atob(atob(base64String));
                                          const blob = new Blob([decoded], { type: 'text/csv' });
                                          return URL.createObjectURL(blob);
                                        })()}
                                      >
                                        <img src={DownloadIcon} alt="Download icon" style={{ width: '2.5rem' }} />
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            <div
                              style={{
                                textAlign: 'right',
                                marginTop: '10px',
                                marginRight: '1em',
                              }}
                              className="d-flex justify-content-end"
                            >
                              <button onClick={onSaveButtonClick} disabled={loaderUpdateMetaData} className="fsa-btn" data-testid="save-button">
                                <span>Save</span>
                              </button>
                              {loaderUpdateMetaData && (
                                <div>
                                  <GlobalLoader />
                                </div>
                              )}
                            </div>
                          </TabStripTab>
                        )}
                        {userPermissions['RecordManagement_View_Object'] === true && (
                          <TabStripTab title="Records Management" data-testid="record-management">
                            {/* TODO2 RENAME to AssignFileCode */}
                            {/* <SearchDetail metaData={objectMetadata} /> */}

                            <div style={{ overflowX: 'auto' }} data-testid="record-management">
                              <div className="detailSection">
                                <div className="detailSectionTitle">
                                  <h4>Filecode information</h4>
                                  <div className="flexrow">
                                    <button onClick={() => toggleUpdateObjFileCodePopup(true)} className="fsa-btn clear-btn" data-testid="filecode-button">
                                      Update Filecode
                                    </button>
                                    <span>
                                      {sectionOpen ? (
                                        <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                                      ) : (
                                        <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="detailSectionContent">
                                  <div className="metaItem">
                                    <span className="metaName">File Code Name: {fileCodeName}</span>
                                  </div>
                                  <div className="metaItem">
                                    <span className="metaName">File Code id: {objectMetadata.record_management ? objectMetadata.record_management.file_code : ''}</span>
                                  </div>
                                </div>
                              </div>
                              <UpdateObjectFileCodeModal
                                setObjectMetadata={setObjectMetadata}
                                objectMetadata={objectMetadata}
                                objectID={objectID}
                                windowHeight={window.innerHeight}
                                windowWidth={window.innerWidth}
                                loadingFileCodes={loadingFileCodes}
                                setLoadingFileCodes={setLoadingFileCodes}
                                objFileCodePopupOpen={objFileCodePopupOpen}
                                setRecords={setRecords}
                                records={records}
                                toggleUpdateObjFileCodePopup={toggleUpdateObjFileCodePopup}
                                ids={[objectMetadata._id]}
                              ></UpdateObjectFileCodeModal>

                              <div className="detailSection">
                                <div className="detailSectionTitle">
                                  <h4>Hold information</h4>
                                  <div className="flexrow">
                                    <button className="fsa-btn clear-btn" onClick={openHoldModal} data-testid="hold-button">
                                      Update Hold
                                    </button>
                                    <span>
                                      {sectionOpen ? (
                                        <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                                      ) : (
                                        <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="detailSectionContent">
                                  <div className="metaItem">
                                    <span className="metaName">Hold Name: {holdInfo !== null ? holdInfo?.holdName : ''}</span>
                                  </div>
                                  <div className="metaItem">
                                    <span className="metaName">Type of Hold: {holdInfo !== null ? holdInfo?.holdtype : ''}</span>
                                    <br></br>
                                  </div>
                                </div>
                              </div>
                              <UpdateObjectHoldModal onClose={closeHoldModal} visible={isHoldModalVisible} recordIds={[objectMetadata?._id]} />

                              {/* Disposition Information Start */}
                              <div className="detailSection">
                                <div className="detailSectionTitle">
                                  <h4>Disposition information</h4>
                                  <div className="flexrow">
                                    <button className="fsa-btn clear-btn" onClick={UpdateDisposition} data-testid="disposition-button">
                                      Update Disposition
                                    </button>
                                    <span>
                                      {sectionOpen ? (
                                        <FontAwesomeIcon onClick={closeSection} className="float-right chevron" icon={faChevronUp} />
                                      ) : (
                                        <FontAwesomeIcon className="float-right chevron" icon={faChevronDown} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="detailSectionContent">
                                  <div className="metaItem">
                                    <span className="metaName">Cutoff Date:</span>
                                    {/* <DatePicker className="disposition-datepicker" onChange={handleStartDateChange} selected={startDate} /> */}

                                    <input type="date" value={startDate} format="MM/dd/yyyy" name={'end_' + startDate} onChange={handleStartDateChange} />
                                  </div>
                                  <div className="metaItem">
                                    <span className="metaName">Disposition Date:</span>
                                    <span>
                                      {objectMetadata?.record_management?.projected_disposition_date !== undefined
                                        ? moment(objectMetadata?.record_management?.projected_disposition_date).format('MM-DD-YYYY')
                                        : '-'}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* Disposition Information End */}
                              <div
                                style={{
                                  textAlign: 'right',
                                  marginTop: '10px',
                                  marginRight: '1em',
                                }}
                                className="d-flex justify-content-end"
                              >
                                {' '}
                                <button onClick={onSaveButtonClick} disabled={loaderUpdateMetaData} className="fsa-btn">
                                  <span>Save</span>
                                </button>
                                {loaderUpdateMetaData && (
                                  <div>
                                    <GlobalLoader />
                                  </div>
                                )}
                              </div>
                            </div>
                          </TabStripTab>
                        )}
                        {userPermissions['View_Logs'] === true && (
                          <TabStripTab title="Logs">
                            <div style={{ display: 'flex', justifyContent: 'center' }} data-testid="Logs-container">
                              <a
                                style={{
                                  color: '#fff',
                                  borderRadius: '5px',
                                  padding: '2rem 0 2rem',
                                  height: '100%',
                                  width: '100%',
                                  textAlign: 'center',
                                  marginBottom: '1em',
                                  background: '#205493',
                                  textDecoration: 'none',
                                  fontSize: '1.2em',
                                  fontWeight: '500',
                                  letterSpacing: '0.24px',
                                  lineHeight: 'normal',
                                }}
                                download={'logs.txt'}
                                href={`data:text/plain,${JSON.stringify(objectMetadata.doc_modification_history)}`}
                              >
                                Download Logs
                              </a>
                            </div>

                            <h3 style={{ color: '#000' }}>Object History</h3>
                            <ListView
                              style={{ maxHeight: '45 em', overflow: 'hidden' }}
                              data={objectMetadata.doc_modification_history}
                              item={LogItemRender}
                              className="k-listview"
                            />
                            <h3 style={{ color: '#000' }}>Records Management History</h3>
                            <ListView
                              style={{ maxHeight: '45em', overflow: 'hidden' }}
                              data={objectMetadata.record_management ? objectMetadata.record_management.file_code_change_history : []}
                              item={(props) => FcLogItemRender(props, objectMetadata.record_management)}
                              className="k-listview"
                            />
                          </TabStripTab>
                        )}
                        {userPermissions['Can_View_Sense'] === true && (
                          <TabStripTab title="SENSE - β" data-testid="sense-container">
                            <AliceSenseTab docNumber={objectMetadata?.object_specific?.document_ocr_id} docData={objectMetadata} />
                          </TabStripTab>
                        )}
                      </TabStrip>
                    </>
                  ) : (
                    <div className="center">
                      <h3>{errorMessage}</h3>
                      <div className="fsa-divider"></div>
                      {/* <h2>{cellClicked}</h2> */}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    textAlign: 'right',
                    marginTop: '10px',
                    marginRight: '5em',
                  }}
                >
                  {' '}
                </div>
              </div>
            </>
          )}
          <SuccessProcessGrowl
            isSuccessGrowlHidden={isSuccessGrowlHidden}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            message={'Successfully updated file object metadata.'}
          />
          <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden1} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden1} message={message} />
          <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        </Window>

        <div>
          {deleteModalShow === true && (
            <DeleteModal
              show={deleteModalShow}
              DeleteDataFunc={DeleteDataFunc}
              onHide={() => setDeleteModalShow(false)}
              setDeleteModalShow={setDeleteModalShow}
              selectedDeleteRowData={deleteData}
              setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
              setMessage={setMessage}
              popupTitle="Object"
            ></DeleteModal>
          )}
        </div>
      </div>
    )
  );
}
