import React, { useEffect, useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './RedactionReference.module.scss';
import '../../../GlobalStyle.scss';
import RedactionReferenceData from './RedactionReferenceData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { getRedactionReferenceList, deleteRedactionReference, searchRedactionReference, updateRedactionReferenceList } from './RedactionReferenceService';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import { handleIsRequiredError, handleMaxLimitError, handleUrlError } from '../../../utils/helper/handleReuiredErrorFunc';

const TableRedactionReference = lazy(() => import('./RedactionReferenceTable'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Redaction Reference', link: '' },
];

export default function RedactionReference() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contextId: userInformation?.contextId,
    referenceId: '',
    redactionReference: '',
    redactionReferenceText: '',
    activeInd: true,
  });
  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [RedactionReferenceTableDetails, setRedactionReferenceTableDetails] = useState(RedactionReferenceData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const userContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  async function fetchRedactionReferenceData() {
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRedactionReference(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchObjectHierarchyData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchRedactionReference(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createRedactionReference = () => {
    setFormData({
      contextId: userInformation?.contextId,
      redactionReference: '',
      redactionReferenceText: '',
      activeInd: true,
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const deleteRedactionFunction = (DeleteData) => {
    setLoading(true);

    const sendData = {
      contextId: DeleteData?.contextId,
      referenceId: DeleteData?.referenceId,
    };

    deleteRedactionReference(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchRedactionReferenceData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Redaction Reference Deleted Successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editRedactionReference = (data) => {
    setFormData({
      contextId: data?.contextId,
      referenceId: data?.referenceId,
      redactionReference: data?.redactionReference,
      redactionReferenceText: data?.redactionReferenceText,

      activeInd: data.activeInd === null || data.activeInd === undefined ? true : data.activeInd,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    console.log('delete function called');
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) onSearch(searchText);
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      contextId: userContextId,
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchRedactionReference(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newObjectHierarchyData = JSON.parse(JSON.stringify(RedactionReferenceTableDetails));
    newObjectHierarchyData.forEach((itm) => {
      itm.sortType = '';
    });
    setRedactionReferenceTableDetails(newObjectHierarchyData);
    await fetchNewObjectHierarchyData();
  };

  // Code needs to change
  const fetchNewObjectHierarchyData = async () => {
    let payload = {
      contextId: userContextId,
      text: '',
      column: '',
      order: '',
    };
    await searchRedactionReference(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      contextId: userContextId,
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchRedactionReference(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.RedactionReferencePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.RedactionReferencePageContent}>
        <h1 className={styles.pageTitle}>Redaction Reference</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create Redaction Reference" className={styles.createRecordBtnStyle} onClick={createRedactionReference} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.redactionReferenceTable, 'glbObjectHierarchyTable'].join(' ')}>
          <TableRedactionReference
            data={data}
            page={page}
            editFunc={editRedactionReference}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={RedactionReferenceTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditRedactionReference
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchRedactionReferenceData={fetchRedactionReferenceData}
          userContextId={userContextId}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={deleteRedactionFunction}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'Redaction Reference'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditRedactionReference = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  // Handle selection change
  const onChangeReferenceId = (selectedOption, fromCreate) => {
    if (!selectedOption) {
      props.setFormData((prevData) => ({
        ...prevData,
        objectClass: '',
      }));
      return;
    }
    if (fromCreate === 'fromCreate') {
      props.setFormData((prevData) => ({
        ...prevData,
        objectClass: selectedOption.label,
      }));
    } else {
      props.setFormData((prevData) => ({
        ...prevData,
        objectClass: selectedOption.label,
      }));
    }
  };

  const handleRedactionReferenceTextChange = async (text) => {
    props.setFormData((prevData) => ({
      ...prevData,
      redactionReferenceText: value,
    }));
    const value = text.value || text;
  };

  const handleRedactionReferenceChange = async (text) => {
    props.setFormData((prevData) => ({
      ...prevData,
      redactionReference: value,
    }));
    const value = text.value || text;
  };

  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };
  const handleSubmit = async () => {
    try {
      if (
        !props.formData.contextId ||
        props.formData.contextId?.length > 50 ||
        !props.formData.redactionReferenceText ||
        props.formData.redactionReferenceText?.length > 200 ||
        props.formData.redactionReference &&
        (props.formData.redactionReference?.length > 200 || handleUrlError(props.formData.redactionReference))
      ) 
      {
        setisRequiredError(true);
        return;
      }
      setisRequiredError(false);
      let payload = _.cloneDeep(props.formData);

      // payload.objectClass = payload?.objectClass?.trim();
      // payload.name = payload?.name?.trim();
      //payload.description = payload?.description?.trim();
      // payload.buowName = payload?.buowName?.trim();

      payload.contextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;
      props.isLoading(true);
      await getRedactionReferenceList(payload)
        .then((response) => {
          if (response.data) {
            props.fetchRedactionReferenceData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('Redaction Reference Created Successfully.');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEdit = async () => {
    if (
      !props.formData.contextId ||
      props.formData.contextId?.length > 50 ||
      !props.formData.redactionReferenceText ||
      props.formData.redactionReferenceText.length > 200 ||
      props.formData.redactionReference &&
      (props.formData.redactionReference?.length > 200 || handleUrlError(props.formData.redactionReference))
    ) {
      setisRequiredError(true);
      return;
    }
    setisRequiredError(false);
    let payload = _.cloneDeep(props.formData);

    props.isLoading(true);

    await updateRedactionReferenceList(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('Redaction Reference Updated Successfully.');

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchRedactionReferenceData();
        props.setRecordModalShow(false);

        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };
  const firstInputRef = useRef(null);
  const redactionReferenceRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused';
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused';
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.ObjectHierarchyModal, 'glbRedactionReferenceModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Redaction Reference' : 'Edit Redaction Reference'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.ObjectHierarchyModalBody}>
          <div className={styles.modalBodySection}>
            <div className={styles.inputFieldSection}>
              {props.isNew ? (
                <div className={styles.inputMainContainer}>
                  <div className={styles.inputFieldSection} style={{ flexDirection: 'column', gap: '0' }}>
                    <label>
                      Redaction Reference Text<span style={{ color: 'red', fontSize: '15px' }}>*</span>
                    </label>
                    <input
                      ref={firstInputRef}
                      onFocus={() => handleFocus(firstInputRef)}
                      required
                      name="redactionReferenceText"
                      onChange={handleInputChange}
                      value={props.formData?.redactionReferenceText || ''}
                    />
                    {(props.formData.redactionReferenceText === null || props.formData.redactionReferenceText === '') && isRequiredError === true && handleIsRequiredError()}

                    {props.formData.redactionReferenceText && props.formData.redactionReferenceText.length > 200 && handleMaxLimitError(200)}
                  </div>

                  <div className={styles.inputFieldSection} style={{ flexDirection: 'column', gap: '0' }}>
                    <label>Redaction Reference</label>
                    <input
                      ref={redactionReferenceRef}
                      onFocus={() => handleFocus(redactionReferenceRef)}
                      required
                      name="redactionReference"
                      value={props?.formData?.redactionReference}
                      onChange={handleInputChange}
                    />
                    { props.formData.redactionReference && handleUrlError(props.formData?.redactionReference)}

                    {props.formData.redactionReference?.length > 200 && handleMaxLimitError(200)}
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Context ID</label>
                    <input
                      type="text"
                      value={props?.formData?.contextId || ''}
                      onChange={(e) => onChangeContextId(e.target.value)}
                      className={props.isNew ? '' : styles.disabledInput}
                      disabled={props.isNew ? false : true}
                    />
                  </div>
                  <div className={styles.inputFieldInternalDiv}>
                    <label>Reference ID</label>
                    <input
                      type="text"
                      value={props?.formData?.referenceId || ''}
                      onChange={(e) => {
                        onChangeReferenceId(e.target.value, null);
                      }}
                      disabled={!props.isNew}
                      className={props.isNew ? '' : styles.disabledInput}
                    />

                    {(props.formData.referenceId === null || props.formData.referenceId === '') && isRequiredError === true && handleIsRequiredError()}
                  </div>
                </>
              )}
            </div>
            {!props.isNew && (
              <div className={styles.inputFieldInternalDiv}>
                <label>
                  Redaction Reference Text<span style={{ color: 'red', fontSize: '15px' }}>*</span>
                </label>
                <div style={{ paddingBottom: '20px' }}>
                  <input
                    ref={firstInputRef}
                    onFocus={() => handleFocus(firstInputRef)}
                    value={props.formData.redactionReferenceText || ''}
                    onChange={(e) => handleRedactionReferenceTextChange(e.target.value)}
                    style={{ width: '100%', height: '32px' , border: '1px solid #ced4da' , borderRadius:'4px'}}
                  />
                </div>

                {(props.formData.redactionReferenceText === null || props.formData.redactionReferenceText === '') && isRequiredError === true && handleIsRequiredError()}
                {props.formData.redactionReferenceText && props.formData.redactionReferenceText.length > 200 && handleMaxLimitError(200)}
              </div>
            )}

            {!props.isNew && (
              <div className={styles.inputFieldInternalDiv}>
                <label>Redaction Reference </label>
                <div>
                  <input
                    ref={redactionReferenceRef}
                    onFocus={() => handleFocus(redactionReferenceRef)}
                    value={props.formData.redactionReference || ''}
                    onChange={(e) => handleRedactionReferenceChange(e.target.value)}
                    style={{ width: '100%', height: '32px' , border: '1px solid #ced4da' , borderRadius:'4px'}}
                  />
                </div>

                { props.formData.redactionReference && handleUrlError(props.formData?.redactionReference)}
                {props.formData.redactionReference?.length > 200 && handleMaxLimitError(200)}


              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6 pl-0'].join(' ')}>
                <div className={[styles.radioInputOuterDiv, 'mt-1'].join(' ')} data-testid="radio-function">
                  <div>Active Ind</div>
                  <div style={{ display: 'flex' }}>
                    <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                    True
                    <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.orchestrationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" parentWarpper={'d-flex align-items-center'} className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
