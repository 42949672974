import React, { useEffect,useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './DashboardGroupCardXRef.module.scss';
import '../../../GlobalStyle.scss';

import DashboardGroupCardXRefData from './DashboardGroupCardXRefData.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import {
  createDashboardGroupCardXRef,
  updateOneDashboardGroupCardXRef,
  deleteDashboardGroupCardXRef,
  searchDashboardGroupCardXRef,
  getDefaultElement,
  getIndicatorGroupName,
} from './TableDashboardGroupCardXRefService.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal.js';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';

const TableDashboardGroupCardXRef = lazy(() => import('./TableDashboardGroupCardXRef.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Dashboard Group Card XRef', link: '' },
];

export default function DashboardGroupCardXRef() {
  const glbContextId = JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  // const [selectedRows, setSelectedRows] = useState();
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [dashboardGroupCardXRefDetails, setTableDashboardGroupCardXRefData] = useState(DashboardGroupCardXRefData);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [formData, setFormData] = useState({
    activeInd: true,
    contextId: glbContextId,
    indicatorGroupName: '',
    cardId: '',
  });

  async function fetchTableDashboardGroupCardXRefData() {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchDashboardGroupCardXRef(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchTableDashboardGroupCardXRefData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchKeyword,
      column: '',
      order: '',
      contextId: glbContextId,
    };
    setSorting({ column: '', order: '' });
    await searchDashboardGroupCardXRef(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
      contextId: glbContextId,
    };
    await searchDashboardGroupCardXRef(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      activeInd: true,
      contextId: glbContextId,
      indicatorGroupName: '',
      cardId: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const editFunc = async (data) => {
    setFormData({
      activeInd: data.activeInd,
      contextId: data.contextId,
      indicatorGroupName: data.indicatorGroupName,
      cardId: data.cardId,
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewDashboardGroupCardXRefData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newRoutingStepsData = JSON.parse(JSON.stringify(dashboardGroupCardXRefDetails));
    newRoutingStepsData.forEach((itm) => {
      itm.sortType = '';
    });
    setTableDashboardGroupCardXRefData(newRoutingStepsData);
  };

  // Code needs to change
  const fetchNewDashboardGroupCardXRefData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
      contextId: glbContextId,
    };
    await searchDashboardGroupCardXRef(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
      contextId: glbContextId,
    };
    await searchDashboardGroupCardXRef(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      contextId: glbContextId,
      cardId: DeleteData.cardId,
    };
    deleteDashboardGroupCardXRef(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await onSearch(searchKeyword);
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('Dashboard Group Card XRef deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    //Parent Component
    <div data-testid="dashboardGroupCardXRefDetails-container" className={styles.dashboardGroupCardXRefPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="dashboardGroupCardXRefDetails-page-wrapper" className={styles.dashboardGroupCardXRefPageContent}>
        <h1 data-testid="dashboardGroupCardXRefDetails-heading" className={styles.pageTitle}>
          Dashboard Group Card XRef
        </h1>
        <div>
          <div data-testid="dashboardGroupCardXRefDetails-search-container" className={styles.searchSection}>
            <div data-testid="dashboardGroupCardXRefDetails-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="dashboardGroupCardXRefDetails-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-dashboardGroupCardXRefDetails'}
                ></SearchAdminPanelComponent>
              </div>
              <div data-testid="dashboardGroupCardXRefDetails-create-btn-container">
                <CustomButton
                  testId={'create-dashboardGroupCardXRefDetails-btn'}
                  title="Create Dashboard Group Card XRef"
                  className={styles.createRecordBtnStyle}
                  onClick={createNewFunc}
                />
              </div>
            </div>
            <span data-testid="dashboardGroupCardXRefDetails-help-text" className="searchNoteStyle">
              {' '}
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="dashboardGroupCardXRefDetails-table-container" className={[styles.dashboardGroupCardXRefTable, 'glbDashboardGroupCardXRefTable'].join(' ')}>
          <TableDashboardGroupCardXRef
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={dashboardGroupCardXRefDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>

        <AddEditDashboardGroupCardXRefModal
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchTableDashboardGroupCardXRefData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
          glbContextId={glbContextId}
        />

        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            deleteData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            selectedDeleteRowData={deleteData}
            popupTitle={'Dashboard Group Card XRef'}
          ></DeleteModal>
        )}

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const AddEditDashboardGroupCardXRefModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color:'black'
    }),
  };

  const loadObjectElementIdList = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let payload = { contextId: props.formData.contextId, text: ' ' };
      // await response.data.
      await getDefaultElement(payload).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.indicatorGroupName, value: res.indicatorGroupName });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        callback(options);
      });
    } else {
      let payload = { contextId: props.formData.contextId, text: inputValue };
      await getIndicatorGroupName(payload).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.indicatorGroupName, value: res.indicatorGroupName };
        });

        callback(options);
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value ? value.trimStart() : value,
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      activeInd: booleanValue,
    }));
  };

  const handleSubmit = async (data) => {
    if (!data.cardId || !data.indicatorGroupName || data.cardId.length > 200) {
      setisRequiredError(true);
      return;
    } else {
      props.isLoading(true);
      let payload = _.cloneDeep(data);
      payload.cardId = payload?.cardId?.trim();
      await createDashboardGroupCardXRef(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);

          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Dashboard Group Card XRef created successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchTableDashboardGroupCardXRefData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const handleEdit = async (data) => {
    if (!data.cardId || !data.indicatorGroupName || data.cardId.length > 200) {
      setisRequiredError(true);
      return;
    } else {
      let payload = _.cloneDeep(data);
      payload.cardId = payload?.cardId?.trim();

      props.isLoading(true);

      await updateOneDashboardGroupCardXRef(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Dashboard Group Card XRef updated successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchTableDashboardGroupCardXRefData();
          props.setRecordModalShow(false);
          setIsUserSelected(true);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          console.log('error in EditMetadata()');
        }
      });
    }
  };

  const onChangeObjectElementId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        indicatorGroupName: '',
      }));
    const { value, label } = event;
    const updatedValue = value === '' ? null : value;
    const updatedLabel = label === '' ? null : label;
    props.setFormData((prevData) => ({
      ...prevData,
      indicatorGroupName: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
    setIsUserSelected(true);
  };
const firstInputRef = useRef(null);

// Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.dashboardGroupCardXRefModal, 'glbDashboardGroupCardXRefModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create Dashboard Group Card XRef' : 'Edit Dashboard Group Card XRef'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className={styles.dashboardGroupCardXRefModalBody}>
          <div className={styles.modalBodySection}>
            {props.isNew === false && (
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context ID *</label>
                  <input
                    required
                    name="contextId"
                    value={props.formData.contextId}
                    disabled={props.isNew ? false : true}
                    onChange={handleInputChange}
                    className={props.isNew ? '' : styles.disabledInput}
                  />
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <label>Card ID *</label>
                <input
                  required
                  name="cardId"
                  value={props.formData.cardId}
                  disabled={props.isNew ? false : true}
                  onChange={handleInputChange}
                  className={props.isNew ? '' : styles.disabledInput}
                  ref={props.isNew === true ? firstInputRef:null}
                  onFocus={() => handleFocus(firstInputRef)}
                />
                {(props.formData.cardId === null || props.formData.cardId === '' || props.formData.cardId === undefined) && isRequiredError === true && handleIsRequiredError()}
                {props.formData.cardId.length > 200 && handleMaxLimitError(200)}
              </div>

              <div className={styles.inputFieldInternalDiv}>
                <label>Indicator Group Name *</label>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  isDisabled={props.isNew ? false : true}
                  loadOptions={loadObjectElementIdList}
                  value={props?.formData?.indicatorGroupName !== '' ? { label: props?.formData?.indicatorGroupName, value: props?.formData?.indicatorGroupName } : null}
                  onChange={onChangeObjectElementId}
                  isSearchable={true}
                  defaultOptions
                  className={props.isNew ? '' : styles.disabledInput}
                />
                {(props.formData.indicatorGroupName === null || props.formData.indicatorGroupName === '') && isRequiredError === true && handleIsRequiredError()}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={styles.inputFieldInternalDiv}>
                <div className={styles.radioInputOuterDiv}>
                  Active Ind: <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} /> True
                  <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.dashboardGroupCardXRefFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={() => handleSubmit(props.formData)} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            )}
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
