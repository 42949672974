import axios from 'axios';

export async function createRedactionActor(data) {
  let updateResponse = [];
  try {
    const url = '/buow_api/redactionactor';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in creating routing orchestration: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function updateOneRedactionActor(data) {
  console.log(data);
  let updateResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/redactionactor/' + data?.contextId + '/' + data?.actorType;
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteRedactionActor(params) {
  console.log('params', params);
  let deleteResponse = {};
  try {
    const url = `/buow_api/redactionactor/${params.contextId}/${params.actorType}`;
    console.log('deleteOrc: deleteRedactionActor()' + url);
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error in deleting routing orchestration data: ', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchRedactionActor(data) {
  console.log('searchRedactionActor', data);
  let workQueueFilterResponse = [];
  try {
    let url = `${window.env.REACT_APP_BACKEND_URL}/buow_api/redactionactor/search?contextId=${data.contextId}&keywords=${data.text}`;

    if (data.column !== '' && data.order !== '') {
      url += `&sortOn=${data.column}&sortOrder=${data.order}`;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getUserInfoID(text) {
  let workQueueFilterResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/security_api/user/' + text;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
