import axios from 'axios';
import history from '../../history'; // Import the custom history instance
import { userLogout } from '../../components/Login/LoginService';
import { v4 as uuidv4 } from 'uuid';

// Function to set up Axios interceptors
export const configureAxiosInterceptors = (setIsLoading) => {
  // Set base URL globally
  axios.defaults.baseURL = window.env.REACT_APP_BACKEND_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  axios.interceptors.request.use(
    (config) => {
      const correlationId = uuidv4();

      config.headers['X-Correlation-ID'] = correlationId;
      return config;
    },
    null,
    { synchronous: true }
  );
  // Response interceptor
  axios.interceptors.response.use(
    (response) => response, // Simply return the response if successful
    async (error) => {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Access denied...', error.response.status);

        // Start the loader
        setIsLoading(true);

        let data = {
          token: sessionStorage.getItem('userToken'),
        };

        await userLogout(data).then(() => {
          sessionStorage.clear();
          localStorage.clear();

          setTimeout(() => {
            setIsLoading(false); // Stop the loader after redirect
            history.replace('/login');
          }, 3000);
        });
      }
      return Promise.reject(error); // Reject the error to let the calling function handle it
    }
  );
};
