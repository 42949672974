import axios from 'axios';

export async function getTransfer(payload) {
  try {
    const url = `/recordsmanagementapi/records-management/transfers/search`;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    };
    const response = await axios.post(url, payload, { headers });
    return response.data;
  } catch (ex) {
    console.error('Error in EditMetadata()', ex.message);
    throw ex;
  }
}
export async function createTransfer(data) {
  let createResponse = [];
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
  };
  try {
    const url = `/recordsmanagementapi/records-management/transfers`;
    const response = await axios.post(url, data, { headers });
    createResponse = response;
  } catch (ex) {
    console.error('Error in EditMetadata()', ex.message);
    createResponse.error = ex.response.data;

    // throw ex;
  }
  return createResponse;
}

export async function updateTransfer(data, headers) {
  let createResponse = [];
  const userInfo = JSON.parse(sessionStorage.getItem('user_information'));
  const config = {
    headers: {
      'Content-Type': 'application/json',
      userName: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.userName : '',
      usdaeauthid: userInfo !== null && Object.keys(userInfo).length > 0 ? userInfo.usdaeauthid : '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    },
  };
  try {
    const url = `/recordsmanagementapi/records-management/transfers/${data.transferNumber}`;

    const response = await axios.put(url, data, config);

    createResponse = response;
  } catch (ex) {
    console.error('Error in EditMetadata()', ex.message);
    createResponse.error = ex.response.data;

    // throw ex;
  }
  return createResponse;
}

export async function searchTransferData(data) {
  let searchTransfer = [];
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
  };
  try {
    const url = `/recordsmanagementapi/records-management/transfers/search`;

    const response = await axios.post(url, data, { headers });
    searchTransfer = response.data;
  } catch (err) {
    console.error('Error in searching', err.message);
    searchTransfer.error = err.response?.data;

    // throw ex;
  }
  return searchTransfer;
}
