import axios from 'axios';
import { serviceUserJwt } from '../../../services/serviceConfig';
export async function createMessageTable(data) {
  let createResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/message';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error:', ex.response.data);
    createResponse.error = ex.response.data;
  }
  // console.log(createResponse);
  return createResponse;
}

export async function updateMessage(data) {
  console.log('data', data);
  let updateResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/message/' + data.messageId + '/';
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error :', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deleteMessage(data) {
  let deleteResponse = [];
  try {
    const url = `${window.env.REACT_APP_BACKEND_URL}/buow_api/message/${data.messageId}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchForMessageTable(data) {
  let workQueueFilterResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/buow_api/message/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += 'search?keywords=' + data.text;
    } else {
      url += 'search?keywords=' + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function searchUsers(text) {
  let getSystemConfigResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/security_api/user/search/${text}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getSystemConfigResponse = response;
      });
  } catch (ex) {
    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getSystemConfigResponse;
}

export async function searchWorkgroups(text) {
  let getSystemConfigResponse = [];
  try {
    const url = window.env.REACT_APP_BACKEND_URL + `/buow_api/workgroups/search/${text}`;
    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        getSystemConfigResponse = response;
      });
  } catch (ex) {
    console.log('Error in get getSystemConfig ', ex.message);
  }
  return getSystemConfigResponse;
}
