import React, { useState, useRef, useEffect } from 'react';

// import UploadFileIcon from '../../../fsa-style/img/svgs/UploadFileIcon.svg';
import UploadedFileIconBlack from '../../../fsa-style/img/svgs/uploadedFileBlack.svg';
import AddButton from '../../../fsa-style/img/svgs/AddButton.svg';
import CrossIcon from '../../../fsa-style/img/svgs/CrossIcon.svg';
import DownArrowIcon from '../../../fsa-style/img/svgs/DownArrowIcon.svg';
import UploadIcon from '../../../fsa-style/img/svgs/uploadIcon.svg';
import UploadFileImg from '../../../fsa-style/img/svgs/uploadFileImg.svg';
import CheckIcon from '../../../fsa-style/img/svgs/checkIcon.svg';
import styles from './SuspenseQueuePopup.module.scss';
import UploadFileIcon from '../../../fsa-style/img/svgs/uploadFileIcon.png';
import redicon from '../../../fsa-style/img/svgs/redicon.svg';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
// import DocumentErrorModal from '../../../newcomponents/DocumentErrorModal/DocumentErrorModal';

const SuspenseQueuePopup = ({
  ismodalOpen,
  toggleModalCollapse,
  TriggerPointFirefoxFunc,
  closeUpload,
  isUploadSuccess,
  isModalCollapsed,
  progressVal,
  progressVal2,
  filesData,
  filesData1,
  onFilesAdded,
  fileloading,
  fileProcessing,
  onAddInvalidFiles,
  errorMsg,
  onCheckVersion,
  fileSizeErr,
  setIsErrorGrowlHidden3,
  isPackagerMode,
}) => {
  const fileInput = useRef();
  const [uploadedFileCount, setUploadedFileCount] = useState('');
  // const [selectedFiles, setSelectedFiles] = useState(filesData);

  const [uploadStatus, setUploadStatus] = useState('');

  const [progressStatus, setProgressStatus] = useState('');

  const [versionStatus, setVersionStatus] = useState('');

  useEffect(() => {
    if (filesData && filesData !== null) {
      let uploadedFiles = filesData.filter((item) => item.progressVal === 100);
      setUploadedFileCount(uploadedFiles.length);
    }

    if (filesData && filesData !== null) {
      let uploadStatus1 = filesData.filter((item) => item.progressVal);
      setUploadStatus(uploadStatus1);
    }
    if (filesData && filesData !== null) {
      let versionStatus1 = filesData.filter((item) => item.errorMessage !== null);
      setVersionStatus(versionStatus1);
    }

    if (filesData && filesData !== null) {
      let progressedFiles = filesData.filter((item) => item.progressVal2 === 100);
      setProgressStatus(progressedFiles.length);
    }
  }, [filesData]);

  const handleSelectFile = (e) => {
    e.preventDefault();
    e.target.value = '';
    fileInput.current.click();
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    files?.forEach((file, index) => {
      if (file.size / 1000 ** 2 > 300) {
        setIsErrorGrowlHidden3(false);
        fileSizeErr('File Size should not exceed 300');
        setTimeout(() => {
          setIsErrorGrowlHidden3(true);
        }, 3000);
        files.splice(index, 1);
      } else {
        return file;
      }
    });
    setTimeout(() => {
      files.length > 0 && handleFiles(files);
    }, 1000);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    files?.forEach((file, index) => {
      if (file.size / 1000 ** 2 > 300) {
        setIsErrorGrowlHidden3(false);
        fileSizeErr('File Size should not exceed 300');
        setTimeout(() => {
          setIsErrorGrowlHidden3(true);
        }, 3000);
        files.splice(index, 1);
      } else {
        return file;
      }
    });
    setTimeout(() => {
      files.length > 0 && handleFiles(files);
    }, 1000);
  };
  const dragOver = (e) => {
    e.preventDefault();
  };
  const dragEnter = (e) => {
    e.preventDefault();
  };
  const dragLeave = (e) => {
    e.preventDefault();
  };

  const validateFile = (file) => {
    const allowedExtensions = ['pdf', 'docx', 'doc', 'xml', 'png', 'tif', 'tiff'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const CheckV = () => {
    onCheckVersion();
  };

  const handleFiles = (files) => {
    let validFiles = [];
    let invalidFiles = [];

    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        validFiles.push(files[i]);
      } else {
        files[i].invalid = true;
        invalidFiles.push(files[i]);
      }

      if (i === files.length - 1) {
        onFilesAdded(validFiles);
        if (invalidFiles.length > 0) onAddInvalidFiles(invalidFiles);
      }
    }
  };

  return (
    <div className={[styles.uploadPopup, `${ismodalOpen ? styles.open : ''}`].join(' ')}>
      {/* Added loader */}
      {fileloading && (
        <div className={styles.spinnerOuterDiv}>
          <Spinner color="#000" size={40} speed={1} animating={true} />
        </div>
      )}

      {fileProcessing && errorMsg === false ? (
        <div className={styles.spinnerOuterDiv}>
          <Spinner color="#000" size={40} speed={1} animating={true} />
        </div>
      ) : (
        ' '
      )}

      <div className={styles.uploadHeader} onMouseEnter={(event) => TriggerPointFirefoxFunc(event)} onClick={toggleModalCollapse}>
        <div className={styles.uploadTitle}>
          <img src={UploadIcon} alt="UploadIcon" />

          {filesData === null ? (
            <span className={styles.uploadedFilesText}>Upload files</span>
          ) : progressStatus === 0 ? (
            <span className={styles.uploadedFilesText}>{uploadedFileCount} files uploaded Successfully</span>
          ) : (
            <span className={styles.uploadedFilesText}>{progressStatus} files processed Successfully</span>
          )}
          {/* {isUploadSuccess && <span className={styles.uploadSuccess}>✓</span>} */}
        </div>
        <div>
          <button className={styles.collapseButton}>
            {isModalCollapsed ? (
              <img className={styles.arrowIcon} alt="arrow-down" style={{ transform: 'rotate(180deg)' }} src={DownArrowIcon}></img>
            ) : (
              <img className={styles.arrowIcon} alt="arrow-down" src={DownArrowIcon}></img>
            )}
          </button>
          <img src={CrossIcon} alt="close" className={styles.closeButton} onClick={closeUpload}></img>
        </div>
      </div>

      {!isModalCollapsed && (
        <>
          {filesData === null ? (
            <div onMouseEnter={(event) => TriggerPointFirefoxFunc(event)} className={styles.uploadBody} style={fileloading === true ? { opacity: '0.6' } : {}}>
              <div className={styles.dropFileSection} onDrop={fileDrop} onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave}>
                <div className={styles.dropFileDragNDropPointer} onClick={handleSelectFile} data-testid="dropFileContainer">
                  <img src={UploadFileImg} alt={UploadFileImg} />
                  <h3 className={styles.fileText}>Drop files here or Click to upload</h3>
                  <span className={styles.fileTypeText}>Accepted file type: PDF, XML, PNG, TIF, TIFF and DOCX</span>
                </div>

                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={fileInput}
                  accept=".pdf, .xml, .png, .tif, .tiff, .docx"
                  onChange={handleFileChange}
                  multiple
                  value={''}
                  data-testid="fileInput"
                />
              </div>
            </div>
          ) : versionStatus.length !== 0 ? (
            <div onMouseEnter={(event) => TriggerPointFirefoxFunc(event)} className={styles.uploadBody}>
              {/* Content of the modal body */}
              <div className={fileloading === true ? styles.blurredDraggableSection : styles.draggableSection}>
                <div className={styles.uploadBodyContainer}>
                  <ul className={styles.noBullets}>
                    {filesData?.map((obj, index) => {
                      return (
                        <li
                          className={obj.errorMessage === null ? styles.uploadBodyList : styles.uploadBodyList1}
                          {...(obj.errorMessage !== null
                            ? {
                                onClick: () => {
                                  CheckV();
                                },
                              }
                            : {})}
                          key={index}
                        >
                          <div>
                            {obj.errorMessage === null ? (
                              <img className={styles.icon} alt="uploaded-file-black" src={UploadedFileIconBlack}></img>
                            ) : (
                              <img alt="red" className={styles.icon} src={redicon}></img>
                            )}
                          </div>
                          <div style={{ width: '82%' }}>
                            <div className={styles.pOne}>
                              <div title={obj.fileName} className={obj.errorMessage === null ? styles.fileName : styles.fileNamehide}>
                                {obj.fileName}
                              </div>

                              <div className={obj.errorMessage === null ? styles.status : styles.statushide}>
                                <span className={styles.titleText}>Status:</span>
                                <span className={styles.titleValue}> {obj.status}</span>
                              </div>

                              <div className={obj.errorMessage === null ? styles.pbOne : styles.pbOnehide}>
                                <HorizontalProgressBar value={obj.progressVal} />
                              </div>
                            </div>

                            {obj.errorMessage !== null ? (
                              <div className={styles.pOne}>
                                <div style={{ textAlign: 'start' }}>
                                  <span className={styles.titleTexthide}>{obj.errorMessage}</span>
                                </div>
                              </div>
                            ) : (
                              <div className={styles.pOne}>
                                <div style={{ width: '13rem', textAlign: 'start' }}>
                                  <span className={styles.titleText}>File size:</span>
                                  <span className={styles.titleValue}> {obj.fileSize.toFixed(1)} kb</span>
                                </div>
                                <div className={styles.status}>
                                  <span className={styles.titleText}>Status:</span>
                                  <span className={styles.titleValue}> {obj.status1}</span>
                                </div>
                                <div className={obj.status1 === 'Processed' ? styles.conditioned : styles.pbTwo}>
                                  <HorizontalProgressBar value={obj.progressVal2} />
                                </div>
                              </div>
                            )}
                          </div>

                          {obj.progressVal2 === 100 ? (
                            <div className={styles.checkIconOuter}>
                              <img src={CheckIcon} alt="check" style={{ position: 'absolute' }} />
                            </div>
                          ) : (
                            <div className={obj.errorMessage === null ? styles.circleOuterStyle1 : styles.circleOuterStyle}>
                              <img src={CrossIcon} alt="close" />
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div>
                <div className={styles.fileOuterWrapper} onDrop={fileDrop} onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave}>
                  <div className={styles.fileOuterWrapperFlex} onClick={handleSelectFile}>
                    <img src={UploadFileIcon} alt={UploadFileIcon} style={{ height: '5rem' }} />
                    <div className="ml-2">
                      <h3 className={[styles.uploadFileText, 'mt-0'].join(' ')}>Drop files here or Click to upload</h3>
                      <span className={styles.uploadFileTypeText}>Accepted file type: PDF, XML, PNG, TIF, TIFF and DOCX</span>
                    </div>
                  </div>
                  <input style={{ display: 'none' }} type="file" ref={fileInput} accept=".pdf, .xml, .png, .tif, .tiff, .docx" onChange={handleFileChange} multiple value={''} />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button className={[styles.addButton, 'fsa-btn upload-btn float-right'].join(' ')} onClick={handleSelectFile}>
                    <img src={AddButton} alt="add-more"></img>&nbsp; Add more
                  </button>
                  <input style={{ display: 'none' }} type="file" ref={fileInput} accept=".pdf, .xml, .png, .tif, .tiff, .docx" onChange={handleFileChange} multiple value={''} />
                </div>
              </div>
            </div>
          ) : (
            <div onMouseEnter={(event) => TriggerPointFirefoxFunc(event)} className={styles.uploadBody}>
              {/* Content of the modal body */}
              <div className={fileloading === true ? styles.blurredDraggableSection : styles.draggableSection}>
                <div className={styles.uploadBodyContainer}>
                  <ul className={styles.noBullets}>
                    {filesData?.map((obj, index) => {
                      return (
                        <li className={styles.uploadBodyList} key={index}>
                          <div>
                            <img src={UploadedFileIconBlack} alt="uploaded-file-black"></img>
                          </div>
                          <div style={{ width: '82%' }}>
                            <div className={styles.pOne}>
                              <div title={obj.fileName} className={styles.fileName}>
                                {obj.fileName}
                              </div>

                              <div className={styles.status}>
                                <span className={styles.titleText}>Status:</span>
                                <span className={styles.titleValue}> {obj.status}</span>
                              </div>

                              <div className={styles.pbOne}>
                                <HorizontalProgressBar value={obj.progressVal} />
                              </div>
                            </div>

                            {isPackagerMode === false && (
                              <div className={styles.pOne}>
                                <div style={{ width: '13rem', textAlign: 'start' }}>
                                  <span className={styles.titleText}>File size:</span>
                                  <span className={styles.titleValue}> {obj.fileSize.toFixed(1)} kb</span>
                                </div>
                                <div className={styles.status}>
                                  <span className={styles.titleText}>Status:</span>
                                  <span className={styles.titleValue}> {obj.status1}</span>
                                </div>
                                <div className={obj.status1 === 'Processed' ? styles.conditioned : styles.pbTwo}>
                                  <HorizontalProgressBar value={obj.progressVal2} />
                                </div>
                              </div>
                            )}
                          </div>

                          {obj.progressVal2 === 100 ? (
                            <div className={styles.checkIconOuter}>
                              <img src={CheckIcon} alt="check" style={{ position: 'absolute' }} />
                            </div>
                          ) : (
                            <div className={obj.errorMessage === null ? styles.circleOuterStyle1 : styles.circleOuterStyle}>
                              <img src={CrossIcon} alt="cross" />
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div>
                <div className={styles.fileOuterWrapper} onDrop={fileDrop} onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave}>
                  <div className={styles.fileOuterWrapperFlex} onClick={handleSelectFile}>
                    <img src={UploadFileIcon} alt={UploadFileIcon} style={{ height: '5rem' }} />
                    <div className="ml-2">
                      <h3 className={[styles.uploadFileText, 'mt-0'].join(' ')}>Drop files here or Click to upload</h3>
                      <span className={styles.uploadFileTypeText}>Accepted file type: PDF, XML, PNG, TIF, TIFF and DOCX</span>
                    </div>
                  </div>
                  <input style={{ display: 'none' }} type="file" ref={fileInput} accept=".pdf, .xml, .png, .tif, .tiff, .docx" onChange={handleFileChange} multiple value={''} />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button className={[styles.addButton, 'fsa-btn upload-btn float-right'].join(' ')} onClick={handleSelectFile}>
                    <img src={AddButton} alt="add-more"></img>&nbsp; Add more
                  </button>
                  <input style={{ display: 'none' }} type="file" ref={fileInput} accept=".pdf, .xml, .png, .tif, .tiff, .docx" onChange={handleFileChange} multiple value={''} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SuspenseQueuePopup;

const HorizontalProgressBar = ({ value }) => {
  let [val, setVal] = useState(value);

  useEffect(() => {
    isNaN(value) === true ? setVal(0) : setVal(value);
  }, [value]);

  return (
    <>
      {val !== null && val !== undefined && (
        <>
          <div className={`${styles.progressBarContainer} ${styles.truncatedTextConfidenceBar}`} title={val}>
            <div
              className={styles.progressBars}
              style={{
                width: val + '%',
                backgroundColor: '#6FCF97',
              }}
            ></div>
          </div>
        </>
      )}
    </>
  );
};
