import React, { useEffect, useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './UsersTable.module.scss';
import '../../../GlobalStyle.scss';
// import TableUsersTable from './TableUsersTable';
import UsersTablesData from './UsersTable.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import AsyncSelect from 'react-select/async';
import { createUsersTable, deleteUsersTable, searchUsersTable, updateUsersTable, getRoles, getDefaultRoles } from './UsersTableService';
//Reusable Components Start
// import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
// import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
// import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
// import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
// import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
// import Footer from '../../../newcomponents/Footer/Footer';
import { handleIsRequiredError, handleMaxLimitError, handleMinLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import EyeOpenIcon from '../../../fsa-style/img/svgs/eyeOpenIcon.svg';
import EyeCloseIcon from '../../../fsa-style/img/svgs/eyeCloseIcon.svg';

//Reusable Components End

var _ = require('lodash');
const userContextId = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Users', link: '' },
];

const TableUsersTable = lazy(() => import('./TableUsersTable'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function UsersTable() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contextId: userContextId,
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    isActive: true,
    isSuperUser: false,
    roles: [],
    password: '',
  });

  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [UsersTableDetails, setUsersTableData] = useState(UsersTablesData);
  const [userRole, setUserRole] = useState(JSON.parse(sessionStorage.getItem('user_information'))?.rolesName);
  const [inputDisabled, setInputDisabled] = useState(false);

  async function fetchUsersTableData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchUsersTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setUserRole(userRole);
  }, [userRole]);

  useEffect(() => {
    setLoading(true);
    // fetchUsersTableData();
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchUsersTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: userContextId,
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      isActive: true,
      isSuperUser: false,
      roles: [],
      password: '',
    });
    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      userId: DeleteData.userId,
    };

    deleteUsersTable(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchUsersTableData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('User deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      userName: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      isActive: data.isActive,
      isSuperUser: data.isSuperUser,
      roles: data.roles,
      userId: data.userId,
      password: '',
    });
    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearchText(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchUsersTable(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newUsersTableData = JSON.parse(JSON.stringify(UsersTableDetails));
    newUsersTableData.forEach((itm) => {
      itm.sortType = '';
    });
    setUsersTableData(newUsersTableData);
    await fetchNewUsersTableData();
  };

  // Code needs to change
  const fetchNewUsersTableData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchUsersTable(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchUsersTable(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.usersTablePage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.usersTablePageContent}>
        <h1 className={styles.pageTitle}>Users</h1>
        <div>
          <div className={styles.searchSection}>
            <div className={styles.keywordSearchTitle}>Keyword Search</div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                ></SearchAdminPanelComponent>
              </div>
              <div>{userRole?.includes('Admin') && <CustomButton title="Create User" className={styles.createRecordBtnStyle} onClick={createNewFunc} />}</div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.usersTableTable, 'glbUsersTableTable'].join(' ')}>
          <TableUsersTable
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={UsersTableDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
            userRole={userRole}
          />
        </div>
        <CreateEditUsersTable
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchUsersTableData={fetchUsersTableData}
          userRole={userRole}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            popupTitle={'User'}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditUsersTable = (props) => {
  const loggedInUserName = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'))?.userName;
  const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

  const [isSuperUser, setIsSuperUser] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      maxHeight: '80px',
      minHeight: '80px',
      overflowY: 'auto',
      padding: '0px 8px',
      alignItems: 'flex-start',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
      alignItems: 'flex-start',
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position:'absolute',
      top: '10%',
    }),
  };
  const customStyles2 = {
    control: (base) => ({
      ...base,
      height: '100%',
      minHeight: '100%',
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      overflowY: 'auto',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };

  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: userContextId,
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  //Get Roles
  const loadRolesList = async (inputValue, callback) => {
    if (inputValue === '') {
      // callback([]);
      await getDefaultRoles(inputValue).then(async (response) => {
        let options = [];
        await response.data.map((res) => {
          options.push({ label: res.roleName, value: res.roleName });
          setTimeout(() => {
            callback(options);
          }, 1000);
        });
        // props.setFormData((prevData) => ({
        //   ...prevData,
        //   roles: options,
        // }));
        callback(options);
      });
    } else {
      await getRoles(inputValue).then(async (response) => {
        let options = await response.data.map((res) => {
          return { label: res.roleName, value: res.roleName };
        });
        // props.setFormData((prevData) => ({
        //   ...prevData,
        //   roles: options,
        // }));
        callback(options);
      });
    }
  };

  // Define the onChangeRoles function
  const onChangeRoles = (selectedOptions) => {
    if (!selectedOptions) {
      selectedOptions = [];
    }
    // const updatedRoles = selectedOptions.map((option) => option.value);
    // const rolesString = updatedRoles.join(', ');
    // let list = selectedOptions;
    // if (list.filter(e => e.value === 'Admin').length > 0) {
    //   setIsSuperUser(true);
    // }
    // else {
    //   setIsSuperUser(false);

    // }

    props.setFormData((prevData) => ({
      ...prevData,
      roles: selectedOptions.map((role) => role.value),
    }));
  };

  const [isRequiredError, setisRequiredError] = useState(false);
  useEffect(() => {
    setisRequiredError(false);
    setPasswordVisible(false);

    return () => {
      // Reset default value when component unmounts
      returnValues();
    };
  }, [props.formData, props.isNew]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };

  const handleInputUserName = (event) => {
    const { name, value } = event.target;
    const removeSpace = value.replace(/\s/g, '');
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: removeSpace.trimStart(),
    }));
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        !props.formData.userName ||
        props.formData.userName?.length > 50 ||
        !props.formData.firstName ||
        props.formData.firstName?.length > 30 ||
        !props.formData.email ||
        !props.formData.lastName ||
        props.formData.lastName?.length > 150 ||
        props.formData.roles.length === 0 ||
        !props.formData.password ||
        props.formData.password?.length < 20
      ) {
        setisRequiredError(true);
        return;
      }

      let payload = _.cloneDeep(props.formData);

      payload.userName = payload?.userName?.trim();
      payload.firstName = payload?.firstName?.trim();
      payload.lastName = payload?.lastName?.trim();
      payload.email = payload?.email?.trim();
      payload.password = payload?.password?.trim();

      payload.roles = props.formData.roles?.map((item) => ({
        roleName: item,
      }));
      payload.contextId = sessionStorage.getItem('user_information') !== 'undefined' && JSON.parse(sessionStorage.getItem('user_information'))?.contextId;

      // BAS-3237 Need to remove general user role from default role
      // if (payload.roles.length === 0) {
      //   payload.roles = [
      //     {
      //       roleName: 'General User',
      //     },
      //   ];
      // }

      props.isLoading(true);
      await createUsersTable(payload)
        .then((response) => {
          if (response.data) {
            props.fetchUsersTableData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('User created successfully');

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            setIsSuperUser(false);
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = async () => {
    if (
      // !props.formData.contextId ||
      // props.formData.contextId?.length > 50 ||
      !props.formData.userName ||
      props.formData.userName?.length > 50 ||
      !props.formData.firstName ||
      props.formData.firstName?.length > 30 ||
      !props.formData.email ||
      !props.formData.lastName ||
      props.formData.lastName?.length > 150 ||
      props.formData.roles.length === 0
    ) {
      setisRequiredError(true);
      return;
    }
    let payload = _.cloneDeep(props.formData);

    payload.userName = payload?.userName?.trim();
    payload.firstName = payload?.firstName?.trim();
    payload.lastName = payload?.lastName?.trim();
    payload.email = payload?.email?.trim();

    payload.roles = props.formData.roles?.map((item) => ({
      roleName: item,
    }));
    delete payload.password;

    props.isLoading(true);
    await updateUsersTable(payload).then((response) => {
      if (response.data) {
        props.setIsSuccessGrowlHidden(false);
        props.setMessage('User updated successfully');

        if (props?.formData?.userName === loggedInUserName) {
          userInformation.rolesName = props.formData?.roles;
          sessionStorage.setItem('user_information', JSON.stringify(userInformation));
        }

        setTimeout(() => {
          props.setIsSuccessGrowlHidden(true);
        }, 3000);

        props.fetchUsersTableData();
        props.setRecordModalShow(false);
        props.isLoading(false);
      } else if (response.error) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        props.isLoading(false);
      }
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const returnValues = async () => {
    if (props?.formData?.roles?.length > 0) {
      return props?.formData?.roles.map((role) => ({
        label: role,
        value: role,
      }));
    }
  };
  const firstInputRef = useRef(null);
  const firstNameInputRef = useRef(null);
  const userNameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const lastNameInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.usersTableModal, 'glbUsersTableModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={() => {
        props.onHide();
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create User' : 'Edit User'}</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.usersTableModalBody}>
          <div className={styles.modalBodySection}>
            {!props.isNew && (
              <div className={styles.inputFieldSection}>
                <div className={[styles.inputFieldInternalDiv, 'col-12'].join(' ')}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles2}
                    isClearable
                    isDisabled={props.isNew ? false : true}
                    // loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : { label: '---Select---' }}
                    onChange={onChangeContextId}
                  />
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
              </div>
            )}

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>User Name *</label>
                <input
                  className={props.isNew ? '' : styles.disabledInput}
                  disabled={props.isNew ? false : true}
                  name="userName"
                  value={props.formData.userName}
                  onChange={handleInputUserName}
                  ref={props.isNew === true ? firstInputRef : userNameInputRef}
                  onFocus={() => handleFocus(userNameInputRef)}
                />
                {(props.formData.userName === null || props.formData.userName === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.userName?.length > 50 && handleMaxLimitError(50)}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>First Name *</label>
                <input
                  ref={props.isNew === false ? firstInputRef : firstNameInputRef}
                  onFocus={() => handleFocus(firstNameInputRef)}
                  name="firstName"
                  value={props.formData.firstName}
                  onChange={handleInputChange}
                  disabled={props.userRole?.includes('Admin') ? false : true}
                />
                {(props.formData.firstName === null || props.formData.firstName === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.firstName?.length > 30 && handleMaxLimitError(30)}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Email *</label>
                <input
                  ref={emailInputRef}
                  onFocus={() => handleFocus(emailInputRef)}
                  className={props.isNew ? '' : styles.disabledInput}
                  name="email"
                  value={props.formData.email}
                  onChange={handleInputChange}
                  disabled={props.isNew ? false : true}
                />
                {(props.formData.email === null || props.formData.email === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {/* {props.formData.email?.length > 30 && handleMaxLimitError(30)} */}
              </div>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <label>Last Name *</label>
                <input ref={lastNameInputRef} onFocus={() => handleFocus(lastNameInputRef)} name="lastName" value={props.formData.lastName} onChange={handleInputChange} disabled={props.userRole?.includes('Admin') ? false : true} />
                {(props.formData.lastName === null || props.formData.lastName === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                {props.formData.lastName?.length > 150 && handleMaxLimitError(150)}
              </div>
            </div>
            <div className={styles.inputFieldSection}>
              {props.isNew && (
                <div className={[styles.inputFieldInternalDiv, styles.passwordContainer, 'col-6 justify-content-start mt-0'].join(' ')}>
                  <label>Password *</label>
                  <input ref={passwordInputRef} onFocus={() => handleFocus(passwordInputRef)} name="password" value={props.formData.password} onChange={handleInputChange} type={passwordVisible ? 'text' : 'password'} />

                  <span className={[styles.togglePassword].join(' ')} style={isRequiredError === true ? { top: '23%' } : { top: '23%' }} onClick={togglePasswordVisibility}>
                    {passwordVisible ? (
                      <img className={styles.passwordVisiblityIcon} alt="EyeOpenIcon" src={EyeOpenIcon} style={{ position: 'relative', top: '15px', width: '3rem' }} />
                    ) : (
                      <img className={styles.passwordVisiblityIcon} alt="EyeCloseIcon" src={EyeCloseIcon} style={{ position: 'relative', top: '15px', width: '3rem' }} />
                    )}
                  </span>

                  {props.isNew && (props.formData.password === null || props.formData.password === '') && isRequiredError === true && <div>{handleIsRequiredError()}</div>}
                  {isRequiredError === true && props.formData.password.length > 0 && props.formData.password?.length < 20 && handleMinLimitError(20)}
                </div>
              )}

              <div className={[styles.inputFieldInternalDiv, props.isNew === true ? 'col-6' : 'col-12'].join(' ')}>
                <label>Roles *</label>
                <AsyncSelect
                  isMulti
                  styles={customStyles}
                  isClearable
                  defaultOptions
                  loadOptions={loadRolesList}
                  value={props?.formData?.roles.map((role) => ({
                    label: role,
                    value: role,
                  }))}
                  onChange={onChangeRoles}
                />
                {(props.formData.roles === null || props.formData.roles === '' || props.formData.roles.length === 0) && isRequiredError === true && (
                  <div>{handleIsRequiredError()}</div>
                )}
              </div>
            </div>

            <div className={styles.inputFieldSection}>
              <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                <div className={styles.radioInputOuterDiv}>
                  <div className="col-6 pl-0">Is Active: </div>
                  <div className="col-6" style={{ display: 'flex' }}>
                    <input
                      type="radio"
                      name="isActive"
                      value={true}
                      checked={props.formData.isActive === true}
                      onChange={handleRadioChange}
                      disabled={props.isNew === true ? true : props.userRole?.includes('Admin') ? false : true}
                    />{' '}
                    True
                    <input
                      type="radio"
                      name="isActive"
                      value={false}
                      checked={props.formData.isActive === false}
                      onChange={handleRadioChange}
                      disabled={props.isNew === true ? true : props.userRole?.includes('Admin') ? false : true}
                    />{' '}
                    False
                  </div>
                </div>
              </div>
              {props?.formData?.roles?.includes('Admin') &&
                sessionStorage.getItem('user_information') !== 'undefined' &&
                JSON.parse(sessionStorage.getItem('user_information'))?.superUser && (
                  <div className={[styles.inputFieldInternalDiv, 'col-6'].join(' ')}>
                    <div className={styles.radioInputOuterDiv}>
                      <div className="col-6 pl-0">Is Superuser: </div>
                      <div className="col-6" style={{ display: 'flex' }}>
                        <input type="radio" name="isSuperUser" value={true} checked={props.formData.isSuperUser === true} onChange={handleRadioChange} />
                        True
                        <input type="radio" name="isSuperUser" value={false} checked={props.formData.isSuperUser === false} onChange={handleRadioChange} />
                        False
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.usersTableFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            {props.isNew ? (
              <CustomButton title="Save" className={styles.saveRecordBtn} onClick={handleSubmit} />
            ) : (
              <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
            )}
            <CustomButton
              title="Cancel"
              className={styles.cancelRecordBtn}
              onClick={() => {
                props.setRecordModalShow(false);
                setisRequiredError(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
