import React, { useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import TransferForm from './TransferForm';
import ObjectsInTransfer from './ObjectsInTransfer';
export default function EditTransfer(props) {
  const { onClose, existingTransfer, onSubmit, onUpdate } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const onTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  return (
    <TabStrip tabContentStyle={{ width: '100%', paddingLeft: 0, paddingRight: 0 }} selected={selectedTab} onSelect={onTabSelect} className="editTransferTab">
      <TabStripTab title="Objects in Transfer">
        <ObjectsInTransfer onClose={onClose} transferObject={existingTransfer} />
      </TabStripTab>

      <TabStripTab title="Transfer Details">
        <TransferForm existingTransfer={existingTransfer} onClose={onClose} onSubmit={onSubmit} onUpdate={onUpdate} />
      </TabStripTab>

      {/* <TabStripTab title="Logs"></TabStripTab> */}
    </TabStrip>
  );
}
