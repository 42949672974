import axios from 'axios';

export async function savePermissionTable(data) {
  let createResponse = [];
  console.log(data)
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/elementgroupxwalk/save';
    await axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        createResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.response.data);
    createResponse.error = ex.response.data;
  }
  // console.log(createResponse);
  return createResponse;
}
// '/buow_api/elementgroupxwalk/get'
export async function updatePermissionTable(data) {
  let updateResponse = [];
  try {
    const url =
    window.env.REACT_APP_BACKEND_URL  +
      '/buow_api/elementgroupxwalk' 
    await axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        updateResponse = response;
      });
  } catch (ex) {
    console.log('Error in scanOnDemandService.js claimJob()', ex.message);
    updateResponse.error = ex.response.data;
  }
  return updateResponse;
}

export async function deletePermissionTable(data) {
  let deleteResponse = [];
  try {
    const url =
      `${window.env.REACT_APP_BACKEND_URL}/buow_api/elementgroupxwalk` +
      `/${data.id}`;
    await axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        deleteResponse = response;
      });
  } catch (ex) {
    console.log('Error', ex.message);
    deleteResponse.error = ex.response.data;
  }
  return deleteResponse;
}

export async function searchPermissionTable(data) {
  let workQueueFilterResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + '/buow_api/elementgroupxwalk/';
    if (data.text !== '' && data.column === '' && data.order === '') {
      url += `search?contextId=${data.contextId}&keywords=` + data.text;
    } else {
      url += `search?contextId=${data.contextId}&keywords=` + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    }

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}
export async function getAllEleGroups(data) {
  let workQueueFilterResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + `/buow_api/elementgroupxwalk/get?contextId=${data?.contextId}&elementGroupId=${data?.elementGroupId}`;
    
      // url += `search?contextId=${data.contextId}&keywords=` + data.text + '&sortOn=' + data.column + '&sortOrder=' + data.order;
    

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function searchElementName(data) {
  console.log('data', data);
  let workQueueFilterResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + `/buow_api/elements/name/?contextId=${data.contextId}&keywords=${data.inputValue}`;

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function searchGroupName(data) {
  console.log('data', data);
  let workQueueFilterResponse = [];
  try {
    let url = window.env.REACT_APP_BACKEND_URL + `/buow_api/elementgroup/groupname/?contextId=${data.contextId}&keywords=${data.inputValue}`;

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get messages ', ex.message);
  }
  return workQueueFilterResponse;
}