import React, { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { keywordSearchFileCodes } from '../../services/recordsManagementService';

export default function FcKeywordSearch(props) {
  const { setFileCodeData, onSearch } = props;
  const [keywords, setKeywords] = useState('');

  const handleKeywordChange = (e) => {
    setKeywords(e.target.value);
  };

  const handleKeywordSearch = async () => {
    let payload = {};
    if (keywords !== '') payload.keywords = keywords;
    keywordSearchFileCodes(payload).then((response) => {
      setFileCodeData(response.data);
      onSearch();
    });
  };

  return (
    <div className="formRow">
      <div className="form-field" style={{ width: '30%', marginRight: '1.5rem' }}>
        <label className="fsa-field__label" htmlFor="keywordSearch">
          Keyword Search:
          <br />
        </label>
        <Input id="keywordSearch" name="keywordSearch" value={keywords} onChange={handleKeywordChange} className="fsa-input fc-keyword-input" />
      </div>
      <div className="form-field">
        <button className="fsa-btn" onClick={handleKeywordSearch}>
          Search
        </button>
      </div>
    </div>
  );
}
