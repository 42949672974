import React, { useEffect, useState } from 'react';
import { Field, FieldWrapper, Form } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import Table from '../../Table/Table';
import UpdateObjectTransferFields from './UpdateObjectTransferFields.json';
import '../../../GlobalStyle.scss';
// import { getTransfer, searchTransferData } from '../../../services/transferServices';

export default function UpdateObjectTransferForm(props) {
  const { onClose, onSubmit, onSearch } = props;
  const initialData = {
    username: '',
    transferName: '',
    agencyTransferNumber: '',
    NARATransferNumber: '',
    fileCode: '',
    holdNumber: '',
  };
  const [formData, setFormData] = useState(initialData);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [selectedTransfer, setSelectedTransfer] = useState(null);
  const [transferData, setTransferData] = useState(props.data);

  const handleFieldChange = (e, fieldName) => {
    const { value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    const newPage = page.skip / page.take;
    setPage(newPage);
  };

  const onRowClick = (e) => {
    setSelectedTransfer(e);
  };
  const resetForm = () => {
    const initialData = {
      username: '',
      transferName: '',
      agencyTransferNumber: '',
      NARATransferNumber: '',
      fileCode: '',
      holdNumber: '',
    };
    setFormData(initialData);

    onSearch(initialData);

    setReset((prev) => !prev);
  };
  useEffect(() => {
    const initialData = {
      username: '',
      transferName: '',
      agencyTransferNumber: '',
      NARATransferNumber: '',
      fileCode: '',
      holdNumber: '',
    };
    setFormData(initialData);
  }, [reset]);
  const handleSearch = async (searchData) => {
    onSearch(searchData); // Pass the search data to the parent component
  };

  const onSubmitForm = () => {
    onSubmit(selectedTransfer);
  };
  useEffect(() => {
    setTransferData(props.data);
  }, [props.data]);

  return (
    <Form
      key={reset}
      initialValues={formData}
      onSubmitClick={handleSearch}
      render={(formRenderProps) => {
        return (
          <div className={'row'}>
            <div className={'col-3 searchTransfer'}>
              <div className={'row'}>
                <div className={'col-12'}>
                  <h3 style={{ marginTop: 0 }} className="transferHeading">
                    Search Transfer
                  </h3>
                  {/* <div className="fsa-divider"/> */}
                </div>
              </div>
              <div className={'row mt-3'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label">Username</label>
                  {/* <Field name={'username'} className="fsa-input fsa-field__item" component={Input} /> */}
                  <Field
                    name={'username'}
                    className="fsa-input fsa-field__item"
                    component={Input}
                    value={formData.username}
                    onChange={(e) => {
                      handleFieldChange(e, 'username');
                    }}
                  />
                </FieldWrapper>
              </div>
              <div className={'row mt-3'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label">Transfer Name</label>
                  {/* <Field name={'transferName'} className="fsa-input fsa-field__item" component={Input} /> */}
                  <Field
                    name={'transferName'}
                    className="fsa-input fsa-field__item"
                    component={Input}
                    value={formData.transferName}
                    onChange={(e) => {
                      handleFieldChange(e, 'transferName');
                    }}
                  />
                </FieldWrapper>
              </div>
              <div className={'row mt-3'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label">Agency Transfer Number</label>
                  {/* <Field name={'agencyTransferNumber'} className="fsa-input fsa-field__item" component={Input} /> */}
                  <Field
                    name={'agencyTransferNumber'}
                    className="fsa-input fsa-field__item"
                    component={Input}
                    value={formData.agencyTransferNumber}
                    onChange={(e) => {
                      handleFieldChange(e, 'agencyTransferNumber');
                    }}
                  />
                </FieldWrapper>
              </div>
              {/* <div className={'row mt-3'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label">NARA Provided Transfer Number</label>
                  <Field name={'NARATransferNumber'} className="fsa-input fsa-field__item" component={Input} />
                  <Field
                    name={'NARATransferNumber'}
                    className="fsa-input fsa-field__item"
                    component={Input}
                    value={formData.NARATransferNumber}
                    onChange={(e) => {
                      handleFieldChange(e, 'NARATransferNumber');
                    }}
                  />
                </FieldWrapper>
              </div> */}
              {/* <div className={'row mt-3'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label">File Code</label>
                  <Field name={'fileCode'} className="fsa-input fsa-field__item" component={Input} />
                  <Field
                    name={'fileCode'}
                    className="fsa-input fsa-field__item"
                    component={Input}
                    value={formData.fileCode}
                    onChange={(e) => {
                      handleFieldChange(e, 'fileCode');
                    }}
                  />
                </FieldWrapper>
              </div> */}
              <div className={'row mt-3'}>
                <FieldWrapper className={'col-12'}>
                  <label className="fsa-field__label">Hold Number</label>
                  {/* <Field name={'holdNumber'} className="fsa-input fsa-field__item" component={Input} /> */}
                  <Field
                    name={'holdNumber'}
                    className="fsa-input fsa-field__item"
                    component={Input}
                    value={formData.holdNumber}
                    onChange={(e) => {
                      handleFieldChange(e, 'holdNumber');
                    }}
                  />
                </FieldWrapper>
              </div>
              <div className={'row mt-4'}>
                <div style={{ display: 'flex' }} className={'col-12 justify-content-center'}>
                  <button className="fsa-btn" onClick={formRenderProps.onSubmit}>
                    Search
                  </button>
                  <button className="fsa-btn lite-btn" type="reset" onClick={resetForm}>
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className={'col-9 holdTable'}>
              <div className={'row'}>
                <div className={'col-12'}>
                  <h3 style={{ marginTop: 0 }} className="transferSearchHeading">
                    Select a Transfer
                  </h3>
                  {/* <div className="fsa-divider"/> */}
                </div>
              </div>
              <div className={'row mt-3'}>
                <div className={'glbSearchTransferModal col-12'}>
                  <Table
                    data={transferData.slice(page * pageSize, page * pageSize + pageSize)}
                    selectType={'radio'}
                    fields={UpdateObjectTransferFields}
                    onRowClick={onRowClick}
                    onPageChange={onPageChange}
                    page={page}
                    pageSize={pageSize}
                    total={transferData.length}
                  />
                </div>
              </div>
              <div className={'row mt-4'}>
                <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                  <button onClick={onSubmitForm} className="fsa-btn" disabled={selectedTransfer === null || Object.keys(selectedTransfer).length <= 0}>
                    Update
                  </button>
                  <button onClick={onClose} className="fsa-btn lite-btn">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
