import React, { useContext } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import axios from 'axios';
import { Field, FieldWrapper, Form } from '@progress/kendo-react-form';
import PrimaryOrganizationDropDownList from './FormModules/PrimaryOrganizationDropDownList';
import SecondaryOrganizationDropDownList from './FormModules/SecondaryOrganizationDropDownList';
import FormTextArea from './FormModules/FormTextArea';
import ScheduleNameDropDownList from './FormModules/ScheduleNameDropDownList';
import RecordTypeDropDownList from './FormModules/RecordTypeDropDownList';
import VitalRadioGroup from './FormModules/VitalRadioGroup';
import ProgramStatusRadioGroup from './FormModules/ProgramStatusRadioGroup';
import FileCodeStatusRadioGroup from './FormModules/FileCodeStatusRadioGroup';
import SchedulingStatusDropDownList from './FormModules/SchedulingStatusDropDownList';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
// import { useNotificationContext } from "../Notification/NotificationProvider";
import UserContext from '../UserContext/UserContext';

export default function FileCodeAdvSearch(props) {
  const { setFileCodeData, initialValues = {}, setInitialValues, onClose, onSearch } = props;
  const { userInfo } = useContext(UserContext);
  // const {addNotification} = useNotificationContext();

  const onSubmitSearch = (search) => {
    const data = omitBy(search.values, (v) => isEmpty(v) && typeof v !== 'boolean');
    setInitialValues(data);
    axios
      .post('/recordsmanagementapi/records-management/filecodes/search?param=getFilecodesWithPredicate', data, {
        headers: {
          'Content-Type': 'application/json',
          userName: userInfo.userName,
          usdaeauthid: userInfo.usdaeauthid,
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
        },
      })
      .then((response) => {
        setFileCodeData(response.data);
        onSearch();
        onClose();
      })
      .catch((err) => {
        console.error(err);
        // addNotification({style: 'error', msg: err.message})
      });
  };

  // This is just for displaying current values. This does not change the initial values.
  const onResetForm = ({ onChange }) => {
    onChange('primaryorg', { value: '' });
    onChange('secondaryorg', { value: '' });
    onChange('filecodeName', { value: '' });
    onChange('filecodeId', { value: '' });
    onChange('recordtitle', { value: '' });
    onChange('primarydescription', { value: '' });
    onChange('secondarydescription', { value: '' });
    onChange('keywords', { value: '' });
    onChange('schedule', { value: '' });
    onChange('recordtype', { value: '' });
    onChange('instructions', { value: '' });
    onChange('vital', { value: '' });
    onChange('systemoforigin', { value: '' });
    onChange('actreference', { value: '' });
    onChange('dispositionAuthority', { value: '' });
    onChange('programstatus', { value: '' });
    onChange('filecodestatus', { value: '' });
    onChange('schedulestatus', { value: '' });
    onChange('filecodeComments', { value: '' });
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmitClick={onSubmitSearch}
      render={(fieldRenderProps) => {
        return (
          <div>
            <div className="row">
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Primary Organization</label>
                <Field name={'primaryorg'} className="fsa-input fsa-field__item" component={PrimaryOrganizationDropDownList} />
              </FieldWrapper>
              <FieldWrapper className={'col-6'}>
                <label className="fsa-field__label">Secondary Organization</label>
                <Field name={'secondaryorg'} className="fsa-input fsa-field__item" component={SecondaryOrganizationDropDownList} />
              </FieldWrapper>
              {/* <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Schedule ID</label>
                <Field
                  name={'filecodeName'}
                  className="fsa-input fsa-field__item"
                  component={Input}
                />
              </FieldWrapper> */}
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">File Code</label>
                <Field name={'filecodeId'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Record Title</label>
                <Field name={'recordtitle'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
              <FieldWrapper className={'col-9'}>
                <label className="fsa-field__label">Primary Records Description</label>
                <Field name={'primarydescription'} className="fsa-input fsa-field__item" component={FormTextArea} />
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-6'}>
                <label className="fsa-field__label">Secondary Records Description</label>
                <Field name={'secondarydescription'} className="fsa-input fsa-field__item" component={FormTextArea} />
              </FieldWrapper>
              <FieldWrapper className={'col-6'}>
                <label className="fsa-field__label">Keywords</label>
                <Field name={'keywords'} className="fsa-input fsa-field__item" component={FormTextArea} />
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Retention Instructions</label>
                <Field name={'schedule'} className="fsa-input fsa-field__item" component={ScheduleNameDropDownList} validationMessage={'required'} />
              </FieldWrapper>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Record Type</label>
                <Field name={'recordtype'} className="fsa-input fsa-field__item" component={RecordTypeDropDownList} />
              </FieldWrapper>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Physical Record Instructions</label>
                <Field name={'instructions'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Vital</label>
                <Field name={'vital'} className="fsa-input fsa-field__item" component={VitalRadioGroup} />
              </FieldWrapper>
            </div>

            <div className={'row mt-3'}>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">System of Origin</label>
                <Field name={'systemoforigin'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Act Reference</label>
                <Field name={'actreference'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Disposition Authority</label>
                <Field name={'dispositionAuthority'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Program Status</label>
                <Field name={'programstatus'} className="fsa-input fsa-field__item" component={ProgramStatusRadioGroup} />
              </FieldWrapper>
            </div>

            <div className="row mt-3">
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">File Code Status</label>
                <Field name={'filecodestatus'} className="fsa-input fsa-field__item" component={FileCodeStatusRadioGroup} />
              </FieldWrapper>
              <FieldWrapper className={'col-3'}>
                <label className="fsa-field__label">Record Series Status</label>
                <Field name={'schedulestatus'} className="fsa-input fsa-field__item" component={SchedulingStatusDropDownList} />
              </FieldWrapper>
              <FieldWrapper className={'col-6'}>
                <label className="fsa-field__label">Record Series Comments</label>
                <Field name={'filecodeComments'} className="fsa-input fsa-field__item" component={Input} />
              </FieldWrapper>
            </div>

            <div className="row mt-5">
              <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                <button className="fsa-btn" onClick={fieldRenderProps.onSubmit}>
                  Search
                </button>
                <button onClick={() => onResetForm(fieldRenderProps)} className="fsa-btn fsa-btn--secondary">
                  Clear
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
