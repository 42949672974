import React, { useEffect,useRef, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './PackagerConfiguration.module.scss';
import '../../../GlobalStyle.scss';

import PackagerConfigurationData from './PackagerConfiguration.json';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';

import AsyncSelect from 'react-select/async';
import { updateOnePackagerConfiguration, searchPackagerConfiguration } from './PackagerConfigurationService.js';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';

import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc.js';
import { getSearchContextId } from '../RoutingSteps/RoutingStepsServices.js';

const TablePackagerConfiguration = lazy(() => import('./TablePackagerConfiguration.js'));
const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl.js'));
const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb.js'));
const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl.js'));
const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton.js'));
const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent.js'));
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'Packager Configuration', link: '' },
];

const runFrequencyData = ['Constant', 'Hourly', 'Daily'];

export default function PackagerConfiguration() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ column: '', order: '' });
  const [packagerConfigurationDetails, setPackagerConfigurationData] = useState(PackagerConfigurationData);
  const [inputDisabled, setInputDisabled] = useState(false);
  const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

  const [formData, setFormData] = useState({
    runFrequency: '',
    contextId: userInformation?.contextId,
    id: '',
    activeInd: true,
  });

  const onRemoveSort = async () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    setLoading(true);
    let payload = {
      contextId: userInformation?.contextId,
      text: searchKeyword,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchPackagerConfiguration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSearch = async (searchKeyword) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      contextId: userInformation?.contextId,
      text: searchKeyword,
      column: sorting.column,
      order: sorting.order,
    };
    await searchPackagerConfiguration(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const editFunc = async (data) => {
    setFormData({
      runFrequency: data.runFrequency,
      contextId: data.contextId,
      id: data.id,
      activeInd: data?.activeInd === null || data?.activeInd === undefined ? true : data?.activeInd,
    });
    setRecordModalShow(true);
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchKeyword('');
    setSorting({ column: '', order: '' });
    await fetchNewPackagerConfigurationData();
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newPackagerConfigurationData = JSON.parse(JSON.stringify(PackagerConfigurationData));
    newPackagerConfigurationData.forEach((itm) => {
      itm.sortType = '';
    });
    setPackagerConfigurationData(newPackagerConfigurationData);
    await fetchNewPackagerConfigurationData();
  };

  // Code needs to change
  const fetchNewPackagerConfigurationData = async () => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    let payload = {
      contextId: userInformation?.contextId,
      text: '',
      column: '',
      order: '',
    };
    await searchPackagerConfiguration(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    const userInformation = sessionStorage.getItem('user_information') !== undefined && JSON.parse(sessionStorage.getItem('user_information'));

    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };
    setSorting(sortObj);
    let payload = {
      contextId: userInformation?.contextId,
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchKeyword,
    };
    await searchPackagerConfiguration(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKeyword(value.trimStart());
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchKeyword);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchKeyword.length === 0) {
      onSearch(searchKeyword);
    }
  }, [searchKeyword]);

  return (
    //Parent Component
    <div data-testid="packagerconfiguration-container" className={styles.packagerConfigurationPage}>
      {isLoading && <GlobalLoader />}
      <Breadcrumb data={breadcrumbData} />

      <div data-testid="packagerconfiguration-page-wrapper" className={styles.packagerConfigurationPageContent}>
        <h1 data-testid="packagerconfiguration-heading" className={styles.pageTitle}>
          Packager Configuration
        </h1>
        <div>
          <div data-testid="packagerconfiguration-search-container" className={styles.searchSection}>
            <div data-testid="packagerconfiguration-search-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div data-testid="packagerconfiguration-input-container" className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchKeyword}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'search-packagerconfiguration'}
                ></SearchAdminPanelComponent>
              </div>
            </div>
            <span data-testid="packagerconfiguration-help-text" className="searchNoteStyle">
              Note: Entering 3 letters enables search capabilities.
            </span>
          </div>
        </div>
        <div data-testid="packagerconfiguration-table-container" className={[styles.packagerConfigurationTable, 'glbPackagerConfigurationTable'].join(' ')}>
          <TablePackagerConfiguration
            data={data}
            page={page}
            editFunc={editFunc}
            pageSize={pageSize}
            fields={packagerConfigurationDetails}
            onPageChange={onPageChange}
            searchKeyword={searchKeyword}
            // setSelectedRows={setSelectedRows}
            setRecordModalShow={setRecordModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <EditPackagerConfigurationModal
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          fetchPackagerConfigurationData={() => onSearch(searchKeyword)}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          isLoading={setLoading}
        />

        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
    </div>
  );
}

// START:- Create / Edit record modal with functioanlity
const EditPackagerConfigurationModal = (props) => {
  const [isRequiredError, setisRequiredError] = useState(false);

  useEffect(() => {
    if (props.show === false) {
      setisRequiredError(false);
    }
  }, [props.show]);
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
    }),
    menu: (base) => ({
      ...base,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
    }),
  };

  const loadContextIdList = async (inputValue, callback) => {
    if (inputValue === '') {
    } else {
      await getSearchContextId(inputValue).then(async (response1) => {
        let options = await response1.data.map((res) => {
          return { label: res.contextId, value: res.contextId };
        });
        callback(options);
      });
    }
  };

  const loadRunFrequency = async (inputValue, callback) => {
    if (inputValue === '') {
      callback([]);
      let options = [];
      runFrequencyData.map((res) => {
        options.push({ label: res, value: res });
        setTimeout(() => {
          callback(options);
        }, 1000);
      });
      callback(options);
    } else {
      let options = await runFrequencyData.map((res) => {
        return { label: res, value: res };
      });
      callback(options);
    }
  };

  const handleEdit = async (data) => {
    if (!data.contextId || !data.runFrequency || data.contextId.length > 50) setisRequiredError(true);
    else {
      setisRequiredError(false);
      let payload = _.cloneDeep(data);
      props.isLoading(true);

      await updateOnePackagerConfiguration(payload).then((response) => {
        if (response.data) {
          props.isLoading(false);
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('Packager Configuration Updated Successfully');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);
          props.fetchPackagerConfigurationData();
          props.setRecordModalShow(false);
        } else if (response.error) {
          props.isLoading(false);
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
        }
      });
    }
  };
  const onChangeContextId = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        contextId: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      contextId: updatedValue,
    }));
  };

  const onChangeRunFrequency = (event) => {
    if (event == null)
      return props.setFormData((prevData) => ({
        ...prevData,
        runFrequency: '',
      }));
    const { value } = event;
    const updatedValue = value === '' ? null : value;
    props.setFormData((prevData) => ({
      ...prevData,
      runFrequency: updatedValue,
    }));
  };

  const handleCancel = () => {
    props.setRecordModalShow(false);
  };
  const handleModalCrossIconBtn = () => {
    props.setRecordModalShow(false);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const booleanValue = value === 'true';
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: booleanValue,
    }));
  };
const firstInputRef = useRef(null);

  // Handle modal show logic and initial focus
  useEffect(() => {
    const handleModalShown = () => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus on the first input
        if (firstInputRef.current?.style !== undefined) {
          firstInputRef.current.className = 'firstEleFocused'; // Apply the focus class
        }
      }
    };

    if (props.show === true) {
      handleModalShown();
    }
  }, [props.show]);

  // Focus handler for dynamically adding/removing class
  const handleFocus = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.className = 'firstEleFocused'; // Add the focus class
    }
  };

  return (
    <Modal
      {...props}
      className={[styles.packagerConfigurationModal, 'glbPackagerConfigurationModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      onHide={props.onHide}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>{'Edit Packager Configuration'}</h2>
            </div>
            <div className={styles.modalHeaderIcon} onClick={() => handleModalCrossIconBtn()}>
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.packagerConfigurationModalBody}>
          <div className={styles.modalBodySection}>
            <>
              <div className={styles.inputFieldSection}>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Context Id *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    isDisabled
                    loadOptions={loadContextIdList}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.contextId, value: props?.formData?.contextId } : null}
                    onChange={onChangeContextId}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && handleIsRequiredError()}
                  {props.formData.contextId.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
                <div className={styles.inputFieldInternalDiv}>
                  <label>Run Frequency *</label>
                  <AsyncSelect
                    styles={customStyles}
                    isClearable
                    loadOptions={loadRunFrequency}
                    value={props?.formData?.contextId !== '' ? { label: props?.formData?.runFrequency, value: props?.formData?.runFrequency } : null}
                    onChange={onChangeRunFrequency}
                    defaultOptions
                    ref={ firstInputRef }
                    onFocus={() => handleFocus(firstInputRef)}

                  />
                  {(props.formData.runFrequency === null || props.formData.runFrequency === '') && isRequiredError === true && handleIsRequiredError()}
                </div>
              </div>

              <div className={styles.inputFieldSection}>
                <div className={[styles.inputFieldInternalDiv, 'col-6 pl-0'].join(' ')}>
                  <div className={[styles.radioInputOuterDiv, 'mt-3'].join(' ')} data-testid="radio-function">
                    <div>Active Ind: </div>
                    <div style={{ display: 'flex' }}>
                      <input type="radio" name="activeInd" value={true} checked={props.formData.activeInd === true} onChange={handleRadioChange} />
                      True
                      <input type="radio" name="activeInd" value={false} checked={props.formData.activeInd === false} onChange={handleRadioChange} /> False
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.packagerConfigurationFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            <CustomButton title="Update" className={styles.saveRecordBtn} onClick={() => handleEdit(props.formData)} />
            <CustomButton title="Cancel" className={styles.cancelRecordBtn} onClick={() => handleCancel()} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Create / Edit record modal with functionality
