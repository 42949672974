import axios from 'axios';

export async function EditMetadata(id, data, header) {
  try {
    const userInfo = JSON.parse(sessionStorage.getItem('user_information'));
    const url = `/doc_and_metadata_submission_api/submission/v2?docId=${id}&isCustomerUpload=true`;

    const headers = {
      'Content-Type': 'application/json',
      wsstatictoken: '2c72c2e6-4faf-470f-9b4b-346f8b52de9e',
      agency_code: header.agencycode,
      applicationid: header.applicationid,
      userName: userInfo.userName || '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    };
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (ex) {
    console.error('Error in EditMetadata()', ex.message);
    throw ex;
  }
}
export async function getFileCodeById(id, header) {
  try {
    const url = `/recordsmanagementapi/records-management/filecodes?filecodeId=${id}`;

    const headers = {
      'Content-Type': 'application/json',
      wsstatictoken: '2c72c2e6-4faf-470f-9b4b-346f8b52de9e',
      // agency_code: header.agencycode,
      // applicationid: header.applicationid,
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    };
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (ex) {
    console.error('Error in EditMetadata()', ex.message);
    throw ex;
  }
}

export async function updateDispositionDate(date, data, header) {
  const userInfo = JSON.parse(sessionStorage.getItem('user_information'));

  try {
    const url = `/recordsmanagementapi/records-management/record/complete?completionDate=${date}`;

    const headers = {
      'Content-Type': 'application/json',
      wsstatictoken: '2c72c2e6-4faf-470f-9b4b-346f8b52de9e',
      agency_code: header.agencycode,
      applicationid: header.applicationid,
      userName: userInfo.name || '',
      usdaeauthid: userInfo.usdaeauthid || '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    };
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (ex) {
    console.error('Error in EditMetadata()', ex.message);
    throw ex;
  }
}

export async function deleteObject(data, header) {
  let responseObj = {};
  try {
    const url = `/doc_and_metadata_submission_api/submission/v2?deleteAllVersions=true&hardDelete=true&docId=${data.id}`;
    const headers = {
      'Content-Type': 'application/json',
      wsstatictoken: '0c7c5191-f0ed-405a-83e2-f4fe07cea66b',
      agency_code: header.agencycode,
      applicationid: header.applicationid,
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
    };
    const response = await axios.delete(url, { data: data, headers });
    responseObj.data = response;
  } catch (ex) {
    console.error('Error in EditMetadata()', ex.message);
    responseObj.error = ex.response;
  }
  return responseObj;
}

export async function fetchCsvFiles(docId) {
  let searchResponse = [];
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
  };
  try {
    const url = window.env.REACT_APP_BACKEND_URL + '/buow_api/get-document/' + docId + '/csvs';
    await axios.get(url, { headers }).then((response) => {
      searchResponse = response;
    });
  } catch (ex) {
    console.log('Error in fetchCsvFiles()', ex.message);
  }
  return searchResponse;
}
