import React, { useContext, useEffect, useState } from 'react';
import { Field, FieldWrapper, Form } from '@progress/kendo-react-form';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import TransferTypeDropDownList from './FormModules/TransferTypeDropDownList';
import TransferMediaDropDownList from './FormModules/TransferMediaDropDownList';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Hint } from '@progress/kendo-react-labels';
import ValidatedInput from '../FormModules/ValidatedInput';
// import FormDatepicker from '../FormModules/FormDatepicker';
import { requiredValidator } from '../FormModules/validators';
import UserContext from '../UserContext/UserContext';
import { handleCustomErrorMsg } from '../../utils/helper/handleReuiredErrorFunc';
import axios from 'axios';
import { GlobalLoader } from '../../newcomponents/GlobalLoader/GlobalLoader';
import SuccessProcessGrowl from '../SuspenseQueue/SuccessProcessGrowl';
import ErrorGrowl from '../SuspenseQueue/ErrorGrowl';

export default function TransferForm(props) {
  const { existingTransfer, onClose, onSubmit, onUpdate } = props;
  const { userPermissions } = React.useContext(UserContext);
  const [data, setData] = useState(
    existingTransfer || {
      description: '',
      organization: '',
      transferType: '',
      transferMedia: '',
      transferName: '',
      fullTextFile: false,
    }
  );

  const [isRequired, setIsRequired] = useState(false);

  const [fileValidation, setFileValidation] = useState(false);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedObjs, setSelectedObjs] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const { userInfo } = useContext(UserContext);

  const onFormSubmit = () => {
    if (!data.organization || !data.description || !data.transferType || !data.transferMedia || !data.transferName) {
      setIsRequired(true);

      return;
    } else if (data.transferFileSize < 0) {
      setFileValidation(true);
      return;
    } else {
      setIsRequired(false);
    }
    onSubmit(data);
  };

  const onFormUpdate = () => {
    if (!data.organization || !data.description || !data.transferType || !data.transferMedia || !data.transferName) {
      setIsRequired(true);
      return;
    } else if (data.transferFileSize < 0) {
      setFileValidation(true);
      return;
    } else {
      setIsRequired(false);
      if (!data.fullTextFile) data.fullTextFile = false;
      onUpdate(data);
    }
  };
  const updateValue = (e, name) => {
    // const trimmedValue = (e.value || '').trim();
    if (e.value > 0 && name === 'transferFileSize') {
      setFileValidation(false);
    }
    setData((prevData) => ({
      ...prevData,
      [name]: name === 'transferFileSize' || name === 'fullTextFile' ? e.value : (e.value || '').trimStart(),
    }));
  };

  useEffect(() => {
    if (existingTransfer !== null && Object.keys(existingTransfer).length > 0) {
      const url = `/doc_and_metadata_submission_api/submission/search?page=0&limit=1000`;
      const searchData = {
        searchCriteria: [
          {
            condition: 'EQUALS',
            field1: {
              name: 'doc_repo.is_latest',
              value: [true],
            },
          },
          {
            condition: 'CONTAINS',
            field1: {
              name: 'transfers.transfer_number',
              value: [existingTransfer?.transferNumber],
            },
          },
        ],
        sort: [],
        aggregation: [],
      };

      const config = {
        // TODO replace static officeId with officeId from userInfo
        headers: {
          agencyCode: userInfo.usdaagencycode[0],
          eauthid: userInfo.usdaeauthid,
          // officeId: userInfo.usdaworkofficeid[0] || null,
          username: userInfo.name,
          Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
          'Content-Type': 'application/json',
        },
      };
      setLoading(true);

      axios
        .post(url, searchData, config)
        .then((result) => {
          result.data.metadata?.map((itm) => {
            selectedObjs.push(itm._id);
          });
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, []);

  const createTransfer = async () => {
    setApiLoading(true);
    let headers = {
      userName: userInfo.name || '',
      usdaeauthid: userInfo.usdaeauthid || '',
      Authorization: 'Bearer ' + sessionStorage.getItem('userToken'),
      'Content-Type': 'application/json',
    };

    const url = '/recordsmanagementapi/records-management/transfers/execute/' + existingTransfer.id;

    await axios
      .put(url, selectedObjs, { headers })
      .then((response) => {
        setMessage('Transfer package created successfully');
        setIsSuccessGrowlHidden(false);
        // EventEmitter.emit('refreshTransferGrid');

        setTimeout(() => {
          onClose();
          setIsSuccessGrowlHidden(true);
          setApiLoading(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setMessage(err.response.data.message ? err.response.data.message : 'Something went wrong');
        setIsErrorGrowlHidden(false);

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
          setApiLoading(false);
        }, 3000);
      });
  };
  useEffect(() => {
    if (selectedObjs.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });
  return (
    <>
      {apiLoading && <GlobalLoader />}
      {loading ? (
        <GlobalLoader />
      ) : (
        <Form
          initialValues={data}
          onSubmit={onFormSubmit}
          onFormUpdate={onFormUpdate}
          render={(fieldRenderProps) => {
            const transferType = fieldRenderProps.valueGetter('transferType');

            return (
              <div style={{ padding: '1rem' }} className='glbTransferForm'>
                <div className={'row'}>
                  <FieldWrapper className={'col-12'}>
                    <label className="fsa-field__label">Dept./Agency/Branch Name*</label>
                    <Field
                      name={'organization'}
                      onChange={(e) => updateValue(e, 'organization')}
                      className="fsa-input fsa-field__item"
                      component={ValidatedInput}
                      validator={requiredValidator}
                    />
                  </FieldWrapper>
                </div>

                <div className={'row'}>
                  <FieldWrapper className={'col-12'}>
                    <label className="fsa-field__label">Transfer Name*</label>
                    <Field
                      name={'transferName'}
                      onChange={(e) => updateValue(e, 'transferName')}
                      className="fsa-input fsa-field__item"
                      component={ValidatedInput}
                      validator={requiredValidator}
                    />
                  </FieldWrapper>
                </div>

                <div className={'row mt-4'}>
                  <FieldWrapper className={'col-4'}>
                    <label className="fsa-field__label">Transfer Type*</label>
                    <Field name={'transferType'} onChange={(e) => updateValue(e, 'transferType')} component={TransferTypeDropDownList} validator={requiredValidator} />
                  </FieldWrapper>

                  {/* <div className={'row mt-3'}> */}
                  <FieldWrapper className={'offset-2 col-5'}>
                    <label className="fsa-field__label">Hold Number</label>
                    <Field
                      name={'holdNumber'}
                      onChange={(e) => updateValue(e, 'holdNumber')}
                      className="fsa-input fsa-field__item"
                      disabled={transferType !== 'Hold'}
                      component={Input}
                    />
                    <Hint className='holdNumberField'>If applicable</Hint>
                  </FieldWrapper>
                  {/* </div> */}
                  {/* <FieldWrapper className={'col-5'}>
                <label className="fsa-field__label">NARA Provided Transfer Number</label>
                <Field
                  name={'NARATransferNumber'}
                  onChange={(e) => updateValue(e, 'NARATransferNumber')}
                  disabled={transferType !== 'NARA'}
                  className="fsa-input fsa-field__item"
                  component={Input}
                />
                <Hint>From ARCIS if applicable</Hint>
              </FieldWrapper>
              <FieldWrapper className={'col-5'}>
                <label className="fsa-field__label">File Code</label>
                <Field name={'fileCode'} onChange={(e) => updateValue(e, 'fileCode')} disabled={transferType !== 'NARA'} className="fsa-input fsa-field__item" component={Input} />
                <Hint>For NARA Transfers Only</Hint>
              </FieldWrapper> */}
                </div>

                <div className={'row mt-4'}>
                  <FieldWrapper className={'col-12'}>
                    <label className="fsa-field__label">General Description*</label>
                    <Field
                      name={'description'}
                      className="fsa-input fsa-field__item"
                      onChange={(e) => updateValue(e, 'description')}
                      component={ValidatedInput}
                      validator={requiredValidator}
                    />
                  </FieldWrapper>
                </div>

                <div className={'row mt-4'}>
                  <FieldWrapper className={'col-4'}>
                    <label className="fsa-field__label">Transfer Media*</label>
                    <Field name={'transferMedia'} onChange={(e) => updateValue(e, 'transferMedia')} component={TransferMediaDropDownList} validator={requiredValidator} />
                  </FieldWrapper>
                  {/* {existingTransfer !== null && (
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Date of Transfer</label>
                  <Field name={'TransferDate'} onChange={(e) => updateValue(e, 'TransferDate')} className="fsa-field__item" component={FormDatepicker} />
                </FieldWrapper>
              )} */}
                  <FieldWrapper className={'col-4'}>
                    <label className="fsa-field__label">Transfer File Size</label>
                    <Field name={'transferFileSize'} onChange={(e) => updateValue(e, 'transferFileSize')} className="fsa-field__item" min={0} component={NumericTextBox} />
                    <Hint>In Gigabytes</Hint>

                    {(data.transferFileSize !== null || data.transferFileSize <= 0) && fileValidation === true && handleCustomErrorMsg('Invalid file size')}
                  </FieldWrapper>

                  <FieldWrapper className={'col-4'}>
                    <label className="fsa-field__label">Full Text Files</label>
                    <Field name={'fullTextFile'} onChange={(e) => updateValue(e, 'fullTextFile')} component={Checkbox} label={'Include Full Text Files'} />
                    <Hint>If available</Hint>
                  </FieldWrapper>
                </div>

                <div className={'row mt-4'}>
                  {/* {existingTransfer !== null && (
                <FieldWrapper className={'col-4'}>
                  <label className="fsa-field__label">Transfer Acceptance Date</label>
                  <Field name={'transferAcceptanceDate'} onChange={(e) => updateValue(e, 'transferAcceptanceDate')} className=" fsa-field__item" component={FormDatepicker} />
                </FieldWrapper>
              )} */}
                </div>
                <div className={'row mt-4'}>
                  <div style={{ display: 'flex' }} className={'col-12 justify-content-end'}>
                    {existingTransfer !== null && Object.keys(existingTransfer).length > 0 && (
                      <button
                        className="fsa-btn mr-auto"
                        onClick={() => {
                          createTransfer();
                        }}
                        disabled={disabled}
                      >
                        Create Transfer Package
                      </button>
                    )}
                    {userPermissions['Update_Transfer'] === true && (
                      <>
                        {existingTransfer == null && (
                          <button onClick={fieldRenderProps.onSubmit} disabled={!fieldRenderProps.allowSubmit} className="fsa-btn">
                            Save
                          </button>
                        )}
                        {existingTransfer !== null && Object.keys(existingTransfer).length > 0 && (
                          <button className="fsa-btn" onClick={onFormUpdate} disabled={!fieldRenderProps.allowSubmit}>
                            Save
                          </button>
                        )}
                      </>
                    )}
                    <button onClick={onClose} className="fsa-btn lite-btn">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        />
      )}
      <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
    </>
  );
}
